import ClientListActive from '@pages/ClientList/Active';
import ClientListInActive from '@pages/ClientList/Inactive';
import ClientlistDetails from '@pages/ClientList/Active/Clientlist-Details';
import Setup from '@pages/ClientList/Active/Clientlist-Details/setup/index';
import MappedCheckpoint from '@pages/ClientList/Active/Clientlist-Details/MappedCheckpoint';
import ClientlistUsers from '@pages/ClientList/Active/Clientlist-Details/Users';
import CheckpointMaster from '@pages/ClientList/Active/Clientlist-Details/CheckpointMaster';
import ClientUserDetailsInfo from '@pages/ClientList/Active/Clientlist-Details/Users/User-Details';

export const ClientListRoutes = [
    {
        path: '/clientlist/active',
        name: 'client_active',
        component: ClientListActive ,
      },
      {
        path: '/add-clientlist/profile/:domain/:id',
        name: 'client_active',
        component: ClientlistDetails ,
      },
      {
        path: '/clientlist/inactive',
        name: 'client_inactive',
        component: ClientListInActive ,
      },
      {
        path: '/add-clientlist/profile/:domain/:id',
        name: 'client_active',
        component: ClientlistDetails ,
      },
      {
        path: '/add-clientlist/profile',
        name: 'client_active',
        component: ClientlistDetails ,
      },
      {
        path: '/add-clientlist/setup/:domain/:id',
        name: 'client_active',
        component: Setup ,
      },
      {
        path: '/add-clientlist/users/:domain/:id',
        name: 'client_active',
        component: ClientlistUsers ,
      },
    
      {
        path: '/add-clientlist/users-add/:domain/:id',
        name: 'client_active',
        component: ClientUserDetailsInfo ,
      },
      {
        path: '/add-clientlist/users-add/:domain/:id/:childId',
        name: 'client_active',
        component: ClientUserDetailsInfo ,
      },
      {
        path: '/add-clientlist/mapped-checkpoint/:domain/:id',
        name: 'client_active',
        component: MappedCheckpoint ,
      },
      {
        path: '/add-clientlist/checkpoint-master/:domain/:id',
        name: 'client_active',
        component: CheckpointMaster ,
      },
];
