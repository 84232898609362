// import DashboardLayout from '@layouts/dashboard/dashboard-layout';
// import ClientListNavigation from '@components/ClientList/clientlist-navigation';
// import { useNavigate, useParams } from "react-router-dom";

// export default function ClientlistUsers() {
//   let navigate = useNavigate();
// 	let params = useParams();
//   return (
//     <DashboardLayout>
//       <ClientListNavigation id={params.id}/>
//     </DashboardLayout>
//   );
// }

import React, { useEffect, useState } from "react";
import {
  adminDeleteSpecificUser,
  adminGetUsers,
  adminUpdateBulkUser,
} from "@services/users.service";
import { getValue } from "@utils/lodash";
import qs from "query-string";
import "./user.scss";
import { NavLink } from "react-router-dom";
import moment from "moment";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate, useParams } from "react-router-dom";
import SearchHeader from "@components/common/SearchHeader";
import PaginationPage from "@components/pagination/pagination";
import DataTable from "@components/DataTable/index";
import NavigationBar from "@components/NotificationBar";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import DeleteModal from "@_common/deleteModal";
import Notfound from "@components/Not-Found";
import ClientListNavigation from "@components/ClientList/clientlist-navigation";
import {
  adminGetClientUsers,
  adminGetClientUsersNew,
  adminResendInviteClientUsers,
} from "@services/client-list.service";
import Loader from "@components/common/Loader";
import NoAccessPage from "@components/common/NoAccess";
import ScrollableReactTable from "@components/ScrollableDatatable";

interface IAdminUsersProps {}

const ClientlistUsers: React.FunctionComponent<IAdminUsersProps> = (
  props: any
) => {
  let navigate = useNavigate();
  let orgParams = useParams();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [usersList, setUsersList] = React.useState<[]>([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.search_text) {
        setSearchInput(params.search_text);
        setShowSearchTextbox(true);
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let payload: any = {
      page_no: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : 10,
      search_text: params.search_text,
      status: params.status,
      sort_by: params.sort_by,
      user_type: "CLIENT",
      org_id: orgParams.id,
      domain_name: orgParams.domain,
    };
    // removing null values
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload);

    try {
      setIsLoading(true);
      let resp = await adminGetClientUsersNew(orgParams.domain, queryRequest);
      if (resp) {
        setTotalCount(getValue(resp, `data.data.pagination.total`, 0));
        setUsersList(
          getValue(resp, `data.data.users`, []).map((item: object) => ({
            ...item,
            status: getValue(item, `is_active`, "") ? "Active" : "Inactive",
            createdAtFormatted: getValue(item, `created_at`, "")
              ? moment(getValue(item, `created_at`, "")).format("DD MMM YYYY")
              : "",
            last_signed_inFormatted: getValue(item, `last_signed_in`, "")
              ? moment(getValue(item, `last_signed_in`, "")).format(
                  "DD MMM YYYY, hh:mm a"
                )
              : "",
          }))
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      // removing null values
      Object.keys(payload).forEach((key) => {
        if (!payload[key] || payload[key] === undefined) {
          delete payload[key];
        }
      });
      let queryRequest = qs.stringify(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    setSearchInput(e.target.value);

    let payload: any = {
      search_text: e.target.value,
    };
    // removing null values
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeSortBy = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.sort_by = value;
    props.history.push({
      pathname: window.location.pathname,
      query: params,
    });
  };

  const handleChangeStatus = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.status = value;
    props.history.push({
      pathname: window.location.pathname,
      query: params,
    });
  };

  const [selectedIDs, setSelectedIDs] = useState<any>([]);
  const getTableState = (value: object) => {
    let selectedIndexList = Object.keys(
      getValue(value, `selectedRowIds`, {})
    ).map((item) => parseInt(item));
    let selectedIds =
      usersList.length > 0
        ? usersList.filter((_item: object, index: number) =>
            selectedIndexList.includes(index)
          )
        : [];
    if (selectedIds.length > 0) {
      setSelectedIDs(
        selectedIds.map((item: object) => getValue(item, `id`, ""))
      );
    } else {
      setSelectedIDs([]);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await adminDeleteSpecificUser(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.page_no = page_no;
    Object.keys(params).forEach((key) => {
      if (!params[key] || params[key] === undefined) {
        delete params[key];
      }
    });
    let queryRequest = qs.stringify(params);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.limit = limit;
    Object.keys(params).forEach((key) => {
      if (!params[key] || params[key] === undefined) {
        delete params[key];
      }
    });
    let queryRequest = qs.stringify(params);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleEdit = (id: string) => {
    navigate(`/edit-user/${id}`);
  };

  const handleChangeActiveStatus = async (e: any, id: string) => {
    e.stopPropagation();
    try {
      let resp = await adminResendInviteClientUsers({
        ids: [id],
        domain_name: orgParams.domain,
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        getData();
      }
    } catch (error) {}
  };

  const handleChangeBulkActiveStatus = async (is_active: any) => {
    try {
      let resp = await adminUpdateBulkUser({
        ids: selectedIDs,
        domain_name: getValue(orgParams, `domain`, ""),
        is_active: is_active === "active" ? true : false,
      });
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSelectedIDs([]);
        getData();
      }
    } catch (error) {}
  };

  const rowClick = (obj: object) => {
    navigate(
      `/add-clientlist/users-add/${orgParams.domain}/${orgParams.id}/${getValue(
        obj,
        `original.id`,
        ""
      )}`
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "User ID",
        accessor: "email",
        width: 250,
        Cell: ({ row }: any) => {
          return (
            <NavLink
              to={`/add-clientlist/users-add/${orgParams.domain}/${orgParams.id}/${row.original.id}`}
            >
              {row.values.email}
            </NavLink>
          );
        },
      },
      {
        Header: "First Name",
        accessor: "first_name",
        width: 250,
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        width: 250,
      },
      {
        Header: "Status",
        accessor: "status",
        width: 250,
      },
      {
        Header: "Added on",
        accessor: "createdAtFormatted",
        width: 250,
      },
      {
        Header: "Last Signed-in",
        accessor: "last_signed_inFormatted",
        width: 250,
      },
      {
        Header: "Action",
        width: 200,
        Cell: ({ row }: any) => {
          return (
            !row.original.is_verified && (
              <>
                <button
                  className="resend-btn"
                  onClick={(e: any) =>
                    handleChangeActiveStatus(e, row.original.id)
                  }
                >
                  <img src="/images/resend.svg" className="img-fluid d-block" />
                  Resend
                </button>
                {/* &nbsp;&nbsp;&nbsp;
							<Button
								color="danger"
								onClick={() =>
									handleDeleteFun(
										row.original.id,
										row.values.first_name + " " + row.values.last_name
									)
								}
							>
								Delete
							</Button> */}
              </>
            )
          );
        },
      },
    ],
    []
  );

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };
  const getTrProps = (rowInfo: any) => {
    return {};
  };
  return (
    <>
      <DashboardLayout
        permissions={getValue(props, `tabPermissionList`, [])}
        subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
      >
        {!isLoading &&
        !getValue(props, `subTabPermissionList`, []).includes(
          "client_active"
        ) ? (
          <NoAccessPage />
        ) : (
          <>
            {getValue(selectedIDs, `length`, 0) > 0 && (
              <NavigationBar
                selectedIDs={selectedIDs}
                handleRemoveNavItems={handleRemoveNavItems}
                handleChangeActiveStatus={handleChangeBulkActiveStatus}
                multi
                permissions={props.permissions}
                //  resendInvite={true}
              />
            )}
            <ClientListNavigation id={orgParams.id} domain={orgParams.domain} />

            <div className="admin-wrapper position-relative">
              <SearchHeader
                route={`/add-clientlist/users-add/${orgParams.domain}/${orgParams.id}`}
                searchInput={search_text}
                showSearchTextbox={showSearchTextbox}
                toggleSearch={toggleSearch}
                toggleSearchClose={toggleSearchClose}
                handleChangeSearch={handleChangeSearch}
                add={
                  getValue(props, `permissions`, []).includes("create")
                    ? "true"
                    : "false"
                }
              />
            </div>

            <div className="admin-wrapper">
              {isLoading || !props.permissionAPITriggered ? (
                <Loader />
              ) : usersList.length > 0 ? (
                <ScrollableReactTable
                  columns={columns}
                  data={usersList}
                  getTableState={getTableState}
                  rowClick={rowClick}
                  check={check}
                  selectedIDs={selectedIDs}
                  getTrProps={getTrProps}
                />
              ) : (
                <>
                  <>
                    {Object.keys(params).length !== 0 ? (
                      <Notfound />
                    ) : (
                      <Notfound />
                    )}
                  </>
                </>
              )}
            </div>
            <div className="footer-wrapper d-flex  justify-content-between">
              {usersList.length > 0 && (
                <img
                  src="/images/building-illustration.svg"
                  className="img-fluid"
                />
              )}
              {totalCount > 10 ? (
                <PaginationPage
                  totalCount={totalCount}
                  limit={limit}
                  pageNumber={page_no}
                  handleChangePagination={handleChangePagination}
                  handleChangeLimit={handleChangeLimit}
                />
              ) : (
                ""
              )}
            </div>
            <DeleteModal
              isOpen={isDelete}
              handleModal={handleDelete}
              handleSubmit={handleDeleteFunction}
              deleteValue={deleteValue}
            />
          </>
        )}
      </DashboardLayout>{" "}
    </>
  );
};

export default ClientlistUsers;
