export function authHeaderClient(url,domain) {
	// return authorization header with jwt token
	let access_token = localStorage.getItem("accessToken");
	let port = window.location.origin.split("//")[1].split(":")[0];

	if (access_token) {
		return {
			Authorization: "Bearer " + access_token,
			"Content-Type": "application/json",
			source:domain?domain:
				port === "localhost"
					? "client.dev.lexcare.in"
					: window.location.origin?.split("//")[1],
		};
	} else {
		return {
			"Content-Type": "application/json",
			source:domain?domain:
				port === "localhost"
					? "client.dev.lexcare.in"
					: window.location.origin?.split("//")[1],
		};
	}
}
