import FileLogs from '@pages/Admin/FileLogs';
import LegalUpdate from '@pages/Admin/LegalUpdate';
import LegalUpdateDetailsInfo from '@pages/Admin/LegalUpdate/Create';
import AdminRoles from '@pages/Admin/Roles';
import RoleDetails from '@pages/Admin/Roles/Role-Details';
import AdminUsers from '@pages/Admin/Users';
import UserDetailsInfo from '@pages/Admin/Users/User-Details';
import DocumentLibrary from '@pages/DocumentLibrary';
import DocumentLibraryDetails from '@pages/DocumentLibrary/create';

export const AdminRoutes = [
  // admin > Users
  { path: "/admin/users", name: "admin_user", component: AdminUsers },
  {
    path: "/add-user",
    name: "admin_user",
    component: UserDetailsInfo,
  },
  {
    path: "/edit-user/:id",
    name: "admin_user",
    component: UserDetailsInfo,
  },

  // admin > Roles
  { path: "/admin/roles", name: "admin_role", component: AdminRoles },

  // admin > Legal Update
  {
    path: "/legal-update",
    name: "legal_update_legal_update",
    component: LegalUpdate,
  },
  {
    path: "/legal-update/add",
    name: "legal_update_legal_update",
    component: LegalUpdateDetailsInfo,
  },
  {
    path: "/legal-update/edit/:id",
    name: "legal_update_legal_update",
    component: LegalUpdateDetailsInfo,
  },
  {
    path: "/add-role",
    name: "admin_role",
    component: RoleDetails,
  },
  {
    path: "/edit-role/:id",
    name: "admin_role",
    component: RoleDetails,
  },
  { path: "/file-logs/:type", name: "client_active", component: FileLogs },

  { 
    path: "/document-library", 
    name: "admin_role", 
    component: DocumentLibrary 
  },

  { 
    path: "/document-library/add", 
    name: "admin_role", 
    component: DocumentLibraryDetails 
  },
  {
    path: "document-library/edit/:id",
    name: "admin_role",
    component: DocumentLibraryDetails,
  },
];
