import CommonDataImported from "./popup/DataImported";
import CommonImportData from "./popup/importdata";

export default function CommonImportDataPopup(props: any) {
	const { isDataImported } = props;
	return (
		<section className="popup-wrapper d-flex">
			{!isDataImported && (
				<CommonImportData
					handleUpload={props.handleUpload}
					handleChangeUpload={props.handleChangeUpload}
					importedSuccessfully={props.importedSuccessfully}
					closePopup={() => {
						props.closePopup();
					}}
					importedFile={props.importedFile}
					setImportTedFile={props.setImportTedFile}
					handleDownload={props.handleDownload}
					submitLoading={props.submitLoading}
					url={props.url}
					type={props.type}
				/>
			)}
			{isDataImported && (
				<CommonDataImported
					closePopup={() => {
						props.closePopup();
					}}
					response={props.response}
					type={props.type}
				/>
			)}
		</section>
	);
}
