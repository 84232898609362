import { authHeaderClient } from './client-auth-header';
import { authHeaderOnlyToken } from './auth-header-collector';

import { toast } from 'react-toastify';
import { getValue } from '@utils/lodash';

let access_token = localStorage.getItem('accessToken');

export const postClient = (url, payload,domain) => {
  const requestOptions = {
    method: 'POST',
    headers: authHeaderClient(url,domain),
    body: JSON.stringify(payload),
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const postFileUploadClient = (url, payload) => {
  const requestOptions = {
    method: 'POST',
    headers: { Authorization: 'Bearer ' + access_token },
    body: payload,
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const patchFileUpload = (url, payload) => {
  const requestOptions = {
    method: 'PATCH',
    headers: { Authorization: 'Bearer ' + access_token },
    body: payload,
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const patchClient = (url, payload,domain) => {
  const requestOptions = {
    method: 'PATCH',
    headers: authHeaderClient(url,domain),
    body: JSON.stringify(payload),
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const putClient = (url, payload) => {
  const requestOptions = {
    method: 'PUT',
    headers: authHeaderClient(url),
    body: JSON.stringify(payload),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const DeleteClient = (url) => {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeaderClient(url),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const getClient = (url,domain) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: authHeaderClient(url,domain),
    };
    return fetch(`${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  } catch (error) {
  }
};

export const getPayloadClient = (url, payload) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeaderClient(url),
    body: JSON.stringify(payload),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

function logout() {
  localStorage.clear();
}

export const getOnlyTokenClient = (url) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: authHeaderOnlyToken(url),
    };
    return fetch(`${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  } catch (error) {
  }
};

export const postOnlyTokenClient = (url, payload) => {
  try {
    const requestOptions = {
      method: 'POST',
      headers: authHeaderOnlyToken(url),
      body: JSON.stringify(payload),
    };
    return fetch(`${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  } catch (error) {
  }
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.href = (`/login`)
      }
      const error = (data && data.message) || response.statusText;

      // toast.error(error, { className: 'red-circle' });
      if (typeof data.message === "string") {
				const error = (data && data.message) || response.statusText;
				toast.error(error);
			} else {
				getValue(data, `message`, []).forEach((item) => {
					toast.error(item);
				});
			}
      return Promise.reject(error);
    }

    return data;
  });
}
