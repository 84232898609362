import Switch from "../switch";
import { getValue } from "@utils/lodash";

export default function AddLocation(props: any) {
	return (
		<div
			className={`list-managemnet-wrapper__country-items d-flex align-items-center justify-content-between ${getValue(props, `selected`, "") == getValue(props, `id`, "")
					? "active"
					: ""
				}`}
			onClick={() => props.onChange(getValue(props, `id`, ""))}
		>
			<h6 className="list-managemnet-wrapper__country-items-title">
				{props.name}<br />
				{getValue(props, `category_of_law.text`, '') &&
					<span style={{ color: "#d7242e" }}>{getValue(props, `category_of_law.text`, '')}</span>}
			</h6>
			{getValue(props,`permissions`,[]).includes('update')&&
			<div className="list-managemnet-wrapper__country-items-alter d-flex align-items-center">
				<img
					src="/images/list-management/country/edit-icon.svg"
					width={24}
					height={24}
					className="img-fluid"
					onClick={() => props.handleEdit(getValue(props, `item`, ""))}
				/>
				 {/* <img
					src="/images/list-management/country/delete-icon.svg"
					className="img-fluid"
					width={24}
					height={24}
					onClick={() => props.handleDelete(getValue(props, `item`, ""))}
				/>  */}
				{/* <Switch
					is_active={getValue(props, `is_active`, "")}
					onChange={props.handleChangeStatus}
					id={getValue(props, `id`, "")}
				/> */}
			</div>
}
		</div>
	);
}
