import './comments-section.scss';

export default function Comments() {
  return (
    <section className="add-comments-wrapper">
      <div className="add-comments-wrapper__form-group form-group d-flex align-items-center">
        <label className="add-comments-wrapper__label">
          <h6>R</h6>
        </label>

        <textarea rows={3} className="w-100 add-comments-wrapper__textarea">
          Add a Comment
        </textarea>
      </div>
      <div className="add-comments-wrapper__cta-wrapper d-flex">
        <button className="add-comments-wrapper__cta-btn mx-3">Cancel</button>
        <button className="add-comments-wrapper__cta-btn">Comment</button>
      </div>
    </section>
  );
}
