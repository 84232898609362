import { getValue } from "@utils/lodash";
import "./index.scss";
import { NavLink } from "react-router-dom";
export default function CommonDataImported(props: any) {
	const handleDownload = (url: string) => {
		if (url) {
			var link = document.createElement("a");
			link.target = "_blank";
			link.download = "Contact";
			link.href = url;
			link.click();
		}
	};
	return (
		<>
			<div className="popup-wrapper__details text-center">
				<div className="popup-wrapper__close-btn">
					<img
						src="/images/close-black.svg"
						className="img-fluid cursor-pointer"
						onClick={() => {
							props.closePopup();
						}}
					/>
				</div>
				<h6 className="popup-wrapper__data-imported">Data Imported</h6>
				<img src="/images/popup/transfer-files.svg" className="img-fluid" />
				<h5 className="popup-wrapper__records">
					{/* {getValue(props, `response.counts.totalRecords`, 0)} Records Found */}
				</h5>
				<p>
					{/* {getValue(props, `response.counts.failedRecords`, 0)} Records Import
					Failed -{" "} */}
					<NavLink
						to={`/file-logs/${getValue(props,`type`,'') === "CHECKPOINT_MASTER" ? "CHECKPOINT" : getValue(props,`type`,'')}`}
						style={{ color: "blue", cursor: "pointer" }}
					>
						Click here to view status
					</NavLink>
				</p>
			</div>
		</>
	);
}
