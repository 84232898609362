import "./filter-form.scss";
// import AddFilterNew from "../AddFilterNew";
import InputField from "@_common/InputRuleFeild";
import SearchableDropdownSelect from "@_common/SearchableDropdown";
import { getValue } from "@utils/lodash";
import { Col, Row } from "reactstrap";

export default function LegalFilterForm(props: any) {
	const {
		handleChangeSelect,
		countryList,
        statesList,
        handleChangeStateMulti,
        selectedStatesListIds,
		states,
		request,
		//loading...
		categoryLawLoading,
		legislationLoading,
		legislationRuleLoading,
		resetInput
	} = props;
	return (
		<div className="filter-form-wrappper w-100 active">
			<div className="filter-form-wrappper__container">
				<div className="d-flex align-items-center justify-content-between my-3">
					<h6 className="filter-form-wrappper__title">Filter by</h6>
					<img
						src="/images/close-black.svg"
						className="img-fluid cursor-pointer"
						onClick={props.setLegalFilterFormVisibility}
					/>
				</div>
				<div className="d-flex justify-content-between gap">
					<div className="filter-form-wrappper__location flex-1">
						<div className="filter-form-wrappper__location-form-group form-group">
							<label htmlFor="country" className="filter-form-wrappper__label">
								Country
							</label>
							<div className="filter-form-wrappper__location-input">
								{/* <input
                  type="text"
                  placeholder="Choose Company Name"
                  id="country"
                  className="filter-form-wrappper__form-control form-control"
                />
                <img
                  src="/images/arrow-drop.svg"
                  className="img-fluid filter-form-wrappper__image"
                /> */}
								<InputField
									inputType="INPUT_REACT_SELECT"
									placeholder="Choose Country"
									className="form-control w-100"
									name="country_id"
									id="country_id"
									options={countryList}
									value={props.country_ID}
									onChange={(e: any) => handleChangeSelect(e, "country_id")}
									label="Country"
								/>
							</div>
						</div>
						<div className="filter-form-wrappper__location-form-group form-group">
							<label htmlFor="state" className="filter-form-wrappper__label">
								State
							</label>
							{/* <div className="filter-form-wrappper__location-input">
								<InputField
									inputType="INPUT_REACT_SELECT"
									placeholder="Choose State"
									className="form-control w-100"
									name="state_id"
									id="state_id"
									label="Last Name"
									options={states}
									value={props.state_ID}
									onChange={(e: any) => handleChangeSelect(e, "state_id")}
								/>
							</div> */}
                            <div className="filter-form-wrappper__location-input select-dd-new">
                            <InputField
                              inputType="INPUT_REACT_SELECT"
                              placeholder="Choose State"
                              className="form-control w-100"
                              name="state_id"
                              id="state_id"
                              label="Last Name"
                              options={statesList}
                              // options={states}
                              value={selectedStatesListIds}
                              // value={props.state_ID}
                              onChange={(e: any) =>
                                handleChangeStateMulti(e)
                              }
                              // onChange={(e: any) =>
                              //   handleChangeSelect(e, "state_id")
                              // }
                              // isClear
                              isMulti
                            />
                          </div>
						</div>
					</div>
					<div className="filter-form-wrappper__location flex-2">
						<div className="filter-form-wrappper__location-form-group form-group">
							<label htmlFor="law" className="filter-form-wrappper__label">
								Category of Law
							</label>
							
						</div>
						
					</div>
				</div>
			</div>

			{/* <AddFilterNew /> */}
		</div>
	);
}
