import { getValue } from "@utils/lodash";
import "./notificationbar.scss";
import Dropdown from "@common/Dropdown";
export default function NavigationBar(props: any) {
	return (
		<section className="navigation-bar-wrapper">
			<div className="d-flex align-items-center justify-content-between">
				<div className="d-flex align-items-center">
					<p className="navigation-bar-wrapper__selected">
						{getValue(props, `selectedIDs.length`, 0)} Selected
					</p>
					{props.isFilterVisisbile && (
						// getValue(props,`permissions`,[]).includes('update')&&
						<>
							<Dropdown
								filterOptions={props.filterOptions}
								handleUpdateStatus={props.handleUpdateStatus}
							/>
							{/* <div className="filter-checklist d-flex align-items-center">
								<img src="/images/filter-white.svg" className="img-fluid" />
								<p className="navigation-bar-wrapper__selected cursor-pointer">
									FILTER SIMILAR CHECKLIST
								</p>
							</div> */}
						</>
					)}

					{props.isMarkInactiveSectionVisible && (
						getValue(props,`permissions`,[]).includes('update')&&
						<div
							className="navigation-bar-wrapper__inactive-count d-flex cursor-pointer"
							onClick={() => props.handleChangeActiveStatus()}
						>
							<img src="/images/assignment.svg" className="img-fluid" />
							<h6>
								{getValue(props, `text`, "")
									? getValue(props, `text`, "")
									: "MARK INACTIVE"}
							</h6>
						</div>
					)}

					{props.multi &&
						getValue(props, `permissions`, []).includes("update") && (
							<>
								<div
									className="navigation-bar-wrapper__inactive-count d-flex cursor-pointer"
									onClick={() => props.handleChangeActiveStatus("active")}
								>
									<img src="/images/assignment.svg" className="img-fluid" />
									<h6>
										{getValue(props, `text`, "")
											? getValue(props, `text`, "")
											: "MARK ACTIVE"}
									</h6>
								</div>
								<div
									className="navigation-bar-wrapper__inactive-count d-flex cursor-pointer"
									onClick={() => props.handleChangeActiveStatus("inactive")}
								>
									<img src="/images/assignment.svg" className="img-fluid" />
									<h6>
										{getValue(props, `text`, "")
											? getValue(props, `text`, "")
											: "MARK INACTIVE"}
									</h6>
								</div>
							</>
						)}

					{props.resendInvite && (
						<div
							className="navigation-bar-wrapper__inactive-count d-flex cursor-pointer"
							onClick={props.handleChangeActiveStatus}
						>
							<img src="/images/assignment.svg" className="img-fluid" />
							<h6>
								{getValue(props, `text`, "")
									? getValue(props, `text`, "")
									: "Resend Invite"}
							</h6>
						</div>
					)}

					{props.assign &&
						getValue(props, `permissions`, []).includes("update") && (
							<div
								className="navigation-bar-wrapper__inactive-count d-flex cursor-pointer"
								onClick={props.handleChangeAssign}
							>
								<img src="/images/assignment.svg" className="img-fluid" />
								<h6>
									{getValue(props, `text`, "")
										? getValue(props, `text`, "")
										: "ASSIGN CHECKPOINTS"}
								</h6>
							</div>
						)}
				</div>
				<img
					src="/images/close-white.svg"
					className="img-fluid navigation-bar-wrapper__close-btn"
					onClick={props.handleRemoveNavItems}
				/>
			</div>
		</section>
	);
}
