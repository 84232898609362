import OnPremClientList from "@pages/On-Prem/Active";
import OnPremClientlistDetails from "@pages/On-Prem/Active/OnPrem-Details";
import OnPremClientlistUsers from "@pages/On-Prem/Active/OnPrem-Details/Users";
import OnPremClientUserDetailsInfo from "@pages/On-Prem/Active/OnPrem-Details/Users/User-Details";
import OnPremSetup from "@pages/On-Prem/Active/OnPrem-Details/setup";
import OnPremClientListInActive from "@pages/On-Prem/Inactive";

export const OnPremRoutes = [
  {
    path: "/on-prem/client-list/active",
    name: "on_prem_client_active",
    component: OnPremClientList,
  },
  {
    path: "/on-prem/add-clientlist/profile/:domain/:id",
    name: "on_prem_client_active",
    component: OnPremClientlistDetails,
  },
  {
    path: "/on-prem/client-list/inactive",
    name: "on_prem_client_inactive",
    component: OnPremClientListInActive,
  },
  {
    path: "/on-prem/add-clientlist/profile/:domain/:id",
    name: "on_prem_client_active",
    component: OnPremClientlistDetails,
  },
  {
    path: "/on-prem/add-clientlist/profile",
    name: "on_prem_client_active",
    component: OnPremClientlistDetails,
  },
  {
    path: "/on-prem/add-clientlist/setup/:domain/:id",
    name: "on_prem_client_active",
    component: OnPremSetup,
  },
  {
    path: "/on-prem/add-clientlist/users/:domain/:id",
    name: "on_prem_client_active",
    component: OnPremClientlistUsers,
  },

  {
    path: "/on-prem/add-clientlist/users-add/:domain/:id",
    name: "on_prem_client_active",
    component: OnPremClientUserDetailsInfo,
  },
  {
    path: "/on-prem/add-clientlist/users-add/:domain/:id/:childId",
    name: "on_prem_client_active",
    component: OnPremClientUserDetailsInfo,
  },
];
