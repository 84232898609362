import * as React from 'react';
import { toast } from 'react-toastify';
import { Col, Input, Modal, Row } from 'reactstrap';
import {
	adminCreateListManagementStateCategoryList,
	adminCreateListManagementStateCategoryListNew,
	adminGetListManagementGroupCode,
	adminGetListManagementStateCategoryList,
} from '@services/list-management.service';
import { getValue } from '@utils/lodash';
import '../popup/addpopup.scss';

interface ICategoryPopupProps {
	isOpen: boolean;
	handleModal: () => void;
	selectedState: any;
}

const CategoryPopup: React.FunctionComponent<ICategoryPopupProps> = (props:any) => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [groups, setGroups] = React.useState([]);
	const [selectedCategory, setSelectedCategory] = React.useState<any>([]);
	React.useEffect(() => {
		getData();
	}, [props.permissionAPITriggered]);

	React.useEffect(() => {
		if (props.selectedState) {
			getAlreadyAttachedData();
		}
		// eslint-disable-next-line
	}, [props.selectedState]);
	// category-of-law
	const getData = async () => {
		try {
			setIsLoading(true);
			let resp = await adminGetListManagementGroupCode('category-of-law');
			if (resp) {
				setGroups(getValue(resp, `data.listItems`, []));
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	const getAlreadyAttachedData = async () => {
		try {
			// setIsLoading(true);
			let resp = await adminGetListManagementStateCategoryList(
				props.selectedState
			);
			if (resp) {
				setSelectedCategory(
					getValue(resp, `data.stateCategoryOfLaw`, []).map((item: object) =>
						getValue(item, `category_of_law.id`, '')
					)
				);
			} else {
				// setIsLoading(false);
			}
		} catch (error) {
			// setIsLoading(false);
		}
	};

	const handleSubmit = async () => {
		try {
			let payload = {
				state_id: parseInt(props.selectedState),
				category_of_laws: selectedCategory,
			};
			let resp = await adminCreateListManagementStateCategoryListNew(payload);
			if (resp) {
				toast.success(getValue(resp, 'message', ''));
				props.handleModal();
				getAlreadyAttachedData();
			}
		} catch (error) {}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let selected = selectedCategory.filter((item: object) => item === e);
		if (getValue(selected, `length`, 0) > 0) {
			let selected = selectedCategory.filter((item: object) => item !== e);
			setSelectedCategory(selected);
		} else {
			selectedCategory.push(e);
			setSelectedCategory([...selectedCategory]);
		}
	};
	return (
		<Modal
			isOpen={getValue(props, `isOpen`, '')}
			toggle={getValue(props, `isOpen`, '')}
			centered
		>
			<section className="add-popup-wrapper">
				<div className="add-popup-wrapper__popup add-popup-wrapper__popup--no-max-height">
					<div className="add-popup-wrapper__form-group form-group">
						<h3 className="text-center mb-3">Map Category Of Law</h3>
						{isLoading ? (
							<p className="text-center mt-2">Please wait...</p>
						) : getValue(groups, `length`, 0) > 0 ? (
							<div className="form-group">
								{groups.map((item, index) => {
									return (
										<>
											<div className="d-flex align-items-center mb-2">
												<label
													className="m-0 position-relative"
													htmlFor={`category-${getValue(item, `id`, '')}`}
												>
													<input
														type="checkbox"
														className="default-checkbox"
														id={`category-${getValue(item, `id`, '')}`}
														onChange={() =>
															handleChange(getValue(item, `id`, ''))
														}
														checked={selectedCategory.includes(
															getValue(item, `id`, '')
														)}
													/>
													<div className="custom-checkbox">
														<img
															src="/images/uploaded-details/tick.svg"
															className="img-fluid uploaded-tick"
														/>
													</div>
												</label>
												<div className="mx-3">
													<label
														className="m-0 cursor-pointer text-capitalize"
														htmlFor={`category-${getValue(item, `id`, '')}`}
													>
														{getValue(item, `text`, '')}
													</label>
												</div>
											</div>
										</>
									);
								})}
							</div>
						) : (
							<p className="text-center mt-2">No Data Found</p>
						)}
					</div>
					<div className="add-popup-wrapper__cta-wrapper">
						<button
							onClick={props.handleModal}
							className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
						>
							Cancel
						</button>
						<button
							className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
							onClick={handleSubmit}
						>
							Save
						</button>
					</div>
				</div>
			</section>
		</Modal>
	);
};

export default CategoryPopup;
