import "./index.scss";
import LoginPageLyout from "@layouts/login";
import { useState, useRef, useEffect } from "react";
import { adminLogin, getCapchaByID } from "@services/auth.service";
import { NavLink } from "react-router-dom";
import { getValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import InputField from "@_common/InputRuleFeild";
import { toast } from "react-toastify";
import { aesGcmEncrypt, getHmac } from "@_common/text-helpers";
import {nanoid} from "nanoid";
import { QueryRequestHelper } from "@_common/query-request-helper";
import { config } from "../../../env";
import RefreshSvgComponent from "../../../assets/svg/refresh";
interface ILoginPageProps {}

const LoginPage: React.FunctionComponent<ILoginPageProps> = (props: any) => {
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rid, setRid] = useState("");
  const [captcha_enable, setCaptcha_enable] = useState(false);
  const [captcha_text, setCaptcha_text] = useState("");

  useEffect(() => {
    let rid: any = nanoid();
    setRid(rid);
    //
    let count: any = getValue(localStorage, `cn`, "")
      ? parseInt(getValue(localStorage, `cn`, ""))
      : 0;
    if (count === 3 || count > 2) {
      setCaptcha_enable(true);
      getCapcha(rid);
    }
  }, []);

  const [capchaImage, setCapchaImage] = useState("");
  const getCapcha = async (id: string) => {
    try {
      let payload = {
        rid: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getCapchaByID(queryRequest);
      if (resp) {
        setCapchaImage(getValue(resp, `data.captcha_image`, ""));
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      localStorage.setItem("hash", rid);
      let pass = await aesGcmEncrypt(password);
      let hashedRid = await getHash(localStorage.getItem("hash"));
      try {
        setIsLoading(true);
        let payload: any = {
          email: email,
          encrypted_password: pass,
          rid: rid,
          captcha_text: captcha_text,
        };
        Object.keys(payload).forEach((key) => {
          if (!payload[key] || payload[key] === undefined) {
            delete payload[key];
          }
        });
        let resp = await adminLogin(payload);
        if (resp) {
          if (getValue(resp, `data.rid_hash`, "") !== hashedRid) {
            localStorage.removeItem("hash");
            toast.error("Oops! Please try again later.");
            setIsLoading(false);
          } else {
            localStorage.removeItem("cn");
            setIsLoading(false);
            localStorage.setItem(
              "accessToken",
              getValue(resp, `data.accessToken`, "")
            );
            localStorage.setItem(
              "refreshToken",
              getValue(resp, `data.refreshToken`, "")
            );
            localStorage.setItem(
              "user",
              JSON.stringify(getValue(resp, `data.user`, ""))
            );
            window.location.href = `/audit/overview`;
            localStorage.removeItem("hash");
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        let rid: any = nanoid();
        setRid(rid);
        let count: any = getValue(localStorage, `cn`, "")
          ? parseInt(getValue(localStorage, `cn`, ""))
          : 0;
        localStorage.setItem("cn", count ? count + 1 : 1);
        if (count === 2 || count > 2) {
          setCaptcha_enable(true);
          getCapcha(rid);
        }
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && e.target.tagName.toLowerCase() !== "button") {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const getHash = async (id: any) => {
    // console.log(nanoid(), await sha256(nanoid()));
    return await getHmac(id, config.AUTH_SHARED_KEY);
  };
  return (
    <>
      <LoginPageLyout>
        <div className="m-auto form-wrapper__form">
          <h2 className="form-wrapper__form-title">Sign in to Lexcare</h2>
          <div className="form-group">
            <label htmlFor="email">EmaiI</label>
            <InputField
              inputType="TEXT"
              id="email"
              name="email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              placeholder="Enter Email"
              className="form-control"
              label="Email"
              validator={simpleValidator}
              onKeyDown={handleKeyDown}
              />
          </div>
          <div className="form-group">
            <label>Password</label>
            <InputField
              inputType="PASSWORD"
              id="password"
              name="password"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              placeholder="Enter Password"
              className="form-control"
              validator={simpleValidator}
              label={"Password"}
              onKeyDown={handleKeyDown}
            />
          </div>
          {captcha_enable && capchaImage && (
              <div>
                <div className=" position-relative">
                  <div
                    dangerouslySetInnerHTML={{ __html: capchaImage }}
                    className="captcha_container"
                  />
                  <div
                    className="captcha_reload"
                    onClick={() => getCapcha(rid)}
                  >
                    <RefreshSvgComponent />
                  </div>
                </div>
                <input
                  type="text"
                  className="captcha_input"
                  name={"captcha_text"}
                  value={captcha_text}
                  onChange={(e: any) => setCaptcha_text(e.target.value)}
                  placeholder="Enter Captcha"
                />
              </div>
            )}
          <div className="form-cta-button">
            <NavLink to="/reset-password">
              <button className="form-cta-button__secondary-btn">
                Forgot password?
              </button>
            </NavLink>
            {isLoading ? (
              <button className="form-cta-button__primary-btn">
                Please wait...
              </button>
            ) : (
              <button
                onClick={handleSubmit}
                className="form-cta-button__primary-btn"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </LoginPageLyout>
    </>
  );
};

export default LoginPage;
