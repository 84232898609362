import {  Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface DialogBoxProps {
	showDialog: boolean;
	cancelNavigation: any;
	confirmNavigation: any;
}

const PromptModal: React.FC<DialogBoxProps> = ({
	showDialog,
	cancelNavigation,
	confirmNavigation,
}) => {
	return (
		<Modal isOpen={showDialog}>
			<ModalHeader>
				<h5>Are you sure?</h5>
			</ModalHeader>
			<ModalBody>
				Are you sure you want to leave this page? All your modifications will be
				lost
			</ModalBody>
			<ModalFooter>
				<button
					className="ascent-button ascent-button--header-buttons ascent-button--primary"
					onClick={cancelNavigation}
				>
					No
				</button>
				<button
					className="ascent-button  ascent-button--header-buttons ascent-button--secondary"
					onClick={confirmNavigation}
				>
					Yes
				</button>
			</ModalFooter>
		</Modal>
	);
};
export default PromptModal;
