import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getTaskComments = (queryRequest:string) => get(`${config.API_URL}/audit/comments?${queryRequest}`);

export const getSpecificTaskComment = (id: string) =>
	get(`${config.API_URL}/audit/comments/${id}`);

export const addTaskComments = (payload: object) =>
	post(`${config.API_URL}/audit/comments`, payload);

export const updateTaskComments = (id: string, payload: object) =>
	patch(`${config.API_URL}/audit/comments/${id}`, payload);

export const deleteTaskComments = (id: String) =>
	Delete(`${config.API_URL}/audit/comments/${id}`);
