import React, { useState, useEffect } from "react";
import qs from "query-string";
import { getValue } from "@utils/lodash";
import {
	adminDeleteSpecificRole,
	adminGetRoles,
} from "@services/roles.service";
import Notfound from "@components/Not-Found";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "@components/DataTable/index";
import NavigationBar from "@components/NotificationBar";
import PaginationPage from "@components/pagination/pagination";
import "./roles.scss";
import SearchHeader from "@components/common/SearchHeader";
import { NavLink } from "react-router-dom";
import { Button } from "reactstrap";
import DeleteModal from "@_common/deleteModal";
import { toast } from "react-toastify";
import Loader from "@components/common/Loader";
import moment from "moment";
import { adminBulkRoleUpdateStatus } from "@services/auth.service";
import NoAccessPage from "@components/common/NoAccess";
import ScrollableReactTable from "@components/ScrollableDatatable";

interface IAdminRolesProps {}

const AdminRoles: React.FunctionComponent<IAdminRolesProps> = (props: any) => {
	let navigate = useNavigate();

	/* -------------------------------------------------------------------------- */
	/*                               UseState Section                             */
	/* -------------------------------------------------------------------------- */

	const [isLoading, setIsLoading] = React.useState(true);
	const [rolesList, setRolesList] = React.useState<[]>([]);

	/* -------------------------------------------------------------------------- */
	/*                               UseEffect Section                            */
	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
			getData();
		}
	}, [props.permissionAPITriggered]);

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
			getData();
			if (params.search_text) {
				setSearchInput(params.search_text);
				setShowSearchTextbox(true);
			}
			if (params.page_no) {
				setPage_no(parseInt(params.page_no));
			}
			if (params.limit) {
				setLimit(parseInt(params.limit));
			}
		}
	}, [window.location.href, props.permissionAPITriggered]);

	/* -------------------------------------------------------------------------- */
	/*                               API Section                                  */
	/* -------------------------------------------------------------------------- */

	const getData = async () => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());

		let payload: any = {
			page: params.page_no ? parseInt(params.page_no) : 1,
			page_size: params.limit ? parseInt(params.limit) : 10,
			search_text: params.search_text,
			status: params.status,
			sort_by: params.sort_by,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);

		try {
			setIsLoading(true);
			let resp = await adminGetRoles(queryRequest);
			if (resp) {
				setRolesList(
					getValue(resp, `data.roles`, []).map((item: object) => ({
						...item,
						active: getValue(item, `is_active`, false) ? "Active" : "Inactive",
						id: getValue(item, `id`, ""),
						createdAtFormatted: moment(getValue(item, `created_at`, "")).format(
							"DD MMM YYYY"
						),
					}))
				);
				setTotalCount(getValue(resp, `data.pagination.total`, 0));
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	/* -------------------------------------------------------------------------- */
	/*                               Search section                               */
	/* -------------------------------------------------------------------------- */
	const [showSearchTextbox, setShowSearchTextbox] = useState(false);
	const [search_text, setSearchInput] = useState<any>("");

	const toggleSearch = () => {
		setShowSearchTextbox((prevState) => !prevState);
	};
	const toggleSearchClose = () => {
		setSearchInput("");
		toggleSearch();
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (params.search_text) {
			delete params.search_text;
			let payload: any = { ...params };
			// removing null values
			Object.keys(payload).forEach((key) => {
				if (!payload[key] || payload[key] === undefined) {
					delete payload[key];
				}
			});
			let queryRequest = qs.stringify(payload);
			navigate(`${window.location.pathname}?${queryRequest}`);
			getData();
		}
	};

	const handleChangeSearch = async (e: any) => {
		setSearchInput(e.target.value);
		let payload: any = {
			search_text: e.target.value,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		navigate(`${window.location.pathname}?${queryRequest}`);
		if (!e.target.value) {
			getData();
		}
	};

	/* -------------------------------------------------------------------------- */
	/*                                 Delete section                             */
	/* -------------------------------------------------------------------------- */

	const [deleteValue, setDeleteValue] = useState("");
	const [deleteId, setDeleteId] = useState("");
	const handleDeleteFun = (id: string, value: string) => {
		setDeleteValue(value);
		setDeleteId(id);
		handleDelete();
	};
	const [isDelete, setIsDelete] = useState(false);
	const handleDelete = () => {
		setIsDelete(!isDelete);
	};

	const handleDeleteFunction = async () => {
		try {
			let resp = await adminDeleteSpecificRole(deleteId);
			if (resp) {
				toast.success(getValue(resp, `message`, ""));
				handleDelete();
				getData();
			}
		} catch (error) {}
	};

	/* -------------------------------------------------------------------------- */
	/*                               Pagination section                           */
	/* -------------------------------------------------------------------------- */

	const [totalCount, setTotalCount] = useState(0);
	const [limit, setLimit] = useState(10);
	const [page_no, setPage_no] = useState(1);

	const handleChangePagination = (page_no: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.page_no = page_no;
		Object.keys(params).forEach((key) => {
			if (!params[key] || params[key] === undefined) {
				delete params[key];
			}
		});
		let queryRequest = qs.stringify(params);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	const handleChangeLimit = (limit: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.limit = limit;
		Object.keys(params).forEach((key) => {
			if (!params[key] || params[key] === undefined) {
				delete params[key];
			}
		});
		let queryRequest = qs.stringify(params);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	/* -------------------------------------------------------------------------- */
	/*                               Onchange section                             */
	/* -------------------------------------------------------------------------- */

	const handleChangeSortBy = (value: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.sort_by = value;
		props.history.push({
			pathname: window.location.pathname,
			query: params,
		});
	};

	const handleChangeStatus = (value: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.status = value;
		props.history.push({
			pathname: window.location.pathname,
			query: params,
		});
	};

	const [selectedIDs, setSelectedIDs] = useState<any>([]);
	const getTableState = (value: object) => {
		let selectedIndexList = Object.keys(
			getValue(value, `selectedRowIds`, {})
		).map((item) => parseInt(item));
		let selectedIds =
			rolesList.length > 0
				? rolesList.filter((_item: object, index: number) =>
						selectedIndexList.includes(index)
				  )
				: [];
		if (selectedIds.length > 0) {
			setSelectedIDs(
				selectedIds.map((item: object) => getValue(item, `id`, ""))
			);
		} else {
			setSelectedIDs([]);
		}
	};

	const rowClick = (obj: object) => {
		navigate(`/edit-role/${getValue(obj, `original.id`, "")}`);
	};

	const handleEdit = (id: string) => {
		navigate(`/edit-role/${id}`);
	};
	const columns = React.useMemo(
		() => [
			{
				Header: "Role Name",
				accessor: "title",
				width: 350,
				Cell: ({ row }: any) => {
					return (
						<NavLink to={`/edit-role/${row.original.id}`}>
							{row.values.title}
						</NavLink>
					);
				},
			},
			{
				Header: "Type",
				accessor: "description",
				width: 350,
			},
			// {
			// 	Header: "# of Users",
			// 	accessor: "-",
			// 	width: 250,
			// 	minWidth: 94,
			// },
			// {
			// 	Header: "Permissions",
			// 	accessor: "permissions",
			// 	width: 350,
			// },
			{
				Header: "Status",
				accessor: "active",
				width: 350,
			},
			{
				Header: "Added on",
				accessor: "createdAtFormatted",
				width: 250,
			},
			// {
			// 	Header: "Action",
			// 	width: 170,
			// 	Cell: ({ row }: any) => {
			// 		return (
			// 			<>
			// 				<Button
			// 					color="primary"
			// 					onClick={() => handleEdit(row.original.id)}
			// 				>
			// 					Edit
			// 				</Button>
			// 				&nbsp;&nbsp;&nbsp;
			// 				<Button
			// 					color="danger"
			// 					onClick={() =>
			// 						handleDeleteFun(row.original.id, row.values.title)
			// 					}
			// 				>
			// 					Delete
			// 				</Button>
			// 			</>
			// 		);
			// 	},
			// },
		],
		[]
	);

	const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());

	const [check, setCheck] = useState(false);
	const handleRemoveNavItems = () => {
		setCheck(!check);
		setSelectedIDs([]);
	};
	const handleChangeActiveStatus = async (value: string) => {
		try {
			let resp = await adminBulkRoleUpdateStatus({
				ids: selectedIDs,
				is_active: value === "active" ? true : false,
			});
			if (resp) {
				toast.success(getValue(resp, `message`, ""));
				setSelectedIDs([]);
				getData();
			} else {
				getData();
			}
		} catch (error) {}
	};
	const getTrProps = (rowInfo: any) => {
		return {};
	};
	return (
		<DashboardLayout
			permissions={getValue(props, `tabPermissionList`, [])}
			subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
		>
			{!isLoading &&
			!getValue(props, `subTabPermissionList`, []).includes("admin_role") ? (
				<NoAccessPage />
			) : (
				<>
					<div className="dashboard-wrapper__header">
						<h4 className="dashbaord-wrapper__header-title">Roles</h4>
					</div>
					{getValue(selectedIDs, `length`, 0) > 0 && (
						<NavigationBar
							selectedIDs={selectedIDs}
							handleRemoveNavItems={handleRemoveNavItems}
							permissions={props.permissions}
							multi={
								getValue(props, `permissions`, []).includes("update")
									? true
									: false
							}
							handleChangeActiveStatus={handleChangeActiveStatus}
						/>
					)}
					<div className="admin-wrapper position-relative">
						<SearchHeader
							route={"/add-role"}
							searchInput={search_text}
							showSearchTextbox={showSearchTextbox}
							toggleSearch={toggleSearch}
							toggleSearchClose={toggleSearchClose}
							handleChangeSearch={handleChangeSearch}
							add={
								getValue(props, `permissions`, []).includes("create")
									? "true"
									: "false"
							}
						/>
					</div>
					<div className="datatable__table">
						{isLoading || !props.permissionAPITriggered ? (
							<Loader />
						) : rolesList.length > 0 ? (
							<ScrollableReactTable
								columns={columns}
								data={rolesList}
								getTableState={getTableState}
								rowClick={rowClick}
								check={check}
								selectedIDs={selectedIDs}
								getTrProps={getTrProps}
							/>
						) : (
							<>
								{Object.keys(params).length !== 0 ? <Notfound /> : <Notfound />}
							</>
						)}
					</div>
					<div className="footer-wrapper d-flex justify-content-between">
						{rolesList.length > 0 && (
							<img
								src="/images/building-illustration.svg"
								className="img-fluid"
							/>
						)}
						{totalCount > 10 ? (
							<PaginationPage
								totalCount={totalCount}
								limit={limit}
								pageNumber={page_no}
								handleChangePagination={handleChangePagination}
								handleChangeLimit={handleChangeLimit}
							/>
						) : (
							""
						)}
					</div>
					<DeleteModal
						isOpen={isDelete}
						handleModal={handleDelete}
						handleSubmit={handleDeleteFunction}
						deleteValue={deleteValue}
					/>
				</>
			)}
		</DashboardLayout>
	);
};

export default AdminRoles;
