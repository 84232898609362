import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import AddLocation from "@components/list-management/AddLocation/index";
import AddLocationMore from "@components/list-management/add-location-more";

import "./checkpoint.scss";
import {
  adminDeleteSpecificLegislationManagement,
  adminDeleteSpecificLegislationManagementRule,
  adminGetCountriesListManagement,
  adminGetLegislationManagement,
  adminGetLegislationManagementRule,
  adminGetListManagementGroupCode,
  adminGetListManagementStateCategoryList,
  adminGetStatesListManagement,
  adminImportListManagement,
  adminUpdateSpecificLegislationManagement,
  adminUpdateSpecificLegislationManagementRule,
  exportLegislation,
  getTemplates,
} from "@services/list-management.service";
import { getValue } from "@utils/lodash";
import LegislationPopup from "./popup/legislationpopup";
import NewRulePopup from "./popup/newrulepopup";
import { useNavigate } from "react-router-dom";
import qs from "query-string";
import CategoryPopup from "./popup/categorypopup";
import { toast } from "react-toastify";
import DeleteModal from "@_common/deleteModal";
import Loader from "@components/common/Loader";
import { handleDownloadOnClick } from "@_common/downloadfile";
import NoAccessPage from "@components/common/NoAccess";
import { formatText } from "@_common/format-text";
import { QueryRequestHelper } from "@_common/query-request-helper";
import BulkUploadDropdown from "@components/BulkUploadDropdown";

interface ICheckPointProps {}

const CheckPoint: React.FunctionComponent<ICheckPointProps> = (props: any) => {
  let navigate = useNavigate();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);

  const [countryList, setCountryList] = useState([]);
  const [states, setStates] = useState([]);
  const [legislationList, setLegislationList] = useState([]);
  const [ruleList, setRuleList] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState<any>();
  const [selectedLegislation, setSelectedLegislation] = useState<any>();

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [groups, setGroups] = React.useState([]);

  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleModal1Cancel = () => {
    handleModal1();
    setEditLegislation({});
  };
  const handleModal2Cancel = () => {
    handleModal2();
    setEditLegislationRule({});
  };

  const handleModal2 = () => {
    setIsOpen2(!isOpen2);
  };
  const handleModal3 = () => {
    setIsOpen3(!isOpen3);
  };

  // delete legislation and rule

  const [deleteChoiceGroup, setDeleteChoiceGroup] = useState("");
  const [deleteChoiceGroupID, setDeleteChoiceGroupID] = useState("");

  const [deleteChoiceValue, setDeleteChoiceValue] = useState("");
  const [deleteChoiceValueID, setDeleteChoiceValueID] = useState("");

  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const [isDelete1, setIsDelete1] = useState(false);
  const handleDelete1 = () => {
    setIsDelete1(!isDelete1);
  };

  const handleDeleteChoice = async (item: object) => {
    setDeleteChoiceGroup(getValue(item, `legislation_name`, ""));
    setDeleteChoiceGroupID(getValue(item, `id`, ""));
    handleDelete();
  };
  const handleDeleteChoiceValue = async (item: object) => {
    setDeleteChoiceValue(getValue(item, `rule_name`, ""));
    setDeleteChoiceValueID(getValue(item, `id`, ""));
    handleDelete1();
  };

  const [editLegislation, setEditLegislation] = useState({});
  const [editLegislationRule, setEditLegislationRule] = useState({});

  const handleEditLegislation = async (item: object) => {
    setEditLegislation(item);
    handleModal1();
  };

  const handleEditLegislationRule = async (item: object) => {
    setEditLegislationRule(item);
    handleModal2();
  };

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (props.permissionAPITriggered) {
      getCountriesList();
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      if (
        getValue(params, `countryId`, "") &&
        !getValue(params, `stateId`, "")
      ) {
        getStatesList(parseInt(getValue(params, `countryId`, "")));
        setSelectedCountry(
          getValue(params, `countryId`, "")
            ? getValue(params, `countryId`, "")
            : ""
        );
      }
      if (getValue(params, `stateId`, "")) {
        getStateCategoryLawList(getValue(params, `stateId`, ""));
      }

      if (
        getValue(params, `stateId`, "") &&
        !getValue(params, `legislation_id`, "")
      ) {
        getLegislationList(getValue(params, `stateId`, ""));
        setSelectedState(
          getValue(params, `stateId`, "") ? getValue(params, `stateId`, "") : ""
        );
      }

      if (getValue(params, `legislation_id`, "")) {
        getLegislationRuleList(getValue(params, `legislation_id`, ""));
        setSelectedLegislation(
          getValue(params, `legislation_id`, "")
            ? getValue(params, `legislation_id`, "")
            : ""
        );
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  const [stateGroups, setStateGroups] = useState([]);
  const getStateCategoryLawList = async (stateId: string) => {
    try {
      let resp = await adminGetListManagementStateCategoryList(stateId);
      if (resp) {
        setStateGroups(
          getValue(resp, `data.stateCategoryOfLaw`, []).map((item: object) => ({
            id: getValue(item, `category_of_law.id`, ""),
            label: getValue(item, `category_of_law.text`, ""),
            value: getValue(item, `category_of_law.text`, ""),
          }))
        );
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getData = async () => {
    try {
      let resp = await adminGetListManagementGroupCode("category-of-law");
      if (resp) {
        setGroups(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            id: getValue(item, `id`, ""),
            label: getValue(item, `text`, ""),
            value: getValue(item, `text`, ""),
          }))
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  // const getCountriesList = async () => {
  // 	const urlSearchParams = new URLSearchParams(window.location.search);
  // 	const params = Object.fromEntries(urlSearchParams.entries());
  // 	try {
  // 		setIsLoading(true);
  // 		let resp = await adminGetCountriesListManagement();
  // 		if (resp) {
  // 			setCountryList(getValue(resp, `data.countries`, []));
  // 			if (getValue(params, `countryId`, "")) {
  // 				setSelectedCountry(getValue(params, `countryId`, ""));
  // 				getStatesList(getValue(params, `countryId`, ""));
  // 			} else {
  // 				setSelectedCountry(getValue(resp, `data.countries[${0}].id`, ""));
  // 				if (getValue(resp, `data.countries[${0}].id`, "")) {
  // 					getStatesList(getValue(resp, `data.countries[${0}].id`, ""));
  // 				}
  // 			}
  // 			setIsLoading(false);
  // 		} else {
  // 			setIsLoading(false);
  // 		}
  // 	} catch (error) {
  // 		setIsLoading(false);
  // 	}
  // };
  const getCountriesList = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    try {
      setIsLoading(true);
      let resp = await adminGetCountriesListManagement();
      if (resp) {
        let countries = getValue(resp, `data.countries`, []).filter(
          (item: object) =>
            getValue(item, `id`, "") == 101 || getValue(item, `id`, "") == 174 || getValue(item, `id`, "") == 154
        );
        setCountryList(countries);
        setCountryListMain(countries);
        if (getValue(params, `countryId`, "")) {
          setSelectedCountry(getValue(params, `countryId`, ""));
          getStatesList(getValue(params, `countryId`, ""));
        } else {
          setSelectedCountry(getValue(countries, `[${0}].id`, ""));
          if (getValue(countries, `[${0}].id`, "")) {
            getStatesList(getValue(countries, `[${0}].id`, ""));
          }
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getStatesList = async (countryId: number) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    try {
      setIsLoading1(true);
      let resp = await adminGetStatesListManagement(countryId);
      if (resp) {
        setStates(getValue(resp, `data.states`, []));
        setStatesMain(getValue(resp, `data.states`, []));
        if (getValue(params, `stateId`, "")) {
          setSelectedState(getValue(params, `stateId`, ""));
          getLegislationList(getValue(params, `stateId`, ""));
        } else {
          setSelectedState(getValue(resp, `data.states[${0}].id`, ""));
          if (getValue(resp, `data.states[${0}].id`, "")) {
            getLegislationList(getValue(resp, `data.states[${0}].id`, ""));
            setSelectedState(getValue(resp, `data.states[${0}].id`, ""));
          } else {
            setLegislationList([]);
          }
        }
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };
  const getLegislationList = async (stateId: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    try {
      setIsLoading2(true);
      let resp = await adminGetLegislationManagement(stateId);
      if (resp) {
        setIsLoading2(false);
        setLegislationList(getValue(resp, `data.legislations`, []));
        if (getValue(params, `legislation_id`, "")) {
          setSelectedLegislation(getValue(params, `legislation_id`, ""));
          getLegislationRuleList(getValue(params, `legislation_id`, ""));
        } else {
          setSelectedLegislation(
            getValue(resp, `data.legislations[${0}].id`, "")
          );
          if (getValue(resp, `data.legislations[${0}].id`, "")) {
            getLegislationRuleList(
              getValue(resp, `data.legislations[${0}].id`, "")
            );
          } else {
            setRuleList([]);
          }
        }
      } else {
        setIsLoading2(false);
      }
    } catch (error) {
      setIsLoading2(false);
    }
  };
  const getLegislationRuleList = async (legislation_id: string) => {
    try {
      setIsLoading3(true);
      let resp = await adminGetLegislationManagementRule(legislation_id);
      if (resp) {
        setIsLoading3(false);
        setRuleList(getValue(resp, `data.legislationRules`, []));
        // setLegislationList(getValue(resp, `data.legislations`, []));
      } else {
        setIsLoading3(false);
      }
    } catch (error) {
      setIsLoading3(false);
    }
  };

  /* -------------------------   Update status    ----------------------------- */
  const handleChangeStatusLegislation = async (
    id: string,
    status: boolean,
    text: string,
    category_of_law_id: string
  ) => {
    try {
      let resp = await adminUpdateSpecificLegislationManagement(id, {
        is_active: status,
        legislation_name: text,
        category_of_law_id: category_of_law_id,
        state_id: parseInt(selectedState),
      });
      if (resp) {
        toast.success(getValue(resp, "message", ""));
        getLegislationList(selectedState);
      }
    } catch (error) {}
  };

  const handleChangeStatusLegislationRule = async (
    id: string,
    status: boolean
  ) => {
    try {
      let resp = await adminUpdateSpecificLegislationManagementRule(id, {
        is_active: status,
        legislation_id: selectedLegislation,
      });
      if (resp) {
        toast.success(getValue(resp, "message", ""));
        getLegislationRuleList(selectedLegislation);
      }
    } catch (error) {}
  };
  /* -------------------------   delete    ----------------------------- */
  const handleDeleteGroup = async () => {
    try {
      let resp = await adminDeleteSpecificLegislationManagement(
        deleteChoiceGroupID
      );
      if (resp) {
        toast.success(getValue(resp, "message", ""));
        handleDelete();
        getLegislationList(selectedState);
      } else {
        handleDelete();
      }
    } catch (error) {}
  };

  const handleDeleteValue = async () => {
    try {
      let resp = await adminDeleteSpecificLegislationManagementRule(
        deleteChoiceValueID
      );
      if (resp) {
        toast.success(getValue(resp, "message", ""));
        handleDelete1();
        getLegislationRuleList(selectedLegislation);
      } else {
        handleDelete1();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeCountry = (id: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      countryId: id,
    };
    // removing null values
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeState = (id: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      countryId: params.countryId,
      stateId: id,
    };
    // removing null values
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLegislation = (id: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      countryId: params.countryId,
      stateId: params.stateId,
      legislation_id: id,
    };
    // removing null values
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLegislationRule = (id: any) => {};

  const [uploadLoading, setUploadLoading] = useState(false);
  const importLegislationFile = async (e: any) => {
    try {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      setUploadLoading(true);
      let resp = await adminImportListManagement(formData);
      if (resp) {
        toast.success("Imported Successfully");
        navigate(`/file-logs/LEGISLATION`);
        handleDownloadOnClick(
          getValue(resp, `data.downloadUrl`, ""),
          "Legislation Error Report"
        );
        getLegislationList(selectedState);
        setUploadLoading(false);
      } else {
        setUploadLoading(false);
      }
    } catch (error) {
      setUploadLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                Search section                              */
  /* -------------------------------------------------------------------------- */

  const [serchInput1, setSearchInput1] = useState("");
  const [serchInput2, setSearchInput2] = useState("");
  const [countryListMain, setCountryListMain] = useState([]);
  const [statesMain, setStatesMain] = useState([]);
  const handleSearchContry = (e: string) => {
    setSearchInput1(e);
    if (e) {
      let main: any =
        countryListMain.length > 0
          ? countryListMain.filter((item: any) =>
              item.name.toLowerCase().includes(e)
            )
          : [];
      setCountryList(main);
    } else {
      setCountryList(countryListMain);
    }
  };
  const handleSearchState = (e: string) => {
    setSearchInput2(e);
    if (e) {
      let main: any =
        statesMain.length > 0
          ? statesMain.filter((item: any) =>
              item.name.toLowerCase().includes(e)
            )
          : [];
      setStates(main);
    } else {
      setStates(statesMain);
    }
  };

  const handleExport = async (type: string) => {
    let payload: any = {
      // state_id: selectedState,
      // legislation_id: selectedLegislation,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      let resp = await exportLegislation(queryRequest);
      if (resp) {
        let URL = getValue(resp, `data.downloadUrl`, "");
        if (URL) {
          handleDownloadOnClick(URL, "legislation_report");
        }
      }
    } catch (error) {}
  };
  const handleNavigate = (name:string) => {
    navigate(`/file-logs/${name?name:"LEGISLATION"}`);
  };
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "list_management_checkpoint"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          <div className="dashboard-wrapper__header">
            <h4 className="dashbaord-wrapper__header-title">Check Point</h4>
          </div>
          <section className="list-managemnet-wrapper list-managemnet-wrapper--max-height d-flex align-items-flex-start">
            <div className="list-managemnet-wrapper__country-table w-100">
              <div className="list-managemnet-wrapper__country-common-container">
                <div
                  className="list-managemnet-wrapper__form-group form-group"
                  // onClick={handleModal1}
                >
                  <input
                    type="text"
                    placeholder="Search Country"
                    className="form-control list-managemnet-wrapper__form-control"
                    // disabled
                    name="serchInput1"
                    value={serchInput1}
                    onChange={(e) => handleSearchContry(e.target.value)}
                  />
                  <div className="list-managemnet-wrapper__form-add-icon">
                    {/* <img src="/images/add-red-btn.svg" className="img-fluid" /> */}
                  </div>
                </div>
              </div>
              <div>
                <h5 className="list-managemnet-wrapper__title">Country</h5>
              </div>
              {getValue(countryList, `length`, 0) > 0 ? (
                <div className="list-management-wrapper__scroll-table">
                  {isLoading || !props.permissionAPITriggered ? (
                    <Loader />
                  ) : (
                    countryList.map((countryNameObj, index) => {
                      return (
                        <>
                          <AddLocation
                            key={index}
                            id={getValue(countryNameObj, `id`, "")}
                            name={getValue(countryNameObj, `name`, "")}
                            selected={selectedCountry}
                            onChange={handleChangeCountry}
                            permissions={getValue(props, `permissions`, [])}
                          />
                        </>
                      );
                    })
                  )}
                </div>
              ) : (
                <p className="text-center mt-2">No Data Found</p>
              )}
            </div>
            <div className="list-managemnet-wrapper__country-table w-100">
              <div className="list-managemnet-wrapper__country-common-container">
                <div
                  className="list-managemnet-wrapper__form-group form-group"
                  // onClick={handleModal1}
                >
                  <input
                    type="text"
                    placeholder="Search State"
                    className="form-control list-managemnet-wrapper__form-control"
                    // disabled
                    name="serchInput2"
                    value={serchInput2}
                    onChange={(e) => handleSearchState(e.target.value)}
                  />
                  <div className="list-managemnet-wrapper__form-add-icon">
                    {/* <img src="/images/add-red-btn.svg" className="img-fluid" /> */}
                  </div>
                </div>
              </div>
              <h5 className="list-managemnet-wrapper__title">State</h5>
              <div className="list-management-wrapper__scroll-table">
                {isLoading1 || !props.permissionAPITriggered ? (
                  <Loader />
                ) : getValue(states, `length`, 0) > 0 ? (
                  states.map((countryNameObj, index) => {
                    return (
                      <AddLocation
                        id={getValue(countryNameObj, `id`, "")}
                        name={getValue(countryNameObj, `name`, "")}
                        selected={selectedState}
                        onChange={handleChangeState}
                        add={true}
                        handleAddCategory={handleModal3}
                        permissions={getValue(props, `permissions`, [])}
                      />
                    );
                  })
                ) : (
                  <p className="text-center mt-2">No Data Found</p>
                )}
              </div>
            </div>
            <div className="list-managemnet-wrapper__country-table w-100">
              <div className="list-managemnet-wrapper__country-common-container">
                <div className="d-flex justify-content-between align-center">
                  <div
                    className="list-managemnet-wrapper__form-group form-group"
                    onClick={handleModal1}
                  >
                    <input
                      type="text"
                      placeholder="Add New Legislation"
                      className="form-control list-managemnet-wrapper__form-control"
                    />
                    <div className="list-managemnet-wrapper__form-add-icon">
                      <img
                        src="/images/add-red-btn.svg"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <BulkUploadDropdown
                      url={getValue(props, `downloadURL`, "")}
                      dropdownOptions={[
                        { value: "LEGISLATION", label: "Legislation" },
                      ]}
                    />
                    <div
                      className="add-filter-wrapper__filter-container cursor-pointer"
                      onClick={() => handleExport("LEGISLATION")}
                    >
                      <div className="d-flex">
                        <img
                          src="/images/download_sharp.svg"
                          className="img-fluid add-filter-wrapper__upload-image"
                        />
                      </div>
                      <h6 className="add-filter-wrapper__filter-title">
                        EXPORT
                      </h6>
                    </div>
                    <div className="add-filter-wrapper__filter-container cursor-pointer">
                      <div className="d-flex" onClick={() => handleNavigate('LEGISLATION')}>
                        <img
                          src="/images/taskback.svg"
                          className="img-fluid add-filter-wrapper__upload-image"
                        />
                      </div>
                      <h6 className="add-filter-wrapper__filter-title">
                        {"VIEW LOGS"}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              {/* <label
                htmlFor="file"
                className="cursor-pointer custom-file-label"
              >
                <input
                  type={"file"}
                  onChange={importLegislationFile}
                  id="file"
                  className="d-none"
                  disabled={uploadLoading}
                />
                <div className="custom-input-file text-center m-3">
                  <img
                    src="/images/upload-icon.svg"
                    className="img-fluid mb-2"
                  />
                  {uploadLoading ? (
                    <p className="custom-input-file__text">Please Wait...</p>
                  ) : (
                    <>
                      <p className="custom-input-file__text">
                        Drag & Drop Files here
                      </p>
                      <p className="custom-input-file__text">OR</p>
                      <p className="custom-input-file__text">Browse Files</p>
                    </>
                  )}
                </div>
              </label> */}
              <h5 className="list-managemnet-wrapper__title">Legislation</h5>
              <div className="list-management-wrapper__scroll-table">
                {isLoading2 || !props.permissionAPITriggered ? (
                  <Loader />
                ) : getValue(legislationList, `length`, 0) > 0 ? (
                  legislationList.map((countryNameObj, index) => {
                    return (
                      <AddLocationMore
                        item={countryNameObj}
                        id={getValue(countryNameObj, `id`, "")}
                        name={getValue(countryNameObj, `legislation_name`, "")}
                        is_active={getValue(countryNameObj, `is_active`, "")}
                        selected={selectedLegislation}
                        onChange={handleChangeLegislation}
                        handleChangeStatus={handleChangeStatusLegislation}
                        handleDelete={handleDeleteChoice}
                        handleEdit={handleEditLegislation}
                        category_of_law={getValue(
                          countryNameObj,
                          `category_of_law`,
                          ""
                        )}
                        permissions={getValue(props, `permissions`, [])}
                      />
                    );
                  })
                ) : (
                  <p className="text-center mt-2">No Data Found</p>
                )}
              </div>
            </div>

            <div className="list-managemnet-wrapper__country-table w-100">
              <div className="list-managemnet-wrapper__country-common-container">
                <div className="d-flex justify-content-between align-items-start">
                  <div
                    className="list-managemnet-wrapper__form-group form-group"
                    onClick={handleModal2}
                  >
                    <input
                      type="text"
                      placeholder="Add New Rule"
                      className="form-control list-managemnet-wrapper__form-control"
                    />
                    <div className="list-managemnet-wrapper__form-add-icon">
                      <img
                        src="/images/add-red-btn.svg"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <BulkUploadDropdown
                      url={getValue(props, `downloadURL`, "")}
                      dropdownOptions={[
                        { value: "LEGISLATION_RULE", label: "Legislation Rule" },
                      ]}
                    />
                    {/* <div
                      className="add-filter-wrapper__filter-container cursor-pointer"
                      onClick={() => handleExport("LEGISLATION")}
                    >
                      <div className="d-flex">
                        <img
                          src="/images/download_sharp.svg"
                          className="img-fluid add-filter-wrapper__upload-image"
                        />
                      </div>
                      <h6 className="add-filter-wrapper__filter-title">
                        EXPORT
                      </h6>
                    </div> */}
                    <div className="add-filter-wrapper__filter-container cursor-pointer">
                      <div className="d-flex" onClick={() => handleNavigate('LEGISLATION_RULE')}>
                        <img
                          src="/images/taskback.svg"
                          className="img-fluid add-filter-wrapper__upload-image"
                        />
                      </div>
                      <h6 className="add-filter-wrapper__filter-title">
                        {"VIEW LOGS"}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <h5 className="list-managemnet-wrapper__title">Rule</h5>
              <div className="list-management-wrapper__scroll-table">
                {
                  // isLoading3 ? (
                  // 	<Loader />
                  // ) :
                  getValue(ruleList, `length`, 0) > 0 ? (
                    ruleList.map((countryNameObj, index) => {
                      return (
                        <AddLocationMore
                          item={countryNameObj}
                          id={getValue(countryNameObj, `id`, "")}
                          name={getValue(countryNameObj, `rule_name`, "")}
                          is_active={getValue(countryNameObj, `is_active`, "")}
                          onChange={handleChangeLegislationRule}
                          handleChangeStatus={handleChangeStatusLegislationRule}
                          handleDelete={handleDeleteChoiceValue}
                          handleEdit={handleEditLegislationRule}
                          permissions={getValue(props, `permissions`, [])}
                        />
                      );
                    })
                  ) : (
                    <p className="text-center mt-2">No Data Found</p>
                  )
                }
              </div>
            </div>
          </section>
          <LegislationPopup
            isOpen={isOpen1}
            handleModal={handleModal1}
            handleModalCancel={handleModal1Cancel}
            groups={stateGroups}
            getLegislationList={getLegislationList}
            selectedState={selectedState}
            editObj={editLegislation}
          />
          <NewRulePopup
            isOpen={isOpen2}
            handleModal={handleModal2}
            selectedLegislation={selectedLegislation}
            getLegislationRuleList={getLegislationRuleList}
            editObj={editLegislationRule}
            handleModalCancel={handleModal2Cancel}
          />
          <CategoryPopup
            isOpen={isOpen3}
            handleModal={handleModal3}
            selectedState={selectedState}
          />

          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteGroup}
            deleteValue={deleteChoiceGroup}
          />
          <DeleteModal
            isOpen={isDelete1}
            handleModal={handleDelete1}
            handleSubmit={handleDeleteValue}
            deleteValue={deleteChoiceValue}
          />
        </>
      )}
    </DashboardLayout>
  );
};

export default CheckPoint;
