import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const adminGetRolesListPermissions = () =>
	get(`${config.API_URL}/roles/list/permissions`);

export const adminCreateRole = (payload: object) =>
	post(`${config.API_URL}/roles`, payload);

export const adminGetRoles = (queryRequest:string) => get(`${config.API_URL}/roles?${queryRequest}`);

export const adminGetAllRoles = () => get(`${config.API_URL}/roles`);

export const adminGetSpecificRole = (id: string) =>
	get(`${config.API_URL}/roles/${id}`);

export const adminUpdateSpecificRole = (id: string, payload: object) =>
	patch(`${config.API_URL}/roles/${id}`, payload);

export const adminDeleteSpecificRole = (id: string) =>
	Delete(`${config.API_URL}/roles/${id}`);


// Legal Update
export const legalUpdate = (payload: object) =>
	post(`${config.API_URL}/legal-update/getblogs`,payload);

// Create Legal Update
export const createLegalUpdate = (payload: object) =>
	post(`${config.API_URL}/legal-update`,payload);

export const editLegalUpdate = (payload: object) =>
	patch(`${config.API_URL}/legal-update/update-blog`, payload);

//Get Specific Legal Update
export const getSpecificLegal = (payload: object) =>
	post(`${config.API_URL}/legal-update/getblogs`,payload);

export const deleteSpecificLegal = (id: string) =>
	Delete(`${config.API_URL}/legal-update/delete-blog/${id}`);

export const getDocumentLibrary = (queryRequest:string) => 
	get(`${config.API_URL}/document-library?${queryRequest}`);


//Create Document Library
export const createDocumentLibrary = (payload: object) =>
	post(`${config.API_URL}/document-library`,payload);

//Edit Document Library
export const updateDocumentLibrary = (id: string, payload: object) =>
	patch(`${config.API_URL}/document-library/${id}`, payload);

// Get Specific Document Library
export const getSpecificDocumentLibrary = (id: string) =>
	get(`${config.API_URL}/document-library/${id}`);
