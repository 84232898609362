import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getValue } from "@utils/lodash";
import "./LeftNavigation.scss";
import { useLocation } from "react-router-dom";

export default function LeftNavigation(props: any) {
  const location = useLocation();
  const [navigationMenu, setNavigationMenu] = useState([
    {
      title: "DashBoard",
      icon: "/images/navigation-icons/dashboard.svg",
      link: "/audit/overview",
      parentLink: "/audit/overview",
      name: "dashboard",
      subMenu: [
        {
          title: "Vendor Audit",
          link: "/audit/overview",
          name: "dashboard_vendor_audit",
        },
      ],
    },
    {
      title: "Client List",
      icon: "/images/navigation-icons/client-list.svg",
      parentLink: "/clientlist",
      name: "client",
      subMenu: [
        {
          title: "Active",
          link: "/clientlist/active",
          name: "client_active",
        },
        {
          title: "Inactive",
          link: "/clientlist/inactive",
          name: "client_inactive",
        },
      ],
    },
    {
      title: "On Prem Clients",
      icon: "/images/navigation-icons/client-list.svg",
      parentLink: "/on-prem/client-list",
      name: "client",
      subMenu: [
        {
          title: "Active",
          link: "/on-prem/client-list/active",
          name: "client_active",
        },
        {
          title: "Inactive",
          link: "/on-prem/client-list/inactive",
          name: "client_inactive",
        },
      ],
    },
    {
      title: "Checkpoint",
      icon: "/images/navigation-icons/checkpoint.svg",
      parentLink: "/checkpoint",
      name: "checkpoint",
      subMenu: [
        {
          title: "Active",
          link: "/checkpoint/active",
          name: "checkpoint_active",
        },
        {
          title: "Inactive",
          link: "/checkpoint/inactive",
          name: "checkpoint_inactive",
        },
      ],
    },
    // {
    // 	title: "Audit",
    // 	icon: "/images/navigation-icons/audit.svg",
    // 	parentLink: "/audit",
    // 	subMenu: [
    // 		{
    // 			title: "Overview",
    // 			link: "/audit/overview",
    // 		},
    // 		{
    // 			title: "Submitted",
    // 			link: "/audit/uploaded",
    // 		},
    // 		{
    // 			title: "Completed",
    // 			link: "/audit/completed",
    // 		},
    // 		{
    // 			title: "Not Submitted",
    // 			link: "/audit/not-uploaded",
    // 		},
    // 	],
    // },
    // {
    // 	title: "Support",
    // 	icon: "/images/navigation-icons/support.svg",
    // 	parentLink: "/support",
    // 	name: "support",
    // 	subMenu: [
    // 		{
    // 			title: "Open",
    // 			link: "/support/open",
    // 			name: "support",
    // 		},
    // 	],
    // },
    // {
    // 	title: "Reports",
    // 	icon: "/images/navigation-icons/reports.svg",
    // 	parentLink: "/reports",
    // 	name: "report",
    // },
    {
      title: "Admin",
      icon: "/images/navigation-icons/admin.svg",
      parentLink: "/admin",
      name: "admin",
      subMenu: [
        {
          title: "Users",
          link: "/admin/users",
          name: "admin_user",
        },
        {
          title: "Roles",
          link: "/admin/roles",
          name: "admin_role",
        },
      ],
    },
    {
      title: "List Management",
      icon: "/images/navigation-icons/list-mananagement.svg",
      parentLink: "/list-management",
      name: "list_management",
      subMenu: [
        // {
        // 	title: "Country",
        // 	link: "/list-management/country",
        // },
        {
          title: "Choice List",
          link: "/list-management/choice-list",
          name: "list_management_choice_list",
        },
        {
          title: "Checkpoint",
          link: "/list-management/checkPoint",
          name: "list_management_checkpoint",
        },
      ],
    },

    {
      title: "Legal Update",
      icon: "/images/navigation-icons/list-mananagement.svg",
      link: "/legal-update",
      parentLink: "/legal-update",
      name: "legal_update",
    },
    {
      title: "Document Library",
      icon: "/images/navigation-icons/list-mananagement.svg",
      link: "/document-library",
      parentLink: "/document-library",
      name: "legal_update",
    },
  ]);
  const [navigation, setNavigation] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    let arr: any = [];
    for (let i = 0; i < getValue(navigationMenu, `length`, 0); i++) {
      if (
        getValue(props, `permissions`, []).includes(
          getValue(navigationMenu, `[${i}].name`, "")
        )
      ) {
        let item = getValue(navigationMenu, `[${i}]`, {});
        let subItem = getValue(
          navigationMenu,
          `[${i}].subMenu`,
          []
        ).filter((item: any) =>
          getValue(props, `subTabPermissionList`, []).includes(item.name)
        );
        if (getValue(subItem, `length`, 0) > 0) {
          arr.push({
            icon: item.icon,
            name: item.name,
            parentLink: item.parentLink,
            title: item.title,
            subMenu: subItem,
          });
        } else {
          arr.push({
            ...item,
          });
        }
      }
    }
    if (arr.length > 0) {
      setNavigation(arr);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [props.permissions]);
  return (
    <nav className="navigation-menu">
      {!isLoading ? (
        <ul>
          {navigation.map((menuObj: any, index: number) => {
            return (
              <>
                <li
                  className={`navigation-menu__menu-item ${
                    !menuObj.subMenu ? "navigation-menu__no-sub-menu-item" : ""
                  }
								${
                  !menuObj.subMenu &&
                  window.location.pathname ===
                    getValue(menuObj, `parentLink`, "")
                    ? "active"
                    : ""
                }
							`}
                >
                  <label
                    className={`d-flex w-100 align-items-flex-start ${
                      menuObj.subMenu ? "cursor-pointer" : ""
                    }`}
                    htmlFor={`nav-menu-${index}`}
                  >
                    <div className="navigation-menu__menu-icon">
                      <img
                        src={menuObj.icon}
                        className="material-symbols-outlined img-fluid"
                      />
                    </div>
                    <div className="d-flex align-items-flex-start justify-content-between navigation-menu__menu-elements">
                      {menuObj.subMenu ? (
                        <a className="nav-link">{menuObj.title}</a>
                      ) : (
                        <NavLink
                          to={getValue(menuObj, `link`, "")}
                          className="nav-link w-100"
                        >
                          <a>{menuObj.title}</a>
                        </NavLink>
                      )}

                      {menuObj.subMenu && (
                        <img
                          src="/images/arrow-down.svg"
                          className="img-fluid dropdown-arrow cursor-pointer"
                        />
                      )}
                    </div>
                  </label>
                  {menuObj.subMenu && (
                    <input
                      type="checkbox"
                      id={`nav-menu-${index}`}
                      name="navigation-menu"
                      className="left-navigation__checkbox d-none"
                      defaultChecked={location.pathname.includes(
                        menuObj.parentLink
                      )}
                    />
                  )}
                  {menuObj.subMenu && (
                    <ul className={`navigation-menu__sub-menu`}>
                      {menuObj.subMenu?.map(
                        (subMenuObj: any, index: number) => {
                          return (
                            <NavLink
                              to={getValue(subMenuObj, `link`, "")}
                              key={`navigation-menu__sub-menu-${index}`}
                            >
                              <li
                                className={`${index} navigation-menu__sub-menu-link
                            								${
                                              window.location.pathname ===
                                                getValue(
                                                  subMenuObj,
                                                  `link`,
                                                  ""
                                                ) && "active"
                                            }`}
                              >
                                <span>{subMenuObj.title}</span>
                              </li>
                            </NavLink>
                          );
                        }
                      )}
                    </ul>
                  )}
                </li>
              </>
            );
          })}
        </ul>
      ) : (
        <p>Please Wait...</p>
      )}
    </nav>
  );
}
