import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import AddLocation from "@components/list-management/add-location-more";
import "./choicelist.scss";
import {
	adminListManagementCreateGroup,
	adminListManagementCreateGroupItems,
	adminListManagementDeleteGroup,
	adminListManagementDeleteGroupItems,
	adminListManagementGetGroups,
	adminListManagementGetGroupsItems,
	adminListManagementPatchGroup,
	adminListManagementPatchGroupItems,
} from "@services/list-management.service";
import { getValue } from "@utils/lodash";
import ChoicePopup from "./popup/choice";
import ChoiceValuePopup from "./popup/value";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import qs from "query-string";
import DeleteModal from "@_common/deleteModal";
import Loader from "@components/common/Loader";
import NoAccessPage from "@components/common/NoAccess";

interface IChoiceListProps {}

const ChoiceList: React.FunctionComponent<IChoiceListProps> = (props:any) => {
	let navigate = useNavigate();

	/* -------------------------------------------------------------------------- */
	/*                               UseState Section                             */
	/* -------------------------------------------------------------------------- */

	const [isLoading, setIsLoading] = useState(true);
	const [isLoading1, setIsLoading1] = useState(true);

	const [text, setText] = useState("");
	const [textEditId, setTextEditId] = useState("");

	const [value, setValue] = useState("");
	const [valueEditId, setValueEditId] = useState("");

	const [choiceList, setChoiceList] = useState([]);
	const [valueList, setValueList] = useState([]);

	const [selectedChoice, setSelectedChoice] = useState("");

	const [isOpenChoice, setIsOpenChoice] = useState(false);
	const [isOpenChoiceValue, setIsOpenChoiceValue] = useState(false);

	const [deleteChoiceGroup, setDeleteChoiceGroup] = useState("");
	const [deleteChoiceGroupID, setDeleteChoiceGroupID] = useState("");

	const [deleteChoiceValue, setDeleteChoiceValue] = useState("");
	const [deleteChoiceValueID, setDeleteChoiceValueID] = useState("");

	const handleModalChoice = () => {
		setIsOpenChoice(!isOpenChoice);
	};

	const handleModalChoiceCancel = () => {
		handleModalChoice();
		setText("");
		setTextEditId("");
	};

	const handleModalChoiceValue = () => {
		setIsOpenChoiceValue(!isOpenChoiceValue);
	};
	const handleModalChoiceValueCancel = () => {
		handleModalChoiceValue();
		setValue("");
		setValueEditId("");
	};

	/* -------------------------------------------------------------------------- */
	/*                               UseEffect Section                            */
	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		if(props.permissionAPITriggered){
		getChoiceList();
		}
		
	}, [props.permissionAPITriggered]);

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
			if (getValue(params, `groupId`, "")) {
				getValueList(parseInt(getValue(params, `groupId`, "")));
				setSelectedChoice(
					getValue(params, `groupId`, "") ? getValue(params, `groupId`, "") : ""
				);
			}
		}
	}, [window.location.href,props.permissionAPITriggered])

	/* -------------------------------------------------------------------------- */
	/*                               API Section                                  */
	/* -------------------------------------------------------------------------- */

	const getChoiceList = async () => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		try {
			setIsLoading(true);
			let resp = await adminListManagementGetGroups();
			if (resp) {
				setChoiceListMain(getValue(resp, `data.listGroups`, []));
				setChoiceList(getValue(resp, `data.listGroups`, []));
				if (getValue(params, `groupId`, "")) {
					getValueList(parseInt(getValue(params, `groupId`, "")));
					setSelectedChoice(
						getValue(params, `groupId`, "")
							? getValue(params, `groupId`, "")
							: ""
					);
				} else {
					setSelectedChoice(getValue(resp, `data.listGroups[${0}].id`, ""));
					getValueList(getValue(resp, `data.listGroups[${0}].id`, ""));
				}
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	const getChoiceListRefetch = async (id: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		try {
			let resp = await adminListManagementGetGroups();
			if (resp) {
				setChoiceListMain(getValue(resp, `data.listGroups`, []));
				setChoiceList(getValue(resp, `data.listGroups`, []));
				if (id) {
					getValueList(id);
				}
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	const getValueList = async (id: any) => {
		try {
			setIsLoading1(true);
			let resp = await adminListManagementGetGroupsItems(id);
			if (resp) {
				setValueList(getValue(resp, `data.listItems`, []));
				setValueListMain(getValue(resp, `data.listItems`, []));
				setIsLoading1(false);
			} else {
				setIsLoading1(false);
			}
		} catch (error) {
			setIsLoading1(false);
		}
	};

	const getValueListRefetch = async (id: any) => {
		try {
			let resp = await adminListManagementGetGroupsItems(id);
			if (resp) {
				setValueListMain(getValue(resp, `data.listItems`, []));
				setValueList(getValue(resp, `data.listItems`, []));
				setIsLoading1(false);
			} else {
				setIsLoading1(false);
			}
		} catch (error) {
			setIsLoading1(false);
		}
	};

	const addChoice = async () => {
		if (text) {
			if (textEditId) {
				try {
					let resp = await adminListManagementPatchGroup(textEditId, {
						text: text,
					});
					if (resp) {
						toast.success(getValue(resp, "message", ""));
						setText("");
						getChoiceListRefetch(getValue(resp, "data.id", ""));
						handleModalChoice();
						setSelectedChoice(getValue(resp, "data.id", ""));
					}
				} catch (error) {}
			} else {
				try {
					let resp = await adminListManagementCreateGroup({ text: text });
					if (resp) {
						toast.success(getValue(resp, "message", ""));
						setText("");
						getChoiceListRefetch(getValue(resp, "data.id", ""));
						handleModalChoice();
						setSelectedChoice(getValue(resp, "data.id", ""));
					}
				} catch (error) {}
			}
		} else {
			toast.error("All fields are mandatory");
		}
	};

	const handleDeleteGroup = async () => {
		try {
			let resp = await adminListManagementDeleteGroup(deleteChoiceGroupID);
			if (resp) {
				toast.success(getValue(resp, "message", ""));
				getChoiceListRefetch("");
				handleDelete();
			} else {
				getChoiceList();
				handleDelete();
			}
		} catch (error) {}
	};

	const addChoiceItem = async () => {
		if (value) {
			if (valueEditId) {
				try {
					let resp = await adminListManagementPatchGroupItems(
						selectedChoice,
						valueEditId,
						{
							text: value,
						}
					);
					if (resp) {
						toast.success(getValue(resp, "message", ""));
						setValue("");
						setValueEditId("");
						getValueListRefetch(selectedChoice);
						handleModalChoiceValue();
					}
				} catch (error) {}
			} else {
				try {
					let resp = await adminListManagementCreateGroupItems(selectedChoice, {
						text: value,
					});
					if (resp) {
						toast.success(getValue(resp, "message", ""));
						setValue("");
						getValueListRefetch(selectedChoice);
						handleModalChoiceValue();
					}
				} catch (error) {}
			}
		} else {
			toast.error("All fields are mandatory");
		}
	};

	const handleDeleteValue = async () => {
		try {
			let resp = await adminListManagementDeleteGroupItems(
				selectedChoice,
				deleteChoiceValueID
			);
			if (resp) {
				toast.success(getValue(resp, "message", ""));
				getValueListRefetch(selectedChoice);

				handleDelete1();
			} else {
				handleDelete1();
			}
		} catch (error) {}
	};

	const handleChangeStatusGroup = async (
		id: string,
		status: boolean,
		text: string
	) => {
		try {
			let resp = await adminListManagementPatchGroup(id, {
				is_active: status,
				text: text,
			});
			if (resp) {
				toast.success(getValue(resp, "message", ""));
				getChoiceListRefetch("");
			}
		} catch (error) {}
	};
	const handleChangeStatusValue = async (
		id: string,
		status: boolean,
		text: string
	) => {
		try {
			let resp = await adminListManagementPatchGroupItems(selectedChoice, id, {
				is_active: status,
				text: text,
			});
			if (resp) {
				toast.success(getValue(resp, "message", ""));
				getChoiceListRefetch(selectedChoice);
			}
		} catch (error) {}
	};
	/* -------------------------------------------------------------------------- */
	/*                               Onchange section                             */
	/* -------------------------------------------------------------------------- */

	const handleChangeChoice = (id: any) => {
		setSelectedChoice(id);
		getValueListRefetch(id);
		// const urlSearchParams = new URLSearchParams(window.location.search);
		// const params = Object.fromEntries(urlSearchParams.entries());
		// let payload: any = {
		// 	groupId: id,
		// };
		// // removing null values
		// Object.keys(payload).forEach((key) => {
		// 	if (!payload[key] || payload[key] === undefined) {
		// 		delete payload[key];
		// 	}
		// });
		// let queryRequest = qs.stringify(payload);
		// navigate(`${window.location.pathname}?${queryRequest}`);
	};

	const handleChangeChoiceValue = (id: any) => {};

	const [isDelete, setIsDelete] = useState(false);
	const handleDelete = () => {
		setIsDelete(!isDelete);
	};
	const [isDelete1, setIsDelete1] = useState(false);
	const handleDelete1 = () => {
		setIsDelete1(!isDelete1);
	};

	const handleEditChoice = async (item: object) => {
		setText(getValue(item, `text`, ""));
		setTextEditId(getValue(item, `id`, ""));
		handleModalChoice();
	};

	const handleEditChoiceValue = async (item: object) => {
		setValue(getValue(item, `text`, ""));
		setValueEditId(getValue(item, `id`, ""));
		handleModalChoiceValue();
	};

	const handleDeleteChoiceValue = async (item: object) => {
		setDeleteChoiceValue(getValue(item, `text`, ""));
		setDeleteChoiceValueID(getValue(item, `id`, ""));
		handleDelete1();
	};

	const handleDeleteChoice = async (item: object) => {
		setDeleteChoiceGroup(getValue(item, `text`, ""));
		setDeleteChoiceGroupID(getValue(item, `id`, ""));
		handleDelete();
	};
	/* -------------------------------------------------------------------------- */
	/*                                Search section                              */
	/* -------------------------------------------------------------------------- */

	const [serchInput1, setSearchInput1] = useState("");
	const [serchInput2, setSearchInput2] = useState("");
	const [choiceListmain, setChoiceListMain] = useState([]);
	const [valueListMain, setValueListMain] = useState([]);
	const handleSearchDropdown = (e: string) => {
		setSearchInput1(e);
		if (e) {
			let main: any =
				choiceListmain.length > 0
					? choiceListmain.filter((item: any) =>
							item.text.toLowerCase().includes(e)
					  )
					: [];
			setChoiceList(main);
		} else {
			setChoiceList(choiceListmain);
		}
	};
	const handleSearchChoicelist = (e: string) => {
		setSearchInput2(e);
		if (e) {
			let main: any =
				valueListMain.length > 0
					? valueListMain.filter((item: any) =>
							item.text.toLowerCase().includes(e)
					  )
					: [];
			setValueList(main);
		} else {
			setValueList(valueListMain);
		}
	};

	return (
		<DashboardLayout
			permissions={getValue(props, `tabPermissionList`, [])}
			subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
		>
			{!isLoading &&
			!getValue(props, `subTabPermissionList`, []).includes("list_management_choice_list") ? (
				<NoAccessPage />
			) : (
				<>
					<div className="dashboard-wrapper__header">
						<h4 className="dashbaord-wrapper__header-title">Choice List</h4>
					</div>
					<section className="list-managemnet-wrapper d-flex align-items-flex-start">
						<div className="list-managemnet-wrapper__country-table w-100">
							<div className="list-managemnet-wrapper__country-common-container">
								<div
									className="list-managemnet-wrapper__form-group form-group"
									onClick={handleModalChoice}
								>
									{/* <input
										type="text"
										placeholder="Add Dropdown Name"
										className="form-control list-managemnet-wrapper__form-control"
									/> */}
									<input
										type="text"
										placeholder="Search dropdown"
										className="form-control list-managemnet-wrapper__form-control"
										onChange={(e) => handleSearchDropdown(e.target.value)}
										value={serchInput1}
										name="serchInput1"
									/>
									<div className="list-managemnet-wrapper__form-add-icon">
								<img src="/images/add-red-btn.svg" className="img-fluid" />
							</div>
								</div>
							</div>

							<h5 className="list-managemnet-wrapper__title">Dropdown List</h5>
							<div className="list-managemnet-wrapper__location">
								{isLoading || !props.permissionAPITriggered ? (
									<Loader />
								) : choiceList.length > 0 ? (
									choiceList.map((countryNameObj, index) => {
										return (
											<AddLocation
												item={countryNameObj}
												name={getValue(countryNameObj, `text`, "")}
												id={getValue(countryNameObj, `id`, "")}
												is_active={getValue(countryNameObj, `is_active`, "")}
												selected={selectedChoice}
												onChange={handleChangeChoice}
												handleEdit={handleEditChoice}
												handleDelete={handleDeleteChoice}
												handleChangeStatus={handleChangeStatusGroup}
												permissions={getValue(props, `permissions`, [])}
											/>
										);
									})
								) : (
									<p className="text-center mt-2">No Data Found</p>
								)}
							</div>
						</div>
						<div className="list-managemnet-wrapper__country-table w-100">
							<div className="list-managemnet-wrapper__country-common-container">
								<div
									className="list-managemnet-wrapper__form-group form-group"
									onClick={handleModalChoiceValue}
								>
									{/* <input
											type="text"
											placeholder="Add Choices"
											className="form-control list-managemnet-wrapper__form-control"
										/> */}
									<input
										type="text"
										placeholder="Search Choice List"
										className="form-control list-managemnet-wrapper__form-control"
										onChange={(e) => handleSearchChoicelist(e.target.value)}
										value={serchInput2}
										name="serchInput1"
									/>
									<div className="list-managemnet-wrapper__form-add-icon">
										<img src="/images/add-red-btn.svg" className="img-fluid" />
									</div>
								</div>
							</div>

							<h5 className="list-managemnet-wrapper__title">Choice List</h5>
							<div className="list-managemnet-wrapper__location">
								{isLoading1 || !props.permissionAPITriggered ? (
									<Loader />
								) : valueList.length > 0 ? (
									valueList.map((countryNameObj, index) => {
										return (
											<AddLocation
												item={countryNameObj}
												name={getValue(countryNameObj, `text`, "")}
												id={getValue(countryNameObj, `id`, "")}
												// selected={selectedChoice}
												onChange={handleChangeChoiceValue}
												handleEdit={handleEditChoiceValue}
												handleDelete={handleDeleteChoiceValue}
												is_active={getValue(countryNameObj, `is_active`, "")}
												handleChangeStatus={handleChangeStatusValue}
												permissions={getValue(props, `permissions`, [])}
											/>
										);
									})
								) : (
									<p className="text-center mt-2">No Data Found</p>
								)}
							</div>
						</div>
					</section>
					<ChoicePopup
						isOpen={isOpenChoice}
						handleModal={handleModalChoice}
						handleSubmit={addChoice}
						text={text}
						setText={setText}
						handleModalCancel={handleModalChoiceCancel}
					/>
					<ChoiceValuePopup
						isOpen={isOpenChoiceValue}
						handleModal={handleModalChoiceValue}
						handleSubmit={addChoiceItem}
						text={value}
						setText={setValue}
						handleModalCancel={handleModalChoiceValueCancel}
					/>
					<DeleteModal
						isOpen={isDelete}
						handleModal={handleDelete}
						handleSubmit={handleDeleteGroup}
						deleteValue={deleteChoiceGroup}
					/>
					<DeleteModal
						isOpen={isDelete1}
						handleModal={handleDelete1}
						handleSubmit={handleDeleteValue}
						deleteValue={deleteChoiceValue}
					/>
				</>
			)}
		</DashboardLayout>
	);
};

export default ChoiceList;
