import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import ImportPopup from "./importPopup";

function BulkUploadDropdown(props: any) {
	const { dropdownOptions, url, type } = props;
	const [selectedOption, setSelectedOption] = useState("");
	const [isBulkUploadPopupVisible, setBulkuploadPopupVisibility] =
		useState(false);
	return (
		<div>
			<UncontrolledDropdown>
				<DropdownToggle>
					<div className="add-filter-wrapper__filter-container">
						<div className="cursor-pointer">
							<img
								// src="/images/file-upload.svg"
								src="/images/upload_sharp.svg"
								className="img-fluid add-filter-wrapper__upload-image"
							/>
							<p className="add-filter-wrapper__filter-title">BULK UPLOAD</p>
						</div>
					</div>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu custom-menu">
					{getValue(dropdownOptions, `length`, 0) > 0 &&
						dropdownOptions.map((item: object) => {
							return (
								<DropdownItem
									onClick={() => {
										setSelectedOption(getValue(item, `value`, ""));
										setBulkuploadPopupVisibility(true);
									}}
								>
									{getValue(item, `label`, "")}
								</DropdownItem>
							);
						})}
				</DropdownMenu>
			</UncontrolledDropdown>
			{isBulkUploadPopupVisible && (
				<ImportPopup
					url={url}
					type={selectedOption}
					setBulkuploadPopupVisibility={setBulkuploadPopupVisibility}
					isBulkUploadPopupVisible={isBulkUploadPopupVisible}
					setSelectedOption={setSelectedOption}
				/>
			)}
		</div>
	);
}

export default BulkUploadDropdown;
