import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { getValue } from '@utils/lodash';
import './dropdown.scss';

export default function Dropdown(props:any) {
  return (
    <div className="mx-2 cursor-pointer d-flex align-items-center">
      {/* <h6 className="dropdown__title">Take Action</h6>
                <img src="/images/arrow-drop-white.svg" className="img-fluid" /> */}
      <UncontrolledDropdown>
        <DropdownToggle caret>Take Action</DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="dropdown-header-title"><b>Update Status</b></h6>
              {/* <img src="/images/close-black.svg" className="img-fluid" /> */}
            </div>
          </DropdownItem>
          {
            getValue(props,`filterOptions`,[]).map((item:object)=>{
              return <DropdownItem onClick={()=>props.handleUpdateStatus(getValue(item,`value`,''))} value={getValue(item,`value`,'')}>{getValue(item,`label`,'')}</DropdownItem>
            })
          }
          
          {/* <DropdownItem>MARK PARTIALLY COMPLIED</DropdownItem>
          <DropdownItem>MARK COMPLIED</DropdownItem>
          <DropdownItem>MARK COMPLIED</DropdownItem>
          <DropdownItem>MARK COMPLIED</DropdownItem> */}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}
