import React, { useEffect, useState } from 'react';
import Pagination from 'react-js-pagination';
import { getValue } from '@utils/lodash';
import './pagination.scss';

export default function PaginationPage(props: any) {
  const [options] = useState<any>([]);
  useEffect(() => {
    for (let i = 0; i < 11; i++) {
      if (i) options.push(i * 10);
    }
  }, []);
  return (
    <section className="pagination-wrapper d-flex align-items-center ">
      <div className="show-rows d-flex align-items-center">
       <p className="show-rows-count">Total Count: {props.totalCount}</p>
        <p className="show-rows-count">Show rows:</p>
        <select
          className="show-select"
          onChange={(e) => props.handleChangeLimit(e.target.value)}
        >
          {options.map((item: number) => {
            return (
              <option selected={item == props.limit} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      </div>
      <div className="show-count mx-4">
        {getValue(props, `pageNumber`, 0)} -{' '}
        {Math.ceil(
          getValue(props, `totalCount`, 0) / getValue(props, `limit`, 1)
        )}{' '}
        of{' '}
        {Math.ceil(
          getValue(props, `totalCount`, 0) / getValue(props, `limit`, 1)
        )}
      </div>

      <div>
        <Pagination
          activePage={props.pageNumber}
          itemsCountPerPage={props.limit}
          totalItemsCount={props.totalCount}
          pageRangeDisplayed={5}
          onChange={props.handleChangePagination}
        />
      </div>
    </section>
  );
}
