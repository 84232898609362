import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import ClientListNavigation from "@components/ClientList/clientlist-navigation";

import qs from "query-string";
import { getValue } from "@utils/lodash";
//import { adminGetUsers } from '@services/users.service';
import { NavLink } from "react-router-dom";
//import moment from 'moment';
import {
	adminDeleteSpecificCheckpoint,
	adminGetCheckpoint,
	adminUpdateBulkStatusCheckpoint,
} from "@services/checkpoint.service";
import { useNavigate, useParams } from "react-router-dom";
import FilterForm from "@components/common/AddFilter/FilterForm";
import NavigationBar from "@components/NotificationBar/index";
import DeleteModal from "@_common/deleteModal";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import DataTable from "@components/DataTable/index";
import PaginationPage from "@components/pagination/pagination";
import SearchHeader from "@components/common/SearchHeader";
import {
	adminGetCountriesListManagement,
	adminGetLegislationManagement,
	adminGetLegislationManagementRule,
	adminGetListManagementGroupCode,
	adminGetListManagementStateCategoryList,
	adminGetStatesListManagement,
} from "@services/list-management.service";
import Notfound from "@components/Not-Found";
import {
	adminAssignCheckedPoints,
	adminGetMappedCheckedPoints,
	adminGetMasterCheckedPoints,
	adminUnAssignCheckedPoints,
} from "@services/client-list.service";
import Loader from "@components/common/Loader";
import NoAccessPage from "@components/common/NoAccess";
import ScrollableReactTable from "@components/ScrollableDatatable";

interface IMappedCheckpointProps {}

const MappedCheckpoint: React.FunctionComponent<IMappedCheckpointProps> = (
	props: any
) => {
	let navigate = useNavigate();
	let OrgParams = useParams();
	/* -------------------------------------------------------------------------- */
	/*                               UseState Section                             */
	/* -------------------------------------------------------------------------- */

	const [isLoading, setIsLoading] = React.useState(true);
	const [list, setList] = React.useState<[]>([]);
	const [isFilterVisible, setFilterVisibility] = React.useState(false);
	/* -------------------------------------------------------------------------- */
	/*                               UseEffect Section                            */
	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
			getData();
			getCountriesList();
			getSmartFilterList();
		}
	}, [props.permissionAPITriggered]);

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
			getData();
			getCountriesList();
			getSmartFilterList();
			if (params.page_no) {
				setPage_no(parseInt(params.page_no));
			}
			if (params.search_text) {
				setSearchInput(params.search_text);
				setShowSearchTextbox(true);
			}
			if (params.limit) {
				setLimit(parseInt(params.limit));
			}
			setRequest({
				country_id: getValue(params, `country_id`, ""),
				state_id: getValue(params, `state_id`, ""),
				category_of_law_id: getValue(params, `category_of_law_id`, ""),
				legislation_id: getValue(params, `legislation_id`, ""),
				legislation_rule_id: getValue(params, `legislation_rule_id`, ""),
			});
			if (getValue(params, `country_id`, "")) {
				setFilterVisibility(true);
				getStatesList(getValue(params, `country_id`, ""));
			}
			if (getValue(params, `state_id`, "")) {
				getAlreadyAttachedData(getValue(params, `state_id`, ""));
				getLegislationList(getValue(params, `state_id`, ""));
			}
			if (getValue(params, `legislation_id`, "")) {
				getLegislationRuleList(getValue(params, `legislation_id`, ""));
			}
		}
	}, [window.location.href, props.permissionAPITriggered]);
	const [smartFilterList, setSmartFilterList] = useState([]);
	const [selectedSmartFilterIds, setSelectedSmartFilterIds] = useState<any>([]);
	const handleChangeSelectMulti = (e: any) => {
		setSelectedSmartFilterIds(e);
	};
	const getSmartFilterList = async () => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		let selectedIDs: any = urlSearchParams.getAll("smart_filter_tags");

		try {
			let resp = await adminGetListManagementGroupCode("smart-filter");
			if (resp) {
				let obj = getValue(resp, `data.listItems`, []).map((item: object) => ({
					id: getValue(item, `id`, ""),
					is_active: getValue(item, `is_active`, ""),
					text: getValue(item, `text`, ""),
					value: getValue(item, `text`, ""),
					label: getValue(item, `text`, ""),
				}));
				setSmartFilterList(obj);
				if (getValue(selectedIDs, `length`, 0) > 0) {
					let arr: [] | any = [];
					selectedIDs.map((item: object) => {
						let filtered = obj.filter((ob: any) => ob.id == item);
						if (getValue(filtered, `length`, 0) > 0) {
							arr.push(filtered[0]);
						}
					});
					setSelectedSmartFilterIds(arr);
				}
			} else {
			}
		} catch (error) {}
	};

	/* -------------------------------------------------------------------------- */
	/*                               API Section                                  */
	/* -------------------------------------------------------------------------- */

	const getData = async () => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		let selectedIDs: any = urlSearchParams.getAll("smart_filter_tags");

		let payload: any = {
			page_no: params.page_no ? parseInt(params.page_no) : 1,
			page_size: params.limit ? parseInt(params.limit) : limit,
			search_text: params.search_text,
			// status: "active",
			sort_by: params.sort_by,
			country_id: getValue(params, `country_id`, ""),
			state_id: getValue(params, `state_id`, ""),
			category_of_law_id: getValue(params, `category_of_law_id`, ""),
			legislation_id: getValue(params, `legislation_id`, ""),
			legislation_rule_id: getValue(params, `legislation_rule_id`, ""),
			domain_name: OrgParams.domain,
			smart_filter_tags: selectedIDs,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);

		try {
			setIsLoading(true);
			let resp = await adminGetMappedCheckedPoints(payload);
			// let resp = await adminGetUsers(queryRequest);
			if (resp) {
				setList(
					getValue(resp, `data.checkpoints`, []).map((item: object) => ({
						...item,
						category_of_lawName: getValue(item, `category_of_law.text`, ""),
						compliance_typeName: getValue(item, `compliance_type.text`, ""),
						frequencyName: getValue(item, `frequency.text`, ""),
						legislationName: getValue(item, `legislation.legislation_name`, ""),
						compliance_activity: getValue(item, `compliance_activity`, ""),
						legislation_ruleName: getValue(
							item,
							`legislation_rule.rule_name`,
							""
						),
					}))
				);
				setTotalCount(getValue(resp, `data.pagination.total`, 0));
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	const handleChangeAssign = async () => {
		try {
			let resp = await adminUnAssignCheckedPoints({
				checkpoint_ids: selectedIDs,
				domain_name: OrgParams.domain,
			});
			if (resp) {
				toast.success(getValue(resp, `message`, ""));
				setSelectedIDs([]);
				getData();
			}
		} catch (error) {}
	};

	/* -------------------------------------------------------------------------- */
	/*                               Search section                               */
	/* -------------------------------------------------------------------------- */
	const [showSearchTextbox, setShowSearchTextbox] = useState(false);
	const [search_text, setSearchInput] = useState<any>("");

	const toggleSearch = () => {
		setShowSearchTextbox((prevState) => !prevState);
	};
	const toggleSearchClose = () => {
		setSearchInput("");
		toggleSearch();
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (params.search_text) {
			delete params.search_text;
			let payload: any = { ...params };
			// removing null values
			Object.keys(payload).forEach((key) => {
				if (!payload[key] || payload[key] === undefined) {
					delete payload[key];
				}
			});
			let queryRequest = qs.stringify(payload);
			navigate(`${window.location.pathname}?${queryRequest}`);
			getData();
		}
	};

	const handleChangeSearch = async (e: any) => {
		setSearchInput(e.target.value);

		let payload: any = {
			search_text: e.target.value,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		navigate(`${window.location.pathname}?${queryRequest}`);
		if (!e.target.value) {
			getData();
		}
	};

	const [selectedIDs, setSelectedIDs] = useState<any>([]);
	const getTableState = (value: object) => {
		let selectedIndexList = Object.keys(
			getValue(value, `selectedRowIds`, {})
		).map((item) => parseInt(item));
		let selectedIds =
			list.length > 0
				? list.filter((_item: object, index: number) =>
						selectedIndexList.includes(index)
				  )
				: [];
		if (selectedIds.length > 0) {
			setSelectedIDs(
				selectedIds.map((item: object) => getValue(item, `id`, ""))
			);
		} else {
			setSelectedIDs([]);
		}
	};

	/* -------------------------------------------------------------------------- */
	/*                                 Delete section                             */
	/* -------------------------------------------------------------------------- */

	const [deleteValue, setDeleteValue] = useState("");
	const [deleteId, setDeleteId] = useState("");
	const handleDeleteFun = (id: string, value: string) => {
		setDeleteValue(value);
		setDeleteId(id);
		handleDelete();
	};
	const [isDelete, setIsDelete] = useState(false);
	const handleDelete = () => {
		setIsDelete(!isDelete);
	};

	const handleDeleteFunction = async () => {
		try {
			let resp = await adminDeleteSpecificCheckpoint(deleteId);
			if (resp) {
				toast.success(getValue(resp, `message`, ""));
				handleDelete();
				getData();
			}
		} catch (error) {}
	};

	/* -------------------------------------------------------------------------- */
	/*                               Onchange section                             */
	/* -------------------------------------------------------------------------- */

	const handleChangeSortBy = (value: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.sort_by = value;
		props.history.push({
			pathname: window.location.pathname,
			query: params,
		});
	};

	const handleChangeStatus = (value: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.status = value;
		props.history.push({
			pathname: window.location.pathname,
			query: params,
		});
	};
	/* -------------------------------------------------------------------------- */
	/*                               Pagination section                           */
	/* -------------------------------------------------------------------------- */

	const [totalCount, setTotalCount] = useState(0);
	const [limit, setLimit] = useState(100);
	const [page_no, setPage_no] = useState(1);

	const handleChangePagination = (page_no: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.page_no = page_no;
		Object.keys(params).forEach((key) => {
			if (!params[key] || params[key] === undefined) {
				delete params[key];
			}
		});
		let queryRequest = qs.stringify(params);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	const handleChangeLimit = (limit: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.limit = limit;
		Object.keys(params).forEach((key) => {
			if (!params[key] || params[key] === undefined) {
				delete params[key];
			}
		});
		let queryRequest = qs.stringify(params);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};
	const handleEdit = (id: string) => {
		navigate(`/checkpoint/active-detail/${id}`);
	};
	const rowClick = (obj: object) => {
		// navigate(`/checkpoint/active-detail/${getValue(obj, `original.id`, "")}`);
	};
	const columns = React.useMemo(
		() => [
			{
				Header: "Checkpoint ID",
				accessor: "checkpoint_id",
				width: 250,
				// Cell: ({ row }: any) => {
				// 	return (
				// 		<NavLink to={`/checkpoint/active-detail/${row.original.id}`}>
				// 			{row.values.checkpoint_id}
				// 		</NavLink>
				// 	);
				// },
			},
			{
				Header: "Category of Law",
				accessor: "category_of_lawName",
				width: 250,
			},
			{
				Header: "Legislation",
				accessor: "legislationName",
				width: 250,
			},
			{
				Header: "Rule",
				accessor: "legislation_ruleName",
				width: 250,
			},
			{
				Header: "Compliance Activity",
				accessor: "compliance_activity",
				width: 250,
			},
			{
				Header: "Reference",
				accessor: "reference",
				width: 250,
			},
			{
				Header: "Frequency",
				accessor: "frequencyName",
				width: 250,
			},

			// {
			// 	Header: "Action",
			// 	width: 250,
			// 	Cell: ({ row }: any) => {
			// 		return (
			// 			<>
			// 				<Button
			// 					color="primary"
			// 					onClick={() => handleEdit(row.original.id)}
			// 				>
			// 					Edit
			// 				</Button>
			// 				&nbsp;&nbsp;&nbsp;
			// 				<Button
			// 					color="danger"
			// 					onClick={() =>
			// 						handleDeleteFun(row.original.id, row.values.checkpoint_id)
			// 					}
			// 				>
			// 					Delete
			// 				</Button>
			// 			</>
			// 		);
			// 	},
			// },
		],
		[]
	);

	/* -------------------------------------------------------------------------- */
	/*                                  Smart Filters                             */
	/* -------------------------------------------------------------------------- */
	const [request, setRequest] = useState<any>({
		country_id: "101",
		state_id: "",
		category_of_law_id: "",
		legislation_id: "",
		legislation_rule_id: "",
	});
	useEffect(() => {
		getStatesList(request.country_id);
	}, [request.country_id]);

	const [countryList, setCountryList] = useState([]);
	const [states, setStates] = useState([]);

	const [categoryOfLawList, setCategoryOfLawList] = useState([]);
	const [legislationList, setLegislationList] = useState([]);
	const [ruleList, setRuleList] = useState([]);

	const reset = () => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		let payload: any = {
			page_no: params.page_no,
			search_text: getValue(params, `search_text`, ""),
			limit: getValue(params, `limit`, ""),
		};
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		navigate(`${window.location.pathname}?${queryRequest}`);
		getData();
		reset1();
	};
	const reset1 = () => {
		setRequest({
			...request,
			// country_id: "",
			state_id: "",
			category_of_law_id: "",
			legislation_id: "",
			legislation_rule_id: "",
		});
		setSelectedSmartFilterIds([]);
	};

	const getCountriesList = async () => {
		try {
			let resp = await adminGetCountriesListManagement();
			if (resp) {
				setCountryList(
					getValue(resp, `data.countries`, []).map((item: object) => ({
						...item,
						value: getValue(item, `name`, ""),
						label: getValue(item, `name`, ""),
					}))
				);
			} else {
			}
		} catch (error) {}
	};

	const getStatesList = async (countryId: number) => {
		if (countryId)
			try {
				let resp = await adminGetStatesListManagement(countryId);
				if (resp) {
					setStates(
						getValue(resp, `data.states`, []).map((item: object) => ({
							...item,
							value: getValue(item, `name`, ""),
							label: getValue(item, `name`, ""),
						}))
					);
				} else {
				}
			} catch (error) {}
	};

	const getAlreadyAttachedData = async (state_id: string) => {
		if (state_id)
			try {
				let resp = await adminGetListManagementStateCategoryList(state_id);
				if (resp) {
					setCategoryOfLawList(
						getValue(resp, `data.stateCategoryOfLaw`, []).map(
							(item: object) => ({
								// ...item,
								id: getValue(item, `category_of_law.id`, ""),
								value: getValue(item, `category_of_law.text`, ""),
								label: getValue(item, `category_of_law.text`, ""),
							})
						)
					);
				} else {
				}
			} catch (error) {}
	};

	const getLegislationList = async (stateId: string) => {
		if (stateId)
			try {
				let resp = await adminGetLegislationManagement(stateId);
				if (resp) {
					setLegislationList(
						getValue(resp, `data.legislations`, []).map((item: object) => ({
							...item,
							value: getValue(item, `legislation_name`, ""),
							label: getValue(item, `legislation_name`, ""),
						}))
					);
				} else {
				}
			} catch (error) {}
	};
	const getLegislationRuleList = async (legislation_id: string) => {
		if (legislation_id)
			try {
				let resp = await adminGetLegislationManagementRule(legislation_id);
				if (resp) {
					setRuleList(
						getValue(resp, `data.legislationRules`, []).map((item: object) => ({
							...item,
							value: getValue(item, `rule_name`, ""),
							label: getValue(item, `rule_name`, ""),
						}))
					);
				} else {
				}
			} catch (error) {}
	};

	const handleChangeSelect = (e: any, type: string) => {
		if (type === "country_id") {
			getStatesList(e.id);
			setStates([]);
			setCategoryOfLawList([]);
			setLegislationList([]);
			setRuleList([]);
			setRequest({
				...request,
				country_id: e.id,
				state_id: "",
				category_of_law_id: "",
				legislation_id: "",
				legislation_rule_id: "",
			});
		}
		if (type === "state_id") {
			getAlreadyAttachedData(e.id);
			getLegislationList(e.id);
			setCategoryOfLawList([]);
			setLegislationList([]);
			setRuleList([]);
			setRequest({
				...request,
				state_id: e.id,
				category_of_law_id: "",
				legislation_id: "",
				legislation_rule_id: "",
			});
		}
		if (type === "category_of_law_id") {
			setRequest({
				...request,
				category_of_law_id: e.id,
			});
		}
		if (type === "legislation_id") {
			getLegislationRuleList(e.id);
			setRequest({
				...request,
				legislation_id: e.id,
				legislation_rule_id: "",
			});
		}

		if (type === "legislation_rule_id") {
			setRequest({
				...request,
				legislation_rule_id: e.id,
			});
		}
	};

	const handleSubmitSmartFilter = () => {
		let selectedSmartFilterIdsPayload = JSON.parse(
			JSON.stringify(selectedSmartFilterIds)
		);
		if (
			getValue(request, `country_id`, "")
			// getValue(request, `state_id`, "")
			// && getValue(request, `category_of_law_id`, "") &&
			// getValue(request, `legislation_id`, "") &&
			// getValue(request, `legislation_rule_id`, "")
		) {
			const urlSearchParams = new URLSearchParams(window.location.search);
			const params = Object.fromEntries(urlSearchParams.entries());
			let payload: any = {
				page_no: params.page_no,
				country_id: getValue(request, `country_id`, ""),
				state_id: getValue(request, `state_id`, ""),
				category_of_law_id: getValue(request, `category_of_law_id`, ""),
				legislation_id: getValue(request, `legislation_id`, ""),
				legislation_rule_id: getValue(request, `legislation_rule_id`, ""),
				search_text: getValue(params, `search_text`, ""),
				limit: getValue(params, `limit`, ""),
				smart_filter_tags:
					selectedSmartFilterIdsPayload.length > 0
						? selectedSmartFilterIdsPayload.map((item: object) =>
								getValue(item, `id`, "")
						  )
						: [],
			};
			Object.keys(payload).forEach((key) => {
				if (!payload[key] || payload[key] === undefined) {
					delete payload[key];
				}
			});
			let queryRequest = qs.stringify(payload);
			navigate(`${window.location.pathname}?${queryRequest}`);
		} else {
			toast.error("All fields are mandatory");
		}
	};

	const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());

	let country_ID = countryList.filter(
		(item: object) =>
			getValue(item, `id`, "") == getValue(request, `country_id`, "")
	);
	let state_ID = states.filter(
		(item: object) =>
			getValue(item, `id`, "") == getValue(request, `state_id`, "")
	);
	let cat_ID = categoryOfLawList.filter(
		(item: object) =>
			getValue(item, `id`, "") == getValue(request, `category_of_law_id`, "")
	);
	let leg_ID = legislationList.filter(
		(item: object) =>
			getValue(item, `id`, "") == getValue(request, `legislation_id`, "")
	);
	let rule_ID = ruleList.filter(
		(item: object) =>
			getValue(item, `id`, "") == getValue(request, `legislation_rule_id`, "")
	);

	const [check, setCheck] = useState(false);
	const handleRemoveNavItems = () => {
		setCheck(!check);
		setSelectedIDs([]);
	};
	const getTrProps = (rowInfo: any) => {
		return {};
	};
	return (
		<DashboardLayout
			permissions={getValue(props, `tabPermissionList`, [])}
			subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
		>
			{!isLoading &&
			!getValue(props, `subTabPermissionList`, []).includes("client_active") ? (
				<NoAccessPage />
			) : (
				<>
					<ClientListNavigation
						id={OrgParams.id}
						headerName={"Mapped Checkpoint"}
						domain={OrgParams.domain}
					/>

					{getValue(selectedIDs, `length`, 0) > 0 && (
						<NavigationBar
							selectedIDs={selectedIDs}
							isMarkInactiveSectionVisible={false}
							text={"UNASSIGN CHECKPOINTS"}
							handleRemoveNavItems={handleRemoveNavItems}
							handleChangeAssign={handleChangeAssign}
							assign={true}
							permissions={props.permissions}
						/>
					)}
					{/* <div className="dashboard-wrapper__header">
				<h4 className="dashbaord-wrapper__header-title">Checkpoint</h4>
			</div> */}

					<div className="admin-wrapper position-relative">
						<div className="admin-wrapper position-relative">
							<SearchHeader
								route={"/checkpoint/active-detail"}
								searchInput={search_text}
								showSearchTextbox={showSearchTextbox}
								toggleSearch={toggleSearch}
								toggleSearchClose={toggleSearchClose}
								handleChangeSearch={handleChangeSearch}
								isBulkUploadVisible={false}
								smartFilter={false}
								setFilterFormVisibility={() => {
									setFilterVisibility(!isFilterVisible);
								}}
								isFilterVisible={isFilterVisible}
								placeholder={"Search Checkpoint ID"}
								add={"false"}
							/>
						</div>
						{isFilterVisible && (
							<FilterForm
								setFilterFormVisibility={() => {
									setFilterVisibility(!isFilterVisible);
								}}
								countryList={countryList}
								states={states}
								categoryOfLawList={categoryOfLawList}
								legislationList={legislationList}
								ruleList={ruleList}
								handleChangeSelect={handleChangeSelect}
								country_ID={country_ID}
								state_ID={state_ID}
								cat_ID={cat_ID}
								leg_ID={leg_ID}
								rule_ID={rule_ID}
								reset={reset}
								handleSubmitSmartFilter={handleSubmitSmartFilter}
								// smart filter
								selectedSmartFilterIds={selectedSmartFilterIds}
								handleChangeSelectMulti={handleChangeSelectMulti}
								additionalFilter={true}
								smartFilterList={smartFilterList}
							/>
						)}
						{/* <AddFilter
					isFilterVisible={isFilterVisible}
					isFilterHeadingVisible={true}
					isFilterSubHeadingVisible={true}
					isBulkUploadVisible={true}
					addButtonLink={"/checkpoint/active-detail"}
					setFilterFormVisibility={() => {
						setFilterVisibility(!isFilterVisible);
					}}
				/> */}
						{isLoading || !props.permissionAPITriggered ? (
							<Loader />
						) : list.length > 0 ? (
							<ScrollableReactTable
								columns={columns}
								data={list}
								getTableState={getTableState}
								rowClick={rowClick}
								check={check}
								selectedIDs={selectedIDs}
								getTrProps={getTrProps}
							/>
						) : (
							<>
								{Object.keys(params).length !== 0 ? (
									<>
										<Notfound />
									</>
								) : (
									<Notfound />
								)}
							</>
						)}
					</div>
					<div className="footer-wrapper d-flex justify-content-between">
						{list.length > 0 && (
							<img
								src="/images/building-illustration.svg"
								className="img-fluid"
							/>
						)}
						{totalCount > 100 ? (
							<PaginationPage
								totalCount={totalCount}
								limit={limit}
								pageNumber={page_no}
								handleChangePagination={handleChangePagination}
								handleChangeLimit={handleChangeLimit}
							/>
						) : (
							""
						)}
					</div>
					<DeleteModal
						isOpen={isDelete}
						handleModal={handleDelete}
						handleSubmit={handleDeleteFunction}
						deleteValue={deleteValue}
					/>
				</>
			)}
		</DashboardLayout>
	);
};

export default MappedCheckpoint;
