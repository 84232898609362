import LoginPage from "@pages/Auth/Login";
import PasswordReset from "@pages/Auth/PasswordReset";
import ResetPasswordPage from "@pages/Auth/ResetPassword";
import SentEmail from "@pages/Auth/SentEmail";

export const AuthRoutes = [
	{ path: "/", name: "Login", component: LoginPage  },
	{ path: "/login", name: "Login", component: LoginPage  },
	{
		path: "/reset-password",
		name: "ResetPassword",
		component: PasswordReset ,
	},
	{
		path: "/auth/set-password",
		name: "PasswordReset",
		component: ResetPasswordPage ,
	},
	{ path: "/sent-email", name: "SentEmail", component: SentEmail  },
];
