import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import AddLocation from "@components/list-management/AddLocation/index";
import "./country.scss";
import {
	adminGetCitiesListManagement,
	adminGetCountriesListManagement,
	adminGetStatesListManagement,
} from "@services/list-management.service";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import qs from "query-string";
import Loader from "@components/common/Loader";
import NoAccessPage from "@components/common/NoAccess";

interface ICountryProps {}

const Country: React.FunctionComponent<ICountryProps> = (props: any) => {
	let navigate = useNavigate();
	let params = useParams;
	/* -------------------------------------------------------------------------- */
	/*                               UseState Section                             */
	/* -------------------------------------------------------------------------- */

	const [isLoading, setIsLoading] = useState(true);
	const [isLoading1, setIsLoading1] = useState(true);
	const [isLoading2, setIsLoading2] = useState(true);

	const [countryList, setCountryList] = useState([]);
	const [states, setStates] = useState([]);
	const [cityList, setCityList] = useState([]);

	const [selectedCountry, setSelectedCountry] = useState(0);
	const [selectedState, setSelectedState] = useState();
	const [selectedCity, setSelectedCity] = useState();

	/* -------------------------------------------------------------------------- */
	/*                               UseEffect Section                            */
	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		getCountriesList();
		
	}, [props.permissionAPITriggered]);

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
			if (getValue(params, `countryId`, "")) {
				getStatesList(parseInt(getValue(params, `countryId`, "")));
				setSelectedCountry(
					getValue(params, `countryId`, "")
						? getValue(params, `countryId`, "")
						: ""
				);
			}

			if (getValue(params, `stateId`, "")) {
				getCitiesList(parseInt(getValue(params, `stateId`, "")));
				setSelectedState(
					getValue(params, `stateId`, "") ? getValue(params, `stateId`, "") : ""
				);
			}
		}
		
	}, [window.location.href,props.permissionAPITriggered])

	/* -------------------------------------------------------------------------- */
	/*                               API Section                                  */
	/* -------------------------------------------------------------------------- */

	const getCountriesList = async () => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		try {
			setIsLoading(true);
			let resp = await adminGetCountriesListManagement();
			if (resp) {
				setCountryListMain(
					getValue(resp, `data.countries`, []).filter(
						(item: object) => getValue(item, `id`, "") == 101
					)
				);
				setCountryList(
					getValue(resp, `data.countries`, []).filter(
						(item: object) => getValue(item, `id`, "") == 101
					)
				);
				if (getValue(params, `countryId`, "")) {
					setSelectedCountry(getValue(params, `countryId`, ""));
					getStatesList(getValue(params, `countryId`, ""));
				} else {
					// setSelectedCountry(getValue(resp, `data.countries[${0}].id`, ''));
					setSelectedCountry(101);
					getStatesList(101);
					// if (getValue(resp, `data.countries[${0}].id`, '')) {
					//   getStatesList(getValue(resp, `data.countries[${0}].id`, ''));
					// }
				}

				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	const getStatesList = async (countryId: number) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());

		try {
			setIsLoading1(true);
			let resp = await adminGetStatesListManagement(countryId);
			if (resp) {
				setStates(getValue(resp, `data.states`, []));

				if (getValue(params, `stateId`, "")) {
					setSelectedState(getValue(params, `stateId`, ""));
					getCitiesList(getValue(params, `stateId`, ""));
				} else {
					setSelectedState(getValue(resp, `data.states[${0}].id`, ""));
					if (getValue(resp, `data.states[${0}].id`, "")) {
						getCitiesList(getValue(resp, `data.states[${0}].id`, ""));
					}
				}
				setIsLoading1(false);
			} else {
				setIsLoading1(false);
			}
		} catch (error) {
			setIsLoading1(false);
		}
	};

	const getCitiesList = async (stateId: number) => {
		try {
			setIsLoading2(true);
			let resp = await adminGetCitiesListManagement(stateId);
			if (resp) {
				setCityList(getValue(resp, `data.cities`, []));
				setSelectedCity(getValue(resp, `data.cities[${0}].id`, ""));

				setIsLoading2(false);
			} else {
				setIsLoading2(false);
			}
		} catch (error) {
			setIsLoading2(false);
		}
	};

	/* -------------------------------------------------------------------------- */
	/*                               Onchange section                             */
	/* -------------------------------------------------------------------------- */

	const handleChangeCountry = (id: any) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		let payload: any = {
			countryId: id,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	const handleChangeState = (id: any) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		let payload: any = {
			countryId: params.countryId,
			stateId: id,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	const handleChangeCity = (id: any) => {};

	/* -------------------------------------------------------------------------- */
	/*                                Search section                              */
	/* -------------------------------------------------------------------------- */

	const [serchInput1, setSearchInput1] = useState("");
	const [countryListMain, setCountryListMain] = useState([]);
	const handleSearchContry = (e: string) => {
		setSearchInput1(e);
		if (e) {
			let main: any =
				countryListMain.length > 0
					? countryListMain.filter((item: any) =>
							item.text.toLowerCase().includes(e)
					  )
					: [];
			setCountryList(main);
		} else {
			setCountryList(countryListMain);
		}
	};
	const [permissionLoading, setPermissionLoading] = React.useState(true);

	return (
		<DashboardLayout
			permissions={getValue(props, `tabPermissionList`, [])}
			subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
		>
			{!permissionLoading &&
			!getValue(props, `subTabPermissionList`, []).includes("country_list") ? (
				<NoAccessPage />
			) : (
				<>
					<div className="dashboard-wrapper__header">
						<h4 className="dashbaord-wrapper__header-title">Country</h4>
					</div>
					<section className="list-managemnet-wrapper d-flex align-items-flex-start">
						<div className="list-managemnet-wrapper__country-table w-100">
							<div className="list-managemnet-wrapper__country-common-container">
								<div className="list-managemnet-wrapper__form-group form-group">
									<input
										type="text"
										placeholder="Select Country"
										// className="form-control list-managemnet-wrapper__form-control"
										// disabled
										name="serchInput1"
										value={serchInput1}
										onChange={(e) => handleSearchContry(e.target.value)}
									/>
									{/* <div className="list-managemnet-wrapper__form-add-icon">
                <img src="/images/add-red-btn.svg" className="img-fluid" />
              </div> */}
								</div>
							</div>
							<h5 className="list-managemnet-wrapper__title">Country</h5>
							{isLoading ? (
								<Loader />
							) : (
								countryList.map((countryNameObj, index) => {
									return (
										<AddLocation
											id={getValue(countryNameObj, `id`, "")}
											name={getValue(countryNameObj, `name`, "")}
											selected={selectedCountry}
											onChange={handleChangeCountry}
										/>
									);
								})
							)}
						</div>
						<div className="list-managemnet-wrapper__country-table w-100 position-relative">
							<div className="list-managemnet-wrapper__country-common-container">
								<div className="list-managemnet-wrapper__form-group form-group">
									<input
										type="text"
										placeholder="Select State"
										className="form-control list-managemnet-wrapper__form-control"
										// disabled
									/>
									{/* <div className="list-managemnet-wrapper__form-add-icon">
                <img src="/images/add-red-btn.svg" className="img-fluid" />
              </div> */}
								</div>
							</div>
							<h5 className="list-managemnet-wrapper__title">State</h5>
							{isLoading2 ? (
								<Loader />
							) : getValue(states, `length`, 0) > 0 ? (
								states.map((countryNameObj, index) => {
									return (
										<AddLocation
											id={getValue(countryNameObj, `id`, "")}
											name={getValue(countryNameObj, `name`, "")}
											selected={selectedState}
											onChange={handleChangeState}
										/>
									);
								})
							) : (
								<p className="text-center mt-2">No Data Found</p>
							)}
						</div>
						<div className="list-managemnet-wrapper__country-table w-100 position-relative">
							<div className="list-managemnet-wrapper__country-common-container">
								<div className="list-managemnet-wrapper__form-group form-group">
									<input
										type="text"
										placeholder="Select City"
										className="form-control list-managemnet-wrapper__form-control"
										// disabled
									/>
									{/* <div className="list-managemnet-wrapper__form-add-icon">
                <img src="/images/add-red-btn.svg" className="img-fluid" />
              </div> */}
								</div>
							</div>
							<h5 className="list-managemnet-wrapper__title">State</h5>
							{isLoading2 ? (
								<Loader />
							) : getValue(cityList, `length`, 0) > 0 ? (
								cityList.map((countryNameObj, index) => {
									return (
										<AddLocation
											id={getValue(countryNameObj, `id`, "")}
											name={getValue(countryNameObj, `name`, "")}
											selected={selectedCity}
											onChange={handleChangeCity}
										/>
									);
								})
							) : (
								<p className="text-center mt-2">No Data Found</p>
							)}
						</div>
					</section>
					<img
						src="/images/profile-illustration.svg"
						className="py-3 px-4 img-fluid client-list-details-wrapper__illustration"
					></img>
				</>
			)}
		</DashboardLayout>
	);
};

export default Country;
