import { AdminRoutes } from "./private/admin.routes";
import { AuditRoutes } from "./private/audit.routes";
import { CheckpointRoutes } from "./private/checkpoint.routes";
import { ClientListRoutes } from "./private/clientlist.routes";
import { DashboardRoutes } from "./private/dashboard.routes";
import { ListmanagementRoutes } from "./private/listmanagement.routes";
import { OnPremRoutes } from "./private/on-prem.routes";
import { ReportRoutes } from "./private/reports.routes";
import { SupportRoutes } from "./private/support.routes";


export const PrivateRoutes = [
...AdminRoutes,
...AuditRoutes,
...CheckpointRoutes,
...ClientListRoutes,
...DashboardRoutes,
...ListmanagementRoutes,
...ReportRoutes,
...SupportRoutes,
...OnPremRoutes
]