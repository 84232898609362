import { useEffect, useRef, useState } from "react";
import "./searchstyle.scss";
import { getValue, setValue } from "@utils/lodash";
const CheckpointSearchableDropdownSelect = (props) => {
	const {
		options,
		label,
		id,
		handleChange,
		handleClick,
		value,
		placeholder,
		searchLoading,
		removeClose,
	} = props;

	/* -------------------------------------------------------------------------- */
	/*                               UseState Section                             */
	/* -------------------------------------------------------------------------- */
	const [query, setQuery] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const [inputChange, setInputChange] = useState(false);
	const inputRef = useRef(null);
	/* -------------------------------------------------------------------------- */
	/*                               UseEffect Section                            */
	/* -------------------------------------------------------------------------- */
	useEffect(() => {
		document.addEventListener("click", toggle);
		document.addEventListener("mouseup", toggle);
		return () => {
			document.removeEventListener("click", toggle);
			document.addEventListener("mouseup", toggle);
		};
	}, []);

	useEffect(() => {
		setQuery("");
	}, [props.resetInput]);
	/* -------------------------------------------------------------------------- */
	/*                               Onchange section                             */
	/* -------------------------------------------------------------------------- */
	function toggle(e) {
		setIsOpen(e && e.target === inputRef.current);
	}

	const filter = (options) => {
		return getValue(options, `length`, 0) > 0
			? options.filter(
					(option) =>
						getValue(option, `[${label}]`, "") &&
						option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
			  )
			: [];
	};
	useEffect(() => {
		if (query) {
			setInputChange(true);
		}
	}, [query]);

	return (
		<div
			className={"dropdownselect"}
			style={getValue(props, `className`, "") ? { width: "720px" } : {}}
		>
			<div className="control">
				<div className="selected-value">
					<input
						ref={inputRef}
						type="text"
						value={
							inputChange
								? query
									? query
									: getValue(value, `label`, "")
								: getValue(value, `label`, "")
								? getValue(value, `label`, "")
								: query
						}
						name="searchTerm"
						// autocomplete="new-password"
						autocomplete="off"
						onChange={(e) => {
							setQuery(e.target.value);
							handleChange(e.target.value);
						}}
						onClick={toggle}
						placeholder={placeholder}
						style={getValue(props, `className`, "") ? { width: "740px" } : {}}
					/>
				</div>
				<div className="line"></div>
				{searchLoading ? (
					<div className="arrowtext">...</div>
				) : (
					<div className={`arrow ${isOpen ? "open" : ""}`}></div>
				)}
				{/* <div className="line" onClick={handleTriggerAPI}>&#128269;</div>&#10006; */}
				{(query || getValue(value, `label`, "")) && !removeClose && (
					<div
						className="close"
						onClick={(e) => {
							setQuery("");
							handleChange(null);
						}}
					>
						&#x2715;
					</div>
				)}
			</div>

			<div
				className={`options ${isOpen ? "open" : ""}`}
				style={getValue(props, `className`, "") ? { width: "740px" } : {}}
			>
				{getValue(options, `length`, 0) > 0 &&
				getValue(filter(options), `length`, 0) > 0 ? (
					filter(options).map((option, index) => {
						return (
							<div
								onClick={() => {
									handleClick(option);
									setInputChange(false);
								}}
								className={`option ${
									option[id] === getValue(value, `id`, "") ? "selected" : ""
								}`}
								key={`${id}-${index}`}
							>
								{option[label]}
							</div>
						);
					})
				) : (
					<div className={`option `} style={{ textAlign: "center" }}>
						No Data Found
					</div>
				)}
			</div>
			{props.validator && (
				<span className="form-error">
					{props.validator.current.message(props.name, props.value, "required")}
				</span>
			)}
		</div>
	);
};

export default CheckpointSearchableDropdownSelect;
