import * as React from 'react';
import { toast } from 'react-toastify';
import { Modal, ModalBody } from 'reactstrap';
import {
	clientAuditUploadFile,
	clientUploadSubmitCheckpointLocation,
	vendorFileUpload,
	vendorUploadSubmitCheckpointLocation,
} from '@services/auth.service';
import { getValue } from '@utils/lodash';
import './upload-modal.scss';

interface IUploadModalProps {
	isOpen: boolean;
	handleModal: () => void;
	getData: () => void;
	vendorInfo: object;
}

const UploadModal: React.FunctionComponent<IUploadModalProps> = (props) => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [submitLoading, setSubmitLoading] = React.useState(false);
	const [uploadedDocuments, setUploadedDocuments] = React.useState<any>([]);

	const handleSubmit = async () => {
		try {
			setSubmitLoading(true);
			let payload = {
				vendor_checkpoint: [
					{
						id: getValue(props, `vendorInfo.id`, ''),
						document_ids: uploadedDocuments.map((item: object) =>
							getValue(item, `id`, '')
						),
					},
				],
				domain_name: getValue(localStorage, `client`, ''),
			};
			let resp = await clientUploadSubmitCheckpointLocation(payload);
			if (resp) {
				setSubmitLoading(false);
				props.handleModal();
				props.getData();
				toast.success('Uploaded successfully');
			} else {
				props.getData();
				setSubmitLoading(false);
			}
		} catch (error) {
			setSubmitLoading(false);
		}
	};

	const handleUploadDocumnets = async (e: any) => {
		Array.from(e.target.files).forEach(async (item: any) => {
			let formData = new FormData();
			formData.append('file', item);
			formData.append('domain_name', getValue(localStorage, `client`, ''));
			try {
				let resp = await clientAuditUploadFile(formData);
				if (resp) {
					uploadedDocuments.push(getValue(resp, `data`, {}));
					setUploadedDocuments([...uploadedDocuments]);
					e.target.value = null;
				} else {
					e.target.value = null;
				}
			} catch (error) {
				e.target.value = null;
			}
		});
	};

	const handleRemoveUploadedFiles = (id: string) => {
		let filtered = uploadedDocuments.filter(
			(item: object) => getValue(item, `id`, '') !== id
		);
		setUploadedDocuments(filtered);
	};
	return (
		<div className="comments-popup">
			<Modal
				isOpen={getValue(props, `isOpen`, '')}
				toggle={getValue(props, `isOpen`, '')}
				centered
				size="lg"
			>
				<div className="comments-modal">
					<div className="comments-popup__header d-flex align-items-center justify-content-between mb-3">
						<h3 className="comments-popup__header-title">Attach File</h3>
						<div className="d-flex justify-content-end">
							<button
								className="ascent-button ascent-button--header-buttons ascent-button--primary mx-3"
								onClick={() => props.handleModal()}
							>
								Cancel
							</button>
							{submitLoading ? (
								<button className="ascent-button ascent-button--header-buttons ascent-button--secondary">
									Please wait...
								</button>
							) : (
								<button
									className="ascent-button ascent-button--header-buttons ascent-button--secondary"
									onClick={handleSubmit}
									disabled={getValue(uploadedDocuments, `length`, 0) === 0}
								>
									{'Submit'}
								</button>
							)}
						</div>
					</div>
					<ModalBody>
						<div>
							<label
								className="input-file-label w-100 text-center cursor-pointer mb-4"
								htmlFor="input-file"
							>
								<input
									type={'file'}
									onChange={handleUploadDocumnets}
									multiple
									className="d-none"
									id="input-file"
								/>
								<div className="custom-inputfile">
									<div className="m-auto">
										<img
											src="/images/input-file.png"
											className="img-fluid mb-2"
											height={40}
											width={40}
										/>
										<p className="custom-inputfile__text">
											Drag & Drop Files here
										</p>
										<p className="custom-inputfile__text">OR</p>
										<a href="#0">Browse Files</a>
									</div>
								</div>
							</label>
							<h3 className="attach-files-wrapper__select">
								Select from uploaded files
							</h3>
							{getValue(uploadedDocuments, `length`, 0) > 0 ? (
								uploadedDocuments.map((item: object, key: number) => {
									return (
										<div
											key={key}
											className="attach-files-wrapper__files cursor-pointer d-flex justify-content-between"
										>
											<p>
												{getValue(item, `name`, '')}
												{getValue(item, `extension`, '')}
											</p>
											<div>
												<img
													src="/images/close-black.svg"
													className="img-fluid cursor-pointer"
													onClick={() =>
														handleRemoveUploadedFiles(getValue(item, `id`, ''))
													}
												/>
											</div>
										</div>
									);
								})
							) : (
								<p className="text-center mt-2"> No Data Found</p>
							)}
							<div></div>
						</div>
					</ModalBody>
				</div>
			</Modal>
		</div>
	);
};

export default UploadModal;
