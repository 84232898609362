import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const adminCreateUser = (payload: object) =>
	post(`${config.API_URL}/users`, payload);

export const adminGetUsers = (queryRequest:string) => get(`${config.API_URL}/users?${queryRequest}`);

export const adminGetSpecificUser = (id: string) =>
	get(`${config.API_URL}/users/${id}`);

export const adminUpdateSpecificUser = (id: string, payload: object) =>
	patch(`${config.API_URL}/users/${id}`, payload);

export const adminDeleteSpecificUser = (id: string) =>
	Delete(`${config.API_URL}/users/${id}`);

	export const adminUpdateBulkUser = ( payload: object) =>
	// patch(`${config.API_URL}/users/bulk/active-inactive`, payload);
	patch(`${config.API_URL}/users/client/bulk/active-inactive`, payload);

	export const getAllPermissions = () =>
	get(`${config.API_URL}/users/permissions/list`);
	
