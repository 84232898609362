import CheckPoint from "@pages/ListMangement/CheckPoint";
import ChoiceList from "@pages/ListMangement/ChoiceList";
import Country from "@pages/ListMangement/Country";

export const ListmanagementRoutes = [
	{
		path: "/list-management/country",
		name: "ListManagement",
		component: Country,
	},
	{
		path: "/list-management/choice-list",
		name: "list_management_choice_list",
		component: ChoiceList,
	},
	{
		path: "/list-management/checkPoint",
		name: "list_management_checkpoint",
		component: CheckPoint,
	},
];
