import * as React from "react";
import { toast } from "react-toastify";
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import InputField from "@_common/InputRuleFeild";
import {
  adminCreateLegislationManagementRule,
  adminUpdateSpecificLegislationManagementRule,
} from "@services/list-management.service";
import { getValue } from "@utils/lodash";
import "../popup/addpopup.scss";

interface INewRulePopupProps {
  isOpen: boolean;
  handleModal: () => void;
  selectedLegislation: any;
  getLegislationRuleList: (id: any) => void;
  editObj: object;
  handleModalCancel: () => void;
}

const NewRulePopup: React.FunctionComponent<INewRulePopupProps> = (props) => {
  const [rule_name, setRule_name] = React.useState("");
  const [rule_code, setRule_code] = React.useState("");
  React.useEffect(() => {
    setRule_name(getValue(props, `editObj.rule_name`, ""));
    setRule_code(getValue(props, `editObj.rule_code`, ""));
  }, [getValue(props, `editObj.rule_name`, "")]);

  const handleSubmit = async () => {
    if (props.selectedLegislation) {
      if (getValue(props, `editObj.id`, "")) {
        try {
          let resp = await adminUpdateSpecificLegislationManagementRule(
            getValue(props, `editObj.id`, ""),
            {
              rule_name: rule_name,
              legislation_id: parseFloat(props.selectedLegislation),
              rule_code: rule_code,
            }
          );
          if (resp) {
            toast.success(getValue(resp, "message", ""));
            props.handleModalCancel();
            setRule_name("");
            props.getLegislationRuleList(props.selectedLegislation);
          }
        } catch (error) {}
      } else {
        try {
          let resp = await adminCreateLegislationManagementRule({
            rule_name: rule_name,
            legislation_id: parseFloat(props.selectedLegislation),
            rule_code: rule_code,
          });
          if (resp) {
            toast.success(getValue(resp, "message", ""));
            props.handleModalCancel();
            setRule_name("");
            props.getLegislationRuleList(props.selectedLegislation);
          }
        } catch (error) {}
      }
    } else {
      toast.error("Please select Legislation to continue");
    }
  };
  return (
    <Modal
      isOpen={getValue(props, `isOpen`, "")}
      toggle={getValue(props, `isOpen`, "")}
      className="new-rule-popup"
      centered
    >
      <section className="new-rule-popup-wrapper">
        <div className="new-rule-popup-wrapper__popup">
          <div className="add-popup-wrapper__form-group form-group">
            <Label>Rule Name * </Label>
            <InputField
              inputType="TEXTAREA"
              rows={3}
              placeholder="Enter Rule Name"
              value={rule_name}
              name="rule_name"
              onChange={(e: any) => setRule_name(e.target.value)}
            />
          </div>
          <div className="add-popup-wrapper__form-group form-group">
            <Label>Rule Code * </Label>
            <InputField
              inputType="TEXT"
              rows={3}
              placeholder="Enter Rule Code"
              value={rule_code}
              name="rule_code"
              onChange={(e: any) => setRule_code(e.target.value)}
            />
          </div>
          <div className="add-popup-wrapper__cta-wrapper">
            <button
              onClick={props.handleModalCancel}
              className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
            >
              Cancel
            </button>
            <button
              className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default NewRulePopup;
