// import "./setup.scss";
// import DashboardLayout from "@layouts/dashboard/dashboard-layout";
// import ClientListNavigation from "@components/ClientList/clientlist-navigation";
// import { useNavigate, useParams } from "react-router-dom";
// import "../clientlist-details.scss";
// import { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
// import { getValue } from "@utils/lodash";
// import {
// 	adminCreateSubscription,
// 	adminDelateSubscription,
// 	adminGetSpecificOrganization,
// 	adminSubscription,
// 	adminUpdateSpecificOrganization,
// 	attachProductSubscription,
// 	getAllAttachedSubscriptions,
// 	getAllSubscriptions,
// 	removeSubscription,
// } from "@services/client-list.service";
// import { toast } from "react-toastify";
// import NoAccessPage from "@components/common/NoAccess";
// import qs from "query-string";
// import Notfound from "@components/Not-Found";
// import Loader from "@components/common/Loader";

// export default function Setup(props: any) {
// 	let navigate = useNavigate();
// 	let params = useParams();

// 	const [isLoading, setIsLoading] = useState(true);
// 	const [pe_compliance, setPe_compliance] = useState(false);
// 	const [vendor_audit, setVendor_audit] = useState(false);
// 	const [submitLoading, setSubmitLoading] = useState(false);

// 	const [setupList, setSetupList] = useState([]);
// 	const [isLoading1, setLoading1] = useState(false);

// 	useEffect(() => {
// 		if (getValue(params, `id`, "") && props.permissionAPITriggered) {
// 			getData(getValue(params, `id`, ""));
// 		}
// 		getSetupList();
// 	}, [props.permissionAPITriggered]);

// 	const getData = async (id: string) => {
// 		try {
// 			setIsLoading(true);
// 			let resp = await adminGetSpecificOrganization(id);
// 			if (resp) {
// 				setPe_compliance(getValue(resp, `data.pe_compliance`, false));
// 				setVendor_audit(getValue(resp, `data.vendor_audit`, false));
// 				setIsLoading(false);
// 			} else {
// 				setIsLoading(false);
// 			}
// 		} catch (error) {
// 			setIsLoading(false);
// 		}
// 	};

// 	function json2array(json: any) {
// 		var result: any = [];
// 		var keys = Object.keys(json);
// 		keys.forEach(function (key) {
// 			result.push({
// 				[key]: json[key],
// 			});
// 		});
// 		return result;
// 	}

// 	const [attachedList, setAttachedList] = useState<any>([]);
// 	const getSetupList = async () => {
// 		try {
// 			let payload: any = {
// 				page_no: 1,
// 				page_size: 100,
// 				domain_name: params.domain,
// 			};
// 			setLoading1(true);
// 			let queryRequest = qs.stringify(payload);
// 			let resp = await adminSubscription(queryRequest);
// 			// let resp = await getAllSubscriptions(queryRequest);
// 			// let response = await getAllAttachedSubscriptions(params.id, "");
// 			// if (response) {
// 			// 	setAttachedList(
// 			// 		getValue(response, `data.subscriptions`, []).map((item: object) =>
// 			// 			getValue(item, `id`, "")
// 			// 		)
// 			// 	);
// 			// } else {
// 			// 	setAttachedList([]);
// 			// }
// 			if (resp) {
// 				setLoading1(false);
// 				setSetupList(json2array(getValue(resp, `data`, [])));
// 				// setSetupList(getValue(resp, `data.subscriptions`, []));
// 			} else {
// 				setLoading1(false);
// 				setSetupList([]);
// 			}
// 		} catch (error) {
// 			setLoading1(false);
// 		}
// 	};
// 	const createSetup = async (item: object) => {
// 		try {
// 			let payload = {
// 				title: getValue(item, `title`, ""),
// 				product_code: getValue(item, `product_code`, ""),
// 				description: getValue(item, `description`, ""),
// 				is_active: true,
// 				domain_name: params.domain,
// 				// subscription_id: getValue(item, `id`, ""),
// 			};
// 			let resp = await adminCreateSubscription(payload);
// 			// let resp = await attachProductSubscription(params.id, payload);
// 			if (resp) {
// 				toast.success(getValue(resp, `message`, ""));
// 				getSetupList();
// 			}
// 		} catch (error) {}
// 	};

// 	const deleteSetup = async (id: string) => {
// 		let payload = {
// 			domain_name: params.domain,
// 			// subscription_id: id,
// 		};
// 		try {
// 			let resp = await adminDelateSubscription(id, payload);
// 			// let resp = await removeSubscription(params.id, payload);
// 			if (resp) {
// 				toast.success(getValue(resp, `message`, ""));
// 				getSetupList();
// 			}
// 		} catch (error) {}
// 	};

// 	const handleSubmit = async () => {
// 		let payload: object | any = {
// 			pe_compliance: pe_compliance,
// 			vendor_audit: vendor_audit,
// 		};
// 		if (getValue(params, `id`, "")) {
// 			try {
// 				setSubmitLoading(true);
// 				let resp = await adminUpdateSpecificOrganization(
// 					getValue(params, `id`, ""),
// 					payload
// 				);
// 				if (resp) {
// 					setSubmitLoading(false);
// 					toast.success(getValue(resp, `message`, ""));
// 					// navigate(`/clientlist/active`);
// 				} else {
// 					setSubmitLoading(false);
// 				}
// 			} catch (error) {
// 				setSubmitLoading(false);
// 			}
// 		}
// 	};
// 	return (
// 		<DashboardLayout
// 			permissions={getValue(props, `tabPermissionList`, [])}
// 			subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
// 		>
// 			<ClientListNavigation id={params.id} domain={params.domain} />
// 			{/* {!isLoading &&
// 			!getValue(props, `subTabPermissionList`, []).includes("client_active") ? (
// 				<NoAccessPage />
// 			) : ( */}
// 				<>
// 					<section className="client-list-details-wrapper">
// 						<div className="client-list-details-wrapper__organization-info d-flex align-items-center justify-content-between">
// 							<h3 className="client-list-details-wrapper__organization-title">
// 								Organization Information
// 								{/* - ID 7878787 */}
// 							</h3>
// 							{/* {getValue(props, `permissions`, []).includes("update") && (
// 								<div className="client-list-details-wrapper__cta-wrapper">
// 									<NavLink to={`/clientlist/active`}>
// 										<button className="client-list-details-wrapper__cta-btn client-list-details-wrapper__cta-btn--secondary">
// 											Cancel
// 										</button>
// 									</NavLink>
// 									{submitLoading ? (
// 										<button className="client-list-details-wrapper__cta-btn client-list-details-wrapper__cta-btn--primary">
// 											Please wait...
// 										</button>
// 									) : (
// 										<button
// 											onClick={handleSubmit}
// 											className="client-list-details-wrapper__cta-btn client-list-details-wrapper__cta-btn--primary"
// 										>
// 											{getValue(params, `id`, "") ? "Update" : "Save"}
// 										</button>
// 									)}
// 								</div>
// 							)} */}
// 						</div>
// 						<div className="setup-wrapper">
// 							<div className="setup-wrapper__header">
// 								<h3 className="setup-wrapper__header-title">
// 									Product Subscription
// 								</h3>
// 								<p className="setup-wrapper__header-text">
// 									Lorem Ipsum is simply dummy text of the printing and
// 									typesetting industry.
// 								</p>
// 							</div>
// 							{isLoading || !props.permissionAPITriggered ? (
// 								<Loader />
// 							) : getValue(setupList, `length`, 0) > 0 ? (
// 								setupList.map((item, index) => {
// 									return (
// 										<div className="setup-wrapper__details d-flex align-items-flex-start">
// 											<label
// 												className="position-relative form-group d-flex align-items-center cursor-pointer mt-1"
// 												htmlFor={`select-1-z${index}`}
// 												key={`select-1-z${index}`}
// 											>
// 												<div>
// 													<input
// 														type="checkbox"
// 														className="default-checkbox "
// 														id={`select-1-z${index}`}
// 														//version 2
// 														// checked={attachedList.includes(
// 														// 	getValue(item, `id`, "")
// 														// )}
// 														// onChange={() =>
// 														// 	!attachedList.includes(getValue(item, `id`, ""))
// 														// 		? createSetup(item)
// 														// 		: deleteSetup(getValue(item, `id`, ""))
// 														// }
// 														checked={getValue(
// 															item,
// 															`[${index}].is_selectd`,
// 															false
// 														)}
// 														onChange={() =>
// 															!getValue(item, `[${index}].is_selectd`, "")
// 																? createSetup(getValue(item, `[${index}]`, {}))
// 																: deleteSetup(
// 																		getValue(
// 																			item,
// 																			`[${index}].product_code`,
// 																			""
// 																		)
// 																  )
// 														}
// 													/>
// 													<div className="custom-checkbox--bg-blue">
// 														<img
// 															src="/images/uploaded-details/tick.svg"
// 															className="img-fluid uploaded-tick"
// 														/>
// 													</div>
// 												</div>
// 											</label>
// 											<div className="mx-3">
// 												<h4 className="setup-wrapper__details-title">
// 													{/* {getValue(item, `title`, "")} (
// 													{getValue(item, `product_code`, "")}) */}
// 													{getValue(item, `[${index}].title`, "")} (
// 													{getValue(item, `[${index}].product_code`, "")})
// 												</h4>
// 												<p className="setup-wrapper__details-text">
// 													{/* {getValue(item, `description`, "")} */}
// 													{getValue(item, `[${index}].description`, "")}
// 												</p>
// 											</div>
// 										</div>
// 									);
// 								})
// 							) : (
// 								<p className="text-center ">No Data Found</p>
// 							)}
// 						</div>
// 					</section>
// 					<div className="d-flex align-items-center justify-content-between mx-5 my-5">
// 						<img
// 							src="/images/checkpoint-illustartion.svg"
// 							className="img-fluid"
// 							alt="img"
// 						/>
// 						<img
// 							src="/images/profile-illustration.svg"
// 							className="img-fluid client-list-details-wrapper__illustration"
// 							alt="img"
// 						/>
// 					</div>
// 				</>
// 			{/* )} */}
// 		</DashboardLayout>
// 	);
// }

import "./setup.scss";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import ClientListNavigation from "@components/ClientList/clientlist-navigation";
import { useNavigate, useParams } from "react-router-dom";
import "../clientlist-details.scss";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getValue } from "@utils/lodash";
import {
	adminCreateSubscription,
	adminDelateSubscription,
	adminGetSpecificOrganization,
	adminSubscription,
	adminUpdateSpecificOrganization,
	attachProductSubscription,
	getAllAttachedSubscriptions,
	getAllSubscriptions,
	removeSubscription,
} from "@services/client-list.service";
import { toast } from "react-toastify";
import NoAccessPage from "@components/common/NoAccess";
import qs from "query-string";
import Notfound from "@components/Not-Found";
import Loader from "@components/common/Loader";

export default function Setup(props: any) {
	let navigate = useNavigate();
	let params = useParams();

	const [isLoading, setIsLoading] = useState(true);
	const [pe_compliance, setPe_compliance] = useState(false);
	const [vendor_audit, setVendor_audit] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);

	const [setupList, setSetupList] = useState([]);
	const [isLoading1, setLoading1] = useState(false);

	useEffect(() => {
		if (getValue(params, `id`, "") && props.permissionAPITriggered) {
			getData(getValue(params, `id`, ""));
		}
		getSetupList();
	}, [props.permissionAPITriggered]);

	const getData = async (id: string) => {
		try {
			setIsLoading(true);
			let resp = await adminGetSpecificOrganization(id);
			if (resp) {
				setPe_compliance(getValue(resp, `data.pe_compliance`, false));
				setVendor_audit(getValue(resp, `data.vendor_audit`, false));
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	function json2array(json: any) {
		var result: any = [];
		var keys = Object.keys(json);
		keys.forEach(function (key) {
			result.push({
				[key]: json[key],
			});
		});
		return result;
	}

	const [attachedList, setAttachedList] = useState<any>([]);
	const getSetupList = async () => {
		try {
			let payload: any = {
				page_no: 1,
				page_size: 100,
				domain_name: params.domain,
			};
			setLoading1(true);
			let queryRequest = qs.stringify(payload);
			// let resp = await adminSubscription(queryRequest);
			let resp = await getAllSubscriptions(queryRequest);
			let response = await getAllAttachedSubscriptions(params.id, "");
			if (response) {
				setAttachedList(
					getValue(response, `data.subscriptions`, []).map((item: object) =>
						getValue(item, `id`, "")
					)
				);
			} else {
				setAttachedList([]);
			}
			if (resp) {
				setLoading1(false);
				// setSetupList(json2array(getValue(resp, `data`, [])));
				setSetupList(getValue(resp, `data.subscriptions`, []));
			} else {
				setLoading1(false);
				setSetupList([]);
			}
		} catch (error) {
			setLoading1(false);
		}
	};
	const createSetup = async (item: object) => {
		try {
			let payload = {
				// title: getValue(item, `title`, ""),
				// product_code: getValue(item, `product_code`, ""),
				// description: getValue(item, `description`, ""),
				// is_active: true,
				// domain_name: params.domain,
				subscription_id: getValue(item, `id`, ""),
				domain_name: params.domain
			};
			// let resp = await adminCreateSubscription(payload);
			let resp = await attachProductSubscription(params.id, payload);
			if (resp) {
				toast.success(getValue(resp, `message`, ""));
				getSetupList();
			}
		} catch (error) {}
	};

	const deleteSetup = async (id: string) => {
		let payload = {
			domain_name: params.domain,
			subscription_id: id,
		};
		try {
			// let resp = await adminDelateSubscription(id, payload);
			let resp = await removeSubscription(params.id, payload);
			if (resp) {
				toast.success(getValue(resp, `message`, ""));
				getSetupList();
			}
		} catch (error) {}
	};

	const handleSubmit = async () => {
		let payload: object | any = {
			pe_compliance: pe_compliance,
			vendor_audit: vendor_audit,
		};
		if (getValue(params, `id`, "")) {
			try {
				setSubmitLoading(true);
				let resp = await adminUpdateSpecificOrganization(
					getValue(params, `id`, ""),
					payload
				);
				if (resp) {
					setSubmitLoading(false);
					toast.success(getValue(resp, `message`, ""));
					// navigate(`/clientlist/active`);
				} else {
					setSubmitLoading(false);
				}
			} catch (error) {
				setSubmitLoading(false);
			}
		}
	};
	return (
		<DashboardLayout
			permissions={getValue(props, `tabPermissionList`, [])}
			subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
		>
			<ClientListNavigation id={params.id} domain={params.domain} />
			{!isLoading &&
			!getValue(props, `subTabPermissionList`, []).includes("client_active") ? (
				<NoAccessPage />
			) : (
				<>
					<section className="client-list-details-wrapper">
						<div className="client-list-details-wrapper__organization-info d-flex align-items-center justify-content-between">
							<h3 className="client-list-details-wrapper__organization-title">
								Organization Information
								{/* - ID 7878787 */}
							</h3>
							{/* {getValue(props, `permissions`, []).includes("update") && (
								<div className="client-list-details-wrapper__cta-wrapper">
									<NavLink to={`/clientlist/active`}>
										<button className="client-list-details-wrapper__cta-btn client-list-details-wrapper__cta-btn--secondary">
											Cancel
										</button>
									</NavLink>
									{submitLoading ? (
										<button className="client-list-details-wrapper__cta-btn client-list-details-wrapper__cta-btn--primary">
											Please wait...
										</button>
									) : (
										<button
											onClick={handleSubmit}
											className="client-list-details-wrapper__cta-btn client-list-details-wrapper__cta-btn--primary"
										>
											{getValue(params, `id`, "") ? "Update" : "Save"}
										</button>
									)}
								</div>
							)} */}
						</div>
						<div className="setup-wrapper">
							<div className="setup-wrapper__header">
								<h3 className="setup-wrapper__header-title">
									Product Subscription
								</h3>
								<p className="setup-wrapper__header-text">
									Lorem Ipsum is simply dummy text of the printing and
									typesetting industry.
								</p>
							</div>
							{isLoading || !props.permissionAPITriggered ? (
								<Loader />
							) : getValue(setupList, `length`, 0) > 0 ? (
								setupList.map((item, index) => {
									return (
										<div className="setup-wrapper__details d-flex align-items-flex-start">
											<label
												className="position-relative form-group d-flex align-items-center cursor-pointer mt-1"
												htmlFor={`select-1-z${index}`}
												key={`select-1-z${index}`}
											>
												<div>
													<input
														type="checkbox"
														className="default-checkbox "
														id={`select-1-z${index}`}
														checked={attachedList.includes(
															getValue(item, `id`, "")
														)}
														onChange={() =>
															!attachedList.includes(getValue(item, `id`, ""))
																? createSetup(item)
																: deleteSetup(getValue(item, `id`, ""))
														}
													/>
													<div className="custom-checkbox--bg-blue">
														<img
															src="/images/uploaded-details/tick.svg"
															className="img-fluid uploaded-tick"
														/>
													</div>
												</div>
											</label>
											<div className="mx-3">
												<h4 className="setup-wrapper__details-title">
													{getValue(item, `title`, "")} (
													{getValue(item, `product_code`, "")})
												</h4>
												<p className="setup-wrapper__details-text">
													{getValue(item, `description`, "")}
												</p>
											</div>
										</div>
									);
								})
							) : (
								<p className="text-center ">No Data Found</p>
							)}
						</div>
					</section>
					<div className="d-flex align-items-center justify-content-between mx-5 my-5">
						<img
							src="/images/checkpoint-illustartion.svg"
							className="img-fluid"
							alt="img"
						/>
						<img
							src="/images/profile-illustration.svg"
							className="img-fluid client-list-details-wrapper__illustration"
							alt="img"
						/>
					</div>
				</>
			)}
		</DashboardLayout>
	);
}
