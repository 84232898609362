import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import DashboardLayout from '@layouts/dashboard/dashboard-layout';
import './completed.scss';
import qs from 'query-string';
import { getValue } from '@utils/lodash';
import { adminGetAuditList } from '@services/audit.service';
import Loader from '@components/common/Loader';
import Notfound from '@components/Not-Found';
import PaginationPage from '@components/pagination/pagination';
import DatePicker from 'react-date-picker';
import { formatText } from '@_common/text-format';
import { adminGetOrganization } from '@services/client-list.service';
import InputField from '@_common/InputRuleFeild';

export default function Completed(props:any) {
	let navigate = useNavigate();
	/* -------------------------------------------------------------------------- */
	/*                               UseState Section                             */
	/* -------------------------------------------------------------------------- */

	const [isLoading, setIsLoading] = useState(false);
	const [locations, setLocations] = useState([]);
	const [info, setInfo] = useState({});

	/* -------------------------------------------------------------------------- */
	/*                               UseEffect Section                            */
	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
			setSelectedClient(
				getValue(localStorage, `client`, '')
					? getValue(localStorage, `client`, '')
					: ''
			);
			getClientList();
			setDate(new Date());
		}
	}, [props.permissionAPITriggered]);

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
			setSelectedClient(
				getValue(localStorage, `client`, '')
					? getValue(localStorage, `client`, '')
					: ''
			);
			getClientList();
			if (params.search_text) {
				setSearchInput(params.search_text);
				setShowSearchTextbox(true);
			}
			if (params.page_no) {
				setPage_no(parseInt(params.page_no));
			}
			if (params.limit) {
				setLimit(parseInt(params.limit));
			}
			if (params.date) {
				setDate(new Date(params.date));
			} else {
				setDate(new Date());
			}
		}
	}, [window.location.href,props.permissionAPITriggered])

	/* -------------------------------------------------------------------------- */
	/*                               API Section                                  */
	/* -------------------------------------------------------------------------- */

	const getData = async (client: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		let dates = date ? date : new Date();

		let payload: any = {
			page: params.page_no ? parseInt(params.page_no) : 1,
			page_size: params.limit ? parseInt(params.limit) : 10,
			search_text: params.search_text,
			status: params.status,
			sort_by: params.sort_by,
			month: params.date
				? params.date.split('-')[0]
				: date
				? moment(date).format('MM')
				: moment(dates).format('MM'),
			year: params.date
				? params.date.split('-')[2]
				: date
				? moment(date).format('YYYY')
				: moment(dates).format('YYYY'),
			audit_type: 'COMPLETED',
			domain_name: client,
		};

		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		setIsLoading(true);
		try {
			let resp = await adminGetAuditList(queryRequest);
			if (resp) {
				setLocations(getValue(resp, `data.vendors_checkpoint`, []));
				setInfo(getValue(resp, `data.releaseInfo`, {}));
				setTotalCount(getValue(resp, `data.pagination.total`, 0));
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	/* -------------------------------------------------------------------------- */
	/*                               Pagination section                           */
	/* -------------------------------------------------------------------------- */

	const [totalCount, setTotalCount] = useState(0);
	const [limit, setLimit] = useState(10);
	const [page_no, setPage_no] = useState(1);

	const handleChangePagination = (page_no: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.page_no = page_no;
		Object.keys(params).forEach((key) => {
			if (!params[key] || params[key] === undefined) {
				delete params[key];
			}
		});
		let queryRequest = qs.stringify(params);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	const handleChangeLimit = (limit: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.limit = limit;
		Object.keys(params).forEach((key) => {
			if (!params[key] || params[key] === undefined) {
				delete params[key];
			}
		});
		let queryRequest = qs.stringify(params);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	/* -------------------------------------------------------------------------- */
	/*                               Search section                               */
	/* -------------------------------------------------------------------------- */
	const [showSearchTextbox, setShowSearchTextbox] = useState(false);
	const [search_text, setSearchInput] = useState<any>('');

	const toggleSearch = () => {
		setShowSearchTextbox((prevState) => !prevState);
	};
	const toggleSearchClose = () => {
		setSearchInput('');
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (params.search_text) {
			delete params.search_text;
			let payload: any = { ...params };
			// removing null values
			Object.keys(payload).forEach((key) => {
				if (!payload[key] || payload[key] === undefined) {
					delete payload[key];
				}
			});
			let queryRequest = qs.stringify(payload);
			navigate(`${window.location.pathname}?${queryRequest}`);
			getData(selectedClient);
		}
	};

	const handleChangeSearch = async (e: any) => {
		if (e.target.value) {
			const urlSearchParams = new URLSearchParams(window.location.search);
			const params = Object.fromEntries(urlSearchParams.entries());
			setSearchInput(e.target.value);
			params.search_text = e.target.value;
			let queryRequest = qs.stringify(params);
			navigate(`${window.location.pathname}?${queryRequest}`);
			if (!e.target.value) {
				getData(selectedClient);
			}
		} else {
			toggleSearchClose();
		}
	};

	/* -------------------------------------------------------------------------- */
	/*                               Date section                             */
	/* -------------------------------------------------------------------------- */

	const [date, setDate] = useState<Date | null>(null);
	const handleSubmitDate = () => {
		let dates = moment(date).format('MM-DD-YYYY');
		let payload: any = {
			date: dates,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	const handleNavigate = (bodyObj: object) => {
		navigate(
			`/audit/details/${getValue(bodyObj, `vendor_id`, '')}/${moment(
				date
			).format('MM-DD-YYYY')}/completed/${getValue(bodyObj, `vendor_name`, '')}`
		);
	};
	/* -------------------------------------------------------------------------- */
	/*                               Onchange section                             */
	/* -------------------------------------------------------------------------- */

	const status = ['green-color', 'blue-color', 'black-color'];
	const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());

	/* -------------------------------------------------------------------------- */
	/*                             Client Setup                                   */
	/* -------------------------------------------------------------------------- */

	const [clientList, setClientList] = useState([]);
	const [selectedClient, setSelectedClient] = useState('');
	const handleSelectClient = (e: string) => {
		localStorage.setItem('client', e);
		setSelectedClient(e);
		getData(e);
	};
	const getClientList = async () => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());

		let payload: any = {
			page_no: params.page_no ? parseInt(params.page_no) : 1,
			page_size: 100,
			status: 'active',
			sort_by: params.sort_by,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);

		try {
			let resp = await adminGetOrganization(queryRequest);
			if (resp) {
				let list = getValue(resp, `data.organisations`, []).map(
					(item: object) => ({
						// ...item,
						value: getValue(item, `domain_name`, ''),
						label: getValue(item, `organisation_name`, ''),
						organisation_name: getValue(item, `organisation_name`, ''),
						id: getValue(item, `id`, ''),
					})
				);
				setClientList(list);
				if (getValue(list, `length`, 0) > 0) {
					if (getValue(localStorage, `client`, '')) {
						getData(getValue(localStorage, `client`, ''));
					} else {
						getData(getValue(list, `[${0}].value`, ''));
						localStorage.setItem('client', getValue(list, `[${0}].value`, ''));
						setSelectedClient(getValue(list, `[${0}].value`, ''));
					}
				} else {
					getData('');
				}
			}
		} catch (error) {}
	};
	return (
		<DashboardLayout permissions={getValue(props,`tabPermissionList`,[])}>
			<div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
				<h4 className="dashbaord-wrapper__header-title">Completed</h4>
				<div className="d-flex align-items-center gap">
					<div className="d-flex align-items-center">
						<p>Clients:</p>
						<div className="dashboard-wrapper__input-field">
							<InputField
								inputType="INPUT_REACT_SELECT"
								placeholder={'Select Client'}
								options={clientList}
								onChange={(e: any) => handleSelectClient(e.value)}
								value={clientList.filter(
									(item: any) => item.value === selectedClient
								)}
							/>
						</div>
					</div>
					<DatePicker
						onChange={setDate}
						value={date}
						format="MMM yyyy"
						disableCalendar={true}
						yearAriaLabel={'yyyy'}
					/>
					<button className="header-apply-btn" onClick={handleSubmitDate}>
						Apply
					</button>
				</div>
			</div>
			<section className="uploaded-wrapper">
				{isLoading ? (
					<Loader />
				) : getValue(locations, `length`, 0) > 0 ? (
					<>
						<table className="uploaded-wrapper__table w-100">
							<thead>
								<tr>
									<th></th>
									<th>
										<div className="uploaded-wrapper__table-header-details">
											<h5 className="uploaded-wrapper__table-header-title">
												Cutoff-Date
											</h5>
											<p className="uploaded-wrapper__table-header-text">
												Start Date
											</p>
										</div>
									</th>
									<th>
										<div className="uploaded-wrapper__table-header-details">
											<h5 className="uploaded-wrapper__table-header-title">
												Vendor name
											</h5>
											<p className="uploaded-wrapper__table-header-text">
												Vendor Type
											</p>
										</div>
									</th>
									<th>
										<div className="uploaded-wrapper__table-header-details">
											<h5 className="uploaded-wrapper__table-header-title">
												Location Code
											</h5>
											<p className="uploaded-wrapper__table-header-text">
												Location Name
											</p>
										</div>
									</th>
									<th>
										<div className="uploaded-wrapper__table-header-details">
											<h5 className="uploaded-wrapper__table-header-title">
												Group Name
											</h5>
											{/* <p className="uploaded-wrapper__table-header-text">
												Not Uploaded
											</p> */}
										</div>
									</th>
									<th>
										<div className="uploaded-wrapper__table-header-details">
											<h5 className="uploaded-wrapper__table-header-title">
												Entity Name
											</h5>
											<p className="uploaded-wrapper__table-header-text">
												City Name
											</p>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{locations.map((bodyObj: object, index: number) => {
									return (
										<tr key={index} onClick={() => handleNavigate(bodyObj)}>
											<td>
												<img src="/images/bubble.svg" className="img-fluid" />
											</td>
											<td>
												<div className="uploaded-wrapper__table-body-details">
													<h5
														className={`uploaded-wrapper__table-title uploaded-wrapper__table-title--`}
													>
														{getValue(bodyObj, `cutoff_date`, '')
															? moment(
																	getValue(bodyObj, `cutoff_date`, '')
															  ).format('DD MMM YYYY')
															: ''}
													</h5>
													<p>
														{getValue(bodyObj, `start_date`, '')
															? moment(
																	getValue(bodyObj, `start_date`, '')
															  ).format('DD MMM YYYY')
															: ''}
													</p>
												</div>
											</td>
											<td>
												<div className="uploaded-wrapper__table-body-details">
													<h5
														className={`uploaded-wrapper__table-title uploaded-wrapper__table-title--${status[3]}`}
													>
														{getValue(bodyObj, `vendor_name`, '')}
													</h5>
													<p>
														{' '}
														{formatText(getValue(bodyObj, `vendor_type`, ''))}
													</p>
												</div>
											</td>
											<td key={index}>
												<div className="uploaded-wrapper__table-body-details">
													<h5
														className={`uploaded-wrapper__table-title uploaded-wrapper__table-title--${status[3]}`}
													>
														{getValue(bodyObj, `location_code`, '')}
													</h5>
													<p> {getValue(bodyObj, `location_name`, '')}</p>
												</div>
											</td>
											<td>
												<div className="uploaded-wrapper__table-body-details">
													<h5
														className={`uploaded-wrapper__table-title uploaded-wrapper__table-title--${status[3]}`}
													>
														{getValue(bodyObj, `group_name`, '')}
													</h5>
												</div>
											</td>
											<td>
												<div className="uploaded-wrapper__table-body-details">
													<h5
														className={`uploaded-wrapper__table-title uploaded-wrapper__table-title--${status[3]}`}
													>
														{getValue(bodyObj, `entity_name`, '')}
													</h5>
													<p>{getValue(bodyObj, `city_name`, '')}</p>
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</>
				) : (
					<>{Object.keys(params).length !== 0 ? <Notfound /> : <Notfound />}</>
				)}
				<div className="d-flex justify-content-between">
					{locations.length > 0 && (
						<img src="/images/profile-illustration.svg" className="img-fluid" />
					)}
					{totalCount > 10 ? (
						<PaginationPage
							totalCount={totalCount}
							limit={limit}
							pageNumber={page_no}
							handleChangePagination={handleChangePagination}
							handleChangeLimit={handleChangeLimit}
						/>
					) : (
						''
					)}
				</div>
			</section>
		</DashboardLayout>
	);
}
