import Overview from '@pages/Audit/Overview';
import Uploaded from '@pages/Audit/Uploaded';
import Completed from '@pages/Audit/Completed';
import NotUploaded from '@pages/Audit/NotUploaded';
import UploadedDetails from '@pages/Audit/Uploaded/Uploaded-Details';

export const AuditRoutes = [
    {
        path: '/audit/overview',
        name: 'audit_overview',
        component: Overview ,
      },
      {
        path: '/audit/uploaded',
        name: 'audit_upload',
        component: Uploaded ,
      },
      {
        path: '/audit/details/:id/:date/:route/:name',
        name: 'UploadedDetails',
        component: UploadedDetails ,
      },
      {
        path: '/audit/completed',
        name: 'audit_completed',
        component: Completed ,
      },
      {
        path: '/audit/not-uploaded',
        name: 'audit_not_completed',
        component: NotUploaded ,
      },
];
