import React, { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Header from "@components/common/Header";
import SimpleReactValidator from "simple-react-validator";
import { useParams, useNavigate } from "react-router-dom";
import InputField from "@_common/InputRuleFeild";
import { getValue, setValue } from "@utils/lodash";
import {
  adminCategoryLawList,
  adminGetCountriesListManagement,
  adminGetLegislationManagement,
  adminGetLegislationManagementRule,
  adminGetListManagementGroupCode,
  adminGetListManagementStateCategoryList,
  adminGetStatesListManagement,
  adminLegislationList,
  adminLegislationRuleList,
  adminListManagementList,
} from "@services/list-management.service";
import {
  adminCreateCheckpoint,
  adminGetSpecificCheckpoint,
  adminUpdateSpecificCheckpoint,
} from "@services/checkpoint.service";
import { toast } from "react-toastify";
import "./checkpoint-active-details.scss";
import "./inner-page-navigation.scss";
import _ from "lodash";
import PromptModal from "@prompt/modal";
import { useCallbackPrompt } from "@prompt/useCallbackPrompt";
import { deleteSpecificFileStore, docStoreFileUpload, getSpecificFileStore, updatedocStoreFileUpload, vendorFileUpload } from "@services/auth.service";
import { handleDownloadOnClick } from "@_common/downloadfile";
import NoAccessPage from "@components/common/NoAccess";
import { QueryRequestHelper } from "@_common/query-request-helper";
import SearchableDropdownSelect from "@_common/SearchableDropdown";
import CheckpointSearchableDropdownSelect from "@_common/CheckpointSearchableDropdown";
import DragAndDropFileComponent from "@_common/file-drag-drop";
import { formatSmallString } from "@_common/format-text";

interface ICheckpointActiveDetailProps {}

const CheckpointActiveDetail: React.FunctionComponent<
  ICheckpointActiveDetailProps
> = (props: any) => {
  let params = useParams();
  let navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [request, setRequest] = useState<any>({
    checkpoint_id: "",
    country_id: 101,
    state_id: 0,
    category_of_law_id: 0,
    legislation_id: 0,
    legislation_rule_id: 0,
    compliance_activity: "",
    reference: "",
    frequency_id: 0,
    document_type_id: 0,
    compliance_type_id: 0,
    description: "",
    smart_filter_tags: [],
    risk_type_id: 0,

    who: "",
    when: "",
    procedure: "",
    more_information: "",

    prohibitive_id: 0,
    task_type_id: 0,
    level_id: 0,
    form_no: "",

    impact_id: 0,
    impact_on_organization_id: 0,
    impact_on_unit_id: 0,
    imprisonment_applies_id: 0,

    implication: "",
    imprisonment_duration: "",
    fine_amount: "",
    subsequent_amount_per_day: "",

    events: "",
    exemption_criteria: "",
    inter_linkage: "",
    linked_task_id: "",
    sub_events: 0,

    document_ids: [],
    document_store_ids: [],

    statutory_authority: "",
    proof_of_compliance: "",
    weblinks: "",

    event_id: 0,
    sub_event_id: 0,
    bulk_upload_keyword: "",
  });

  const [dirtyFields, setDirtyFields] = useState<any>({
    checkpoint_id: "",
    country_id: "101",
    state_id: 0,
    category_of_law_id: 0,
    legislation_id: 0,
    legislation_rule_id: 0,
    compliance_activity: "",
    reference: "",
    frequency_id: 0,
    document_type_id: 0,
    compliance_type_id: 0,
    description: "",
    smart_filter_tags: [],
    risk_type_id: 0,
    form_no: "",
    who: "",
    when: "",
    procedure: "",
    more_information: "",
    implication: "",
    imprisonment_duration: "",
    fine_amount: "",
    subsequent_amount_per_day: "",
    events: "",
    exemption_criteria: "",
    inter_linkage: "",
    linked_task_id: "",
    prohibitive_id: 0,
    task_type_id: 0,
    level_id: 0,
    impact_id: 0,
    impact_on_organization_id: 0,
    impact_on_unit_id: 0,
    imprisonment_applies_id: 0,
    sub_events: 0,
    document_ids: [],
    document_store_ids: [],
    statutory_authority: "",
    proof_of_compliance: "",
    weblinks: "",

    event_id: 0,
    sub_event_id: 0,
    bulk_upload_keyword: "",
  });
  const [smartFilterList, setSmartFilterList] = useState([]);
  const [selectedSmartFilter, setSelectedSmartFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getCountriesList();
    getSmartFilterList();
    if (getValue(params, `id`, "")) {
      getData(getValue(params, `id`, ""));
      // getDocumentStore();
    } else {
      //-----list management----//
      getFrequencyList("", "");
      getDocumentTypeList("", "");
      getComplianceTypeList("", "");
      getImpactList("", "");
      getImpactOrganizationList("", "");
      getImprisonmentList("", "");
      getImpactOnUnitList("", "");
      getProhibitiveList("", "");
      getTaskTypeList("", "");
      getLevelList("", "");
      getEventsList("", "");
      getSubEventsList("", "");
    }
  }, []);
  useEffect(() => {
    if (request.country_id) {
      getStatesList(request.country_id);
    }
  }, [request.country_id]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async (id: string) => {
    try {
      let resp = await adminGetSpecificCheckpoint(id);
      let getDocStorepayload: any = {
        checkpoint_id: getValue(request, `checkpoint_id`, ""),
        page_no: 1,
        page_size: 10,
      };
      let resp1 = await getSpecificFileStore(getDocStorepayload);
      if (resp) {
        setRequest({
          ...request,
          country_id: getValue(resp, `data.country.id`, ""),
          state_id: getValue(resp, `data.state.id`, ""),
          category_of_law_id: getValue(resp, `data.category_of_law.id`, ""),
          legislation_id: getValue(resp, `data.legislation.id`, ""),
          legislation_rule_id: getValue(resp, `data.legislation_rule.id`, ""),
          compliance_activity: getValue(resp, `data.compliance_activity`, ""),
          reference: getValue(resp, `data.reference`, ""),
          frequency_id: getValue(resp, `data.frequency.id`, ""),
          document_type_id: getValue(resp, `data.document_type.id`, ""),
          risk_type_id: getValue(resp, `data.risk_type.id`, ""),
          compliance_type_id: getValue(resp, `data.compliance_type.id`, ""),
          description: getValue(resp, `data.description`, ""),
          checkpoint_id: getValue(resp, `data.checkpoint_id`, ""),
          exemption_criteria: getValue(resp, `data.exemption_criteria`, ""),
          fine_amount: getValue(resp, `data.fine_amount`, ""),
          form_no: getValue(resp, `data.form_no`, ""),
          impact_id: getValue(resp, `data.impact.id`, ""),
          impact_on_organization_id: getValue(resp,`data.impact_on_organization.id`,""),
          impact_on_unit_id: getValue(resp, `data.impact_on_unit.id`, ""),
          implication: getValue(resp, `data.implication`, ""),
          imprisonment_applies_id: getValue(
            resp,
            `data.imprisonment_applies.id`,
            ""
          ),
          imprisonment_duration: getValue(
            resp,
            `data.imprisonment_duration`,
            ""
          ),
          inter_linkage: getValue(resp, `data.inter_linkage`, ""),
          who: getValue(resp, `data.who`, ""),
          when: getValue(resp, `data.when`, ""),
          task_type_id: getValue(resp, `data.task_type.id`, ""),
          subsequent_amount_per_day: getValue(
            resp,
            `data.subsequent_amount_per_day`,
            ""
          ),
          // sub_events: getValue(resp, `data.sub_events`, ""),
          prohibitive_id: getValue(resp, `data.prohibitive.id`, ""),
          procedure: getValue(resp, `data.procedure`, ""),
          more_information: getValue(resp, `data.more_information`, ""),
          linked_task_id: getValue(resp, `data.linked_task_id`, ""),
          level_id: getValue(resp, `data.level.id`, ""),
          events: getValue(resp, `data.events`, ""),
          document_ids: getValue(resp, `data.documents`, []),
          smart_filter_tags: [],
          statutory_authority: getValue(resp, `data.statutory_authority`, ""),
          proof_of_compliance: getValue(resp, `data.proof_of_compliance`, ""),
          weblinks: getValue(resp, `data.weblinks`, ""),
          event_id: getValue(resp, `data.events.id`, ""),
          sub_event_id: getValue(resp, `data.sub_events.id`, ""),
          bulk_upload_keyword: getValue(resp, `data.bulk_upload_keyword`, ""),
          document_store_ids: getValue(resp1, `data.checkpoints`, []),
        });
        setDirtyFields({
          ...dirtyFields,
          country_id: getValue(resp, `data.country.id`, ""),
          state_id: getValue(resp, `data.state.id`, ""),
          category_of_law_id: getValue(resp, `data.category_of_law.id`, ""),
          legislation_id: getValue(resp, `data.legislation.id`, ""),
          legislation_rule_id: getValue(resp, `data.legislation_rule.id`, ""),
          compliance_activity: getValue(resp, `data.compliance_activity`, ""),
          reference: getValue(resp, `data.reference`, ""),
          frequency_id: getValue(resp, `data.frequency.id`, ""),
          document_type_id: getValue(resp, `data.document_type.id`, ""),
          risk_type_id: getValue(resp, `data.risk_type.id`, ""),
          compliance_type_id: getValue(resp, `data.compliance_type.id`, ""),
          description: getValue(resp, `data.description`, ""),
          checkpoint_id: getValue(resp, `data.checkpoint_id`, ""),
          exemption_criteria: getValue(resp, `data.exemption_criteria`, ""),
          fine_amount: getValue(resp, `data.fine_amount`, ""),
          form_no: getValue(resp, `data.form_no`, ""),
          impact_id: getValue(resp, `data.impact.id`, ""),
          impact_on_organization_id: getValue(
            resp,
            `data.impact_on_organization.id`,
            ""
          ),
          impact_on_unit_id: getValue(resp, `data.impact_on_unit.id`, ""),
          implication: getValue(resp, `data.implication`, ""),
          imprisonment_applies_id: getValue(
            resp,
            `data.imprisonment_applies.id`,
            ""
          ),
          imprisonment_duration: getValue(
            resp,
            `data.imprisonment_duration`,
            ""
          ),
          inter_linkage: getValue(resp, `data.inter_linkage`, ""),
          who: getValue(resp, `data.who`, ""),
          when: getValue(resp, `data.when`, ""),
          task_type_id: getValue(resp, `data.task_type.id`, ""),
          subsequent_amount_per_day: getValue(
            resp,
            `data.subsequent_amount_per_day`,
            ""
          ),
          // sub_events: getValue(resp, `data.sub_events`, ""),
          prohibitive_id: getValue(resp, `data.prohibitive.id`, ""),
          procedure: getValue(resp, `data.procedure`, ""),
          more_information: getValue(resp, `data.more_information`, ""),
          linked_task_id: getValue(resp, `data.linked_task_id`, ""),
          level_id: getValue(resp, `data.level.id`, ""),
          events: getValue(resp, `data.events`, ""),
          document_ids: getValue(resp, `data.documents`, []),

          smart_filter_tags: [],
          statutory_authority: getValue(resp, `data.statutory_authority`, ""),
          proof_of_compliance: getValue(resp, `data.proof_of_compliance`, ""),
          weblinks: getValue(resp, `data.weblinks`, ""),
          event_id: getValue(resp, `data.events.id`, ""),
          sub_event_id: getValue(resp, `data.sub_events.id`, ""),
          bulk_upload_keyword: getValue(resp, `data.bulk_upload_keyword`, ""),
          document_store_ids: getValue(resp1, `data.checkpoints`, []),
        });
        setdocument_ids(getValue(resp, `data.documents`, []));
        setSelectedSmartFilter(
          getValue(resp, `data.smart_filter_tags`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );

        if (getValue(resp, `data.country.id`, "")) {
          getStatesList(getValue(resp, `data.country.id`, ""));
        }
        if (getValue(resp, `data.state.id`, "")) {
          if (getValue(resp, `data.category_of_law.id`, "")) {
            getListManagementCategoryOfLaw(
              getValue(resp, `data.category_of_law.id`, "")
            );
          } else {
            getCategoryLawList(
              "",
              getValue(resp, `data.state.id`, ""),
              getValue(resp, `data.category_of_law.id`, "")
            );
          }

          // getLegislationList(getValue(resp, `data.state.id`, ""));
          getLegislationList(
            "",
            getValue(resp, `data.state.id`, ""),
            getValue(resp, `data.legislation.id`, "")
          );
        }
        if (getValue(resp, `data.legislation.id`, "")) {
          // getLegislationRuleList(getValue(resp, `data.legislation.id`, ""));
          getLegislationRuleList(
            "",
            getValue(resp, `data.legislation.id`, ""),
            getValue(resp, `data.legislation_rule.id`, "")
          );
        }
        getFrequencyList("", getValue(resp, `data.frequency.id`, ""));
        getDocumentTypeList("", getValue(resp, `data.document_type.id`, ""));
        getComplianceTypeList(
          "",
          getValue(resp, `data.compliance_type.id`, "")
        );
        getImpactList("", getValue(resp, `data.impact.id`, ""));
        getImpactOrganizationList(
          "",
          getValue(resp, `data.impact_on_organization.id`, "")
        );
        getImprisonmentList(
          "",
          getValue(resp, `data.imprisonment_applies.id`, "")
        );
        getImpactOnUnitList("", getValue(resp, `data.impact_on_unit.id`, ""));
        getProhibitiveList("", getValue(resp, `data.prohibitive.id`, ""));
        getTaskTypeList("", getValue(resp, `data.task_type.id`, ""));
        getLevelList("", getValue(resp, `data.level.id`, ""));
        getEventsList("", getValue(resp, `data.events.id`, ""));
        getSubEventsList("", getValue(resp, `data.sub_events.id`, ""));
        getDocumentStore(getValue(resp, `data.checkpoint_id`, ""));
      }
    } catch (error) {}
  };



  const getDocumentStore = async (id:string) => {
    let getDocStorepayload: any = {
      checkpoint_id: id,
      page_no: 1,
      page_size: 10,
    };
    try {
      let resp = await getSpecificFileStore(getDocStorepayload);
      if (resp) {
        setdocument_Store_ids(getValue(resp, `data.checkpoints`, []));
      }
    } catch (error) {}
  }

  const removeNullOrUndefinedProperties = (obj: any) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (
          (obj[key] === null ||
            obj[key] === "" ||
            obj[key] === undefined ||
            (Array.isArray(obj[key]) && obj[key].length === 0)) &&
          typeof obj[key] !== "boolean"
        ) {
          delete obj[key];
        }
      }
    }
    return obj;
  };
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      // if (getValue(selectedSmartFilter, `length`, 0) > 0) {
      if (getValue(params, `id`, "")) {
        try {
          request.smart_filter_tags = selectedSmartFilter.map(
            (item: object) => ({ id: getValue(item, `id`, "") })
          );
          request.document_ids = document_ids.map((item: object) => ({
            id: getValue(item, `id`, ""),
          }));
          setIsLoading(true);
          let resp = await adminUpdateSpecificCheckpoint(
            getValue(params, `id`, ""),
            // removeNullOrUndefinedProperties(request)
            request
          );
          Object.keys(request).forEach((key) => {
            if (!request[key] || request[key] === undefined) {
              delete request[key];
            }
          });
          if (resp) {
            setIsLoading(false);
            // confirmNavigation();
            toast.success(getValue(resp, "message", ""));
            navigate(`/checkpoint/active`);
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      } else {
        try {
          request.smart_filter_tags = selectedSmartFilter.map(
            (item: object) => ({ id: getValue(item, `id`, "") })
          );
          request.document_ids = document_ids.map((item: object) => ({
            id: getValue(item, `id`, ""),
          }));
          Object.keys(request).forEach((key) => {
            if (!request[key] || request[key] === undefined) {
              delete request[key];
            }
          });
          setIsLoading(true);
          let resp = await adminCreateCheckpoint(request);
          if (resp) {
            setIsLoading(false);
            // confirmNavigation();
            toast.success(getValue(resp, "message", ""));
            navigate(`/checkpoint/active`);
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
      // } else {
      // 	toast.error("Smart Filters Required");
      // }
    }
  };

  /* ---------------------------  Country           ----------------------------- */
  const [countryList, setCountryList] = useState([]);
  const getCountriesList = async () => {
    try {
      let resp = await adminGetCountriesListManagement();
      if (resp) {
        setCountryList(
          getValue(resp, `data.countries`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };
  /* ---------------------------  State              ----------------------------- */
  const [states, setStates] = useState([]);
  const getStatesList = async (countryId: number) => {
    try {
      let resp = await adminGetStatesListManagement(countryId);
      if (resp) {
        setStates(
          getValue(resp, `data.states`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };
  /* ---------------------------  Smart Filter List        ----------------------- */
  const getSmartFilterList = async () => {
    try {
      let resp = await adminGetListManagementGroupCode("smart-filter");
      if (resp) {
        setSmartFilterList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            id: getValue(item, `id`, ""),
            is_active: getValue(item, `is_active`, ""),
            text: getValue(item, `text`, ""),
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };
  /* ------------------------------------------------------------------------------ */
  /*                           Custom Dropdown Section                              */
  /* ------------------------------------------------------------------------------ */
  // risk-type

  const [resetInput, setResetInput] = useState(false);

  /* ---------------------------  Category Of Law      ----------------------------- */
  const [categoryOfLawList, setCategoryOfLawList] = useState([]);
  const [categoryLawLoading, setCategoryLawLoading] = useState(false);
  const getCategoryLawList = async (
    value: string,
    state_id: string,
    id: string
  ) => {
    setResetInput(false);
    try {
      setResetInput(false);
      setCategoryLawLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        state_id: state_id ? state_id : getValue(request, `state_id`, ""),
        id: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminCategoryLawList(queryRequest);
      if (resp) {
        setCategoryOfLawList(
          getValue(resp, `data.stateCategoryOfLaw`, []).map((item: object) => ({
            // ...item,
            // id: getValue(item, `category_of_law.id`, ""),
            id: getValue(item, `category_of_law.id`, ""),
            value: getValue(item, `category_of_law.text`, ""),
            label: getValue(item, `category_of_law.text`, ""),
          }))
        );
        setValue(request, `category_of_law_id`, "");
        setCategoryLawLoading(false);
      } else {
        setCategoryLawLoading(false);
      }
    } catch (error) {
      setCategoryLawLoading(false);
    }
    if (!value) {
      setValue(request, `category_of_law_id`, "");
    }
  };
  const getListManagementCategoryOfLaw = async (id: string) => {
    try {
      setResetInput(false);
      let payload = {
        page_no: 1,
        page_size: 10,
        id: id,
        // group_id: "36", //
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        setCategoryOfLawList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );

        setValue(request, `frequency_id`, "");
      } else {
      }
    } catch (error) {}
  };

  /* ---------------------------  Legislation          ----------------------------- */
  const [legislationList, setLegislationList] = useState([]);
  const [legislationLoading, setLegislationLoading] = useState(false);
  const getLegislationList = async (
    value: string,
    state_id: string,
    id: string
  ) => {
    try {
      setResetInput(false);
      setLegislationLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        state_id: state_id ? state_id : getValue(request, `state_id`, ""),
        id: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminLegislationList(queryRequest);
      if (resp) {
        setLegislationList(
          getValue(resp, `data.legislations`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `legislation_name`, ""),
            label: getValue(item, `legislation_name`, ""),
          }))
        );
        setLegislationLoading(false);
        setValue(request, `legislation_id`, "");
      } else {
        setLegislationLoading(false);
      }
    } catch (error) {
      setLegislationLoading(false);
    }
    if (!value) {
      setValue(request, `legislation_id`, "");
    }
  };

  /* ---------------------------  Legislation Rule       ----------------------------- */
  const [ruleList, setRuleList] = useState([]);
  const [legislationRuleLoading, setLegislationRuleLoading] = useState(false);
  const getLegislationRuleList = async (
    value: string,
    legislation_id: string,
    id: string
  ) => {
    try {
      setResetInput(false);
      setLegislationRuleLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        legislation_id: legislation_id
          ? legislation_id
          : getValue(request, `legislation_id`, ""),
        id: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminLegislationRuleList(queryRequest);
      if (resp) {
        setRuleList(
          getValue(resp, `data.legislationRules`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `rule_name`, ""),
            label: getValue(item, `rule_name`, ""),
          }))
        );
        setLegislationRuleLoading(false);
        setValue(request, `legislation_rule_id`, "");
      } else {
        setLegislationRuleLoading(false);
      }
    } catch (error) {
      setLegislationRuleLoading(false);
    }
    if (!value) {
      setValue(request, `legislation_rule_id`, "");
    }
  };

  /* ---------------------------  Frequency List          ----------------------------- */
  const [frequencyList, setFrequencyList] = useState([]);
  const [frequencyLoading, setFrequencyLoading] = useState(false);
  const getFrequencyList = async (value: string, id: string) => {
    try {
      setResetInput(false);
      setFrequencyLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        id: id,
        // group_id: "36", //
        group_code: "frequency",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        setFrequencyList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
        setFrequencyLoading(false);
        setValue(request, `frequency_id`, "");
      } else {
        setFrequencyLoading(false);
      }
    } catch (error) {
      setFrequencyLoading(false);
    }
    if (!value) {
      setValue(request, `frequency_id`, "");
    }
  };

  /* ---------------------------  Document Type           ----------------------------- */
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [documentTypeLoading, setDocumentTypeLoading] = useState(false);
  const getDocumentTypeList = async (value: string, id: string) => {
    try {
      setResetInput(false);
      setDocumentTypeLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        id: id,
        // group_id: "36", //
        group_code: "document-type",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        setDocumentTypeList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
        setDocumentTypeLoading(false);
        setValue(request, `document_type_id`, "");
      } else {
        setDocumentTypeLoading(false);
      }
    } catch (error) {
      setDocumentTypeLoading(false);
    }
    if (!value) {
      setValue(request, `document_type_id`, "");
    }
  };

  /* ---------------------------  Compliance Type           ----------------------------- */
  const [complianceTypeList, setComplianceTypeList] = useState([]);
  const [complianceTypeLoading, setComplianceTypeLoading] = useState(false);
  const getComplianceTypeList = async (value: string, id: string) => {
    try {
      setResetInput(false);
      setComplianceTypeLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        id: id,
        // group_id: "36", //
        group_code: "compliance-type",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        setComplianceTypeList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
        setComplianceTypeLoading(false);
        setValue(request, `compliance_type_id`, "");
      } else {
        setComplianceTypeLoading(false);
      }
    } catch (error) {
      setComplianceTypeLoading(false);
    }
    if (!value) {
      setValue(request, `compliance_type_id`, "");
    }
  };

  /* ---------------------------  Impact                   ----------------------------- */
  const [impactList, setimpactList] = useState([]);
  const [impactLoading, setImpactLoading] = useState(false);
  const getImpactList = async (value: string, id: string) => {
    try {
      setResetInput(false);
      setImpactLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        id: id,
        // group_id: "36", //
        group_code: "impact",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        setimpactList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
        setImpactLoading(false);
        setValue(request, `impact_id`, "");
      } else {
        setImpactLoading(false);
      }
    } catch (error) {
      setImpactLoading(false);
    }
    if (!value) {
      setValue(request, `impact_id`, "");
    }
  };
  /* ---------------------------  Impact on Organization    ---------------------------- */
  const [impact_on_organizationList, setimpact_on_organizationList] = useState(
    []
  );
  const [impact_on_organizationLoading, setImpact_on_organizationLoading] =
    useState(false);
  const getImpactOrganizationList = async (value: string, id: string) => {
    try {
      setResetInput(false);
      setImpact_on_organizationLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        id: id,
        // group_id: "36", //
        group_code: "impact-on-organization",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        setimpact_on_organizationList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
        setImpact_on_organizationLoading(false);
        setValue(request, `impact_on_organization_id`, "");
      } else {
        setImpact_on_organizationLoading(false);
      }
    } catch (error) {
      setImpact_on_organizationLoading(false);
    }
    if (!value) {
      setValue(request, `impact_on_organization_id`, "");
    }
  };
  /* ---------------------------  Impact on Unit            ---------------------------- */
  const [impact_on_unitList, setimpact_on_unitList] = useState([]);
  const [impact_on_unitLoading, setImpact_on_unitLoading] = useState(false);
  const getImpactOnUnitList = async (value: string, id: string) => {
    try {
      setResetInput(false);
      setImpact_on_unitLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        id: id,
        // group_id: "36", //
        group_code: "impact-on-unit",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        setimpact_on_unitList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
        setImpact_on_unitLoading(false);
        setValue(request, `impact_on_unit_id`, "");
      } else {
        setImpact_on_unitLoading(false);
      }
    } catch (error) {
      setImpact_on_unitLoading(false);
    }
    if (!value) {
      setValue(request, `impact_on_unit_id`, "");
    }
  };
  /* ---------------------------  Imprisonment Applies to   ---------------------------- */
  const [imprisonment_applies, setimprisonment_appliesList] = useState([]);
  const [imprisonment_appliesLoading, setImprisonment_appliesLoading] =
    useState(false);
  const getImprisonmentList = async (value: string, id: string) => {
    try {
      setResetInput(false);
      setImprisonment_appliesLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        id: id,
        // group_id: "36", //
        group_code: "imprisonment-applies",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        setimprisonment_appliesList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
        setImprisonment_appliesLoading(false);
        setValue(request, `imprisonment_applies_id`, "");
      } else {
        setImprisonment_appliesLoading(false);
      }
    } catch (error) {
      setImprisonment_appliesLoading(false);
    }
    if (!value) {
      setValue(request, `imprisonment_applies_id`, "");
    }
  };
  /* ---------------------------  Prohibitive                ---------------------------- */
  const [prohibitiveList, setProhibitiveList] = useState([]);
  const [prohibitiveLoading, setProhibitiveLoading] = useState(false);
  const getProhibitiveList = async (value: string, id: string) => {
    try {
      setResetInput(false);
      setProhibitiveLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        id: id,
        // group_id: "36", //
        group_code: "prohibitive",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        setProhibitiveList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
        setProhibitiveLoading(false);
        setValue(request, `prohibitive_id`, "");
      } else {
        setProhibitiveLoading(false);
      }
    } catch (error) {
      setProhibitiveLoading(false);
    }
    if (!value) {
      setValue(request, `prohibitive_id`, "");
    }
  };
  /* ---------------------------  Task Type                  ---------------------------- */
  const [task_typeList, settask_typeList] = useState([]);
  const [task_typeLoading, setTask_typeLoading] = useState(false);
  const getTaskTypeList = async (value: string, id: string) => {
    try {
      setResetInput(false);
      setTask_typeLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        id: id,
        // group_id: "36", //
        group_code: "task-type",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        settask_typeList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
        setTask_typeLoading(false);
        setValue(request, `task_type_id`, "");
      } else {
        setTask_typeLoading(false);
      }
    } catch (error) {
      setTask_typeLoading(false);
    }
    if (!value) {
      setValue(request, `task_type_id`, "");
    }
  };
  /* ---------------------------  Level                      ---------------------------- */
  const [levelList, setlevelList] = useState([]);
  const [levelLoading, setLevelLoading] = useState(false);
  const getLevelList = async (value: string, id: string) => {
    try {
      setResetInput(false);
      setLevelLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        id: id,
        // group_id: "36", //
        group_code: "level",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        setlevelList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
        setLevelLoading(false);
        setValue(request, `level_id`, "");
      } else {
        setLevelLoading(false);
      }
    } catch (error) {
      setLevelLoading(false);
    }
    if (!value) {
      setValue(request, `level_id`, "");
    }
  };

  /* ---------------------------  Events                     ----------------------------- */
  const [eventList, setEventList] = useState([]);
  const [eventLoading, setEventLoading] = useState(false);
  const getEventsList = async (value: string, id: string) => {
    try {
      setResetInput(false);
      setEventLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        id: id,
        // group_id: "36", //
        group_code: "events",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        setEventList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
        setEventLoading(false);
        setValue(request, `event_id`, "");
      } else {
        setEventLoading(false);
      }
    } catch (error) {
      setEventLoading(false);
    }
    if (!value) {
      setValue(request, `event_id`, "");
    }
  };

  /* ---------------------------  Sub events                  ----------------------------- */
  const [subEventList, setsub_eventsList] = useState([]);
  const [subEventLoading, setSubEventLoading] = useState(false);
  const getSubEventsList = async (value: string, id: string) => {
    try {
      setResetInput(false);
      setSubEventLoading(true);
      let payload = {
        page_no: 1,
        page_size: 10,
        search_text: value,
        id: id,
        // group_id: "35",
        group_code: "sub-events",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await adminListManagementList(queryRequest);
      if (resp) {
        setsub_eventsList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
        setSubEventLoading(false);
        setValue(request, `sub_event_id`, "");
      } else {
        setSubEventLoading(false);
      }
    } catch (error) {
      setSubEventLoading(false);
    }
    if (!value) {
      setValue(request, `sub_event_id`, "");
    }
  };

  /* ------------------------------------------------------------------------------ */
  /*                                   Onchange section                             */
  /* ------------------------------------------------------------------------------ */

  const handleChangeSelect = (e: any, type: string) => {
    setRequest({
      ...request,
      [type]: e.id,
    });

    if (type === "country_id") {
      getStatesList(e.id);
      setStates([]);
      setCategoryOfLawList([]);
      setLegislationList([]);
      setRuleList([]);
    }
    if (type === "state_id") {
      getCategoryLawList("", e.id, "");
      // getLegislationList(e.id);
      getLegislationList("", e.id, "");
      setCategoryOfLawList([]);
      setLegislationList([]);
      setRuleList([]);
    }
    if (type === "legislation_id") {
      getLegislationRuleList("", e.id, "");
    }
  };

  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };
  const handleChangeSmartFilter = (e: any) => {
    setSelectedSmartFilter(e);
  };
  const [document_ids, setdocument_ids] = useState<any>([]);
  const handleUploadDocumnets = async (e: any) => {
    Array.from(e.target.files).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        let resp = await vendorFileUpload(formData);
        if (resp) {
          document_ids.push(getValue(resp, `data`, {}));
          setdocument_ids([...document_ids]);
          e.target.value = null;
        } else {
          e.target.value = null;
        }
      } catch (error) {
        e.target.value = null;
      }
    });
  };
  const handleRemoveUploadedFiles = (id: string) => {
    let filtered = document_ids.filter(
      (item: object) => getValue(item, `id`, "") !== id
    );
    setdocument_ids(filtered);
  };

  /* ------------------------------------------------------------------------------ */
  /*                                Prompt Section                                  */
  /* ------------------------------------------------------------------------------ */
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    setIsChanged(
      _.isEqualWith(request, dirtyFields, (a: any, b: any) => {
        // if both are null or equal to an empty string then they are equal
        if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
      })
    );
  });
  // const [
  //   showPrompt,
  //   confirmNavigation,
  //   cancelNavigation,
  // ]: any = useCallbackPrompt(!isChanged);

  const [permissionLoading, setPermissionLoading] = React.useState(true);

//Docs
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const [uploadLoading, setUploadLoading] = useState(false);
  const [importedFile, setImportTedFile] = useState<any>(null);

  const [document_store_ids, setdocument_Store_ids] = useState<any>([]);

  const handleUploadDocumentsStore = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      const newDocumentStoreIds = [...document_store_ids];
      try {
        setUploadLoading(true);
        
        // First API call
        let vendorResp = await vendorFileUpload(formData);        
        if (vendorResp) {
          // Prepare data for the second API call
          let docFormData = new FormData();
          docFormData.append("file", item);
          // Add additional data required for docFileUpload if needed
          const newDocumentId = getValue(vendorResp, `data`, "");
          newDocumentStoreIds.push(newDocumentId);

          let payLoadObj = {
            checkpoint_id: getValue(request, `checkpoint_id`, ""),
            document_ids: [newDocumentId],
          };

          let docResp = await docStoreFileUpload(payLoadObj);            
          if (docResp) {
            console.log(1412,getValue(docResp,`data.documents`, []))
            //  setdocument_Store_ids(getValue(docResp,`data.documents`, []));
            getDocumentStore(getValue(request, `checkpoint_id`, ""));
          }
        }
  
        e = null;
        setUploadLoading(false);
      } catch (error) {
        
        console.log(1420,error)
        console.error(error);
        e = null;
        setUploadLoading(false);
      }
    });
  };
  

  const handleRemoveDocuments = async (checkpointId:string, documentID: string) => {
    let resp = await deleteSpecificFileStore(checkpointId,documentID)
    if (resp) {
      getDocumentStore(getValue(request, `checkpoint_id`, ""))
    }
  }
  
  return (
    <>
      <Header />
      {!permissionLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "checkpoint_active"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          {/* <PromptModal
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          /> */}
          <div className="user-details-wrapper">
            <div className="user-details-wrapper__inner-header">
              <div className="inner-page-navigation-with-back-cta-buttons position-relative">
                <div className="container-fluid d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <NavLink to={"/checkpoint/active"} className="">
                      <img
                        src="/images/back-link.svg"
                        className="img-fluid"
                        height={24}
                        width={24}
                      />
                    </NavLink>
                    <div className="inner-page-navigation-with-back-cta-button__header">
                      <h4 className="inner-page-navigation-with-back-cta-button__header-title">
                        {getValue(params, `id`, "") ? "Update" : "Add"}{" "}
                        Checkpoint
                      </h4>
                    </div>
                  </div>
                  {getValue(props, `permissions`, []).includes("update") && (
                    <div className="user-details-wrapper__cta-btns">
                      <NavLink to={`/checkpoint/active`}>
                        <button className="ascent-button ascent-button--header-buttons ascent-button--primary">
                          Cancel
                        </button>
                      </NavLink>
                      {isLoading ? (
                        <button className="ascent-button ascent-button--header-buttons ascent-button--secondary">
                          Please wait...
                        </button>
                      ) : (
                        <button
                          onClick={handleSubmit}
                          // disabled={isChanged}
                          className={`ascent-button ascent-button--header-buttons ascent-button--${
                            isChanged ? "primary" : "secondary"
                          }`}
                        >
                          {getValue(params, `id`, "") ? "Update" : "Submit"}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="checkpoint-active-details-wrapper__form-wrapper">
              <div className="checkpoint-active-details-wrapper__form-content">
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label className="checkpoint-active-details__label">
                      Country <span>*</span>
                    </label>
                    <InputField
                      inputType="INPUT_REACT_SELECT"
                      placeholder="Enter Country"
                      className="form-control w-100"
                      name="country_id"
                      id="country_id"
                      options={countryList}
                      value={countryList.filter(
                        (item: object) =>
                          getValue(item, `id`, "") ==
                          getValue(request, `country_id`, "")
                      )}
                      onChange={(e: any) => handleChangeSelect(e, "country_id")}
                      label="Country"
                      validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label className="checkpoint-active-details__label">
                      State <span>*</span>
                    </label>
                    <InputField
                      inputType="INPUT_REACT_SELECT"
                      placeholder="Enter State"
                      className="form-control w-100"
                      name="state_id"
                      id="state_id"
                      label="State"
                      options={states}
                      value={states.filter(
                        (item: object) =>
                          getValue(item, `id`, "") ==
                          getValue(request, `state_id`, "")
                      )}
                      onChange={(e: any) => handleChangeSelect(e, "state_id")}
                      validator={simpleValidator}
                    />
                  </div>
                </div>
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label className="checkpoint-active-details__label">
                      Category Of Law <span>*</span>
                    </label>
                    {/* <InputField
											inputType="INPUT_REACT_SELECT"
											placeholder="Enter Category Of Law "
											className="form-control w-100"
											name="category_of_law_id"
											id="category_of_law_id"
											label="Category Law"
											options={categoryOfLawList}
											value={categoryOfLawList.filter(
												(item: object) =>
													getValue(item, `id`, "") ==
													getValue(request, `category_of_law_id`, "")
											)}
											onChange={(e: any) =>
												handleChangeSelect(e, "category_of_law_id")
											}
											validator={simpleValidator}
										/> */}
                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Legislation Rule"}
                      options={categoryOfLawList}
                      searchLoading={categoryLawLoading}
                      handleChange={(val: any) =>
                        getCategoryLawList(val, "", "")
                      }
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "category_of_law_id")
                      }
                      value={
                        getValue(categoryOfLawList, `length`, 0) > 0
                          ? categoryOfLawList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `category_of_law_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Category Of Law"
                      resetInput={resetInput}
                      validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label className="checkpoint-active-details__label">
                      Legislation <span>*</span>
                    </label>
                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Legislation"}
                      options={legislationList}
                      searchLoading={legislationLoading}
                      handleChange={(val: any) =>
                        getLegislationList(val, "", "")
                      }
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "legislation_id")
                      }
                      value={
                        getValue(legislationList, `length`, 0) > 0
                          ? legislationList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `legislation_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Legislation"
                      resetInput={resetInput}
                      validator={simpleValidator}
                    />
                  </div>
                </div>
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Rule <span>*</span>
                    </label>
                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Legislation Rule"}
                      options={ruleList}
                      searchLoading={legislationRuleLoading}
                      handleChange={(val: any) =>
                        getLegislationRuleList(val, "", "")
                      }
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "legislation_rule_id")
                      }
                      value={
                        getValue(ruleList, `length`, 0) > 0
                          ? ruleList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `legislation_rule_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Legislation Rule"
                      resetInput={resetInput}
                      validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Checkpoint ID <span>*</span>
                    </label>
                    <InputField
                      inputType="TEXT"
                      placeholder="Enter Legislation Rule"
                      className="form-control w-100"
                      name="checkpoint_id"
                      id="checkpoint_id"
                      label="Checkpoint ID"
                      value={getValue(request, `checkpoint_id`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      validator={simpleValidator}
                    />
                  </div>
                  {/* <div className="user-details-wrapper__form-group form-group">
								<label className="checkpoint-active-details__label">
									Risk Type <span>*</span>
								</label>
								<InputField
									inputType="INPUT_REACT_SELECT"
									placeholder="Enter Risk Type"
									className="form-control w-100"
									name="risk_type_id"
									id="risk_type_id"
									label="Risk Type"
									options={riskTypeList}
									value={riskTypeList.filter(
										(item: object) =>
											getValue(item, `id`, "") ==
											getValue(request, `risk_type_id`, "")
									)}
									onChange={(e: any) => handleChangeSelect(e, "risk_type_id")}
									validator={simpleValidator}
								/>
							</div> */}
                </div>

                {/* <div className="user-details-wrapper__form-group form-group">
							<label className="checkpoint-active-details__label">
								Description <span>*</span>
							</label>
							<InputField
								inputType="TEXTAREA"
								placeholder="Enter Description"
								className="form-control w-100"
								rows={5}
								name="description"
								id="description"
								label="Description"
								value={getValue(request, `description`, "")}
								onChange={(e: any) => handleChangeText(e)}
								validator={simpleValidator}
							/>
						</div> */}

                <br />
                <h5>Activity Details</h5>
                <br />

                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Who <span></span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      placeholder="Enter Details"
                      className="form-control w-100"
                      name="who"
                      id="who"
                      label="Who"
                      value={getValue(request, `who`, "")}
                      rows={5}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      When <span></span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      placeholder="Enter Details"
                      className="form-control w-100"
                      name="when"
                      id="when"
                      label="When"
                      value={getValue(request, `when`, "")}
                      rows={5}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                </div>
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Compliance Activity <span>*</span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      placeholder="Enter Compliance Activity"
                      className="form-control w-100"
                      name="compliance_activity"
                      id="compliance_activity"
                      label="Compliance Activity"
                      value={getValue(request, `compliance_activity`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      validator={simpleValidator}
                      rows={5}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Reference <span>*</span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      placeholder="Enter Reference"
                      className="form-control w-100"
                      name="reference"
                      id="reference"
                      label="Reference"
                      value={getValue(request, `reference`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      validator={simpleValidator}
                      rows={5}
                    />
                  </div>
                </div>
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Procedure <span></span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      placeholder="Enter Details"
                      className="form-control w-100"
                      name="procedure"
                      id="procedure"
                      label="Procedure"
                      value={getValue(request, `procedure`, "")}
                      rows={5}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      More Information <span></span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      placeholder="Enter Details"
                      className="form-control w-100"
                      name="more_information"
                      id="more_information"
                      label="More Information"
                      value={getValue(request, `more_information`, "")}
                      rows={5}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                </div>
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Frequency <span></span>
                    </label>

                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Frequency"}
                      options={frequencyList}
                      searchLoading={frequencyLoading}
                      handleChange={(val: any) => getFrequencyList(val, "")}
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "frequency_id")
                      }
                      value={
                        getValue(frequencyList, `length`, 0) > 0
                          ? frequencyList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `frequency_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Frequency"
                      resetInput={resetInput}
                      // validator={simpleValidator}
                    />
                  </div>
                </div>
                <br />
                <h5>More Details</h5>
                <br />

                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label className="checkpoint-active-details__label">
                      Prohibitive/Prescriptive <span></span>
                    </label>
                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Prohibitive/Prescriptive"}
                      options={prohibitiveList}
                      searchLoading={prohibitiveLoading}
                      handleChange={(val: any) => getProhibitiveList(val, "")}
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "prohibitive_id")
                      }
                      value={
                        getValue(prohibitiveList, `length`, 0) > 0
                          ? prohibitiveList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `prohibitive_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Prohibitive/Prescriptive"
                      resetInput={resetInput}
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label className="checkpoint-active-details__label">
                      Type of Task <span></span>
                    </label>
                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Type of Task"}
                      options={task_typeList}
                      searchLoading={task_typeLoading}
                      handleChange={(val: any) => getTaskTypeList(val, "")}
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "task_type_id")
                      }
                      value={
                        getValue(task_typeList, `length`, 0) > 0
                          ? task_typeList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `task_type_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Type of Task"
                      resetInput={resetInput}
                      // validator={simpleValidator}
                    />
                  </div>
                </div>
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label className="checkpoint-active-details__label">
                      Level <span></span>
                    </label>
                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Level"}
                      options={levelList}
                      searchLoading={task_typeLoading}
                      handleChange={(val: any) => getLevelList(val, "")}
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "level_id")
                      }
                      value={
                        getValue(levelList, `length`, 0) > 0
                          ? levelList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `level_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Level"
                      resetInput={resetInput}
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label className="checkpoint-active-details__label">
                      Form No. <span></span>
                    </label>
                    <InputField
                      inputType="TEXT"
                      placeholder="Enter Form No"
                      className="form-control w-100"
                      name="form_no"
                      id="form_no"
                      label="Form No"
                      value={getValue(request, `form_no`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                </div>
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label className="checkpoint-active-details__label">
                      Document Type <span></span>
                    </label>
                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Document Type"}
                      options={documentTypeList}
                      searchLoading={documentTypeLoading}
                      handleChange={(val: any) => getDocumentTypeList(val, "")}
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "document_type_id")
                      }
                      value={
                        getValue(documentTypeList, `length`, 0) > 0
                          ? documentTypeList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `document_type_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Document Type"
                      resetInput={resetInput}
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label className="checkpoint-active-details__label">
                      Compliance Type <span>*</span>
                    </label>
                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Compliance Type"}
                      options={complianceTypeList}
                      searchLoading={complianceTypeLoading}
                      handleChange={(val: any) =>
                        getComplianceTypeList(val, "")
                      }
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "compliance_type_id")
                      }
                      value={
                        getValue(complianceTypeList, `length`, 0) > 0
                          ? complianceTypeList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `compliance_type_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Compliance Type"
                      resetInput={resetInput}
                      validator={simpleValidator}
                    />
                  </div>
                </div>
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label className="checkpoint-active-details__label">
                      Statutory Authority <span></span>
                    </label>
                    <InputField
                      inputType="TEXT"
                      placeholder="Enter Document Type"
                      className="form-control w-100"
                      name="statutory_authority"
                      id="statutory_authority"
                      label="Statutory Authority"
                      value={getValue(request, `statutory_authority`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Proof of Compliance <span></span>
                    </label>
                    <InputField
                      inputType="TEXT"
                      placeholder="Enter Compliance TypeList"
                      className="form-control w-100"
                      name="proof_of_compliance"
                      id="proof_of_compliance"
                      label="Proof of Compliance"
                      value={getValue(request, `proof_of_compliance`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                </div>

                <div className="user-details-wrapper__form-group form-group">
                  <label className="checkpoint-active-details__label">
                    Weblinks <span></span>
                  </label>
                  <InputField
                    inputType="TEXTAREA"
                    placeholder="Enter Compliance TypeList"
                    className="form-control w-100"
                    rows={5}
                    name="weblinks"
                    id="weblinks"
                    label="Weblinks"
                    value={getValue(request, `weblinks`, "")}
                    onChange={(e: any) => handleChangeText(e)}
                    // validator={simpleValidator}
                  />
                </div>
                <div className="user-details-wrapper__form-group form-group">
                  <label className="checkpoint-active-details__label mx-0 cursor-pointer">
                    <div className="mb-1">Document Upload</div>
                    <input
                      type={"file"}
                      onChange={handleUploadDocumnets}
                      multiple
                      className="d-none"
                    />
                    <div className="custom-inputfile d-flex align-items-center">
                      <div className="m-auto">
                        <p className="custom-inputfile__text text-center">
                          Attach Files
                        </p>
                        <p>
                          {" "}
                          Files Uploaded ({getValue(document_ids, `length`, 0)})
                        </p>
                      </div>
                    </div>
                  </label>

                  {getValue(document_ids, `length`, 0) > 0
                    ? document_ids.map((item: object, key: number) => {
                        return (
                          <div
                            key={key}
                            className="d-flex justify-content-between m-4"
                          >
                            <p
                              className="cursor-pointer"
                              onClick={() =>
                                handleDownloadOnClick(
                                  getValue(item, `url`, ""),
                                  getValue(item, `name`, "")
                                )
                              }
                            >
                              {getValue(item, `name.length`, "") > 30
                                ? getValue(item, `name`, "")
                                    .substring(0, 30)
                                    .concat("...")
                                : getValue(item, `name`, "")}
                            </p>
                            <div>
                              <img
                                src="/images/close-black.svg"
                                className="img-fluid cursor-pointer"
                                onClick={() =>
                                  handleRemoveUploadedFiles(
                                    getValue(item, `id`, "")
                                  )
                                }
                              />
                            </div>
                          </div>
                        );
                      })
                    : ""}
                  <div></div>
                </div>
                <br />
                <h5>Implications</h5>
                <br />

                <div className="user-details-wrapper__form mb-3">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Impact <span></span>
                    </label>
                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Impact"}
                      options={impactList}
                      searchLoading={documentTypeLoading}
                      handleChange={(val: any) => getImpactList(val, "")}
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "impact_id")
                      }
                      value={
                        getValue(impactList, `length`, 0) > 0
                          ? impactList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `impact_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Impact"
                      resetInput={resetInput}
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Impact on Organization <span></span>
                    </label>
                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Impact On Organization"}
                      options={impact_on_organizationList}
                      searchLoading={documentTypeLoading}
                      handleChange={(val: any) =>
                        getImpactOrganizationList(val, "")
                      }
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "impact_on_organization_id")
                      }
                      value={
                        getValue(impact_on_organizationList, `length`, 0) > 0
                          ? impact_on_organizationList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(
                                  request,
                                  `impact_on_organization_id`,
                                  ""
                                )
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Impact On Organization"
                      resetInput={resetInput}
                      // validator={simpleValidator}
                    />
                  </div>
                </div>
                <div className="user-details-wrapper__form mb-3">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Impact on Unit <span></span>
                    </label>
                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Impact on Unit"}
                      options={impact_on_unitList}
                      searchLoading={documentTypeLoading}
                      handleChange={(val: any) => getImpactOnUnitList(val, "")}
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "impact_on_unit_id")
                      }
                      value={
                        getValue(impact_on_unitList, `length`, 0) > 0
                          ? impact_on_unitList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `impact_on_unit_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Impact on Unit"
                      resetInput={resetInput}
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Imprisonment Applies to <span></span>
                    </label>

                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Imprisonment Applies to"}
                      options={imprisonment_applies}
                      searchLoading={imprisonment_appliesLoading}
                      handleChange={(val: any) => getImprisonmentList(val, "")}
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "imprisonment_applies_id")
                      }
                      value={
                        getValue(imprisonment_applies, `length`, 0) > 0
                          ? imprisonment_applies.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `imprisonment_applies_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Imprisonment Applies to"
                      resetInput={resetInput}
                      // validator={simpleValidator}
                    />
                  </div>
                </div>
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Implication <span></span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      placeholder="Enter Implication"
                      className="form-control w-100"
                      rows={5}
                      name="implication"
                      id="implication"
                      label="Implication"
                      value={getValue(request, `implication`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Imprisonment Duration <span></span>
                    </label>
                    <InputField
                      inputType="TEXT"
                      placeholder="Enter Imprisonment Duration"
                      className="form-control w-100"
                      name="imprisonment_duration"
                      id="imprisonment_duration"
                      label="Imprisonment Duration"
                      value={getValue(request, `imprisonment_duration`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                </div>
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Fine Amount (INR) <span></span>
                    </label>
                    <InputField
                      inputType="TEXT"
                      placeholder="Enter Fine Amount"
                      className="form-control w-100"
                      name="fine_amount"
                      id="fine_amount"
                      label="Fine Amount"
                      value={getValue(request, `fine_amount`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Subsequent Amount per Day (INR) <span></span>
                    </label>
                    <InputField
                      inputType="TEXT"
                      placeholder="Enter Subsequent Amount per Day"
                      className="form-control w-100"
                      name="subsequent_amount_per_day"
                      id="subsequent_amount_per_day"
                      label="Subsequent Amount per Day"
                      value={getValue(request, `subsequent_amount_per_day`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                </div>
                <br />
                <h5>Event & Inter Linkage</h5>
                <br />

                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Events <span></span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      rows={5}
                      placeholder="Enter Events"
                      className="form-control w-100"
                      name="events"
                      id="events"
                      label="Events"
                      value={getValue(request, `events`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Exemption Criteria <span></span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      rows={5}
                      placeholder="Enter Exemption Criteria"
                      className="form-control w-100"
                      name="exemption_criteria"
                      id="exemption_criteria"
                      label="Exemption Criteria"
                      value={getValue(request, `exemption_criteria`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                </div>
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Inter Linkage <span></span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      rows={5}
                      placeholder="Enter Inter Linkage"
                      className="form-control w-100"
                      name="inter_linkage"
                      id="inter_linkage"
                      label="Inter Linkage"
                      value={getValue(request, `inter_linkage`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Linked Task ID <span></span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      rows={5}
                      placeholder="Enter Linked Task ID"
                      className="form-control w-100"
                      name="linked_task_id"
                      id="linked_task_id"
                      label="Linked Task ID"
                      value={getValue(request, `linked_task_id`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                </div>
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Event <span></span>
                    </label>
                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Event"}
                      options={eventList}
                      searchLoading={eventLoading}
                      handleChange={(val: any) => getEventsList(val, "")}
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "event_id")
                      }
                      value={
                        getValue(eventList, `length`, 0) > 0
                          ? eventList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `event_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Event"
                      resetInput={resetInput}
                    />
                  </div>
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Sub Event <span></span>
                    </label>
                    <CheckpointSearchableDropdownSelect
                      placeholder={"Choose Sub Event"}
                      options={subEventList}
                      searchLoading={subEventLoading}
                      handleChange={(val: any) => getSubEventsList(val, "")}
                      handleClick={(e: any) =>
                        handleChangeSelect(e, "sub_event_id")
                      }
                      value={
                        getValue(subEventList, `length`, 0) > 0
                          ? subEventList.filter(
                              (item: object) =>
                                getValue(item, `id`, "") ===
                                getValue(request, `sub_event_id`, "")
                            )[0]
                          : {}
                      }
                      label="label"
                      id="id"
                      name="Sub Event"
                      resetInput={resetInput}
                    />
                  </div>
                </div>
                <br />
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group">
                    <label className="checkpoint-active-details__label">
                      Bulk Upload Keyword<span></span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      rows={5}
                      placeholder="Enter Bulk Upload Keyword"
                      className="form-control w-100"
                      name="bulk_upload_keyword"
                      id="bulk_upload_keyword"
                      label="Linked Task ID"
                      value={getValue(request, `bulk_upload_keyword`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      // validator={simpleValidator}
                    />
                  </div>
                  {/* <div className="user-details-wrapper__form-group form-group">
								<label className="checkpoint-active-details__label">
									Sub Events ID <span></span>
								</label>
								<InputField
									inputType="TEXT"
									placeholder="Enter Sub Events ID"
									className="form-control w-100"
									name="sub_events"
									id="sub_events"
									value={getValue(request, `sub_events`, "")}
									onChange={(e: any) => handleChangeText(e)}
									label="Sub Events ID"
									// validator={simpleValidator}
								/>
							</div> */}
                </div>

                <div className="user-details-wrapper__form-group form-group">
                  <label className="checkpoint-active-details__label">
                    Tag for Smart Filter<span></span>
                  </label>
                  <InputField
                    inputType="INPUT_REACT_SELECT"
                    placeholder="Enter Last Name"
                    className="form-control w-100"
                    name="selectedSmartFilter"
                    id="selectedSmartFilter"
                    label="Last Name"
                    options={smartFilterList}
                    value={selectedSmartFilter}
                    onChange={(e: any) => handleChangeSmartFilter(e)}
                    // validator={simpleValidator}
                    isMulti={true}
                  />
                </div>

                {getValue(params, "id", "") ? (
                  <>
                    <br />
                    <h5>Reference Document</h5>
                    <br />
                    {/* <div className="user-details-wrapper__form-group form-group">
                      <label className="checkpoint-active-details__label mx-0 cursor-pointer">
                        <div className="mb-1">Document Upload</div>
                        <input
                          type={"file"}
                          onChange={handleUploadDocumentStore}
                          multiple
                          className="d-none"
                        />
                        <div className="custom-inputfile d-flex align-items-center">
                          <div className="m-auto">
                            <p className="custom-inputfile__text text-center">
                              Attach Files
                            </p>
                            <p>
                              {" "}
                              Files Uploaded (
                              {getValue(document_store_ids, `length`, 0)})
                            </p>
                          </div>
                        </div>
                      </label>

                      {getValue(document_store_ids, `length`, 0) > 0
                        ? document_store_ids.map((item: object, key: number) => {
                          console.log(item, "ITEEEMMM")
                            return (
                              <div
                                key={key}
                                className="d-flex justify-content-between m-4"
                              >
                                <p
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleDownloadOnClick(
                                      getValue(item, `url`, ""),
                                      getValue(item, `name`, "")
                                    )
                                  }
                                >
                                  {getValue(item, `name.length`, "") > 30
                                    ? getValue(item, `name`, "")
                                        .substring(0, 30)
                                        .concat("...")
                                    : getValue(item, `name`, "")}
                                </p>
                                <div>
                                  <img
                                    src="/images/close-black.svg"
                                    className="img-fluid cursor-pointer"
                                    onClick={() =>
                                      handleRemoveUploadedDocumentStore(
                                        getValue(item, `id`, "")
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div> */}
                    <div>
                      <DragAndDropFileComponent
                        uploadFile={handleUploadDocumentsStore}
                        name="file"
                        types={fileTypes}
                        multiple
                        // className="d-none"
                        classes="bulk-upload_drag-n-drop-wrapper position-absolute"
                        fileName={getValue(request, `document_store_ids`, "")}
                        setImportTedFile={setImportTedFile}
                        file={"false"}
                      />
                      {document_store_ids.map((item: any) => (
                        <div
                          // className="attach-files-wrapper__files cursor-pointer d-flex justify-content-between mt-2"
                          key={item.id}
                        >
                          {item.documents.map((document: any) => (
                            <>
                              <div
                                className="attach-files-wrapper__files cursor-pointer d-flex justify-content-between mt-2"
                                key={item.id}
                              >
                                <p
                                  key={document.id}
                                  style={{
                                    fontSize: "12px",
                                    marginTop: "0px",
                                    textAlign: "center",
                                  }}
                                  onClick={() => handleDownloadOnClick(document.url, document.name)}
                                >
                                  {formatSmallString(document.name)}
                                </p>
                                <img
                                  src="/images/close-red.svg"
                                  className="img-fluid"
                                  style={{ marginLeft: "7px" }}
                                  // onClick={() => setImportTedFile("")}
                                  onClick={() =>
                                    handleRemoveDocuments(
                                      getValue(request, `checkpoint_id`, ""),
                                      document.id
                                    )
                                  }
                                />
                              </div>
                            </>
                          ))}
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mx-5 my-5">
            <img
              src="/images/checkpoint-illustartion.svg"
              className="img-fluid"
            />
            <img
              src="/images/checkpoint-illustartion.svg"
              className="img-fluid"
            />
          </div>
        </>
      )}
    </>
  );
};

export default CheckpointActiveDetail;
