import DashboardLayout from '@layouts/dashboard/dashboard-layout';
import NotificationBar from '@components/NotificationBar/index';
import '../Uploaded-Details/uploaded-details.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import qs from 'query-string';
import { getValue } from '@utils/lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
	adminGetAllAuditList,
	adminGetAuditDashboardInfo,
	adminGetAuditList,
	adminUpdateAudit,
} from '@services/audit.service';
import { NavLink } from 'react-router-dom';
import Loader from '@components/common/Loader';
import Notfound from '@components/Not-Found';
import PaginationPage from '@components/pagination/pagination';
import { formatText } from '@_common/text-format';
import { Col, Label, Modal, ModalBody, Row } from 'reactstrap';
import InputField from '@_common/InputRuleFeild';
import UploadModal from './Modal/upload';
import CommentsModal from './Modal/comments';
import { handleDownloadOnClick } from '@_common/downloadfile';
import { clientDeleteSubmitCheckpointLocation } from '@services/auth.service';

export default function UploadedDetails(props: any) {
	const uploadDetailsArray = [
		{
			rule: 'Vendor Agreement',
			complianceActivity: 'Agreement between Principal Employer and Vendor',
			locationName: 'ITPL Park',
			vendorName: 'A2Z Infra Management & Services Limited',
			frequency: 'Monthly',
			monthFor: 'Aug-2022',
			status: 'Uploaded',
			submittedDate: '1-May-2022',
			documents: [
				{
					type: 'pdf',
					title: 'Agreement PE and VAgreement between Principal ',
				},
				{
					type: 'word',
					title: 'Agreement PE and VPRAgreement between Principal ',
				},
			],
		},
		{
			rule: 'Compensation Agreement',
			complianceActivity:
				'Compensation for working on National and Festival Holiday as per State Specific Guidelines In case if the employees are required to work during the National / Festival Holidays they shall be paid in double for those days (or) shall be given another compensatory Holiday. (If Com-off extended day on which NFH worked day on which com-off extended details to be submitted on letter head)',
			locationName: 'ITPL Park',
			vendorName: 'AP Securitas Private Limited',
			frequency: 'Monthly',
			monthFor: 'Aug-2022',
			status: 'Uploaded',
			submittedDate: '1-May-2022',
			documents: [
				{
					type: 'pdf',
					title: 'Agreement PE and VAgreement between Principal ',
				},
				{
					type: 'word',
					title: 'Agreement PE and VPRAgreement between Principal ',
				},
			],
		},
		{
			rule: 'Vendor Agreement',
			complianceActivity: 'Agreement between Principal Employer and Vendor',
			locationName: 'ITPL Park',
			vendorName: 'A2Z Infra Management & Services Limited',
			frequency: 'Monthly',
			monthFor: 'Aug-2022',
			status: 'Uploaded',
			submittedDate: '1-May-2022',
			documents: [
				{
					type: 'pdf',
					title: 'Agreement PE and VAgreement between Principal ',
				},
				{
					type: 'word',
					title: 'Agreement PE and VPRAgreement between Principal ',
				},
			],
		},
		{
			rule: 'Vendor Agreement',
			complianceActivity: 'Agreement between Principal Employer and Vendor',
			locationName: 'ITPL Park',
			vendorName: 'A2Z Infra Management & Services Limited',
			frequency: 'Monthly',
			monthFor: 'Aug-2022',
			status: 'Uploaded',
			submittedDate: '1-May-2022',
			documents: [
				{
					type: 'pdf',
					title: 'Agreement PE and VAgreement between Principal ',
				},
				{
					type: 'word',
					title: 'Agreement PE and VPRAgreement between Principal ',
				},
			],
		},
	];

	let navigate = useNavigate();
	let mainParams = useParams();

	/* -------------------------------------------------------------------------- */
	/*                               UseState Section                             */
	/* -------------------------------------------------------------------------- */

	const [isLoading, setIsLoading] = useState(false);
	const [locations, setLocations] = useState([]);

	/* -------------------------------------------------------------------------- */
	/*                               UseEffect Section                            */
	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
			getData();
			// getDashboardInfo();
			setDate(new Date());
		}
	}, [props.permissionAPITriggered]);

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
			getData();
			getDashboardInfo();
			if (params.search_text) {
				setSearchInput(params.search_text);
				setShowSearchTextbox(true);
			}
			if (params.page_no) {
				setPage_no(parseInt(params.page_no));
			}
			if (params.limit) {
				setLimit(parseInt(params.limit));
			}
			if (params.date) {
				setDate(new Date(params.date));
			} else {
				setDate(new Date());
			}
		}
	}, [window.location.href,props.permissionAPITriggered])

	/* -------------------------------------------------------------------------- */
	/*                               API Section                                  */
	/* -------------------------------------------------------------------------- */

	const [remittance_date, setRemittance_date] = useState(null);
	const [expiry_date, setExpiry_date] = useState(null);
	const [dashboardInfo, setDashboardInfo] = useState({});
	const [dashboardLoading, setDashboardLoading] = useState(false);
	const getDashboardInfo = async () => {
		let payload: any = {
			location_id: mainParams.id,
			month: mainParams.date ? parseInt(mainParams.date.split('-')[0]) : '',
			year: mainParams.date ? parseInt(mainParams.date.split('-')[2]) : '',
			vendor_id: '',
			domain_name: getValue(localStorage, `client`, ''),
			type: 'SUMMERY',
		};

		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		try {
			setDashboardLoading(true);
			let resp = await adminGetAuditDashboardInfo(payload);
			if (resp) {
				setDashboardLoading(false);
				setDashboardInfo(getValue(resp, `data`, ''));
			} else {
				setDashboardLoading(false);
			}
		} catch (error) {
			setDashboardLoading(false);
		}
	};

	const getData = async () => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		let dates = date ? date : new Date();

		let payload: any = {
			page: params.page_no ? parseInt(params.page_no) : 1,
			page_size: params.limit ? parseInt(params.limit) : 10,
			search_text: params.search_text,
			status: params.status,
			sort_by: params.sort_by,
			upload_status: params.update_status,
			vendor_id: mainParams.id,
			month: mainParams.date ? mainParams.date.split('-')[0] : '',
			year: mainParams.date ? mainParams.date.split('-')[2] : '',
			domain_name: getValue(localStorage, `client`, ''),
			audit_type:
				mainParams.route === 'uploaded'
					? 'UPLOADED'
					: mainParams.route === 'not-uploaded'
					? 'NOT_UPLOADED'
					: 'COMPLETED',
		};

		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		setIsLoading(true);
		try {
			let resp = await adminGetAllAuditList(queryRequest);
			if (resp) {
				setLocations(
					getValue(resp, `data.vendors_checkpoint`, '')
					// .map((item: object) => ({
					// 	...item,
					// 	id: getValue(item, `vendor_id`, ""),
					// }))
				);
				setTotalCount(getValue(resp, `data.pagination.total`, 0));
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	const getDataRefetch = async () => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		let dates = date ? date : new Date();

		let payload: any = {
			page: params.page_no ? parseInt(params.page_no) : 1,
			page_size: params.limit ? parseInt(params.limit) : 10,
			search_text: params.search_text,
			status: params.status,
			sort_by: params.sort_by,
			upload_status: params.update_status,
			vendor_id: mainParams.id,
			month: mainParams.date ? mainParams.date.split('-')[0] : '',
			year: mainParams.date ? mainParams.date.split('-')[2] : '',
			domain_name: getValue(localStorage, `client`, ''),
			audit_type:
				mainParams.route === 'uploaded'
					? 'UPLOADED'
					: mainParams.route === 'not-uploaded'
					? 'NOT_UPLOADED'
					: 'COMPLETED',
		};

		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		setIsLoading(true);
		try {
			let resp = await adminGetAllAuditList(queryRequest);
			if (resp) {
				setLocations(
					getValue(resp, `data.vendors_checkpoint`, '')
					// .map((item: object) => ({
					// 	...item,
					// 	id: getValue(item, `vendor_id`, ""),
					// }))
				);
				setTotalCount(getValue(resp, `data.pagination.total`, 0));
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};
	const handleDeleteFile = async (documentId: string, id: string) => {
		try {
			let resp = await clientDeleteSubmitCheckpointLocation(
				documentId,
				id,
				getValue(localStorage, `client`, '')
			);
			if (resp) {
				toast.success('Deleted successfully');
				getDataRefetch();
			}
		} catch (error) {}
	};

	const [comments, setComments] = useState('');
	const [submitLoading, setSubmitLoading] = useState(false);
	const handleSubmitTask = async () => {
		try {
			let date1 = JSON.parse(JSON.stringify(remittance_date));
			let date2 = JSON.parse(JSON.stringify(expiry_date));
			if (comments) {
				setSubmitLoading(true);
				let payload: any = {
					ids: selectedIndex,
					audit_status: update_status,
					commentsInfo: {
						comments: comments,
						type: 'SUBMISSION_COMMENT',
					},
					domain_name: getValue(localStorage, `client`, ''),
					remittance_date: date1 ? moment(date1).format('YYYY-MM-DD') : '',
					expiry_date: date2 ? moment(date2).format('YYYY-MM-DD') : '',
				};
				Object.keys(payload).forEach((key) => {
					if (!payload[key] || payload[key] === undefined) {
						delete payload[key];
					}
				});
				let resp = await adminUpdateAudit(mainParams.id, payload);
				if (resp) {
					setSubmitLoading(false);
					toast.success('Submitted successfully');
					handleModal2();
					setComments('');
					getDataRefetch();
					setSelectedIndex([]);
					setRemittance_date(null);
					setExpiry_date(null);
				}
			} else {
				toast.error('Please add comments');
				setSubmitLoading(false);
			}
		} catch (error) {
			setSubmitLoading(false);
		}
	};
	const [update_status, setupdate_status] = useState('');
	const handleUpdateStatus = async (update_status: string) => {
		setupdate_status(update_status);
		handleModal2();
	};
	const handleUpdateStatusSpecific = async (
		update_status: string,
		id: String
	) => {
		selectedIndex.push(id);
		setupdate_status(update_status);
		handleModal2();
	};

	/* -------------------------------------------------------------------------- */
	/*                               Pagination section                           */
	/* -------------------------------------------------------------------------- */

	const [totalCount, setTotalCount] = useState(0);
	const [limit, setLimit] = useState(10);
	const [page_no, setPage_no] = useState(1);

	const handleChangePagination = (page_no: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.page_no = page_no;
		Object.keys(params).forEach((key) => {
			if (!params[key] || params[key] === undefined) {
				delete params[key];
			}
		});
		let queryRequest = qs.stringify(params);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	const handleChangeLimit = (limit: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.limit = limit;
		Object.keys(params).forEach((key) => {
			if (!params[key] || params[key] === undefined) {
				delete params[key];
			}
		});
		let queryRequest = qs.stringify(params);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	/* -------------------------------------------------------------------------- */
	/*                               Search section                               */
	/* -------------------------------------------------------------------------- */
	const [showSearchTextbox, setShowSearchTextbox] = useState(false);
	const [search_text, setSearchInput] = useState<any>('');

	const toggleSearch = () => {
		setShowSearchTextbox((prevState) => !prevState);
	};
	const toggleSearchClose = () => {
		setSearchInput('');
		toggleSearch();
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (params.search_text) {
			delete params.search_text;
			let payload: any = { ...params };
			// removing null values
			Object.keys(payload).forEach((key) => {
				if (!payload[key] || payload[key] === undefined) {
					delete payload[key];
				}
			});
			let queryRequest = qs.stringify(payload);
			navigate(`${window.location.pathname}?${queryRequest}`);
			getData();
		}
	};

	const handleChangeSearch = async (e: any) => {
		setSearchInput(e.target.value);
		let payload: any = {
			search_text: e.target.value,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		navigate(`${window.location.pathname}?${queryRequest}`);
		if (!e.target.value) {
			getData();
		}
	};

	/* -------------------------------------------------------------------------- */
	/*                               Onchange section                             */
	/* -------------------------------------------------------------------------- */
	const [isOpen, setIsOpen] = useState(false);
	const handleModal = () => {
		setIsOpen(!isOpen);
	};
	const [isOpen1, setIsOpen1] = useState(false);
	const handleModal1 = () => {
		setIsOpen1(!isOpen1);
	};

	const [isOpen2, setIsOpen2] = useState(false);
	const handleModal2 = () => {
		setIsOpen2(!isOpen2);
	};

	const [vendorInfo, setVendorInfo] = useState({});
	const handleModalComment = (obj: object) => {
		setVendorInfo(obj);
		handleModal();
	};
	const handleModalCommentCancel = () => {
		setVendorInfo({});
		handleModal();
	};

	const handleModalUpload = (obj: object) => {
		setVendorInfo(obj);
		handleModal1();
	};
	const handleModalUploadCancel = () => {
		setVendorInfo({});
		handleModal1();
	};

	/* -------------------------------------------------------------------------- */
	/*                               Date section                                 */
	/* -------------------------------------------------------------------------- */

	const [date, setDate] = useState<Date | null>(null);
	const handleSubmitDate = () => {
		let dates = moment(date).format('MM-DD-YYYY');
		let payload: any = {
			date: dates,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	/* -------------------------------------------------------------------------- */
	/*                               Checkbox Section                             */
	/* -------------------------------------------------------------------------- */

	const [selectedIndex, setSelectedIndex] = useState<any>([]);

	const handleChangeCheckbox = (id: String) => {
		let selected = selectedIndex.filter((item: string) => item === id);
		if (getValue(selected, `length`, 0) === 0) {
			selectedIndex.push(id);
			setSelectedIndex([...selectedIndex]);
		} else {
			let selected = selectedIndex.filter((item: string) => item !== id);
			setSelectedIndex(selected);
		}
	};

	const [all, setAll] = useState('false');
	const handleChangeAllLocations = (value: string) => {
		setAll(value);
		if (value === 'true') {
			let loc = locations.map((item: object) => getValue(item, `id`, ''));
			setSelectedIndex(loc);
		} else {
			setSelectedIndex([]);
		}
	};

	const handleRemoveNavItems = () => {
		handleChangeAllLocations('false');
	};
	const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());
	let enableCopy: any =
		getValue(selectedIndex, `length`, 0) === 1
			? getValue(locations, `length`, 0) > 0 &&
			  locations.filter(
					(item: object) =>
						getValue(item, `id`, '') === getValue(selectedIndex, `[${0}]`, '')
			  )
			: [];

	const [filterOptions] = useState([
		{ value: 'COMPLIED', label: 'MARK COMPLIED' },
		{ value: 'PARTIALLY_COMPLIED', label: 'MARK PARTIALLY COMPLIED' },
		{ value: 'NON_COMPLIED', label: 'ACCEPT NON COMPLIED' },
		{ value: 'NOT_APPLICABLE', label: 'MARK NOT APPLICABLE' },
		{ value: 'SEND_BACK_TO_VENDOR', label: 'REJECT BACK TO VENDOR' },
	]);

	return (
		<DashboardLayout permissions={getValue(props,`tabPermissionList`,[])}>
			{mainParams.route === 'uploaded' &&
				getValue(selectedIndex, `length`, 0) > 0 && (
					<NotificationBar
						isFilterVisisbile={true}
						selectedIDs={selectedIndex}
						handleRemoveNavItems={handleRemoveNavItems}
						filterOptions={filterOptions}
						handleUpdateStatus={handleUpdateStatus}
						permissions={props.permissions}
					/>
				)}
			<div className="dashboard-wrapper__header d-flex mb-4 align-items-center">
				<NavLink to={`/audit/${mainParams.route}?date=${mainParams.date}`}>
					<img src="/images/back-link.svg" className="img-fluid" />
				</NavLink>
				&nbsp;&nbsp;&nbsp;
				<h6>{getValue(mainParams, `name`, '')}</h6>
			</div>

			<section className="uploaded-details-wrapper">
				{isLoading ? (
					<Loader />
				) : getValue(locations, `length`, 0) > 0 ? (
					<>
						<div className="uploaded-details-wrapper__header d-flex  w-100">
							<div className="uploaded-details-wrapper-form-group form-group">
								<label
									className="m-0 position-relative"
									htmlFor="header-checkbox"
								>
									<input
										type="checkbox"
										className="default-checkbox"
										id="header-checkbox"
										onChange={() =>
											handleChangeAllLocations(
												all === 'true' ? 'false' : 'true'
											)
										}
										checked={
											locations.length === selectedIndex.length &&
											all === 'true'
										}
									/>
									<div className="custom-checkbox">
										<img
											src="/images/uploaded-details/tick.svg"
											className="img-fluid uploaded-tick"
										/>
									</div>
								</label>
							</div>
							<div className="uploaded-details-wrapper__rules-wrapper">
								<h6 className="uploaded-details-wrapper__header-title">
									<h5 className="uploaded-details-wrapper__header-title">
										Task ID
									</h5>
									{/* <p className="uploaded-details-wrapper__header-text">
										Start Date
									</p> */}
								</h6>
							</div>
							{/* <div className="uploaded-details-wrapper__status-wrapper">
								<h5 className="uploaded-details-wrapper__header-title">
									Vendor Name
								</h5>
								<p className="uploaded-details-wrapper__header-text">
									Vendor Type
								</p>
							</div> */}
							<div className="uploaded-details-wrapper__location-wrapper">
								<h5 className="uploaded-details-wrapper__header-title">
									Compliance Activity
								</h5>
								{/* <p className="uploaded-details-wrapper__header-text">
									Location Name
								</p> */}
							</div>
							<div className="uploaded-details-wrapper__frequency-wrapper">
								<h5 className="uploaded-details-wrapper__header-title">
									Upload Status
								</h5>
								<p className="uploaded-details-wrapper__header-text">
									Audit Status
								</p>
							</div>
						</div>
						{/* <div className="uploaded-details-wrapper__status-wrapper">
							<h5 className="uploaded-details-wrapper__header-title">
								City name
							</h5>
						</div> */}
						{locations.map((bodyObj: any, index) => {
							return (
								<>
									<div
										className="uploaded-details-wrapper__body d-flex  w-100"
										key={`details-${index}`}
									>
										<div className="uploaded-details-wrapper-form-group form-group">
											<label
												className="m-0 position-relative"
												htmlFor={`details-${index}`}
											>
												<input
													type="checkbox"
													className="default-checkbox"
													id={`details-${index}`}
													checked={selectedIndex.includes(
														getValue(bodyObj, `id`, '')
													)}
													onChange={() =>
														handleChangeCheckbox(getValue(bodyObj, `id`, ''))
													}
												/>
												<div className="custom-checkbox">
													<img
														src="/images/uploaded-details/tick.svg"
														className="img-fluid uploaded-tick"
													/>
												</div>
											</label>
										</div>
										<label
											className="uploaded-details-wrapper__rules-wrapper cursor-pointer"
											htmlFor={`details-${index}`}
										>
											<h6 className="uploaded-details-wrapper__body-title">
												{getValue(bodyObj, `task_id`, '')}
											</h6>
											{/* <p className="uploaded-details-wrapper__body-text">
												{moment(getValue(bodyObj, `start_date`, "")).format(
													"DD MMM YYYY"
												)}
											</p> */}
										</label>
										{/* <div className="uploaded-details-wrapper__status-wrapper">
											<h6 className="uploaded-details-wrapper__body-title uploaded-details-wrapper__body-title">
												{getValue(bodyObj, `vendor.vendor_name`, "")}
											</h6>
											<p className="uploaded-details-wrapper__body-text">
												{formatText(
													getValue(bodyObj, `vendor.vendor_type`, "")
												)}
											</p>
										</div> */}
										<div className="uploaded-details-wrapper__frequency-wrapper">
											<h6 className="uploaded-details-wrapper__body-title">
												{getValue(
													bodyObj,
													`checkpoint.compliance_activity`,
													''
												)}
											</h6>
											{/* <p className="uploaded-details-wrapper__body-text">
												{getValue(
													bodyObj,
													`checkpoint.legislation_rule.rule_name`,
													""
												)}
											</p>*/}
										</div>

										<div className="uploaded-details-wrapper__frequency-wrapper">
											<h6
												className={`uploaded-details-wrapper__body-title uploaded-details-wrapper__body-title--${
													getValue(bodyObj, `upload_status`, '') ===
													'NOT_UPLOADED'
														? 'red'
														: 'green'
												}`}
											>
												{formatText(getValue(bodyObj, `upload_status`, ''))}
											</h6>
											{formatText(getValue(bodyObj, `audit_status`, ''))}
										</div>

										{/* <div className="uploaded-details-wrapper__status-wrapper">
											<h6 className="uploaded-details-wrapper__body-title uploaded-details-wrapper__body-title--red">
												{getValue(bodyObj, `city_name`, "")}
											</h6>
										</div> */}
										<div className="uploaded-details-wrapper__icons-wrapper">
											{mainParams.route === 'uploaded' && (
												<>
													<img
														src="/images/uploaded-details/img4.svg"
														className="img-fluid cursor-pointer"
														height={36}
														width={36}
														onClick={() =>
															handleUpdateStatusSpecific(
																'SEND_BACK_TO_VENDOR',
																getValue(bodyObj, `id`, '')
															)
														}
													/>
													<img
														src="/images/uploaded-details/img3.svg"
														className="img-fluid cursor-pointer"
														height={36}
														width={36}
														onClick={() =>
															handleUpdateStatusSpecific(
																'COMPLIED',
																getValue(bodyObj, `id`, '')
															)
														}
													/>
													<img
														src="/images/uploaded-details/img6.svg"
														className="img-fluid cursor-pointer"
														height={36}
														width={36}
														onClick={() =>
															handleUpdateStatusSpecific(
																'NON_COMPLIED',
																getValue(bodyObj, `id`, '')
															)
														}
													/>
												</>
											)}
											<img
												src="/images/uploaded-details/img1.svg"
												className="img-fluid cursor-pointer"
												height={36}
												width={36}
												onClick={() => handleModalUpload(bodyObj)}
											/>
											<img
												src="/images/uploaded-details/img2.svg"
												className="img-fluid cursor-pointer"
												height={36}
												width={36}
												onClick={() => handleModalComment(bodyObj)}
											/>
										</div>
									</div>
									<div className="uploaded-details-wrapper__agreement-wrapper d-flex align-items-center">
										{getValue(bodyObj, `documents`, []).map(
											(documentsObj: any, index: number) => {
												return (
													<div className="uploaded-details-wrapper__agreement d-flex align-items-center justify-content-between">
														<div className="d-flex align-items-center">
															<img
																src={`/images/uploaded-details/${
																	documentsObj.type
																		? documentsObj.type
																		: documentsObj.file_type === 'document'
																		? documentsObj.file_type
																		: 'word'
																}.svg`}
																className="img-fluid cursor-pointer"
															/>
															<p
																className="uploaded-details-wrapper__agreement-text text-truncate cursor-pointer"
																onClick={() =>
																	handleDownloadOnClick(
																		getValue(documentsObj, `url`, ''),
																		documentsObj.name
																	)
																}
															>
																{documentsObj.name}
															</p>
														</div>
														{/* <img
															onClick={() =>
																handleDeleteFile(
																	getValue(documentsObj, `id`, ''),
																	getValue(bodyObj, `id`, '')
																)
															}
															src="/images/uploaded-details/close-grey.svg"
															className="img-fluid cursor-pointer"
														/> */}
													</div>
												);
											}
										)}
									</div>
								</>
							);
						})}
						<div className="d-flex justify-content-between">
							{locations.length > 0 && (
								<img
									src="/images/profile-illustration.svg"
									className="img-fluid"
								/>
							)}
							{totalCount > 10 ? (
								<PaginationPage
									totalCount={totalCount}
									limit={limit}
									pageNumber={page_no}
									handleChangePagination={handleChangePagination}
									handleChangeLimit={handleChangeLimit}
								/>
							) : (
								''
							)}
						</div>
					</>
				) : (
					<>{Object.keys(params).length !== 0 ? <Notfound /> : <Notfound />}</>
				)}
				{isOpen && (
					<CommentsModal
						{...props}
						isOpen={isOpen}
						handleModal={handleModalCommentCancel}
						vendorInfo={vendorInfo}
						edit={true}
					/>
				)}
				{isOpen1 && (
					<UploadModal
						{...props}
						isOpen={isOpen1}
						handleModal={handleModalUploadCancel}
						vendorInfo={vendorInfo}
						getData={getDataRefetch}
					/>
				)}
				{isOpen2 && (
					<Modal
						isOpen={isOpen2}
						toggle={() => handleModal2()}
						centered
						size="lg"
					>
						<div className="d-flex justify-content-between m-3">
							<div>
								<h3>Add Comments</h3>
							</div>
							<div>
								<img
									src="/images/close-black.svg"
									className="img-fluid cursor-pointer"
									onClick={handleModal2}
								/>
							</div>
						</div>
						<ModalBody>
							<div className="m-4">
								<InputField
									inputType="TEXTAREA"
									value={comments}
									onChange={(e: any) => setComments(e.target.value)}
									rows="10"
									placeholder="Add Comment"
								/>
							</div>
							{update_status === 'COMPLIED' && (
								<div className="m-4">
									<Row>
										<Col md="4">
											<Label>Remittance Date</Label>
											<InputField
												inputType="DATETYPE"
												className="form-control"
												placeholder="Enter Remittance Date"
												value={remittance_date}
												onChange={(e: any) => setRemittance_date(e)}
												// minDate={new Date(mainParams.date)}
											/>
										</Col>
										<Col md="4">
											<Label>Expiry Date</Label>
											<InputField
												inputType="DATETYPE"
												className="form-control"
												placeholder="Enter Expiry Date"
												value={expiry_date}
												onChange={(e: any) => setExpiry_date(e)}
												minDate={new Date()}
											/>
										</Col>
									</Row>
								</div>
							)}
						</ModalBody>
						<div className="d-flex justify-content-end m-3">
							<button
								className="ascent-button ascent-button--header-buttons ascent-button--primary mx-3"
								onClick={() => handleModal2()}
							>
								Close
							</button>
							{submitLoading ? (
								<button className="ascent-button ascent-button--header-buttons ascent-button--secondary">
									Please wait...
								</button>
							) : (
								<button
									className="ascent-button ascent-button--header-buttons ascent-button--secondary"
									onClick={handleSubmitTask}
								>
									Submit
								</button>
							)}
						</div>
					</Modal>
				)}
			</section>
		</DashboardLayout>
	);
}
