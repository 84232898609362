import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

// export const adminGetAuditDashboardInfo = (queryRequest: string) =>
// 	get(`${config.API_URL}/audit/dashboard-info?${queryRequest}`);

export const adminGetAuditDashboardInfo = (payload: object) =>
	post(`${config.API_URL}/audit/dashboard-info`,payload);

export const adminGetAuditList = (queryRequest:string) =>
	get(`${config.API_URL}/audit?${queryRequest}`);

export const adminUpdateAudit = (id: string, payload: object) =>
	patch(`${config.API_URL}/audit`, payload);

export const adminGetAllAuditList = (queryRequest:string) =>
	get(`${config.API_URL}/audit/list-all?${queryRequest}`);
