import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default () => {
	return (
		<div className="login-wrapper flex-1">
			<Swiper
				spaceBetween={50}
				slidesPerView={1}
				modules={[Pagination]}
				pagination={{ clickable: true }}
				onSlideChange={() => console.log("slide change")}
				onSwiper={(swiper: any) => console.log(swiper)}
			>
				<SwiperSlide>
					<img src="/images/Report.svg" className="img-fluid" />
					<h3>Modern and Advanced Audit Platform</h3>
					<p>
						Connect, prepare and analyze your data, create stunning tasks,
						reports and discover hidden insights, all within minutes.
					</p>
				</SwiperSlide>
				<SwiperSlide>
					<img src="/images/Report.svg" className="img-fluid" />
					<h3>Modern and Advanced Audit Platform</h3>
					<p>
						Connect, prepare and analyze your data, create stunning tasks,
						reports and discover hidden insights, all within minutes.
					</p>
				</SwiperSlide>
				<SwiperSlide>
					<img src="/images/Report.svg" className="img-fluid" />
					<h3>Modern and Advanced Audit Platform</h3>
					<p>
						Connect, prepare and analyze your data, create stunning tasks,
						reports and discover hidden insights, all within minutes.
					</p>
				</SwiperSlide>
				<SwiperSlide>
					<img src="/images/Report.svg" className="img-fluid" />
					<h3>Modern and Advanced Audit Platform</h3>
					<p>
						Connect, prepare and analyze your data, create stunning tasks,
						reports and discover hidden insights, all within minutes.
					</p>
				</SwiperSlide>
			</Swiper>
		</div>
	);
};
