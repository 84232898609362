import './index.scss';
export default function Notfound() {
  return (
    <>
      <div className="not-found-wrapper d-flex w-100 my-5">
        <div className="not-found-wrapper__details">
          <img
            src="/images/notfound/no-results-found.svg"
            className="not-found-image img-fluid"
          />
          <p className="no-data-found">No data Found</p>
        </div>
      </div>
    </>
  );
}
