import { QueryRequestHelper } from "@_common/query-request-helper";
import { getSmartFilterCategoryOfLawList } from "@services/list-management.service";
import { getValue } from "@utils/lodash";

export const getSpecificCategoryLawById = async (state_id:string,id: string) => {
  if (id) {
    try {
      let payload = {
        state_id:state_id,
        id: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getSmartFilterCategoryOfLawList(queryRequest);
      if (resp) {
        return getValue(
          resp,
          `data.stateCategoryOfLaw[${0}].category_of_law.id`,
          ""
        );
      }
    } catch (error) {}
  } else {
    return null;
  }
};
