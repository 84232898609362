import React, { useEffect, useState } from "react";
import { adminDeleteSpecificUser } from "@services/users.service";
import { getValue } from "@utils/lodash";
import "./user.scss";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate } from "react-router-dom";
import PaginationPage from "@components/pagination/pagination";
import NavigationBar from "@components/NotificationBar";
import { Button, Modal } from "reactstrap";
import { toast } from "react-toastify";
import DeleteModal from "@_common/deleteModal";
import Notfound from "@components/Not-Found";
// import {
// 	adminCreateGroup,
// 	adminGetGroups,
// 	adminUpdateBulkGroupStatus,
// 	adminUpdateSpecificGroup,
// } from "@services/groups.service";
import Search from "@components/common/Search";
import InputField from "@_common/InputRuleFeild";
import Loader from "@components/common/Loader";
import { QueryRequestHelper } from "@_common/query-request-helper";
import { formatText } from "@_common/format-text";
import moment from "moment";
import NoAccessPage from "@components/common/NoAccess";
import ScrollableReactTable from "@components/ScrollableDatatable";
import { useParams } from "react-router-dom";
import { getTemplates, getTemplatesList } from "@services/list-management.service";
import { handleDownloadOnClick } from "@_common/downloadfile";
interface IAdminUsersProps {}

const FileLogs: React.FunctionComponent<IAdminUsersProps> = (props: any) => {
  let navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [usersList, setUsersList] = React.useState<[]>([]);
  const [group_name, setGroup_name] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState("");

  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleModalCancel = () => {
    setIsOpen(!isOpen);
    setEdit(false);
    setEditId("");
    setGroup_name("");
  };
  const handleModalEdit = (item: object) => {
    setEdit(true);
    setGroup_name(getValue(item, `group_name`, ""));
    setEditId(getValue(item, `id`, ""));
    handleModal();
  };
  const [allStatus] = useState([
    { value: "", label: "All" },
    { value: "STARTED", label: "Started" },
    { value: "STOPPED", label: "Stopped" },
    { value: "SUCCESS", label: "Success" },
    { value: "FAILED", label: "Failed" },
  ]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
      if (params.page_no) {
        setPage_no(parseInt(params.page_no));
      }
      if (params.search_text) {
        setSearchInput(params.search_text);
        setShowSearchTextbox(true);
      }
      if (params.limit) {
        setLimit(parseInt(params.limit));
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const mainParam = useParams();
  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let payload: any = {
      page_no: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : limit,
      search_text: params.search_text,
      status: params.status ? params.status : "",
      sort_by: params.sort_by,
      type: getValue(mainParam, `type`, ""),
      sortBy: "newest",
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getTemplatesList(queryRequest);
      if (resp) {
        setUsersList(
          getValue(resp, `data.imports`, []).map((item: object) => ({
            ...item,
            status: getValue(item, `queue_status`, "")
              ? formatText(getValue(item, `queue_status`, ""))
              : "",
            type_formatted: getValue(item, `type`, "")
              ? formatText(getValue(item, `type`, ""))
              : "",
            created_at: getValue(item, `created_at`, "")
              ? moment(getValue(item, `created_at`, "")).format(
                  "DD MMM YYYY, hh:mm a"
                )
              : "",
          }))
        );
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    // if (edit) {
    // 	try {
    // 		let resp = await adminUpdateSpecificGroup(editId, {
    // 			group_name: group_name,
    // 		});
    // 		if (resp) {
    // 			toast.success(getValue(resp, `message`, ""));
    // 			handleModal();
    // 			getData();
    // 			setGroup_name("");
    // 			setEdit(false);
    // 			setEditId("");
    // 		}
    // 	} catch (error) {}
    // } else {
    // 	try {
    // 		let resp = await adminCreateGroup({ group_name: group_name });
    // 		if (resp) {
    // 			toast.success(getValue(resp, `message`, ""));
    // 			handleModal();
    // 			getData();
    // 			setGroup_name("");
    // 		}
    // 	} catch (error) {}
    // }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.search_text) {
      delete params.search_text;
      let payload: any = { ...params };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    setSearchInput(e.target.value);

    let payload: any = {
      ...params,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeSortBy = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    params.sort_by = value;
    props.history.push({
      pathname: window.location.pathname,
      query: params,
    });
  };

  const handleChangeStatus = (value: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (value) {
      let payload = {
        ...params,
        status: value,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    } else {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      delete params.status;
      let payload = {
        ...params,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const [selectedIDs, setSelectedIDs] = useState<any>([]);
  const getTableState = (value: object) => {
    let selectedIndexList = Object.keys(
      getValue(value, `selectedRowIds`, {})
    ).map((item) => parseInt(item));
    let selectedIds =
      usersList.length > 0
        ? usersList.filter((_item: object, index: number) =>
            selectedIndexList.includes(index)
          )
        : [];
    if (selectedIds.length > 0) {
      setSelectedIDs(
        selectedIds.map((item: object) => getValue(item, `id`, ""))
      );
    } else {
      setSelectedIDs([]);
    }
  };
  const rowClick = (obj: object) => {
    // console.log(getValue(obj,`original`,''))
    // handleModalEdit(getValue(obj, `original`, ""));
  };

  const rowClickUpdate = () => {};

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await adminDeleteSpecificUser(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);

    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...params,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleEdit = (id: string, module: string) => {
    navigate(`/admin/schedules/logs/${id}/${module}`);
  };
  const getTrProps = (rowInfo: any) => {
    if (getValue(rowInfo, `id`, "")) {
      return {
        style: {
          //   background:  'red',
          //   color: 'black'
        },
      };
    }
    return {};
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Job ID",
        accessor: "job_id",
        width: 150,
        Cell: ({ row }: any) => {
          return (
            // <div onClick={() => handleModalEdit(row.original)}>
            <p>{getValue(row, `values.job_id`, "")}</p>
            // </div>
          );
        },
      },
      {
        Header: "Type",
        accessor: "type_formatted",
        width: 250,
      },
      {
        Header: "Attached Count",
        accessor: "attatched_count",
        width: 100,
      },
      {
        Header: "Inserted Count",
        accessor: "inserted_count",
        width: 100,
      },
      {
        Header: "Removed Count",
        accessor: "removed_count",
        width: 100,
      },
      {
        Header: "Failed Count",
        accessor: "failed_count",
        width: 100,
      },
      {
        Header: "Updated Count",
        accessor: "updated_count",
        width: 100,
      },
      {
        Header: "Total Count",
        accessor: "total_count",
        width: 100,
      },

      {
        Header: "Status",
        accessor: "status",
        width: 150,
      },
      {
        Header: "Create At",
        accessor: "created_at",
        width: 250,
      },
      {
        Header: "Download",
        width: 200,
        Cell: ({ row }: any) => {
          return (
            !row.original.is_verified && (
              <div className="table_download_container d-flex align-items-center">
                <button
                  className="resend-btn d-flex align-items-center"
                  onClick={(e) =>
                    handleDownloadOnClick(
                      getValue(row, `original.url`, ""),
                      formatText(getValue(row, `original.type`, ""))
                    )
                  }
                  style={{
                    color: getValue(row, `original.url`, "")
                      ? "#1A73E8"
                      : "black",
                  }}
                  disabled={!getValue(row, `original.url`, "")}
                >
                  {getValue(row, `original.url`, "") ? (
                    <img
                      src="/images/download.svg"
                      className="img-fluid d-block"
                    />
                  ) : getValue(row, `original.queue_status`, "") ===
                    "FAILED" ? (
                    ""
                  ) : (
                    <img
                      src="/images/clock.svg"
                      className="img-fluid d-block"
                      style={{
                        height: "18px",
                        width: "18px",
                        marginRight: "5px",
                      }}
                    />
                  )}
                  {getValue(row, `original.url`, "") ? (
                    "Download"
                  ) : getValue(row, `original.queue_status`, "") ===
                    "FAILED" ? (
                    ""
                  ) : (
                    <p>Processing</p>
                  )}
                </button>
                {!getValue(row, `original.url`, "") &&
                  getValue(row, `original.queue_status`, "") !== "FAILED" && (
                    <img
                      src="/images/reload.svg"
                      className="img-fluid d-block"
                      style={{
                        height: "22px",
                        width: "22px",
                        marginLeft: "10px",
                      }}
                      onClick={() => getData()}
                    />
                  )}
              </div>
            )
          );
        },
      },
    ],
    []
  );

  const handleChangeActiveStatus = async (value: string) => {
    // try {
    // 	let resp = await adminUpdateBulkGroupStatus({
    // 		ids: selectedIDs,
    // 		is_active: value === "active" ? true : false,
    // 	});
    // 	if (resp) {
    // 		toast.success(getValue(resp, `message`, ""));
    // 		setSelectedIDs([]);
    // 		getData();
    // 	} else {
    // 		getData();
    // 	}
    // } catch (error) {}
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };
  return (
    <>
      <DashboardLayout
        permissions={getValue(props, `tabPermissionList`, [])}
        subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
      >
        {!isLoading &&
        !getValue(props, `subTabPermissionList`, []).includes(
          "client_active"
        ) ? (
          <NoAccessPage />
        ) : (
          <>
            <div className="dashboard-wrapper__header">
              <h4 className="dashbaord-wrapper__header-title">Logs</h4>
            </div>
            {getValue(selectedIDs, `length`, 0) > 0 && (
              <NavigationBar
                selectedIDs={selectedIDs}
                isMarkInactiveSectionVisible={true}
                handleChangeActiveStatus={handleChangeActiveStatus}
                text={"MARK INACTIVE"}
                multi={
                  getValue(props, `permissions`, []).includes("update")
                    ? true
                    : false
                }
                handleRemoveNavItems={handleRemoveNavItems}
                permissions={getValue(props, `permissions`, [])}
              />
            )}
            <div className="admin-wrapper">
              {isLoading || !props.permissionAPITriggered ? (
                <Loader />
              ) : usersList.length > 0 ? (
                getValue(props, `permissions`, []).includes("update") ? (
                  <ScrollableReactTable
                    columns={columns}
                    data={usersList}
                    getTableState={getTableState}
                    rowClick={rowClick}
                    check={check}
                    selectedIDs={selectedIDs}
                    checkboxlogo
                    getTrProps={getTrProps}
                  />
                ) : (
                  <ScrollableReactTable
                    columns={columns}
                    data={usersList}
                    getTableState={getTableState}
                    rowClick={rowClickUpdate}
                    check={check}
                    selectedIDs={selectedIDs}
                    checkboxlogo
                    getTrProps={getTrProps}
                  />
                )
              ) : (
                <>
                  <>
                    {Object.keys(params).length !== 0 ? (
                      <Notfound />
                    ) : (
                      <Notfound />
                    )}
                  </>
                </>
              )}
            </div>
            <div className="footer-wrapper d-flex justify-content-between">
              {usersList.length > 0 && (
                <img
                  src="/images/building-illustration.svg"
                  className="img-fluid"
                />
              )}
              {totalCount > 10 ? (
                <PaginationPage
                  totalCount={totalCount}
                  limit={limit}
                  pageNumber={page_no}
                  handleChangePagination={handleChangePagination}
                  handleChangeLimit={handleChangeLimit}
                />
              ) : (
                <p className="show-rows-count mt-3 d-flex">
                  Total Count:&nbsp;&nbsp;{" "}
                  <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
                </p>
              )}
            </div>
            <DeleteModal
              isOpen={isDelete}
              handleModal={handleDelete}
              handleSubmit={handleDeleteFunction}
              deleteValue={deleteValue}
            />
            <Modal
              isOpen={isOpen}
              centered

              // toggle={isOpen}
            >
              <section className="add-popup-wrapper">
                <div className="add-popup-wrapper__popup p-5">
                  <div className="add-popup-wrapper__form-group form-group">
                    <h3>Groups</h3>
                  </div>
                  <InputField
                    inputType="TEXT"
                    value={group_name}
                    onChange={(e: any) => setGroup_name(e.target.value)}
                    name="group_name"
                    id="group_name"
                    placeholder={"Enter Group Name"}
                  />
                  <div className="add-popup-wrapper__cta-wrapper">
                    <button
                      onClick={handleModalCancel}
                      className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
                    >
                      Cancel
                    </button>
                    <button
                      className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </section>
            </Modal>
          </>
        )}
      </DashboardLayout>{" "}
    </>
  );
};

export default FileLogs;
