import { NavLink } from "react-router-dom";
import { getValue } from "@utils/lodash";
import BulkUpload from "../BulkUpload";
import CheckPointExport from "../CheckpointExport";
import CheckpointImport from "../CheckpointImport";
import Search from "../Search";
import "@components/common/AddFilter/add-filter.scss";
import CheckPointMigrate from "../CheckpointMigrate";
import BulkUploadDropdown from "@components/BulkUploadDropdown";
import { useNavigate } from "react-router-dom";

const SearchHeader = (props: any) => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    if (props.log_value) navigate(`/file-logs/${props.log_value}`);
  };
  return (
    <section className="add-filter-wrapper add-filter-wrapper--border w-100 d-flex align-items-center justify-content-between">
      <div className="add-filter-wrapper__add d-flex">
        {props.add != "false" && (
          <NavLink to={`${props.route}`}>
            <div className="add-filter-wrapper__add-icon d-flex">
              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                <path
                  d="M11 19V13H5V11H11V5H13V11H19V13H13V19Z"
                  fill="#ffffff"
                />
              </svg>
            </div>
          </NavLink>
        )}
        {props.smartFilter && (
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={props.setFilterFormVisibility}
          >
            <img
              src="/images/filter-icon.svg"
              className="img-fluid cursor-pointer mx-2"
              height={24}
              width={24}
            />
            <p style={{ color: "#276ef1" }}>Smart Filter</p>
            {props.isHeadingVisibleByDefault && (
              <>
                {props.isFilterHeadingVisible && (
                  <>
                    <h6 className="add-filter-wrapper__filter-status">
                      {props.headingText || "Filter Status"}
                    </h6>
                  </>
                )}
                {props.isFilterSubHeadingVisible && (
                  <h6 className="add-filter-wrapper__add-filter-title">
                    All <span>ADD FILTER</span>
                  </h6>
                )}
              </>
            )}
            {/* {props.isFilterHeadingVisible && (
            <>
              <h6 className="add-filter-wrapper__filter-status">
                {props.headingText || 'Filter Status'}
              </h6>
            </>
          )}
          {props.isFilterSubHeadingVisible && (
            <h6 className="add-filter-wrapper__add-filter-title">
              All <span>ADD FILTER</span>
            </h6>
          )} */}
          </div>
        )}
      </div>

      <div className="add-filter-wrapper__filter d-flex align-items-center">
      <Search
          searchInput={getValue(props, `searchInput`, "")}
          showSearchTextbox={getValue(props, `showSearchTextbox`, "")}
          toggleSearch={props.toggleSearch}
          toggleSearchClose={props.toggleSearchClose}
          handleChangeSearch={props.handleChangeSearch}
          placeholder={props.placeholder}
        />
        {props.migrate === true && (
          <CheckPointMigrate
            handleMigration={props.handleMigration}
            loading={props.migrationLoading}
          />
        )}
        {props.export === true && (
          <CheckPointExport handleExport={props.handleExport} />
        )}

   
        {/* {props.isBulkUploadVisible === true && (
					<BulkUpload
						handleImport={props.handleImport}
						uploadLoader={props.uploadLoader}
					/>
				)} */}
        {props.commonUpload && (
          <BulkUploadDropdown
            url={getValue(props, `downloadURL`, "")}
            dropdownOptions={getValue(props, `dropdownOptions`, [])}
          />
        )}
        {props.log_value && (
          <div className="add-filter-wrapper__filter-container cursor-pointer">
            <div className="d-flex" onClick={() => handleNavigate()}>
              <img
                src="/images/taskback.svg"
                className="img-fluid add-filter-wrapper__upload-image"
              />
            </div>
            <h6 className="add-filter-wrapper__filter-title">{"VIEW LOGS"}</h6>
          </div>
        )}
      </div>
    </section>
  );
};

export default SearchHeader;
