import React, { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import InputField from "@_common/InputRuleFeild";
import Header from "@components/common/Header";
import { getValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import {
	adminCreateRole,
	adminGetRolesListPermissions,
	adminGetSpecificRole,
	adminUpdateSpecificRole,
} from "@services/roles.service";
import _ from "lodash";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import "./roles-details.scss";
import { useCallbackPrompt } from "../../../../prompt/useCallbackPrompt";
import PromptModal from "../../../../prompt/modal";
import { formatText } from "@_common/format-text";
import NoAccessPage from "@components/common/NoAccess";

interface IRoleDetailsProps {}

const RoleDetails: React.FunctionComponent<IRoleDetailsProps> = (
	props: any
) => {
	let params = useParams();
	let navigate = useNavigate();
	/* -------------------------------------------------------------------------- */
	/*                               UseState Section                             */
	/* -------------------------------------------------------------------------- */
	const simpleValidator = useRef(new SimpleReactValidator());
	const [, forceUpdate] = useState(0);
	const [isLoading, setIsLoading] = React.useState(true);
	const [request, setRequest] = React.useState({
		title: "",
		description: "",
	});

	const [dirtyFields, setDirtyFields] = useState({
		title: "",
		description: "",
	});

	const [permissionList, setPermissionList] = useState<any>({});
	const [selectedPermissions, setSelectedPermissions] = useState<any>([]);
	const [mainPermissions, setMainPermissions] = useState([]);
	const [allList, setAllList] = useState<any>([]);
	/* -------------------------------------------------------------------------- */
	/*                               UseEffect Section                            */
	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		if (props.permissionAPITriggered) {
			getData();
			if (getValue(params, `id`, "")) {
				getSpecificRole(getValue(params, `id`, ""));
			}
		}
	}, [props.permissionAPITriggered]);
	/* -------------------------------------------------------------------------- */
	/*                               API Section                                  */
	/* -------------------------------------------------------------------------- */

	const getSpecificRole = async (id: string) => {
		try {
			let resp = await adminGetSpecificRole(id);
			if (resp) {
				setDirtyFields({
					...dirtyFields,
					title: getValue(resp, `data.title`, ""),
					description: getValue(resp, `data.description`, ""),
				});
				setRequest({
					...request,
					title: getValue(resp, `data.title`, ""),
					description: getValue(resp, `data.description`, ""),
				});
				// setTitle(getValue(resp, `data.title`, ''));
				// setDescription(getValue(resp, `data.description`, ''));
				setSelectedPermissions(getValue(resp, `data.permissions`, []));
			}
		} catch (error) {}
	};
	const getData = async () => {
		try {
			setIsLoading(true);
			let resp = await adminGetRolesListPermissions();
			if (resp) {
				let arr: any = [];
				getValue(resp, `data.permissions`, []).filter((item: any) => {
					if (item.is_tab_group) {
						arr.push(item);
					} else {
						arr.push({
							component: item.component,
							is_tab_group: true,
							items: [
								{ component: item.component, permissions: item.permissions },
							],
						});
					}
				});
				setMainPermissions(arr);
				var grouped = _.mapValues(
					_.groupBy(getValue(resp, `data.permissions`, []), "component")
				);
				setPermissionList(grouped);
				setIsLoading(false);
				for (let i = 0; i < getValue(arr, `length`, 0); i++) {
					getValue(arr[i], `items`, []).map((item: any) => {
						return getValue(item, `permissions`, []).map((per: any) => {
							return allList.push(getValue(per, `value`, ""));
						});
					});
				}
				setAllList([...allList]);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};
	const handleSubmit = async () => {
		const formValid = simpleValidator.current.allValid();
		if (!formValid) {
			simpleValidator.current.showMessages();
			forceUpdate(1);
		} else {
			if (getValue(selectedPermissions, `length`, 0) > 0) {
				try {
					let payload = {
						title: request.title,
						description: request.description,
						permissions: selectedPermissions,
					};
					if (getValue(params, `id`, "")) {
						let resp = await adminUpdateSpecificRole(
							getValue(params, `id`, ""),
							payload
						);
						if (resp) {
							confirmNavigation();
							navigate(`/admin/roles`);
							toast.success("Updated successfully");
							window.location.reload();
						}
					} else {
						let resp = await adminCreateRole(payload);
						if (resp) {
							confirmNavigation();
							navigate(`/admin/roles`);
							toast.success("Created successfully");
						}
					}
				} catch (error) {}
			} else {
				toast.error("All fields are mandatory");
			}
		}
	};

	/* -------------------------------------------------------------------------- */
	/*                               Onchange section                             */
	/* -------------------------------------------------------------------------- */
	const handleChangePermission = (value: string) => {
		let selected = selectedPermissions.filter((item: String) => item === value);
		if (getValue(selected, `length`, 0) > 0) {
			let selected = selectedPermissions.filter(
				(item: String) => item !== value
			);
			setSelectedPermissions(selected);
		} else {
			selectedPermissions.push(value);
			setSelectedPermissions([...selectedPermissions]);
		}
	};
	const handleChangeAllCheckList = (
		value: any,
		tab: string,
		status: string
	) => {
		if (status === "true") {
			getValue(permissionList, `[${tab}][${0}].items`, []).filter(
				(item: object) => {
					if (
						getValue(value, `component`, "") === getValue(item, `component`, "")
					) {
						getValue(item, `permissions`, []).map((per: any) => {
							let checkExisted = selectedPermissions.filter(
								(check: any) => check === getValue(per, `value`, "")
							);
							if (checkExisted.length === 0) {
								selectedPermissions.push(getValue(per, `value`, ""));
								setSelectedPermissions([...selectedPermissions]);
							}
						});
					}
				}
			);
		} else {
			getValue(permissionList, `[${tab}][${0}].items`, []).filter(
				(item: object) => {
					if (
						getValue(value, `component`, "") === getValue(item, `component`, "")
					) {
						let array1 = getValue(value, `permissions`, []).map(
							(item: any) => item.value
						);
						let array2 = selectedPermissions;
						setSelectedPermissions(
							array2.filter((val: any) => !array1.includes(val))
						);
					}
				}
			);
		}
	};
	const handleSelectAll = (status: any) => {
		if (status === "true") {
			let arr: any = [];
			allList.map((per: any) => {
				return arr.push(per);
			});
			setSelectedPermissions(arr);
		} else {
			setSelectedPermissions([]);
		}
	};
	function sameMembers(arr1: any, arr2: any) {
		return arr1.some((r: any) => arr2.indexOf(r) >= 0);
	}
	/* -------------------------------------------------------------------------- */
	/*                                Prompt Section                              */
	/* -------------------------------------------------------------------------- */
	const [isChanged, setIsChanged] = useState(false);
	useEffect(() => {
		setIsChanged(
			_.isEqualWith(request, dirtyFields, (a: any, b: any) => {
				// if both are null or equal to an empty string then they are equal
				if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
			})
		);
	});
	const [showPrompt, confirmNavigation, cancelNavigation]: any =
		useCallbackPrompt(!isChanged);


	return (
		<>
			<Header />
			{!isLoading &&
			!getValue(props, `subTabPermissionList`, []).includes("admin_role") ? (
				<NoAccessPage />
			) : (
				<>
					<div className="user-details-wrapper user-details-wrapper__sticky">
						<div className="user-details-wrapper__header d-flex align-items-center justify-content-between">
							<div className="d-flex align-items-center">
								<NavLink
									to="/admin/roles"
									className="user-details-wrapper__header-back-link"
								>
									<img src="/images/back-link.svg" className="img-fluid" />
								</NavLink>
								<div className="user-details-wrapper__add-user-title">
									Roles
								</div>
							</div>
							{getValue(props, `permissions`, []).includes("update") && (
								<div className="user-details-wrapper__cta-btns">
									<NavLink to={`/admin/roles`}>
										<button className="ascent-button ascent-button--header-buttons ascent-button--primary">
											Cancel
										</button>
									</NavLink>
									{isLoading ? (
										<button className="ascent-button ascent-button--header-buttons ascent-button--secondary">
											Please wait...
										</button>
									) : (
										<button
											// className={`ascent-button ascent-button--header-buttons ascent-button--${
											// 	isChanged ? "primary" : "secondary"
											// }`}
											className={`ascent-button ascent-button--header-buttons ascent-button--secondary`}
											onClick={handleSubmit}
											// disabled={isChanged}
										>
											Submit
										</button>
									)}
								</div>
							)}
						</div>
					</div>
					<div className="pt-5">
						<div className="rolls-wrapper pt-5 mt-5">
							<div className="rolls-wrapper__form-wrapper">
								<div className="rolls-wrapper__form">
									<div className="rolls-wrapper__form-group form-group">
										<label htmlFor="title" className="rolls-label">
											Role Name <span>*</span>
										</label>
										<input
											type="text"
											placeholder="Enter Role Name"
											className="form-control rolls-form-control"
											id="title"
											// validator={simpleValidator}
											value={getValue(request, `title`, "")}
											onChange={(e: any) =>
												setRequest({ ...request, title: e.target.value })
											}
										/>
									</div>
								</div>
								<div className="rolls-wrapper__form-group form-group">
									<label htmlFor="descriptiom" className="rolls-label">
										Description <span>*</span>
									</label>
									<InputField
										inputType="TEXTAREA"
										// rows={7}
										placeholder="Enter Description"
										className="form-control w-100"
										name="description"
										id="description"
										value={getValue(request, `description`, "")}
										onChange={(e: any) =>
											setRequest({ ...request, description: e.target.value })
										}
										label="Description"
										validator={simpleValidator}
									/>
								</div>
								<div className="rolls-wrapper__permission">
									<h4 className="rolls-wrapper__permission-title">
										Permissions
									</h4>
									<label
										className="position-relative form-group d-flex align-items-center cursor-pointer"
										htmlFor={`select-1-z2`}
										key={`select-1-z2`}
									>
										<div>
											<input
												type="checkbox"
												className="default-checkbox"
												id={`select-1-z2`}
												onChange={() =>
													handleSelectAll(
														getValue(allList, `length`, 0) ===
															getValue(selectedPermissions, `length`, 0)
															? "false"
															: "true"
													)
												}
												checked={
													getValue(selectedPermissions, `length`, 0) > 0 &&
													getValue(allList, `length`, 0) ===
														getValue(selectedPermissions, `length`, 0)
												}
											/>
											<div className="custom-checkbox--bg-blue">
												<img
													src="/images/uploaded-details/tick.svg"
													className="img-fluid uploaded-tick"
												/>
											</div>
										</div>
										<p className="mx-3"> {"Select All Permissions"}</p>
									</label>
									<p className="rolls-wrapper__permission-text">
										Applications - Define all allowed actions for the applicable
										tab
									</p>
								</div>
								{isLoading || !props.permissionAPITriggered ? (
									<p className="text-center mt-2">Please wait....</p>
								) : (
									<div className="user-details-wrapper__form-group form-group">
										{getValue(mainPermissions, `length`, 0) > 0 &&
											mainPermissions.map((item: any) => {
												return (
													<>
														<div className="d-flex align-items-center justify-content-between">
															<h4
																className="mb-2 mt-2"
																style={{ color: "#266ef1" }}
															>
																{formatText(getValue(item, `component`, ""))}
															</h4>
														</div>
														<div>
															{getValue(item, `items`, []).map(
																(items: any, index: number) => {
																	let arr1 = getValue(
																		items,
																		`permissions`,
																		[]
																	).map((item: any) => item.value);
																	let arr2 = selectedPermissions;
																	let confirmChecked = sameMembers(arr1, arr2);
																	let checker = (arr: any, target: any) =>
																		target.every((v: any) => arr.includes(v));
																	return (
																		<div className="py-3 d-flex justify-content-between">
																			<label
																				className="position-relative form-group d-flex align-items-center cursor-pointer"
																				htmlFor={`select-${getValue(
																					items,
																					`component`,
																					""
																				)}-${getValue(
																					item,
																					`component`,
																					""
																				)}-${index}-z2`}
																				key={index}
																			>
																				<div>
																					<input
																						type="checkbox"
																						className="default-checkbox"
																						id={`select-${getValue(
																							items,
																							`component`,
																							""
																						)}-${getValue(
																							item,
																							`component`,
																							""
																						)}-${index}-z2`}
																						checked={
																							arr2.length > 0 &&
																							checker(arr2, arr1)
																						}
																						onChange={() =>
																							handleChangeAllCheckList(
																								items,
																								item.component,
																								confirmChecked
																									? "false"
																									: "true"
																							)
																						}
																					/>
																					<div className="custom-checkbox--bg-blue">
																						<img
																							src="/images/uploaded-details/tick.svg"
																							className="img-fluid uploaded-tick"
																						/>
																					</div>
																				</div>
																				<p className="mx-3">
																					{" "}
																					{formatText(
																						getValue(items, `component`, "")
																					)}
																				</p>
																			</label>
																			<div className="d-flex">
																				{getValue(items, `permissions`, []).map(
																					(name: object, i: number) => {
																						return (
																							<div className="rolls-details-list__form-group form-group my-0 d-flex align-items-center justify-content-space-between w-100">
																								<label
																									className="position-relative form-group d-flex align-items-center cursor-pointer"
																									htmlFor={`select-${getValue(
																										name,
																										`value`,
																										""
																									)}-${i}-z1`}
																									key={`select-${getValue(
																										name,
																										`value`,
																										""
																									)}-${i}-z1`}
																								>
																									<div>
																										<input
																											type="checkbox"
																											className="default-checkbox"
																											id={`select-${getValue(
																												name,
																												`value`,
																												""
																											)}-${i}-z1`}
																											checked={selectedPermissions.includes(
																												getValue(
																													name,
																													`value`,
																													""
																												)
																											)}
																											onChange={() =>
																												handleChangePermission(
																													getValue(
																														name,
																														`value`,
																														""
																													)
																												)
																											}
																										/>
																										<div className="custom-checkbox--bg-blue">
																											<img
																												src="/images/uploaded-details/tick.svg"
																												className="img-fluid uploaded-tick"
																											/>
																										</div>
																									</div>
																									<p className="mx-3">
																										{" "}
																										{getValue(name, `text`, "")}
																									</p>
																								</label>
																							</div>
																						);
																					}
																				)}
																			</div>
																		</div>
																	);
																}
															)}
															<br />
														</div>
													</>
												);
											})}
									</div>
								)}
								<br />
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default RoleDetails;
