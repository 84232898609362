import * as React from 'react';
import { toast } from 'react-toastify';
import { Label, Modal } from 'reactstrap';
import InputField from '@_common/InputRuleFeild';
import {
	adminCreateLegislationManagement,
	adminUpdateSpecificLegislationManagement,
} from '@services/list-management.service';
import { getValue } from '@utils/lodash';
import '../popup/legislationpopup.scss';

interface ILegislationPopupProps {
	isOpen: boolean;
	handleModal: () => void;
	groups: any;
	getLegislationList: (id: any) => void;
	selectedState: any;
	editObj: object;
	handleModalCancel: () => void;
}

const LegislationPopup: React.FunctionComponent<ILegislationPopupProps> = (
	props
) => {
	React.useEffect(() => {
		setLegislation_name(getValue(props, `editObj.legislation_name`, ''));
		setLegislation_code(getValue(props, `editObj.legislation_code`, ''));
		setCategory_of_law_id(getValue(props, `editObj.category_of_law.id`, ''));
	}, [getValue(props, `editObj.legislation_name`, '')]);
	const [category_of_law_id, setCategory_of_law_id] = React.useState('');
	const [legislation_name, setLegislation_name] = React.useState('');
	const [legislation_code, setLegislation_code] = React.useState('');

	const handleChange = (e: any) => {
		setCategory_of_law_id(e.id);
	};

	const handleSubmit = async () => {
		if (category_of_law_id && legislation_name && props.selectedState) {
			if (getValue(props, `editObj.legislation_name`, '')) {
				try {
					let payload = {
						category_of_law_id: category_of_law_id,
						legislation_name: legislation_name,
						state_id: parseInt(props.selectedState),
						legislation_code: legislation_code,
					};
					let resp = await adminUpdateSpecificLegislationManagement(
						getValue(props, `editObj.id`, ''),
						payload
					);
					if (resp) {
						toast.success(getValue(resp, 'message', ''));
						setCategory_of_law_id('');
						setLegislation_name('');
						props.handleModalCancel();
						props.getLegislationList(props.selectedState);
					}
				} catch (error) {}
			} else {
				try {
					let payload = {
						category_of_law_id: category_of_law_id,
						legislation_name: legislation_name,
						state_id: parseInt(props.selectedState),
						legislation_code: legislation_code,
					};
					let resp = await adminCreateLegislationManagement(payload);
					if (resp) {
						toast.success(getValue(resp, 'message', ''));
						setCategory_of_law_id('');
						setLegislation_name('');
						props.handleModalCancel();
						props.getLegislationList(props.selectedState);
					}
				} catch (error) {}
			}
		} else {
			toast.error('All fields are mandatory');
		}
	};
	return (
		<Modal
			isOpen={getValue(props, `isOpen`, '')}
			toggle={getValue(props, `isOpen`, '')}
			className="legislation-popup"
			centered
		>
			<section className="legislation-wrapper w-100">
				<div className="legislation-wrapper__legislation-header d-flex align-items-center justify-content-between">
					<h3 className="text-center legislation-wrapper__legislation-header-title">
						Create New Legislation
					</h3>
					<img
						src="/images/legislation.svg"
						className="img-fluid legislation-wrapper-image"
					/>
				</div>
				<div className="legislation-wrapper__popup">
					<div className="legislation-wrapper__form-group form-group">
						<Label className="legislation-wrapper__form-label">
							Legislation Name *{' '}
						</Label>
						<InputField
							inputType="TEXTAREA"
							rows={10}
							placeholder="Enter Legislation Name"
							onChange={(e: any) => setLegislation_name(e.target.value)}
							value={legislation_name}
						/>
					</div>
					<div className="legislation-wrapper__form-group form-group">
						<Label className="legislation-wrapper__form-label">
							Legislation Code *{' '}
						</Label>
						<InputField
							inputType="TEXT"
							rows={10}
							placeholder="Enter Legislation Code"
							onChange={(e: any) => setLegislation_code(e.target.value)}
							value={legislation_code}
						/>
					</div>
					<div className="legislation-wrapper__form-group form-group">
						<Label className="legislation-wrapper__form-label">
							Category Of Law{' '}
						</Label>
						<InputField
							inputType="INPUT_REACT_SELECT"
							rows={3}
							options={props.groups}
							placeholder="Enter Legislation Name"
							onChange={handleChange}
							value={props.groups.filter(
								(item: object) => getValue(item, `id`, '') == category_of_law_id
							)}
						/>
					</div>
					<div className="add-popup-wrapper__cta-wrapper">
						<button
							onClick={props.handleModalCancel}
							className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
						>
							Cancel
						</button>
						<button
							className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
							onClick={handleSubmit}
						>
							Save
						</button>
					</div>
				</div>
			</section>
		</Modal>
	);
};

export default LegislationPopup;
