import './Header.scss';
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import { getValue } from '@utils/lodash';
import { useEffect, useState } from "react";
import { clientLogout } from '@services/auth.service';

export default function Header() {

  const handleLogout = async() => {
	localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
	localStorage.removeItem('client')
	let payload = {
		refresh_token: getValue(localStorage, `refreshToken`, ""),
	};
	let resp = await clientLogout(payload);
 
    window.location.href = `/login`
  }
  const [user, setUser] = useState({});
	useEffect(() => {
		let user = JSON.parse(getValue(localStorage, `user`, ""));
		setUser(user);
	}, []);
  return (
    <header className="header-wrapper">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center cursor-pointer">
          {/* <span className="material-symbols-outlined">
            menu
          </span> */}
          <a href="/" className="d-inline-block">
            <img src="/images/ascentlogo.png" width="173" height="52" className="img-fluid" />
          </a>
        </div>
        <div className="bulk-dropdown">
					<UncontrolledDropdown>
						<DropdownToggle caret>
							<div className="header__dropdown m-0">
								<div className="dropdown-content d-flex cursor-pointer">
									<span className="m-auto">
										{getValue(user, `email`, "").charAt(0).length > 0
											? getValue(user, `email`, "").charAt(0).toUpperCase()
											: "A"}
									</span>
								</div>
							</div>
						</DropdownToggle>
						<DropdownMenu>
							<DropdownItem header>
								<h4>Accounts</h4>
							</DropdownItem>
							<DropdownItem>
								<div className="header__dropdown m-0  text-center d-flex">
									<div className="dropdown-content d-flex cursor-pointer">
										<span className="m-auto">
											{getValue(user, `email`, "").charAt(0).length > 0
												? getValue(user, `email`, "").charAt(0).toUpperCase()
												: "A"}
										</span>
									</div>
								</div>
							</DropdownItem>
							<DropdownItem>
								<>
									<p>{getValue(user, `email`, "")}</p>
									<button className="header-btn">Ascent Account</button>
								</>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>
								<div className="text-center">
									<button
										className="ascent-button ascent-button--secondary ascent-button--header-buttons"
										onClick={handleLogout}
									>
										Sign out
									</button>
								</div>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
      </div>
    </header>
  )
}