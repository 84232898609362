import CheckpointActive from '@pages/Checkpoint/Active';
import CheckpointInActive from '@pages/Checkpoint/Inactive';
import CheckpointActiveDetail from '@pages/Checkpoint/Active/active-detail';
//
export const CheckpointRoutes = [
    {
        path: '/checkpoint/active',
        name: 'checkpoint_active',
        component: CheckpointActive ,
      },
      {
        path: '/checkpoint/inactive',
        name: 'checkpoint_inactive',
        component: CheckpointInActive ,
      },
      {
        path: '/checkpoint/active-detail',
        name: 'checkpoint_active',
        component: CheckpointActiveDetail ,
      },
      {
        path: '/checkpoint/active-detail/:id',
        name: 'checkpoint_active',
        component: CheckpointActiveDetail ,
      },
];
