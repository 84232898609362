import Header from "@components/common/Header/index";
import LeftNavigation from "@components/common/LeftNavigation/index";
import { getValue } from "@utils/lodash";
import "./dashboard.scss";

export default function DashboardLayout(props: any) {
	return (
		<>
			<Header />
			<section className="main-wrapper">
				<div className="main-user-wrapper">
					<LeftNavigation
						permissions={getValue(props, `permissions`, [])}
						subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
					/>
				</div>
				<div className="dashboard-wrapper">{props.children}</div>
			</section>
			{/* {props.children} */}
		</>
	);
}
