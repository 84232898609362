import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import "./overview.scss";
// import BreakupBox from "@components/Audit/breakup-box";
import { useEffect, useState } from "react";
import { adminGetAuditDashboardInfo } from "@services/audit.service";
import DatePicker from "react-date-picker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import qs from "query-string";
import { getValue } from "@utils/lodash";
import "./breakup-box.scss";
import { NavLink } from "react-router-dom";
import InputField from "@_common/InputRuleFeild";
import { adminGetOrganization } from "@services/client-list.service";
import NoAccessPage from "@components/common/NoAccess";
import Loader from "@components/common/Loader";
import { stringToFixed } from "@_common/format-text";

export default function Overview(props: any) {
	/* -------------------------------------------------------------------------- */
	/*                               UseState Section                             */
	/* -------------------------------------------------------------------------- */

	const [isLoading, setIsLoading] = useState(true);
	const [dataInfo, setDataInfo] = useState({});

	const navigate = useNavigate();

	/* -------------------------------------------------------------------------- */
	/*                               UseEffect Section                            */
	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
			setDate(new Date());
			setSelectedClient(
				getValue(localStorage, `client`, "")
					? getValue(localStorage, `client`, "")
					: ""
			);
			getClientList();
		}
	}, [props.permissionAPITriggered]);

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
			getClientList();
			if (params.date) {
				setDate(new Date(params.date));
			} else {
				setDate(new Date());
			}
			setSelectedClient(
				getValue(localStorage, `client`, "")
					? getValue(localStorage, `client`, "")
					: ""
			);
		}
	}, [window.location.href, props.permissionAPITriggered]);

	/* -------------------------------------------------------------------------- */
	/*                               API Section                                  */
	/* -------------------------------------------------------------------------- */

	const getData = async (client: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		let dates = date ? date : new Date();
		let payload: any = {
			month: params.date
				? parseInt(params.date.split("-")[0])
				: date
				? parseInt(moment(date).format("MM"))
				: parseInt(moment(dates).format("MM")),
			year: params.date
				? parseInt(params.date.split("-")[2])
				: date
				? parseInt(moment(date).format("YYYY"))
				: parseInt(moment(dates).format("YYYY")),
			domain_name: client,
			type: "SUMMERY",
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		// let queryRequest = qs.stringify(payload);

		try {
			setIsLoading(true);
			let resp = await adminGetAuditDashboardInfo(payload);
			if (resp) {
				setDataInfo(getValue(resp, `data.data`, {}));
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	};

	/* -------------------------------------------------------------------------- */
	/*                              Date Routes                                   */
	/* -------------------------------------------------------------------------- */
	const [date, setDate] = useState<Date | null>(null);
	const handleSubmitDate = () => {
		let dates = moment(date).format("MM-DD-YYYY");
		let payload: any = {
			date: dates,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	/* -------------------------------------------------------------------------- */
	/*                             Client Setup                                   */
	/* -------------------------------------------------------------------------- */

	const [clientList, setClientList] = useState([]);
	const [selectedClient, setSelectedClient] = useState("");
	const handleSelectClient = (e: string) => {
		localStorage.setItem("client", e);
		setSelectedClient(e);
		getData(e);
	};
	const getClientList = async () => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());

		let payload: any = {
			page_no: params.page_no ? parseInt(params.page_no) : 1,
			page_size: 100,
			status: "active",
			sort_by: params.sort_by,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		try {
			let resp = await adminGetOrganization(queryRequest);
			if (resp) {
				let list = getValue(resp, `data.organisations`, []).map(
					(item: object) => ({
						// ...item,
						value: getValue(item, `domain_name`, ""),
						label: getValue(item, `organisation_name`, ""),
						organisation_name: getValue(item, `organisation_name`, ""),
						id: getValue(item, `id`, ""),
					})
				);
				setClientList(list);
				if (getValue(list, `length`, 0) > 0) {
					if (getValue(localStorage, `client`, "")) {
						getData(getValue(localStorage, `client`, ""));
					} else {
						getData(getValue(list, `[${0}].value`, ""));
						localStorage.setItem("client", getValue(list, `[${0}].value`, ""));
						setSelectedClient(getValue(list, `[${0}].value`, ""));
					}
				} else {
					getData("");
				}
			}
		} catch (error) {
			setIsLoading(false)
		}
	};
	return (
		<DashboardLayout
			permissions={getValue(props, `tabPermissionList`, [])}
			subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
		>
			{!isLoading &&
			!getValue(props, `subTabPermissionList`, []).includes(
				"dashboard_vendor_audit"
			) ? (
				<NoAccessPage />
			) : (
				<>
					<div className="dashboard-wrapper__header d-flex align-items-center justify-content-between">
						<h4 className="dashbaord-wrapper__header-title">Overview</h4>
						<div className="dashboard-wrapper__input-field">
							<InputField
								inputType="INPUT_REACT_SELECT"
								placeholder={"Select Client"}
								options={clientList}
								onChange={(e: any) => handleSelectClient(e.value)}
								value={clientList.filter(
									(item: any) => item.value === selectedClient
								)}
							/>
						</div>
					</div>
					<section className="overview-wrapper my-0">
						<div className="overview-wrapper__header d-flex align-items-center justify-content-between">
							<h3 className="overview-wrapper__header-title">
								Average Audit Score -
								<span>
									{stringToFixed(getValue(dataInfo, `auditScore.compliance_score`, "0"),2)}%
								</span>
							</h3>
							<h3 className="overview-wrapper__header-date d-flex align-items-center">
								<div>
									<DatePicker
										onChange={setDate}
										value={date}
										format="MMM yyyy"
										disableCalendar={true}
										yearAriaLabel={"yyyy"}
									/>
									<button
										className="header-btn mx-3"
										onClick={handleSubmitDate}
									>
										Apply
									</button>
								</div>
								{/* Jan-2022 <img src="/images/arrow-drop.svg" className="img-fluid" /> */}
							</h3>
						</div>
						{isLoading || !props.permissionAPITriggered ? (
							// <p className="text-center mt-2">Please wait...</p>
							<Loader />
						) : (
							<>
								<div className="d-flex align-items-center box-gap">
									<section className="breakup-box">
										{/* <NavLink to={`/audit/uploaded`}> */}
										<div className="breakup-box__details">
											<h6 className="breakup-box__title">
												{getValue(dataInfo, `summery.uploaded_count`, "0")}
											</h6>
											<p className="breakup-box__text">{"Uploaded"}</p>
										</div>
										<img
											alt="img"
											src={`/images/upload-image.svg`}
											className="img-fluid breakup-box-image"
										/>
										{/* </NavLink> */}
									</section>
									<section className="breakup-box">
										{/* <NavLink to={`/audit/not-uploaded`}> */}
										<div className="breakup-box__details">
											<h6 className="breakup-box__title">
												{getValue(dataInfo, `summery.not_uploaded_count`, "0")}
											</h6>
											<p className="breakup-box__text">{"Not Uploaded"}</p>
										</div>
										<img
											alt="img"
											src={`/images/not-uploaded-image.svg`}
											className="img-fluid breakup-box-image"
										/>
										{/* </NavLink> */}
									</section>
								</div>
								<div className="overview-wrapper__header d-flex align-items-center justify-content-between">
									<h3 className="overview-wrapper__header-title">
										Uploaded Audit Breakup
									</h3>
								</div>
								<div className="d-flex align-items-center box-gap">
									<section className="breakup-box">
										<div className="breakup-box__details">
											<h6 className="breakup-box__title">
												{getValue(dataInfo, `summery.pending_audit_count`, "0")}
											</h6>
											<p className="breakup-box__text">{"Pending Audit"}</p>
										</div>
										<img
											alt="img"
											src={`/images/upload-image.svg`}
											className="img-fluid breakup-box-image"
										/>
									</section>

									<section className="breakup-box">
										<div className="breakup-box__details">
											<h6 className="breakup-box__title">
												{getValue(dataInfo, `summery.complied_count`, "0")}
											</h6>
											<p className="breakup-box__text">{"Compiled"}</p>
										</div>
										<img
											alt="img"
											src={`/images/chk-circle-green.svg`}
											className="img-fluid breakup-box-image"
										/>
									</section>

									<section className="breakup-box">
										<div className="breakup-box__details">
											<h6 className="breakup-box__title">
												{getValue(dataInfo, `summery.non_complied_count`, "0")}
											</h6>
											<p className="breakup-box__text">{"Non-Complied"}</p>
										</div>
										<img
											alt="img"
											src={`/images/upload-image.svg`}
											className="img-fluid breakup-box-image"
										/>
									</section>

									<section className="breakup-box">
										<div className="breakup-box__details">
											<h6 className="breakup-box__title">
												{getValue(
													dataInfo,
													`summery.partially_complied_count`,
													"0"
												)}
											</h6>
											<p className="breakup-box__text">
												{"Partially Complied"}
											</p>
										</div>
										<img
											alt="img"
											src={`/images/flaky.svg`}
											className="img-fluid breakup-box-image"
										/>
									</section>

									<section className="breakup-box">
										<div className="breakup-box__details">
											<h6 className="breakup-box__title">
												{getValue(
													dataInfo,
													`summery.not_applicable_count`,
													"0"
												)}
											</h6>
											<p className="breakup-box__text">{"Not Applicable"}</p>
										</div>
										<img
											alt="img"
											src={`/images/upload-image.svg`}
											className="img-fluid breakup-box-image"
										/>
									</section>

									<section className="breakup-box">
										<div className="breakup-box__details">
											<h6 className="breakup-box__title">
												{getValue(
													dataInfo,
													`summery.send_back_to_vendor_count`,
													"0"
												)}
											</h6>
											<p className="breakup-box__text">
												{"Sent Back to Vendor"}
											</p>
										</div>
										<img
											alt="img"
											src={`/images/upload-image.svg`}
											className="img-fluid breakup-box-image"
										/>
									</section>
								</div>
							</>
						)}
					</section>
					<div className='"overview-wrapper__bottom-image'>
						<img
							alt="img"
							src="/images/orgbuilding.svg"
							className="img-fluid w-100"
							height={265}
						/>
					</div>
				</>
			)}
		</DashboardLayout>
	);
}
