import React, { ChangeEvent, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getValue } from '@utils/lodash';
import qs from 'query-string';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteModal from '@_common/deleteModal';
import moment from 'moment';
import './comments-modal.scss';
import {
	addTaskComments,
	deleteTaskComments,
	getTaskComments,
	updateTaskComments,
} from '@services/comments.service';

interface ICommentsModalProps {
	isOpen: boolean;
	handleModal: () => void;
	vendorInfo: object;
	edit: boolean;
}

const CommentsModal: React.FunctionComponent<ICommentsModalProps> = (
	props: any
) => {
	const navigate = useNavigate();
	/* -------------------------------------------------------------------------- */
	/*                               UseState Section                             */
	/* -------------------------------------------------------------------------- */
	const [userId, setUserId] = useState('');
	const [comments, setComments] = useState('');
	const [loading, setLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [allComments, setAllComments] = useState([]);
	/* -------------------------------------------------------------------------- */
	/*                               UseEffect Section                            */
	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		getData();
		let user = getValue(localStorage, `user`, '');
		let info = JSON.parse(user);
		setUserId(getValue(info, `id`, ''));
	}, [props.permissionAPITriggered]);

	/* -------------------------------------------------------------------------- */
	/*                               API Section                                  */
	/* -------------------------------------------------------------------------- */
	const getData = async () => {
		try {
			let payload: any = {
				limit: limit,
				page_no: page_no,
				type: 'SUBMISSION_COMMENT',
				vendor_checkpoint_id: getValue(props, `vendorInfo.id`, ''),
				domain_name: getValue(localStorage,`client`,''),
			};
			// removing null values
			Object.keys(payload).forEach((key) => {
				if (!payload[key] || payload[key] === undefined) {
					delete payload[key];
				}
			});
			let queryRequest = qs.stringify(payload);
			setLoading(true);
			let resp = await getTaskComments(queryRequest);
			if (resp) {
				setAllComments(getValue(resp, `data.comments`, []));
				setEdit(false);
				setEditId('');
				setLoading(false);
			} else {
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
		}
	};
	const getDataRefetch = async () => {
		try {
			let payload: any = {
				limit: limit,
				page_no: page_no,
				type: 'SUBMISSION_COMMENT',
				vendor_checkpoint_id: getValue(props, `vendorInfo.id`, ''),
			};
			// removing null values
			Object.keys(payload).forEach((key) => {
				if (!payload[key] || payload[key] === undefined) {
					delete payload[key];
				}
			});
			let queryRequest = qs.stringify(payload);
			let resp = await getTaskComments(queryRequest);
			if (resp) {
				setAllComments(getValue(resp, `data.comments`, []));
				setEdit(false);
				setEditId('');
				setLoading(false);
			} else {
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
		}
	};

	const handleSubmit = async () => {
		if (editId) {
			try {
				setIsLoading(true);
				let payload = {
					comments: comments,
					type: 'SUBMISSION_COMMENT',
					vendor_checkpoint_id: getValue(props, `vendorInfo.id`, ''),
					domain_name: getValue(localStorage,`client`,''),
				};
				let resp = await updateTaskComments(editId, payload);
				if (resp) {
					setIsLoading(false);
					toast.success('Updated successfully');
					setComments('');
					getDataRefetch();
					setEdit(false);
					setEditId('');
				} else {
					setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
			}
		} else {
			try {
				setIsLoading(true);
				let payload = {
					comments: comments,
					type: 'SUBMISSION_COMMENT',
					vendor_checkpoint_id: getValue(props, `vendorInfo.id`, ''),
					domain_name: getValue(localStorage,`client`,''),
				};
				let resp = await addTaskComments(payload);
				if (resp) {
					setIsLoading(false);
					toast.success('Updated successfully');
					setComments('');
					getData();
				} else {
					setIsLoading(false);
				}
			} catch (error) {
				setIsLoading(false);
			}
		}
	};
	/* -------------------------------------------------------------------------- */
	/*                               Onchange section                             */
	/* -------------------------------------------------------------------------- */
	const [edit, setEdit] = useState(false);
	const [editId, setEditId] = useState('');
	const handleEdit = (obj: object) => {
		setEditId(getValue(obj, `id`, ''));
		setComments(getValue(obj, `comments`, ''));
		setEdit(!edit);
	};
	const handleRemoveSelected = () => {
		setComments('');
		setEditId('');
	};

	/* -------------------------------------------------------------------------- */
	/*                               Pagination section                           */
	/* -------------------------------------------------------------------------- */

	const [totalCount, setTotalCount] = useState(0);
	const [limit, setLimit] = useState(10);
	const [page_no, setPage_no] = useState(1);

	const handleChangePagination = (page_no: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.page_no = page_no;
		Object.keys(params).forEach((key) => {
			if (!params[key] || params[key] === undefined) {
				delete params[key];
			}
		});
		let queryRequest = qs.stringify(params);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	const handleChangeLimit = (limit: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.limit = limit;
		Object.keys(params).forEach((key) => {
			if (!params[key] || params[key] === undefined) {
				delete params[key];
			}
		});
		let queryRequest = qs.stringify(params);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	/* -------------------------------------------------------------------------- */
	/*                                 Delete section                             */
	/* -------------------------------------------------------------------------- */

	const [deleteValue, setDeleteValue] = useState('');
	const [deleteId, setDeleteId] = useState('');
	const handleDeleteFun = (id: string, value: string) => {
		setDeleteValue(value);
		setDeleteId(id);
		handleDelete();
	};
	const [isDelete, setIsDelete] = useState(false);
	const handleDelete = () => {
		setIsDelete(!isDelete);
	};

	const handleDeleteFunction = async () => {
		try {
			let resp = await deleteTaskComments(deleteId);
			if (resp) {
				toast.success(getValue(resp, `message`, ''));
				handleDelete();
				getData();
			}
		} catch (error) {}
	};
	return (
		<div className="comments-popup">
			<Modal
				isOpen={getValue(props, `isOpen`, '')}
				toggle={getValue(props, `isOpen`, '')}
				centered
				size="lg"
			>
				{/* <div className="d-flex justify-content-end mb-3">
            <img
              src="/images/close-black.svg"
              className="img-fluid cursor-pointer"
              onClick={props.handleModal}
            />
          </div> */}

				<div className="comments-modal">
					<div className="comments-popup__header d-flex align-items-center justify-content-between mb-3">
						<h3 className="comments-popup__header-title">Comments</h3>
						<div className="d-flex justify-content-end">
							<button
								className="ascent-button ascent-button--header-buttons ascent-button--primary mx-3"
								//onClick={() => handleRemoveSelected()}
								onClick={props.handleModal}
							>
								Cancel
							</button>
							{/* {isLoading ? (
							<button className="ascent-button ascent-button--header-buttons ascent-button--secondary">
								Please wait...
							</button>
						) : (
							<button
								className="ascent-button ascent-button--header-buttons ascent-button--secondary"
								onClick={handleSubmit}
							>
								{editId ? 'Update' : 'Save'}
							</button>
						)} */}
						</div>
					</div>
					<div>
						<h6 className="comments-popup__vendor-name">Vendor Name</h6>
						<p className="comments-popup__vendor-text">
							{getValue(props, `vendorInfo.vendor.vendor_name`, '')}
						</p>
					</div>
					<div className="comments-popup__vendor-details">
						<h6 className="comments-popup__vendor-name">Checklist Name</h6>
						<p className="comments-popup__vendor-text">
							{getValue(props, `vendorInfo.checkpoint.checkpoint_id`, '')}
						</p>
					</div>
					<ModalBody className="p-0">
						{props.edit && (
							<div className="d-flex align-items-start w-100 mb-3 gap pt-3">
								{/* <img
								src="/images/pexels-jigar-maru-2085739/persoon-icon.png"
								className="img-fluid "
								height={36}
								width={36}
							/> */}
								{/* <div 
								style={{
									  height: '32px',
									  width: '32px',
									  borderRadius: '50%',
									  backgroundColor: '#1d4d40',
									  color: '#ffff'
								}}> */}

								<div className="vendorInfo-wrapper d-flex">
									<p className="vendorInfo-wrapper__text">
										{getValue(props, `vendorInfo.vendor.vendor_name`, '')
											.charAt(0)
											.toUpperCase()}
									</p>
								</div>
								{/* </div> */}
								<textarea
									placeholder={'Add a comment'}
									value={comments}
									// onBlur={(e: ChangeEvent<HTMLInputElement>) =>
									//   setComments(e.target.value)
									// }
									onChange={(e) => setComments(e.target.value)}
									className="comments-popup__form-control form-control mx-0"
								/>
							</div>
						)}
						<div className="d-flex justify-content-end">
							<button
								className="ascent-button ascent-button--header-buttons ascent-button--primary mx-3"
								onClick={() => handleRemoveSelected()}
								disabled={!comments}
							>
								Clear
							</button>
							{isLoading ? (
								<button className="ascent-button ascent-button--header-buttons ascent-button--secondary">
									Please wait...
								</button>
							) : (
								<button
									className="ascent-button ascent-button--header-buttons ascent-button--secondary"
									onClick={handleSubmit}
									disabled={!comments}
								>
									{editId ? 'Update' : 'Submit'}
								</button>
							)}
						</div>
						<div>
							{loading ? (
								<p className="text-center mt-2">Please wait...</p>
							) : getValue(allComments, `length`, 0) > 0 ? (
								allComments.map((item: object) => {
									return (
										<div className="comments-popup-wrapper__comments">
											<div>
												<div className="d-flex align-items-start mb-3">
													<div className="py-1">
														<div className="vendorInfo-wrapper d-flex">
															<p className="vendorInfo-wrapper__text">
																{getValue(item, `created_by.first_name`, '')
																	.charAt(0)
																	.toUpperCase()}
																{getValue(item, `created_by.last_name`, '')
																	.charAt(0)
																	.toUpperCase()}
															</p>
														</div>
														{/* <img
														src="/images/pexels-jigar-maru-2085739/persoon-icon.png"
														className="img-fluid"
														height={36}
														width={36}
													/> */}
													</div>
													<div className="mx-3">
														<div className="d-flex align-items-center">
															<div>
																<h3 className="comments-popup-wrapper__comments-title vendorInfo-wrapper__comments-title">
																	{getValue(item, `created_by.first_name`, '')}{' '}
																	{getValue(item, `created_by.last_name`, '')}
																</h3>
															</div>
															<div className="mx-3">
																{userId ===
																	getValue(item, `created_by.id`, '') && (
																	<>
																		<ul className="d-flex align-items-center">
																			<li className="comments-popup-wrapper__comments-list-item">
																				<a
																					className="cursor-pointer"
																					onClick={() => handleEdit(item)}
																				>
																					Edit
																				</a>
																			</li>
																			<li className="comments-popup-wrapper__comments-list-item">
																				<a
																					className="cursor-pointer"
																					onClick={() =>
																						handleDeleteFun(
																							getValue(item, `id`, ''),
																							getValue(item, `comments`, '')
																						)
																					}
																				>
																					Delete
																				</a>
																			</li>
																		</ul>
																	</>
																)}
															</div>
														</div>
														<p className="comments-popup-wrapper__comments-text mb-2">
															Posted on{' '}
															{moment(getValue(item, `created_at`, '')).format(
																'DD-MMM-YYYY, hh:mm a'
															)}
															{'  '}
														</p>
														<p className="comments-popup-wrapper__comments-text">
															{getValue(item, `comments`, '')}
														</p>
													</div>
												</div>
											</div>
										</div>
									);
								})
							) : (
								<p className="text-center mt-2">No Data Found</p>
							)}
						</div>
					</ModalBody>
				</div>
			</Modal>

			<DeleteModal
				isOpen={isDelete}
				handleModal={handleDelete}
				handleSubmit={handleDeleteFunction}
				deleteValue={deleteValue}
			/>
		</div>
	);
};

export default CommentsModal;
