import '../Comments/comments-section.scss';

export default function OldComments() {
  const oldCommentsArray = [
    {
      imageUrl: '/images/support/person-icon.png',
      name: ' Rajendra GS',
    },
    {
      name: 'Anthony Joseph',
    },
    {
      imageUrl: '/images/support/person-icon.png',
      name: ' Rajendra GS',
    },
    {
      name: 'Anthony Joseph',
    },
    {
      imageUrl: '/images/support/person-icon.png',
      name: ' Rajendra GS',
    },
  ];
  return (
    <section className="add-comments-wrapper">
      {oldCommentsArray.map((commentObj, index) => {
        return (
          <div className="my-4" key={index}>
            <div className="d-flex align-items-center">
              {commentObj.imageUrl ? (
                <label className="add-comments-wrapper__img-label">
                  <img
                    src="/images/support/person-icon.png"
                    className="img-fluid"
                    height={48}
                    width={48}
                  />
                </label>
              ) : (
                <div className="add-comments-wrapper__label-container">
                  <label className="add-comments-wrapper__label d-flex">
                    <h6>R</h6>
                  </label>
                </div>
              )}

              <div className="add-comments-wrapper__header-list-wrapper mx-3">
                <ul className="add-comments-wrapper__header-list d-inline-flex align-items-center w-100">
                  <li className="add-comments-wrapper__header-list-items">
                    {commentObj.name}
                  </li>
                  <li className="add-comments-wrapper__header-list-items">
                    Edit
                  </li>
                  <li className="add-comments-wrapper__header-list-items">
                    Delete
                  </li>
                </ul>
                <ul className="add-comments-wrapper__header-description d-inline-flex align-items-center">
                  <li className="add-comments-wrapper__header-description-list">
                    Posted on 22-Jun-2021, 12:00 PM
                  </li>
                  <li className="add-comments-wrapper__header-description-list">
                    AVP Admin
                  </li>
                  <li className="add-comments-wrapper__header-description-list">
                    Accenture - Bangalore
                  </li>
                </ul>
              </div>
            </div>
            <p className="add-comments-wrapper__header-description-text">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </div>
        );
      })}
    </section>
  );
}
