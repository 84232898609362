import { config } from "../env";

export async function getHmac(message:any, secretKey:any) {
    // Convert the message and secretKey to Uint8Array
    const encoder = new TextEncoder();
    const messageUint8Array = encoder.encode(message);
    const keyUint8Array = encoder.encode(secretKey);
  
    // Import the secretKey as a CryptoKey
    const cryptoKey = await window.crypto.subtle.importKey(
      'raw',
      keyUint8Array,
      { name: 'HMAC', hash: 'SHA-256' },
      false,
      ['sign'],
    );
  
    // Sign the message with HMAC and the CryptoKey
    const signature = await window.crypto.subtle.sign(
      'HMAC',
      cryptoKey,
      messageUint8Array,
    );
  
    // Convert the signature ArrayBuffer to a hex string
    const hashArray = Array.from(new Uint8Array(signature));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  
    return hashHex;
  }
  
  export async function aesGcmEncrypt(plaintext:string) {
    const pwUtf8 = new TextEncoder().encode(config.AUTH_SHARED_KEY); // encode secretKey as UTF-8
    const pwHash = await crypto.subtle.digest('SHA-256', pwUtf8); // hash the secretKey
  
    const iv = crypto.getRandomValues(new Uint8Array(12)); // get 96-bit random iv
    const ivStr = Array.from(iv)
      .map((b) => String.fromCharCode(b))
      .join(''); // iv as utf-8 string
  
    const alg = { name: 'AES-GCM', iv: iv }; // specify algorithm to use
  
    const key = await window.crypto.subtle.importKey(
      'raw',
      pwHash,
      alg,
      false,
      ['encrypt'],
    ); // generate key from pw
  
    const ptUint8 = new TextEncoder().encode(plaintext); // encode plaintext as UTF-8
    const ctBuffer = await window.crypto.subtle.encrypt(alg, key, ptUint8); // encrypt plaintext using key
  
    const ctArray = Array.from(new Uint8Array(ctBuffer)); // ciphertext as byte array
    const ctStr = ctArray.map((byte) => String.fromCharCode(byte)).join(''); // ciphertext as string
    return btoa(ivStr + ctStr); // iv+ciphertext base64-encoded
  }
  
  export const removeNullOrUndefinedProperties = (obj: any) => {
    // return removeNullValues(obj);
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (
          (obj[key] === null ||
            obj[key] === "" ||
            obj[key] === undefined ||
            (Array.isArray(obj[key]) && obj[key].length === 0)) &&
          typeof obj[key] !== "boolean"
        ) {
          delete obj[key];
        }
      }
    }
    return obj;
  };