import { config } from "../env";
import {
  postClient,
  postFileUploadClient,
} from "./helpers/client-http-handler";
import {
  Delete,
  DeleteWithPayload,
  get,
  patch,
  post,
  postFileUpload,
} from "./helpers/http-handler";

export const adminLogin = (payload: object) =>
  post(`${config.API_URL}/auth/user/email/login`, payload);

export const adminForgotPassword = (payload: object) =>
  post(`${config.API_URL}/auth/user/email/forgot-password`, payload);

export const adminSetPassword = (payload: object) =>
  post(`${config.API_URL}/auth/user/email/set-password`, payload);

export const adminSuperUser = (payload: object) =>
  post(`${config.API_URL}/auth/user/super-user`, payload);

export const vendorUploadSubmitCheckpointLocation = (payload: object) =>
  patch(`${config.API_URL}/vendor-checkpoint/bulk/update-document`, payload);

export const vendorFileUpload = (payload: object) =>
  postFileUpload(`${config.API_URL}/media-uploads/file/upload`, payload);

// audit-detail-page-apis
export const clientUploadSubmitCheckpointLocation = (payload: object) =>
  patch(`${config.API_URL}/audit/bulk/update-document`, payload);

export const clientAuditUploadFile = (payload: object) =>
  postFileUploadClient(`${config.API_URL}/audit/client/file/upload`, payload);

export const clientDeleteSubmitCheckpointLocation = (
  document_id: string,
  id: string,
  domain_name: string
) =>
  Delete(
    `${config.API_URL}/audit/${id}/document?document_id=${document_id}&domain_name=${domain_name}`
  );

export const clientLogout = (payload: object) =>
  DeleteWithPayload(`${config.API_URL}/auth/user/logout`, payload);

export const clientUpdateToken = (payload: object) =>
  post(`${config.API_URL}/auth/user/refresh`, payload);

export const resendInvite = (id: string) =>
  get(`${config.API_URL}/users/${id}/send-invite`);

export const adminBulkUserUpdateStatus = (payload: object) =>
  patch(`${config.API_URL}/users/bulk/active-inactive`, payload);

export const adminBulkRoleUpdateStatus = (payload: object) =>
  patch(`${config.API_URL}/roles/bulk/active-inactive`, payload);

export const MigrationToClient = () =>
  get(`${config.API_URL}/data-migration/client`);

export const getCapchaByID = (queryRequest: string) =>
  get(`${config.API_URL}/auth/user/login/captcha?${queryRequest}`);

export const docStoreFileUpload = (payload: object) =>
  post(`${config.API_URL}/checkpoint/documents`, payload);

  export const updatedocStoreFileUpload = (payload: object) =>
  patch(`${config.API_URL}/checkpoint/documents`, payload);

export const getSpecificFileStore = (payload: object) =>
  post(`${config.API_URL}/checkpoint/get-documents`, payload);

export const deleteSpecificFileStore = (checkpointId:string, documentID: string) =>
  get(`${config.API_URL}/checkpoint/${checkpointId}/delete-documents/${documentID}`);