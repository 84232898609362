import Header from "@components/common/Header";
import { NavLink } from "react-router-dom";
import "./open-details.scss";
import Comments from "@components/Support/OpenDetails/Comments/comments-section";
import OldComments from "@components/Support/OpenDetails/ExistingComments/old-comments";
import { useEffect, useState } from "react";
import { getValue } from "@utils/lodash";
import NoAccessPage from "@components/common/NoAccess";
export default function OpenDetails(props: any) {
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		
	}, [props.permissionAPITriggered]);
	const [permissionLoading, setPermissionLoading] = useState(true);

	return (
		<>
			{!permissionLoading &&
			!getValue(props, `subTabPermissionList`, []).includes("support") ? (
				<NoAccessPage />
			) : (
				<>
					<Header />
					<div className="user-details-wrapper">
						<div className="user-details-wrapper__header  user-details-wrapper__header--new-header d-flex align-items-center justify-content-between">
							<div className="d-flex align-items-center">
								<NavLink
									to="/support/open"
									className="user-details-wrapper__header-back-link"
								>
									<img src="/images/back-link.svg" className="img-fluid my-2" />
								</NavLink>
								<h6 className="mx-3 user-details-wrapper__header-title">
									Open Ticket
								</h6>
							</div>
							<div className="user-details-wrapper__cta-btns">
								<button className="user-details-wrapper__cta-btn user-details-wrapper__cta-btn--primary">
									Cancel
								</button>

								<button className="user-details-wrapper__cta-btn user-details-wrapper__cta-btn--secondary">
									Submit
								</button>
							</div>
						</div>
					</div>
					<div className="open-ticket-wrapper d-flex w-100">
						<div className="module-wrapper">
							<div className="module-wrapper__form-group form-group">
								<label className="module-wrapper__label">Module</label>
								<select className="module-wrapper__select">
									<option>Reports</option>
								</select>
							</div>
							<div className="module-wrapper__form-group form-group">
								<label className="module-wrapper__label">Severity</label>
								<select className="module-wrapper__select">
									<option>High</option>
								</select>
							</div>
							<div className="module-wrapper__form-group form-group">
								<label className="module-wrapper__label">Ticket Title</label>
								<div className="module-wrapper__text-details">
									<p className="module-wrapper__text">
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry. Lorem Ipsum has been the industry’s
										standard dummy text ever since the 1500s
									</p>
								</div>
							</div>
							<div className="module-wrapper__form-group form-group">
								<label className="module-wrapper__label">Description</label>
								<div className="module-wrapper__text-details">
									<p className="module-wrapper__text">
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry. Lorem Ipsum has been the industry’s
										standard dummy text ever since the 1500s, when an unknown
										printer took a galley of type and scrambled it to make a
										type specimen book. It has survived not only five centuries,
										but also the leap into electronic typesetting, remaining
										essentially unchanged.
									</p>
								</div>
							</div>
						</div>
						<div className="status-details-wrapper">
							<div className="status-details-wrapper__form-group form-group">
								<label className="status-details-wrapper__label">
									Ticket Status
								</label>
								<select className="status-details-wrapper__select">
									<option className="green-color">Completed</option>
								</select>
							</div>
							<div className="status-details-wrapper__form-group form-group">
								<label className="status-details-wrapper__label">
									Created by
								</label>
								<input
									type="text"
									placeholder="Ashwath Kumar"
									className="status-details-wrapper__form-control form-control"
								/>
							</div>
							<div className="status-details-wrapper__form-group form-group">
								<label className="status-details-wrapper__label">
									Created by
								</label>
								<input
									type="text"
									placeholder="01-Apr-2021"
									className="status-details-wrapper__form-control form-control"
								/>
							</div>
							<div className="status-details-wrapper__form-group form-group">
								<label className="status-details-wrapper__label">
									Upload Valid Doc/Screenshots Here
								</label>
								<div className="upload-wrapper">
									<div className="upload-wrapper__header d-flex align-items-center justify-content-between">
										<div className="d-flex align-items-center">
											<button className="attach-btn">Attach Files</button>
											<h6 className="upload-wrapper__header-text">
												Files Uploaded (25)
											</h6>
										</div>
										<div className="d-flex align-items-center">
											<img
												src="/images/search.svg"
												height={20}
												width={20}
												className="img-fluid mx-3"
											/>
											<img
												src="/images/search.svg"
												height={20}
												width={20}
												className="img-fluid"
											/>
										</div>
									</div>
									<div className="d-flex align-items-center justify-content-between">
										<img
											src="/images/support/before.svg"
											className="img-fluid cursor-pointer"
										/>
										<div className="billing-data">
											<div className="billing-data__header d-flex justify-content-between">
												<img
													src="/images/support/attach-files.svg"
													className="img-fluid cursor-pointer"
												/>
												<img
													src="/images/close-black.svg"
													className="img-fluid cursor-pointer"
												/>
											</div>
											<p className="billing-text">
												Billing data_Accenture India Private
												Limited_12032021_120621
											</p>
										</div>
										<img
											src="/images/support/after.svg"
											className="img-fluid cursor-pointer"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="comments-wrapper">
						<div className="comments-wrapper__header">
							<h6>Comments</h6>
						</div>
						<Comments />
						<OldComments />
					</div>
					<div className="d-flex align-items-center justify-content-between">
						<img src="/images/profile-illustration.svg" className="img-fluid" />
						<div>
							<img src="/images/cloud-illustration.png" className="img-fluid" />
							<img
								src="/images/profile-illustration.svg"
								className="img-fluid"
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
}
