// https://stackoverflow.com/questions/70585815/react-this-props-match-is-undefined
// https://reactrouter.com/docs/en/v6/getting-started/tutorial#reading-url-params
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import NotFoundPage from "@pages/404";
import { PrivateRoutes } from "./private.routes";
import { PublicRoutes } from "./public.routes";
import { getValue } from "@utils/lodash";
import _ from "lodash";
import { useEffect, useState } from "react";
import { getAllPermissions } from "@services/users.service";

const IndexRoutes = () => {
	/* -------------------------------------------------------------------------- */
	/*                               UseState Section                             */
	/* -------------------------------------------------------------------------- */
	const [tabPermissionList, setTabPermissionList] = useState([]);
	const [subTabPermissionList, setSubTabPermissionList] = useState([]);
	const [permissionAPITriggered, setPermissionAPITriggered] = useState(false);
	/* -------------------------------------------------------------------------- */
	/*                               UseEffect Section                            */
	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		let token = localStorage.getItem("accessToken");
		if (token) {
			getPermissions();
		}
	}, []);
	/* -------------------------------------------------------------------------- */
	/*                               API Section                                  */
	/* -------------------------------------------------------------------------- */
	const covertSecondString = (s: string) => {
		var nth = 0;
		s = s.replace(/:/g, function (match, i, original) {
			nth++;
			return nth === 2 ? "-" : match;
		});
		return s;
	};
	const [mainPermissions, setMainPermissions] = useState([]);

	const getPermissions = async () => {
		try {
			let resp = await getAllPermissions();
			if (resp) {
				// console.log(getValue(resp, `data.permissions`, []))
				// let lodashList = getValue(resp, `data.permissions`, []).map((item: any) => _.split(item,':'));
				// let groupList = _.groupBy(lodashList,0)
				// let concatGroupList = getValue(resp, `data.permissions`, []).map((item: any) => _.replace(',',':'));
				// console.log(concatGroupList)
				// console.log(lodashList)
				// console.log(groupList)

				let list = getValue(resp, `data.permissions`, []).map((item: any) =>
					covertSecondString(item).split("-").length === 2
						? covertSecondString(item).split("-")
						: item.split(":")
				);
				let arr: any = [];
				for (let i = 0; i < list.length; i++) {
					arr.push({
						tab: list[i][0],
						permission: list[i][1],
					});
				}
				var groupedPermission =
					getValue(arr, `length`, 0) > 0
						? _.groupBy(arr, function (item: any) {
								return item.tab;
						  })
						: [];
				var ObjectEntry: any = Object.entries(groupedPermission);
				const arr1: any = [];
				for (let i = 0; i < ObjectEntry.length; i++) {
					arr1.push({
						tab: ObjectEntry[i][0].split(":")[0],
						tabKey:
							ObjectEntry[i][0].split(":").length > 1
								? ObjectEntry[i][0].split(":")[0] +
								  "_" +
								  ObjectEntry[i][0].split(":")[1]
								: ObjectEntry[i][0].split(":")[0],
						permissions: ObjectEntry[i][1].map((item: any) => item.permission),
					});
				}
				setMainPermissions(arr1);
				setTabPermissionList(_.uniq(arr1.map((item: any) => item.tab)));
				setSubTabPermissionList(_.uniq(arr1.map((item: any) => item.tabKey)));
				setPermissionAPITriggered(true);
			} else {
				setPermissionAPITriggered(true);
			}
		} catch (error) {
			setPermissionAPITriggered(true);
		}
	};
	return (
		<BrowserRouter>
			<Routes>
				{PublicRoutes.map((item, key) => {
					let Element: any = item.component;
					return (
						<Route
							key={key}
							path={item.path}
							element={
								!localStorage.getItem("accessToken") ? (
									<Element />
								) : (
									<Navigate to="/audit/overview" />
								)
							}
						/>
					);
				})}
				{PrivateRoutes.map((item, key) => {
					let permissions = mainPermissions.filter(
						(items: object) => getValue(items, `tabKey`, "") == item.name
					);
					let Element: any = item.component;
					return (
						<Route
							key={key}
							path={item.path}
							element={
								localStorage.getItem("accessToken") ? (
									<Element
										permissions={
											getValue(permissions, `length`, 0) > 0
												? getValue(permissions, `[${0}].permissions`, [])
												: []
										}
										name={getValue(item, `name`, "")}
										tabPermissionList={tabPermissionList}
										permissionAPITriggered={permissionAPITriggered}
										subTabPermissionList={subTabPermissionList}
									/>
								) : (
									<Navigate to="/login" />
								)
							}
						/>
					);
				})}
				<Route path={"*"} element={<NotFoundPage />} />
			</Routes>
		</BrowserRouter>
	);
};

export default IndexRoutes;
