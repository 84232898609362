import { getValue } from "@utils/lodash";
import "./index.scss";
import { handleDownloadOnClick } from "@_common/downloadfile";
import DragAndDropFileComponent from "@_common/file-drag-drop";
import { formatSmallString } from "@_common/format-text";
export default function CommonImportData(props: any) {
	const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
	return (
		<div className="popup-wrapper__details">
			<div className="popup-wrapper__download">
				<button
					className="popup-wrapper__download-btn"
					onClick={() =>
						// handleDownloadOnClick(
						// 	getValue(props, `url`, "")
						// 		? getValue(props, `url`, "")
						// 		: `https://asccuriodata.s3.ap-south-1.amazonaws.com/templates/Task%20Import%20Template.xlsx`,
						// 	"task"
						// )
						props.handleDownload(
							// getValue(props,`type`,'') === "CHECKPOINT_MASTER" ? "CHECKPOINT" : getValue(props,`type`,'')
							getValue(props,`type`,'')
							)
					}
				>
					Download Template
				</button>
			</div>
			<div className="popup-wrapper__import">
				<h6 className="popup-wrapper__import-title">Import Data</h6>
				{/* <p className="popup-wrapper__import-text">
					<span className="d-block">Instructions </span>
					Lorem Ipsum is simply dummy text of the printing and typesetting
					industry. Lorem Ipsum has been the industry’s standard dummy text ever
					since the 1500s,
				</p> */}
			</div>
			<div className="form-group">
				{/* <label className="popup-wrapper__upload-file d-flex">
					{!props.importedFile ? (
						<>
							<input
								type="file"
								className="d-none"
								onChange={props.handleChangeUpload}
							/>
							<div className="popup-wrapper__upload-file-details">
								<img
									src="/images/popup/publish-blue.svg"
									className="img-fluid"
								/>
								<h6 className="color-blue">Upload File</h6>
								<p className="popup-wrapper__upload-file-text">
									Or Drag & Drop your CSV file here
								</p>
							</div>
						</>
					) : (
						<div className="popup-wrapper__upload-file-details">
							<img src="/images/popup/publish-blue.svg" className="img-fluid" />
							<h6 className="color-blue">File Uploaded</h6>{" "}
							<div className="d-flex">
								<p>{getValue(props, `importedFile.name`, "")}</p>
								<img
									src="/images/close-red.svg"
									className="img-fluid"
									style={{ marginLeft: "7px" }}
									onClick={() => props.setImportTedFile("")}
								/>
							</div>
							<p className="popup-wrapper__upload-file-text">
								Or Drag & Drop your CSV file here
							</p>
						</div>
					)}
				</label> */}

				<DragAndDropFileComponent
					uploadFile={props.handleChangeUpload}
					name="file"
					types={fileTypes}
					multiple
					// className="d-none"
					classes="bulk-upload_drag-n-drop-wrapper position-absolute"
					fileName={getValue(props, `importedFile.name`, "")}
					setImportTedFile={props.setImportTedFile}
					file={"false"}
				/>
				{getValue(props, `importedFile.name`, "") && (
					<div className="attach-files-wrapper__files cursor-pointer d-flex justify-content-between mt-2">
						<p
							style={{
								fontSize: "12px",
								marginTop: "0px",
								textAlign: "center",
							}}
						>
							{formatSmallString(getValue(props, `importedFile.name`, ""))}
						</p>
						<img
							src="/images/close-red.svg"
							className="img-fluid"
							style={{ marginLeft: "7px" }}
							onClick={() => props.setImportTedFile("")}
						/>
					</div>
				)}
			</div>
			<div className="popup-wrapper__cta-btns">
				<button
					className="popup-wrapper__cta-button popup-wrapper__cta-button--secondary mx-3"
					onClick={() => {
						props.closePopup();
					}}
				>
					Cancel
				</button>

				<button
					className="popup-wrapper__cta-button popup-wrapper__cta-button--primary"
					onClick={() => {
						// props.importedSuccessfully();
						props.handleUpload();
					}}
					disabled={getValue(props, `submitLoading`, false)}
				>
					{getValue(props, `submitLoading`, false)
						? "Please wait..."
						: "Import"}
				</button>
			</div>
		</div>
	);
}
