import Switch from "../switch";
import { getValue } from "@utils/lodash";

export default function AddLocation(props: any) {
	return (
		<div
			className={`list-managemnet-wrapper__country-items d-flex align-items-center justify-content-between ${
				getValue(props, `selected`, "") == getValue(props, `id`, "")
					? "active"
					: ""
			}`}
			onClick={() => props.onChange(getValue(props, `id`, ""))}
		>
			<div className="d-flex align-items-center justify-content-between w-100">
				<div>
					{/* <label className="m-0 position-relative"> */}
					{/* <input
							type="radio"
							name="vendor-name"
							className="d-none custom-radio-button-input map-vendor__selected-vendor-radio-button"
						/>
						<div className="map-vendor__radio-button radio-wrapper d-flex position-absolute">
							<div className="custom-radio"></div>
						</div> */}
					<div>
						<h6 className="list-managemnet-wrapper__country-items-title px-5">
							{props.name}
						</h6>
					</div>
					{/* </label> */}
				</div>
				<div>
					{getValue(props, `permissions`, []).includes("create") &&
						getValue(props, `add`, false) && (
							<div onClick={props.handleAddCategory}>
								<img
									src="/images/add-btn.svg"
									width={24}
									height={24}
									className="img-fluid"
								/>
							</div>
						)}
				</div>
			</div>
			{/* <div className="list-managemnet-wrapper__country-items-alter d-flex align-items-center"> */}

			{/* <img
					src="/images/list-management/country/delete-icon.svg"
					className="img-fluid"
					width={24}
					height={24}
				/>
				<Switch />
			</div> */}
		</div>
	);
}
