import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import qs from "query-string";
import { getValue } from "@utils/lodash";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NavigationBar from "@components/NotificationBar/index";
import DeleteModal from "@_common/deleteModal";
import { toast } from "react-toastify";
import DataTable from "@components/DataTable/index";
import PaginationPage from "@components/pagination/pagination";
import SearchHeader from "@components/common/SearchHeader";
import {
	adminCreateOrganization,
	adminGetOrganization,
	adminGetSpecificOrganization,
	adminUpdateSpecificOrganization,
	adminDeleteSpecificOrganization,
	adminUpdateBulkStatusOrganization,
} from "@services/client-list.service";

import Notfound from "@components/Not-Found";
import moment from "moment";
import Loader from "@components/common/Loader";
import NoAccessPage from "@components/common/NoAccess";
import ScrollableReactTable from "@components/ScrollableDatatable";

interface IClientListProps {}

const ClientList: React.FunctionComponent<IClientListProps> = (props: any) => {
	let navigate = useNavigate();

	/* -------------------------------------------------------------------------- */
	/*                               UseState Section                             */
	/* -------------------------------------------------------------------------- */

	const [isLoading, setIsLoading] = React.useState(true);
	const [list, setList] = React.useState<[]>([]);
	/* -------------------------------------------------------------------------- */
	/*                               UseEffect Section                            */
	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
			getData();
		}
	}, [props.permissionAPITriggered]);

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
			getData();
			if (params.page_no) {
				setPage_no(parseInt(params.page_no));
			}
			if (params.search_text) {
				setSearchInput(params.search_text);
				setShowSearchTextbox(true);
			}
			if (params.limit) {
				setLimit(parseInt(params.limit));
			}
		}
	}, [window.location.href,props.permissionAPITriggered])

	/* -------------------------------------------------------------------------- */
	/*                               API Section                                  */
	/* -------------------------------------------------------------------------- */

	const getData = async () => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());

		let payload: any = {
			page_no: params.page_no ? parseInt(params.page_no) : 1,
			page_size: params.limit ? parseInt(params.limit) : limit,
			search_text: params.search_text,
			status: "inactive",
			sort_by: params.sort_by,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);

		try {
			setIsLoading(true);
			let resp = await adminGetOrganization(queryRequest);
			if (resp) {
				setList(
					getValue(resp, `data.organisations`, []).map((item: object) => ({
						...item,
						created_at: moment(getValue(item, `created_at`, "")).format(
							"DD MMM YYYY"
						),
					}))
				);
				setTotalCount(getValue(resp, `data.pagination.total`, 0));
				setIsLoading(false);
				
			} else {
				setIsLoading(false);
				
			}
		} catch (error) {
			setIsLoading(false);
			
		}
	};

	const handleChangeActiveStatus = async () => {
		try {
			let resp = await adminUpdateBulkStatusOrganization({
				ids: selectedIDs,
				is_active: true,
			});
			if (resp) {
				toast.success(getValue(resp, `message`, ""));
				setSelectedIDs([]);
				getData();
			}
		} catch (error) {}
	};

	/* -------------------------------------------------------------------------- */
	/*                               Search section                               */
	/* -------------------------------------------------------------------------- */
	const [showSearchTextbox, setShowSearchTextbox] = useState(false);
	const [search_text, setSearchInput] = useState<any>("");

	const toggleSearch = () => {
		setShowSearchTextbox((prevState) => !prevState);
	};
	const toggleSearchClose = () => {
		setSearchInput("");
		toggleSearch();
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (params.search_text) {
			delete params.search_text;
			let payload: any = { ...params };
			// removing null values
			Object.keys(payload).forEach((key) => {
				if (!payload[key] || payload[key] === undefined) {
					delete payload[key];
				}
			});
			let queryRequest = qs.stringify(payload);
			navigate(`${window.location.pathname}?${queryRequest}`);
			getData();
		}
	};

	const handleChangeSearch = async (e: any) => {
		setSearchInput(e.target.value);

		let payload: any = {
			search_text: e.target.value,
		};
		// removing null values
		Object.keys(payload).forEach((key) => {
			if (!payload[key] || payload[key] === undefined) {
				delete payload[key];
			}
		});
		let queryRequest = qs.stringify(payload);
		navigate(`${window.location.pathname}?${queryRequest}`);
		if (!e.target.value) {
			getData();
		}
	};

	const [selectedIDs, setSelectedIDs] = useState<any>([]);
	const getTableState = (value: object) => {
		let selectedIndexList = Object.keys(
			getValue(value, `selectedRowIds`, {})
		).map((item) => parseInt(item));
		let selectedIds =
			list.length > 0
				? list.filter((_item: object, index: number) =>
						selectedIndexList.includes(index)
				  )
				: [];
		if (selectedIds.length > 0) {
			setSelectedIDs(
				selectedIds.map((item: object) => getValue(item, `id`, ""))
			);
		} else {
			setSelectedIDs([]);
		}
	};

	/* -------------------------------------------------------------------------- */
	/*                                 Delete section                             */
	/* -------------------------------------------------------------------------- */

	const [deleteValue, setDeleteValue] = useState("");
	const [deleteId, setDeleteId] = useState("");
	const handleDeleteFun = (id: string, value: string) => {
		setDeleteValue(value);
		setDeleteId(id);
		handleDelete();
	};
	const [isDelete, setIsDelete] = useState(false);
	const handleDelete = () => {
		setIsDelete(!isDelete);
	};

	const handleDeleteFunction = async () => {
		try {
			let resp = await adminDeleteSpecificOrganization(deleteId);
			if (resp) {
				toast.success(getValue(resp, `message`, ""));
				handleDelete();
				getData();
			}
		} catch (error) {}
	};

	/* -------------------------------------------------------------------------- */
	/*                               Onchange section                             */
	/* -------------------------------------------------------------------------- */

	const handleChangeSortBy = (value: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.sort_by = value;
		props.history.push({
			pathname: window.location.pathname,
			query: params,
		});
	};

	const handleChangeStatus = (value: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.status = value;
		props.history.push({
			pathname: window.location.pathname,
			query: params,
		});
	};
	/* -------------------------------------------------------------------------- */
	/*                               Pagination section                           */
	/* -------------------------------------------------------------------------- */

	const [totalCount, setTotalCount] = useState(0);
	const [limit, setLimit] = useState(10);
	const [page_no, setPage_no] = useState(1);

	const handleChangePagination = (page_no: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.page_no = page_no;
		Object.keys(params).forEach((key) => {
			if (!params[key] || params[key] === undefined) {
				delete params[key];
			}
		});
		let queryRequest = qs.stringify(params);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};

	const handleChangeLimit = (limit: string) => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		params.limit = limit;
		Object.keys(params).forEach((key) => {
			if (!params[key] || params[key] === undefined) {
				delete params[key];
			}
		});
		let queryRequest = qs.stringify(params);
		navigate(`${window.location.pathname}?${queryRequest}`);
	};
	const handleEdit = (id: string) => {
		navigate(`/add-clientlist/profile/${id}`);
	};
	const rowClick = (obj: object) => {
		navigate(
			`/add-clientlist/profile/${getValue(
				obj,
				`original.domain_name`,
				""
			)}/${getValue(obj, `original.id`, "")}`
		);
	};
	const columns = React.useMemo(
		() => [
			{
				Header: "Customer ID",
				accessor: "id",
				width: 350,
				Cell: ({ row }: any) => {
					return (
						<NavLink
							to={`/add-clientlist/profile/${getValue(
								row,
								`original.domain_name`,
								""
							)}/${row.original.id}`}
						>
							{row.original.organisation_id}
						</NavLink>
					);
				},
			},
			{
				Header: "Customer Name",
				accessor: "organisation_name",
				width: 350,
			},
			{
				Header: "Corporate Location",
				accessor: "address1",
				width: 350,
			},
			{
				Header: "Active Since",
				accessor: "created_at",
				width: 350,
			},
		],
		[]
	);
	const urlSearchParams = new URLSearchParams(window.location.search);
	const params = Object.fromEntries(urlSearchParams.entries());
	const [check, setCheck] = useState(false);
	const handleRemoveNavItems = () => {
		setCheck(!check);
		setSelectedIDs([]);
	};
	const getTrProps = (rowInfo: any) => {
		return {};
	};
	return (
		<DashboardLayout
			permissions={getValue(props, `tabPermissionList`, [])}
			subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
		>
			{!isLoading &&
			!getValue(props, `subTabPermissionList`, []).includes("client_inactive") ? (
				<NoAccessPage />
			) : (
				<>
					{getValue(selectedIDs, `length`, 0) > 0 && (
						<NavigationBar
							selectedIDs={selectedIDs}
							isMarkInactiveSectionVisible={true}
							handleChangeActiveStatus={handleChangeActiveStatus}
							text={"MARK ACTIVE"}
							handleRemoveNavItems={handleRemoveNavItems}
							permissions={getValue(props,`permissions`,[])}
						/>
					)}
					<div className="dashboard-wrapper__header">
						<h4 className="dashbaord-wrapper__header-title">Inactive</h4>
					</div>

					<div className="admin-wrapper position-relative">
						<div className="admin-wrapper position-relative">
							<SearchHeader
								route={"/add-clientlist/profile"}
								searchInput={search_text}
								showSearchTextbox={showSearchTextbox}
								toggleSearch={toggleSearch}
								toggleSearchClose={toggleSearchClose}
								handleChangeSearch={handleChangeSearch}
								isBulkUploadVisible={false}
								smartFilter={false}
								add={
									getValue(props, `permissions`, []).includes("create")
										? "true"
										: "false"
								}
							/>
						</div>
						{isLoading || !props.permissionAPITriggered ? (
							<Loader />
						) : list.length > 0 ? (
							<ScrollableReactTable
								columns={columns}
								data={list}
								getTableState={getTableState}
								rowClick={rowClick}
								check={check}
								selectedIDs={selectedIDs}
								getTrProps={getTrProps}
							/>
						) : (
							<>
								{Object.keys(params).length !== 0 ? (
									<>
										<Notfound />
									</>
								) : (
									<Notfound />
								)}
							</>
						)}
					</div>
					<div className="footer-wrapper d-flex justify-content-between">
						{list.length > 0 && (
							<img
								src="/images/building-illustration.svg"
								className="img-fluid"
							/>
						)}
						{totalCount > 10 ? (
							<PaginationPage
								totalCount={totalCount}
								limit={limit}
								pageNumber={page_no}
								handleChangePagination={handleChangePagination}
								handleChangeLimit={handleChangeLimit}
							/>
						) : (
							""
						)}
					</div>
					<DeleteModal
						isOpen={isDelete}
						handleModal={handleDelete}
						handleSubmit={handleDeleteFunction}
						deleteValue={deleteValue}
					/>
				</>
			)}{" "}
		</DashboardLayout>
	);
};

export default ClientList;
