import "./switch.scss";

import * as React from "react";
import { getValue } from "@utils/lodash";

interface ISwitchProps {
	is_active: boolean;
	onChange: (status: boolean, id: any) => void;
	id: string;
}

const Switch: React.FunctionComponent<ISwitchProps> = (props) => {
	return (
		<div className="custom-switch-wrapper">
			<label className="switch">
				<input
					type="checkbox"
					checked={getValue(props, `is_active`, false)}
					onChange={() =>
						props.onChange(
							getValue(props, `id`, ""),
							!getValue(props, `is_active`, ""),
						)
					}
				/>
				<span className="slider round"></span>
			</label>
		</div>
	);
};

export default Switch;
