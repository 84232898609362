import LoginSlider from '@components/login-swiper/index';
export default function Layout(props: any) {
  return (
    <>
      <section className="main-wrapper-login">
        <LoginSlider />
        <div className="form-wrapper flex-1 d-flex position-relative">
          <a href="#0" className="logo position-absolute">
            <img src="/images/ascentlogo.png" className="img-fluid" />
          </a>
          {props.children}
        </div>
      </section>
    </>
  );
}
