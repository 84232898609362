import React, { useEffect, useState, useRef } from "react";
import Header from "@components/common/Header/index";
import { NavLink } from "react-router-dom";
import {
  adminCreateUser,
  adminGetSpecificUser,
  adminUpdateSpecificUser,
} from "@services/users.service";
import { toast } from "react-toastify";
import InputField from "@_common/InputRuleFeild";
import { getValue, setValue } from "@utils/lodash";
import { adminGetAllRoles } from "@services/roles.service";
import SimpleReactValidator from "simple-react-validator";
import { useParams, useNavigate } from "react-router-dom";
import countries from "@_common/contry.json";
import {
  adminCreateClientUsers,
  adminGetClientSpecificUser,
  adminUpdateClientUsers,
} from "@services/client-list.service";
import _ from "lodash";
import Switch from "@components/list-management/switch";
import NoAccessPage from "@components/common/NoAccess";

interface IUserDetailsInfoProps {}

const OnPremClientUserDetailsInfo: React.FunctionComponent<IUserDetailsInfoProps> = (
  props: any
) => {
  let params = useParams();
  let navigate = useNavigate();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [request, setRequest] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_area_code: "",
    mobile_number: "",
    roles: [],
    user_timezone: "Asia/Kolkata",
    is_super_user: false,
  });
  const [dirtyFields, setDirtyFields] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_area_code: "",
    mobile_number: "",
    roles: [],
    user_timezone: "Asia/Kolkata",
    is_super_user: false,
  });
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    // getRolesList();
    if (getValue(params, `childId`, "") && props.permissionAPITriggered) {
      getSpecificUser(getValue(params, `childId`, ""));
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getSpecificUser = async (id: string) => {
    try {
      let resp = await adminGetClientSpecificUser(id, params.domain);
      if (resp) {
        let countryCode = countries.filter(
          (item: object) =>
            getValue(item, `dial_code`, "") ===
            getValue(resp, `data.mobile_area_code`, "")
        );
        // mobile_area_code

        let code: any =
          countryCode.length > 0 &&
          countryCode.map((item) => ({
            id: getValue(item, `id`, ""),
            country: item.code,
            label: <>{item.dial_code + " " + item.name}</>,
            value: item.dial_code + " " + item.name,
          }));

        setRequest({
          ...request,
          first_name: getValue(resp, `data.first_name`, ""),
          last_name: getValue(resp, `data.last_name`, ""),
          email: getValue(resp, `data.email`, ""),
          mobile_number: getValue(resp, `data.mobile_number`, ""),
          mobile_area_code:
            getValue(code, `length`, 0) > 0 ? getValue(code, `[${0}]`, "") : "",
          is_super_user: getValue(resp, `data.is_super_user`, false),
          // roles: getValue(resp, `data.roles`, []).map((item: object) => ({
          // 	id: getValue(item, `id`, ""),
          // 	// is_active: getValue(item, `is_active`, ""),
          // 	title: getValue(item, `title`, ""),
          // 	value: getValue(item, `title`, ""),
          // 	label: getValue(item, `title`, ""),
          // })),
          user_timezone: getValue(resp, `data.user_timezone`, ""),
        });

        setDirtyFields({
          ...dirtyFields,
          first_name: getValue(resp, `data.first_name`, ""),
          last_name: getValue(resp, `data.last_name`, ""),
          email: getValue(resp, `data.email`, ""),
          mobile_number: getValue(resp, `data.mobile_number`, ""),
          mobile_area_code:
            getValue(code, `length`, 0) > 0 ? getValue(code, `[${0}]`, "") : "",
          is_super_user: getValue(resp, `data.is_super_user`, false),
          // roles: getValue(resp, `data.roles`, []).map((item: object) => ({
          // 	id: getValue(item, `id`, ""),
          // 	// is_active: getValue(item, `is_active`, ""),
          // 	title: getValue(item, `title`, ""),
          // 	value: getValue(item, `title`, ""),
          // 	label: getValue(item, `title`, ""),
          // })),
          user_timezone: getValue(resp, `data.user_timezone`, ""),
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getRolesList = async () => {
    try {
      let resp = await adminGetAllRoles();
      if (resp) {
        setRolesList(
          getValue(resp, `data.roles`, []).map((item: object) => {
            return {
              id: getValue(item, `id`, ""),
              value: getValue(item, `title`, ""),
              label: getValue(item, `title`, ""),
            };
          })
        );
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsLoading(true);
        let payload: any = {
          first_name: request.first_name,
          last_name: request.last_name,
          email: request.email,
          mobile_area_code: getValue(
            request,
            `mobile_area_code.value`,
            ""
          ).split(" ")[0],
          mobile_number: request.mobile_number,
          // roles: getValue(request,`roles`,[]).map((item:object)=>getValue(item,`id`,'')),
          // roles: getValue(request, `roles`, []),
          user_timezone: request.user_timezone,
          is_super_user: request.is_super_user,
          user_type: "CLIENT",
          org_id: params.id,
          is_invite: true,
          domain_name: params.domain,
        };

        // removing null values
        // Object.keys(payload).forEach((key) => {
        // 	if (!payload[key] || payload[key] === undefined) {
        // 		delete payload[key];
        // 	}
        // });

        if (getValue(params, `childId`, "")) {
          payload.domain_name = params.domain;

          let resp = await adminUpdateClientUsers(
            getValue(params, `childId`, ""),
            payload
          );
          if (resp) {
            setIsLoading(false);
            // confirmNavigation();
            toast.success("Updated successfully");
            navigate(
              `/on-prem/add-clientlist/users/${params.domain}/${params.id}`
            );
          } else {
            setIsLoading(false);
          }
        } else {
          let resp = await adminCreateClientUsers(payload);
          if (resp) {
            setIsLoading(false);
            // confirmNavigation();
            toast.success("Created successfully");
            navigate(
              `/on-prem/add-clientlist/users/${params.domain}/${params.id}`
            );
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  const handleTimeZone = (value: any) => {
    setValue(request, "user_timezone", value);
    setRequest({ ...request });
  };

  const handleChangeCountryCode = (e: any) => {
    setValue(request, "mobile_area_code", e);
    setRequest({ ...request });
  };

  const handleChangeRole = (e: any) => {
    setValue(request, "roles", e);
    setRequest({ ...request });
  };
  /* -------------------------------------------------------------------------- */
  /*                                Prompt Section                              */
  /* -------------------------------------------------------------------------- */
  // const [isChanged, setIsChanged] = useState(false);
  // useEffect(() => {
  // 	setIsChanged(
  // 		_.isEqualWith(request, dirtyFields, (a: any, b: any) => {
  // 			// if both are null or equal to an empty string then they are equal
  // 			if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
  // 		})
  // 	);
  // });
  // const [showPrompt, confirmNavigation, cancelNavigation]: any =
  // 	useCallbackPrompt(!isChanged);
  // const [permissionLoading, setPermissionLoading] = React.useState(true);

  return (
    <>
      {!loading &&
      !getValue(props, `subTabPermissionList`, []).includes("on_prem_client_active") ? (
        <NoAccessPage />
      ) : (
        <>
          <Header />
          {/* <PromptModal
						showDialog={showPrompt}
						confirmNavigation={confirmNavigation}
						cancelNavigation={cancelNavigation}
					/> */}
          <div className="user-details-wrapper">
            <div className="user-details-wrapper__header d-flex align-items-center justify-content-between">
              <NavLink
                to={`/on-prem/add-clientlist/users/${params.domain}/${params.id}`}
                className="user-details-wrapper__header-back-link py-2"
              >
                <img src="/images/back-link.svg" className="img-fluid" />
              </NavLink>
              <div className="user-details-wrapper__navigation">
                <a href="#0" className="user-details-wrapper__add-user-info">
                  User Details
                </a>
              </div>
              <div className="user-details-wrapper__cta-btns">
                {getValue(props, `permissions`, []).includes("create") && (
                  <>
                    <NavLink
                      to={`/on-prem/add-clientlist/users/${params.domain}/${params.id}`}
                    >
                      <button className="user-details-wrapper__cta-btn user-details-wrapper__cta-btn--primary">
                        Cancel
                      </button>
                    </NavLink>
                    {isLoading ? (
                      <button className="user-details-wrapper__cta-btn user-details-wrapper__cta-btn--secondary">
                        Please wait...
                      </button>
                    ) : (
                      <button
                        onClick={handleSubmit}
                        // disabled={isChanged}
                        className={`user-details-wrapper__cta-btn user-details-wrapper__cta-btn--${"secondary"}`}
                      >
                        {getValue(params, `childId`, "")
                          ? "Save"
                          : "Send Invite"}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="user-details-wrapper__form-wrapper">
              <div className="user-details-wrapper__form">
                <div className="user-details-wrapper__form-group form-group">
                  <label className="user-details-wrapper__form-label">
                    First Name <span>*</span>
                  </label>
                  <InputField
                    inputType="TEXT"
                    placeholder="Enter First Name"
                    className="user-details-wrapper__form-control form-control w-100"
                    name="first_name"
                    id="first_name"
                    value={getValue(request, `first_name`, "")}
                    onChange={(e: any) => handleChangeText(e)}
                    label="First Name"
                    validator={simpleValidator}
                  />
                </div>
                <div className="user-details-wrapper__form-group form-group">
                  <label className="user-details-wrapper__form-label">
                    Last Name <span>*</span>
                  </label>
                  <InputField
                    inputType="TEXT"
                    placeholder="Enter Last Name"
                    className="user-details-wrapper__form-control form-control w-100"
                    name="last_name"
                    id="last_name"
                    label="Last Name"
                    value={getValue(request, `last_name`, "")}
                    onChange={(e: any) => handleChangeText(e)}
                    validator={simpleValidator}
                  />
                </div>
                <div className="user-details-wrapper__form-group form-group">
                  <label className="user-details-wrapper__form-label">
                    Mobile Area Code <span>*</span>
                  </label>
                  <InputField
                    inputType="INPUT_REACT_SELECT_COUNTYCODE"
                    placeholder="Enter Last Name"
                    className="user-details-wrapper__form-control form-control w-100"
                    name="mobile_area_code"
                    id="mobile_area_code"
                    label="Mobile Area Code"
                    value={getValue(request, `mobile_area_code`, "")}
                    onChange={(e: any) => handleChangeCountryCode(e)}
                    validator={simpleValidator}
                  />
                </div>
                <div className="user-details-wrapper__form-group form-group">
                  <label className="user-details-wrapper__form-label">
                    Mobile Number
                  </label>
                  <InputField
                    inputType="PHONE"
                    placeholder="Enter Mobile Number"
                    className="user-details-wrapper__form-control form-control w-100"
                    name="mobile_number"
                    id="mobile_number"
                    label="Mobile Number"
                    value={getValue(request, `mobile_number`, "")}
                    onChange={(e: any) => handleChangeText(e)}
                    validator={simpleValidator}
                  />
                </div>
              </div>
              <div className="user-details-wrapper__form-group form-group">
                <label className="user-details-wrapper__form-label">
                  Email ID
                </label>
                <InputField
                  inputType="EMAIL"
                  placeholder="Enter Email ID"
                  className="user-details-wrapper__form-control form-control w-100"
                  name="email"
                  id="email"
                  label="Email"
                  value={getValue(request, `email`, "")}
                  onChange={(e: any) => handleChangeText(e)}
                  validator={simpleValidator}
                />
              </div>
              <div className="user-details-wrapper__form-group form-group">
                <label>Time Zone</label>
                <InputField
                  inputType="TIME_ZONE"
                  label="Time zone"
                  className="user-details-wrapper__form-control form-control w-100"
                  value={getValue(request, `user_timezone`, "")}
                  onChange={(timezone: any) => {
                    handleTimeZone(timezone);
                  }}
                  inputProps={{
                    placeholder: "Select Timezone...",
                    name: "timezone",
                  }}
                  validator={simpleValidator}
                />
              </div>
              <div className="client-list-details-wrapper__form-group mt-4">
                <div className="client-list-details-wrapper__psycology-header d-flex align-items-center ml-4">
                  <p
                    className="client-list-details-wrapper__psycology-header-title"
                    style={{ marginRight: "10px" }}
                  >
                    Is Super User
                  </p>
                  <Switch
                    is_active={getValue(request, `is_super_user`, false)}
                    onChange={() =>
                      setRequest({
                        ...request,
                        is_super_user: !request.is_super_user,
                      })
                    }
                    id={""}
                  />
                </div>
              </div>
              {/* <div className="user-details-wrapper__form-group form-group my-2">
            <label className="user-details-wrapper__form-label">Roles</label>
            <InputField
              inputType="INPUT_REACT_SELECT"
              className="user-details-wrapper__form-control form-control w-100"
              value={getValue(request, `roles`, '')}
              options={rolesList}
              onChange={(value: any) => {
                handleChangeRole(value);
              }}
              label="Roles"
              isMulti={true}
              validator={simpleValidator}
            />
          </div> */}
              {/* <div className="user-details-wrapper__form-batch-wrapper d-flex">
						<button className="badge d-flex align-items-center">
							Supervisor{" "}
							<img src="images/close-red.svg" className="img-fluid close-btn" />
						</button>
						<button className="badge d-flex align-items-center">
							Manager{" "}
							<img src="images/close-red.svg" className="img-fluid close-btn" />
						</button>
					</div> */}
            </div>
            <div className="d-flex align-items-center justify-content-between footer-wrapper-new">
              <img
                src="/images/checkpoint-illustartion.svg"
                className="img-fluid"
              />
              <img
                src="/images/profile-illustration.svg"
                className="img-fluid client-list-details-wrapper__illustration"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OnPremClientUserDetailsInfo;
