import * as React from 'react';
import { Label, Modal } from 'reactstrap';
import InputField from '@_common/InputRuleFeild';
import { getValue } from '@utils/lodash';
import '../popup/addpopup.scss';

interface IChoiceValuePopupProps {
	isOpen: boolean;
	handleModal: () => void;
	text: string;
	setText: any;
	handleSubmit: () => void;
	handleModalCancel: () => void;
}

const ChoiceValuePopup: React.FunctionComponent<IChoiceValuePopupProps> = (
	props
) => {
	return (
		<Modal
			isOpen={getValue(props, `isOpen`, '')}
			toggle={getValue(props, `isOpen`, '')}
			className="choice-popup"
			centered
		>
			<section className="choice-popup-wrapper">
				<div className="choice-popup-wrapper__popup">
					<div className="add-popup-wrapper__form-group form-group">
						<Label>Value *</Label>
						<InputField
							inputType="TEXT"
							placeholder="Enter Value"
							name={'text'}
							value={props.text}
							id={'text'}
							onChange={(e: any) => props.setText(e.target.value)}
						/>
					</div>
					<div className="add-popup-wrapper__cta-wrapper">
						<button
							onClick={props.handleModalCancel}
							className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--secondary"
						>
							Cancel
						</button>
						<button
							className="add-popup-wrapper__cta-btn add-popup-wrapper__cta-btn--primary"
							onClick={props.handleSubmit}
						>
							Save
						</button>
					</div>
				</div>
			</section>
		</Modal>
	);
};

export default ChoiceValuePopup;
