import { NavLink } from "react-router-dom";
import { getValue } from "@utils/lodash";
import "./clientlist-navigation.scss";

export default function OnPremClientListNavigation(props: any) {
  return (
    <>
      <div className="dashboard-wrapper__header">
        <h4 className="dashbaord-wrapper__header-title">
          {getValue(props, `headerName`, "") ? props.headerName : "Client List"}
        </h4>
      </div>
      <section className="client-list-details-wrapper p-0">
        <NavLink
          to="/on-prem/client-list/active"
          className="client-list-details-wrapper__back-btn"
        >
          <img
            src="/images/back-link.svg"
            className="img-fluid"
            height={24}
            width={24}
          />
        </NavLink>
        <div className="client-list-details-wrapper__header d-flex">
          <ul className="client-list-details-wrapper__nav-list d-inline-flex align-items-center justify-content-center">
            {getValue(props, `id`, "") ? (
              <li className="client-list-details-wrapper__nav-list-item">
                <NavLink
                  to={`/on-prem/add-clientlist/profile/${getValue(
                    props,
                    `domain`,
                    ""
                  )}/${getValue(props, `id`, "")}`}
                  className="client-list-details-wrapper__nav-list-item-link"
                >
                  Profile
                </NavLink>
              </li>
            ) : (
              <li className="client-list-details-wrapper__nav-list-item">
                <NavLink
                  to="/on-prem/add-clientlist/profile"
                  className="client-list-details-wrapper__nav-list-item-link"
                >
                  Profile
                </NavLink>
              </li>
            )}
            {getValue(props, `id`, "") && (
              <>
                <li className="client-list-details-wrapper__nav-list-item">
                  <NavLink
                    to={`/on-prem/add-clientlist/setup/${getValue(
                      props,
                      `domain`,
                      ""
                    )}/${getValue(props, `id`, "")}`}
                    className="client-list-details-wrapper__nav-list-item-link"
                  >
                    Setup
                  </NavLink>
                </li>
                <li className="client-list-details-wrapper__nav-list-item">
                  <NavLink
                    to={`/on-prem/add-clientlist/users/${getValue(
                      props,
                      `domain`,
                      ""
                    )}/${getValue(props, `id`, "")}`}
                    className="client-list-details-wrapper__nav-list-item-link"
                  >
                    Users
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </section>
    </>
  );
}
