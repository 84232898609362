import React, { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import InputField from "@_common/InputRuleFeild";
import Header from "@components/common/Header";
import { getValue, setValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import {
  adminCreateRole,
  adminGetRolesListPermissions,
  adminGetSpecificRole,
  adminUpdateSpecificRole,
  createDocumentLibrary,
  getDocumentLibrary,
  getSpecificDocumentLibrary,
  updateDocumentLibrary,
} from "@services/roles.service";
import _ from "lodash";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { formatSmallString, formatText } from "@_common/format-text";
import NoAccessPage from "@components/common/NoAccess";
import { useCallbackPrompt } from "@prompt/useCallbackPrompt";
import { adminGetStatesListManagement } from "@services/list-management.service";
import { QueryRequestHelper } from "@_common/query-request-helper";
import CheckpointSearchableDropdownSelect from "@_common/CheckpointSearchableDropdown";
import qs from "query-string";
import DragAndDropFileComponent from "@_common/file-drag-drop";
import { vendorFileUpload } from "@services/auth.service";
import { handleDownloadOnClick } from "@_common/downloadfile";
import { adminGetOrganization } from "@services/client-list.service";

interface IDocumentLibraryDetailsProps {}

const DocumentLibraryDetails: React.FunctionComponent<IDocumentLibraryDetailsProps> = (
  props: any
) => {
  let params = useParams();
  let navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [request, setRequest] = React.useState({
    state_id: "",
    legislation: "",
    rule: "",
    form: "",
    description: "",
    documents: [],
    applicable_to_all: true,
  });

  const [dirtyFields, setDirtyFields] = useState({
    state_id: "",
    legislation: "",
    rule: "",
    form: "",
    description: "",
    documents: [],
    applicable_to_all: true,
  });

  const [permissionList, setPermissionList] = useState<any>({});
  const [selectedPermissions, setSelectedPermissions] = useState<any>([]);
  const [mainPermissions, setMainPermissions] = useState([]);
  const [allList, setAllList] = useState<any>([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (props.permissionAPITriggered) {
      if (getValue(params, `id`, "")) {
        getSpecificDL(getValue(params, `id`, ""));
      } 
      else {
        getData();
      }
      getStatesList(101);
      getTagsList("", "");
    }
  }, [props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [currentDocuments, setCurrentDocuments] = useState<any>([]);
  const [previousDocuments, setPreviousDocuments] = useState<any>([]);
  const getSpecificDL = async (id: string) => {
    try {
      setIsLoading(true);
      let resp = await getSpecificDocumentLibrary(id);
      if (resp) {
        setRequest({
          ...request,
          state_id: getValue(resp, `data.state.id`, ""),
          legislation: getValue(resp, `data.legislation`, ""),
          rule: getValue(resp, `data.rule`, ""),
          form: getValue(resp, `data.form`, ""),
          description: getValue(resp, `data.description`, ""),
          // documents: getValue(resp, `data.documents`, []),
          applicable_to_all: getValue(resp, `data.applicable_to_all`, false),
        });
        setCurrentDocuments(getValue(resp, `data.current_documents`, []));
        setPreviousDocuments(getValue(resp, `data.previous_documents`, []));

        setSelectedTagsListIds(
          getValue(resp, `data.applicable_to.length`, 0) > 0
            ? getValue(resp, `data.applicable_to`, []).map((item: object) => ({
                id: getValue(item, `id`, ""),
                organisation_name: getValue(item, `organisation_name`, ""),
                value: getValue(item, `organisation_name`, ""),
                label: getValue(item, `organisation_name`, ""),
              }))
            : []
        );
        setSelectedPermissions(getValue(resp, `data.permissions`, []));
        setdocuments(getValue(resp, `data.documents`, []));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getData = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    let payload: any = {
      page: params.page_no ? parseInt(params.page_no) : 1,
      page_size: params.limit ? parseInt(params.limit) : 10,
      search_text: params.search_text,
      status: params.status,
      sort_by: params.sort_by,
    };
    // removing null values
    Object.keys(payload).forEach((key) => {
      if (!payload[key] || payload[key] === undefined) {
        delete payload[key];
      }
    });
    let queryRequest = qs.stringify(payload);
    try {
      setIsLoading(true);
      let resp = await getDocumentLibrary(queryRequest);
      if (resp) {
        setDirtyFields({
          ...dirtyFields,
          state_id: getValue(resp, `data.title`, ""),
          legislation: getValue(resp, `data.legislation`, ""),
          rule: getValue(resp, `data.rule`, ""),
          form: getValue(resp, `data.form`, ""),
          description: getValue(resp, `data.description`, ""),
          documents: getValue(resp, `data.documents`, []),
        });
        setRequest({
          ...request,
          state_id: getValue(resp, `data.title`, ""),
          legislation: getValue(resp, `data.legislation`, ""),
          rule: getValue(resp, `data.rule`, ""),
          form: getValue(resp, `data.form`, ""),
          description: getValue(resp, `data.description`, ""),
          documents: getValue(resp, `data.documents`, []),
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    let selectedTagsListIdsPayload =
      getValue(selectedTagsListIds, `length`, 0) > 0 &&
      JSON.parse(JSON.stringify(selectedTagsListIds));

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        let payload = {
          state_id: request.state_id,
          legislation: request.legislation,
          rule: request.rule,
          form: request.form,
          description: request.description,

          applicable_to_all: getValue(request, `applicable_to_all`, false),
          applicable_to: getValue(request, `applicable_to_all`, false)
            ? []
            : selectedTagsListIdsPayload.length > 0
            ? selectedTagsListIdsPayload.map((item: object) => ({
                id: getValue(item, `id`, ""),
              }))
            : [],
          current_documents: currentDocuments.map((item: object) => ({
            id: getValue(item, "id", ""),
          })),
          previous_documents: previousDocuments.map((item: object) => ({
            id: getValue(item, "id", ""),
          })),
        };

        let resp;
        if (getValue(params, `id`, "")) {
          resp = await updateDocumentLibrary(getValue(params, `id`, ""), payload);
        } else {
          resp = await createDocumentLibrary(payload);
        }
        if (resp) {
          navigate(`/document-library`);
          toast.success(getValue(resp, `message`, ""));
        }
      } catch (error) {}
    }
  };

  //   Fields Functionality
  const [resetInput, setResetInput] = useState(false);

  const [statesList, setStateList] = useState([]);
  //Single Select
  const getStatesList = async (countryId: number) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    try {
      let resp = await adminGetStatesListManagement(countryId);
      if (resp) {
        setStateList(
          getValue(resp, `data.states`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const [tagsList, setTagsList] = useState([]);
  const [selectedTagsListIds, setSelectedTagsListIds] = useState<any>([]);
  const handleChangeTagsMulti = (e: any) => {
    setSelectedTagsListIds(e);
  };

  const getTagsList = async (value: string, id: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    try {
      let payload = {
        page_no: params.page_no ? parseInt(params.page_no) : 1,
        page_size: 100,
        status: "active",
        sort_by: params.sort_by,
      };
      let queryRequest = QueryRequestHelper(payload);

      let resp = await adminGetOrganization(queryRequest);

      if (resp) {
        let list = getValue(resp, `data.organisations`, []).map(
          (item: object) => ({
            value: getValue(item, `domain_name`, ""),
            label: getValue(item, `organisation_name`, ""),
            organisation_name: getValue(item, `organisation_name`, ""),
            id: getValue(item, `id`, ""),
          })
        );
        setTagsList(list);
      } else {
      }
    } catch (error) {}
  };

  const handleChangeSelect = (e: any, type: string) => {
    if (type === "state_id") {
      setRequest({
        ...request,
        state_id: e.id,
      });
    }
  };

  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  //Docs
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const [uploadLoading, setUploadLoading] = useState(false);
  const [importedFile, setImportTedFile] = useState<any>(null);

  const [documents, setdocuments] = useState<any>([]);
  const handleUploadDocumentsStore = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        setUploadLoading(true);
        let vendorResp = await vendorFileUpload(formData);
        currentDocuments.push(getValue(vendorResp, `data`, {}));
        setCurrentDocuments([...currentDocuments]);
        e = null;
        setUploadLoading(false);
      } catch (error) {
        console.error(error);
        e = null;
        setUploadLoading(false);
      }
    });
  };

  const handleRemoveUploadedFilesCurrent = (id: string) => {
    let filtered = currentDocuments.filter(
      (item: object) => getValue(item, `id`, "") !== id
    );
    setCurrentDocuments(filtered);
  };

  const handleRemoveUploadedFilesPrevious = (id: string) => {
    let filtered = previousDocuments.filter(
      (item: object) => getValue(item, `id`, "") !== id
    );
    setPreviousDocuments(filtered);
  };

  const documentLength = Object.keys(documents).length;

  const handleRadioChange = (item: object, type: string) => {
    if (type === "current") {
      let filtered = currentDocuments.filter(
        (obj: object) => getValue(obj, `id`, "") !== getValue(item, `id`, "")
      );
      setCurrentDocuments(filtered);
      previousDocuments.push(item);
      setPreviousDocuments([...previousDocuments]);
    } else {
      let filtered = previousDocuments.filter(
        (obj: object) => getValue(obj, `id`, "") !== getValue(item, `id`, "")
      );
      setPreviousDocuments(filtered);
      currentDocuments.push(item);
      setCurrentDocuments([...currentDocuments]);
    }
  };


  return (
    <>
      <Header />
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes("admin_role") ? (
        <NoAccessPage />
      ) : (
        <>
          <div className="user-details-wrapper user-details-wrapper__sticky">
            <div className="user-details-wrapper__header d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <NavLink
                  to="/document-library"
                  className="user-details-wrapper__header-back-link"
                >
                  <img src="/images/back-link.svg" className="img-fluid" />
                </NavLink>
                <div className="user-details-wrapper__add-user-title">
                  {getValue(params, `id`, "") ? "Edit" : "Add"} Document Library
                </div>
              </div>
              {getValue(props, `permissions`, []).includes("update") && (
                <div className="user-details-wrapper__cta-btns">
                  <NavLink to={`/document-library`}>
                    <button className="ascent-button ascent-button--header-buttons ascent-button--primary">
                      Cancel
                    </button>
                  </NavLink>
                  {isLoading ? (
                    <button className="ascent-button ascent-button--header-buttons ascent-button--secondary">
                      Please wait...
                    </button>
                  ) : (
                    <button
                      className={`ascent-button ascent-button--header-buttons ascent-button--secondary`}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="pt-5">
            <div className="create-doc-library user-details-wrapper mt-0">
              <div className="user-details-wrapper__form-wrapper">
                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label
                      htmlFor="state_id"
                      className="filter-form-wrappper__label filter-fields-label"
                    >
                      State <span>*</span>
                    </label>
                    <div className="filter-form-wrappper__location-input select-dd-new">
                      <InputField
                        inputType="INPUT_REACT_SELECT"
                        placeholder="Choose State"
                        className="form-control w-100"
                        name="state_id"
                        id="state_id"
                        label="State"
                        options={statesList}
                        value={statesList.filter(
                          (item: object) =>
                            getValue(item, `id`, "") ==
                            getValue(request, `state_id`, "")
                        )}
                        onChange={(e: any) => handleChangeSelect(e, "state_id")}
                        validator={simpleValidator}
                      />
                    </div>
                  </div>

                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label
                      htmlFor="tags_id"
                      className="filter-form-wrappper__label filter-fields-label"
                    >
                      Legislation <span>*</span>
                    </label>
                    <InputField
                      inputType="TEXT"
                      placeholder="Enter legislation"
                      className="form-control w-100"
                      name="legislation"
                      id="legislation"
                      value={getValue(request, `legislation`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      label="Legislation"
                      validator={simpleValidator}
                    />
                  </div>
                </div>

                <div className="user-details-wrapper__form">
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label
                      htmlFor="tags_id"
                      className="filter-form-wrappper__label filter-fields-label"
                    >
                      Rule <span>*</span>
                    </label>
                    <InputField
                      inputType="TEXT"
                      placeholder="Enter rule"
                      className="form-control w-100"
                      name="rule"
                      id="rule"
                      value={getValue(request, `rule`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      label="rule"
                      validator={simpleValidator}
                    />
                  </div>

                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <label
                      htmlFor="tags_id"
                      className="filter-form-wrappper__label filter-fields-label"
                    >
                      Form <span>*</span>
                    </label>
                    <InputField
                      inputType="TEXT"
                      placeholder="Enter form"
                      className="form-control w-100"
                      name="form"
                      id="form"
                      value={getValue(request, `form`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      label="form"
                      validator={simpleValidator}
                    />
                  </div>
                </div>

                <div className="user-details-wrapper__form w-100 d-flex">
                  <div className="user-details-wrapper__form-group form-group mb-3">
                    <div className="d-flex align-items-center">
                      <label
                        htmlFor="tags_id"
                        className="filter-form-wrappper__label filter-fields-label"
                      >
                        Applicable to all Clients <span>*</span>
                      </label>
                      <div className="custom-switch-wrapper">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={getValue(
                              request,
                              `applicable_to_all`,
                              false
                            )}
                            onChange={() =>
                              setRequest({
                                ...request,
                                applicable_to_all: !getValue(
                                  request,
                                  `applicable_to_all`,
                                  false
                                ),
                              })
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    {!getValue(request, `applicable_to_all`, false) && (
                      <div className="filter-form-wrappper__location-input select-dd-new">
                        <InputField
                          inputType="INPUT_REACT_SELECT"
                          placeholder="Choose Applicable to"
                          className="form-control w-100"
                          name="tags_id"
                          id="tags_id"
                          label="Tags"
                          options={tagsList}
                          value={selectedTagsListIds}
                          onChange={(e: any) => handleChangeTagsMulti(e)}
                          isMulti
                          // validator={simpleValidator}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="user-details-wrapper__form w-100 d-flex">
                  <div className="user-details-wrapper__form-group form-group mb-3 w-100">
                    <label>
                      Description <span>*</span>
                    </label>
                    <InputField
                      inputType="TEXTAREA"
                      placeholder="Enter Description"
                      className="form-control w-100"
                      name="description"
                      id="description"
                      value={getValue(request, `description`, "")}
                      onChange={(e: any) => handleChangeText(e)}
                      label="description"
                      validator={simpleValidator}
                    />
                  </div>
                </div>

                <div className="user-details-wrapper__form w-100 d-flex">
                  <div className="user-details-wrapper__form-group form-group mb-3 w-100">
                    <label>
                      Document <span>*</span>
                    </label>

                    <div>
                      <DragAndDropFileComponent
                        uploadFile={handleUploadDocumentsStore}
                        name="file"
                        types={fileTypes}
                        multiple
                        // className="d-none"
                        classes="bulk-upload_drag-n-drop-wrapper position-absolute"
                        fileName={getValue(request, `documents.name`, "")}
                        setImportTedFile={setImportTedFile}
                        file={"false"}
                      />

                      <div className="mt-3">
                      {getValue(params, `id`, "") ? (
                        <h6>Previous Documents</h6> ) : ""}

                        {getValue(previousDocuments, `length`, 0) > 0 ? (
                          previousDocuments.map((item: any, key: number) => {
                            return (
                              <div
                                key={key}
                                className="attach-files-wrapper__files cursor-pointer d-flex justify-content-between"
                              >
                                <p
                                  onClick={() =>
                                    handleDownloadOnClick(
                                      getValue(item, `url`, ""),
                                      getValue(item, `name`, "")
                                    )
                                  }
                                >
                                  {getValue(item, `name`, "")}
                                  {getValue(item, `extension`, "")}
                                </p>

                                
                                <div className="d-flex align-items-center">
                                {getValue(params, `id`, "") ? (
                                  <div className="d-flex me-3 align-items-center">
                                    <div className="custom-switch-wrapper">
                                      <label className="switch mb-0">
                                        <input
                                          type="checkbox"
                                          checked={false}
                                          onChange={() =>
                                            handleRadioChange(item, "previous")
                                          }
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>
                                    <label className="mb-0 me-2" htmlFor={`currentDoc-${item.id}`}>
                                      Current
                                    </label>

                                    <div className="custom-switch-wrapper">
                                      <label className="switch mb-0">
                                        <input
                                          type="checkbox"
                                          checked={true}
                                          // onChange={() =>
                                          //   handleRadioChange(item, "previous")
                                          // }
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>

                                    <label className="mb-0" htmlFor={`previousDoc-${item.id}`}>
                                      Previous
                                    </label>
                                  </div>
                                ) : (
                                  ""
                                )}
                                  <img
                                    src="/images/close-black.svg"
                                    className="img-fluid cursor-pointer"
                                    onClick={() =>
                                      handleRemoveUploadedFilesPrevious(
                                        getValue(item, `id`, "")
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <>
                            {getValue(params, `id`, "") ? 
                            <p className="text-center p-2">No Data</p> : ""}
                          </>
                        )}


                        {getValue(params, `id`, "") ? (
                        <h6>Current Documents</h6> ) : ""}
                        {getValue(currentDocuments, `length`, 0) > 0 ? (
                          currentDocuments.map((item: any, key: number) => {
                            return (
                              <div
                                key={key}
                                className="attach-files-wrapper__files cursor-pointer d-flex justify-content-between"
                              >
                                <p
                                  onClick={() =>
                                    handleDownloadOnClick(
                                      getValue(item, `url`, ""),
                                      getValue(item, `name`, "")
                                    )
                                  }
                                >
                                  {getValue(item, `name`, "")}
                                  {getValue(item, `extension`, "")}
                                </p>

                                
                                <div className="d-flex align-items-center">
                                {getValue(params, `id`, "") ? (
                                  <div className="d-flex me-3 align-items-center">
                                    <div className="custom-switch-wrapper">
                                      <label className="switch mb-0">
                                        <input
                                          type="checkbox"
                                          checked={true}
                                          // onChange={() =>
                                          //   handleRadioChange(item, "current")
                                          // }
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>
                                    <label className="mb-0 me-2" htmlFor={`currentDoc-${item.id}`}>
                                      Current
                                    </label>

                                    <div className="custom-switch-wrapper">
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          checked={false}
                                          onChange={() =>
                                            handleRadioChange(item, "current")
                                          }
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>

                                    <label className="mb-0 me-2" htmlFor={`previousDoc-${item.id}`}>
                                      Previous
                                    </label>
                                  </div>
                                ) : (
                                  ""
                                )}
                                  <img
                                    src="/images/close-black.svg"
                                    className="img-fluid cursor-pointer"
                                    onClick={() =>
                                      handleRemoveUploadedFilesCurrent(
                                        getValue(item, `id`, "")
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <>
                            {getValue(params, `id`, "") ? 
                            <p className="text-center p-2">No Data</p> : ""}
                          </>
                        )}
                        {/* {documentLength > 0 ? (
                          documents.map((item: any, key: number) => {
                            return (
                              <div
                                key={key}
                                className="attach-files-wrapper__files cursor-pointer d-flex justify-content-between"
                              >
                                <p
                                  onClick={() =>
                                    handleDownloadOnClick(
                                      getValue(item, `url`, ""),
                                      getValue(item, `name`, "")
                                    )
                                  }
                                >
                                  {getValue(item, `name`, "")}
                                  {getValue(item, `extension`, "")}
                                </p>

                                {getValue(params, `id`, "") ? (
                                  <>
                                    <input
                                      type="radio"
                                      name={`docType-${item.id}`}
                                      id={`currentDoc-${item.id}`}
                                      value="currentDoc"
                                      onChange={(event) =>
                                        handleRadioChange(item, event)
                                      }
                                    />
                                    <label htmlFor={`currentDoc-${item.id}`}>
                                      Current Doc
                                    </label>

                                    <input
                                      type="radio"
                                      name={`docType-${item.id}`}
                                      id={`previousDoc-${item.id}`}
                                      value="previousDoc"
                                      onChange={(event) =>
                                        handleRadioChange(item, event)
                                      }
                                    />
                                    <label htmlFor={`previousDoc-${item.id}`}>
                                      Previous Doc
                                    </label>
                                  </>
                                ) : (
                                  ""
                                )}

                                <div>
                                  <img
                                    src="/images/close-black.svg"
                                    className="img-fluid cursor-pointer"
                                    onClick={() =>
                                      handleRemoveUploadedFiles(
                                        getValue(item, `id`, "")
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p className="text-center mt-2"></p>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DocumentLibraryDetails;
