import { getValue } from "@utils/lodash";
import "./bulk-upload.scss";

export default function CheckPointMigrate(props: any) {
	return (
		<div className="add-filter-wrapper__filter-container">
			<div
				className="d-flex"
				onClick={() =>
					!getValue(props, `loading`, false) && props.handleMigration()
				}
			>
				<img
					src="/images/migrate.svg"
					className="img-fluid add-filter-wrapper__upload-image"
				/>
			</div>
			<h6 className="add-filter-wrapper__filter-title">
				{!getValue(props, `loading`, false) ? "MIGRATE" : "Please Wait..."}
			</h6>
		</div>
	);
}
