import React from "react";
import DatePicker from "react-datepicker";

function CommonDateRange(props: any) {
  const {
    from_date,
    to_date,
    handleDate,
    handleCancelDate,
    handleSubmitDate,
    datePickerRef,
  } = props;
  return (
    <div>
      <div className="filter-form-wrappper__container d-flex">
        <div className="no-bordered-input-calendar arrow-input-calendar d-flex align-items-center">
          <DatePicker
            ref={datePickerRef}
            selectsRange={true}
            startDate={from_date}
            endDate={to_date}
            className="react-date-picker__inputGroup__input"
            shouldCloseOnSelect={false}
            onChange={(update: any) => {
              handleDate(update);
            }}
            dateFormat="d MMM yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          >
            <div className="clearfix"></div>
            <div className="calendar-with-custom-btn-wrapper">
              <button
                className="dashboard-wrapper__cancel-btn"
                onClick={handleCancelDate}
              >
                Cancel
              </button>
              <button
                className="dashboard-wrapper__apply-btn"
                onClick={handleSubmitDate}
              >
                Apply
              </button>
            </div>
          </DatePicker>
          <div className="datepicker-arrow-holder">
            <img src="/images/arrow-drop.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommonDateRange;
