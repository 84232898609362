import Open from '@pages/Support/Open';
import OpenDetails from '@pages/Support/Open/OpenDetails/OpenDetails';

export const SupportRoutes = [
    {
        path: '/support/open',
        name: 'support',
        component: Open ,
      },
      {
        path: '/support/details',
        name: 'support',
        component: OpenDetails ,
      },
      {
        path: '/support/details/:id',
        name: 'support',
        component: OpenDetails ,
      },
];
