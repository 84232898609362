import { config } from "../env";
import {
	Delete,
	get,
	patch,
	post,
	postFileUpload,
} from "./helpers/http-handler";

export const adminCreateCheckpoint = (payload: object) =>
	post(`${config.API_URL}/checkpoint`, payload);

export const adminGetCheckpoint = (queryRequest: string) =>
	get(`${config.API_URL}/checkpoint?${queryRequest}`);

export const adminGetSpecificCheckpoint = (id: string) =>
	get(`${config.API_URL}/checkpoint/${id}`);

export const adminUpdateSpecificCheckpoint = (id: string, payload: object) =>
	patch(`${config.API_URL}/checkpoint/${id}`, payload);

export const adminDeleteSpecificCheckpoint = (id: string) =>
	Delete(`${config.API_URL}/checkpoint/${id}`);

export const adminUpdateBulkStatusCheckpoint = (payload: object) =>
	patch(`${config.API_URL}/checkpoint/bulk/active-inactive`, payload);

export const adminImportCheckpoint = (payload: object) =>
	postFileUpload(`${config.API_URL}/checkpoint/import/data`, payload);

	export const adminImportDataCheckpoint = (queryRequest: string,payload: object) =>
	postFileUpload(`${config.API_URL}/checkpoint/import/data/client?${queryRequest}`, payload);

export const adminExportPoint = (queryRequest: string) =>
	get(`${config.API_URL}/checkpoint/export/data?${queryRequest}`);

export const adminImportLegalUpdate = (payload: object) =>
	postFileUpload(`${config.API_URL}/legal-update/import/data`, payload);

export const adminExportLegalUpdate = (payload: object) =>
	post(`${config.API_URL}/legal-update/export/data`, payload);