import * as React from 'react';
import DashboardLayout from '@layouts/withoutNaviagtion/withoutNavigationLayout';
import './index.scss'
interface INotFoundPageProps {
}

const NotFoundPage: React.FunctionComponent<INotFoundPageProps> = (props) => {
  return(
      <DashboardLayout>
      <div className="loading-wrapper  d-flex w-100 my-5">
			<div className="loading-wrapper__details">
				<img src="/gif/404.gif" className="no-file-image img-fluid" />
			</div>
		</div>
      </DashboardLayout>
  ) ;
};

export default NotFoundPage;
