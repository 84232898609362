//import { NavLink } from 'react-router-dom';
import Switch from "@components/list-management/switch";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import "./clientlist-details.scss";
import ClientListNavigation from "@components/ClientList/clientlist-navigation";
import SimpleReactValidator from "simple-react-validator";
import React, { useState, useRef, useEffect } from "react";
import InputField from "@_common/InputRuleFeild";
import { getValue } from "@utils/lodash";
import {
  adminGetCitiesListManagement,
  adminGetCountriesListManagement,
  adminGetListManagementGroupCode,
  adminGetStatesListManagement,
} from "@services/list-management.service";
import { NavLink } from "react-router-dom";
import {
  adminCreateOrganization,
  adminGetSpecificOrganization,
  adminUpdateSpecificOrganization,
  getOrgRootDomain,
} from "@services/client-list.service";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import countries from "@_common/contry.json";
import _ from "lodash";
import { useCallbackPrompt } from "@prompt/useCallbackPrompt";
import PromptModal from "@prompt/modal";
import NoAccessPage from "@components/common/NoAccess";
import { removeNullOrUndefinedProperties } from "@_common/text-helpers";
import { config } from "../../../../env";

interface IClientlistDetailsProps {}

const ClientlistDetails: React.FunctionComponent<IClientlistDetailsProps> = (
  props: any
) => {
  let navigate = useNavigate();
  let params = useParams();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [request, setRequest] = React.useState({
    organisation_name: "",
    domain_name: "",
    organisation_logo: "",
    industry_id: "",
    business_type_id: "",
    address1: "",
    address2: "",
    country_id: 101,
    state_id: "",
    city_id: "",
    pincode: "",
    phone1: "",
    phone2: "",
    email1: "",
    email2: "",
    country_code_phone1: "",
    country_code_phone2: "",
    is_active: false,
    is_onprem: false,
  });

  const [dirtyFields, setDirtyFields] = useState({
    organisation_name: "",
    domain_name: "",
    organisation_logo: "",
    industry_id: "",
    business_type_id: "",
    address1: "",
    address2: "",
    country_id: 101,
    state_id: "",
    city_id: "",
    pincode: "",
    phone1: "",
    phone2: "",
    email1: "",
    email2: "",
    country_code_phone1: "",
    country_code_phone2: "",
    is_active: false,
    is_onprem: false,
  });
  const [submitLoading, setSubmitLoading] = useState(false);

  const [countryList, setCountryList] = useState([]);
  const [statesList, setStateList] = useState([]);
  const [citiesList, setCityList] = useState([]);

  const [mobile_area_code_1, setMobile_area_code_1] = useState<any>("");
  const [mobile_area_code_2, setMobile_area_code_2] = useState<any>("");

  const [industryTypeList, setIndustryTypeList] = useState([]);
  const [businessTypeList, setBusinessTypeList] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getCountriesList();
    getIndustryList();
    getBusinessTypeList();
    if (getValue(params, `id`, "")) {
      getData(getValue(params, `id`, ""));
    }else{
      getDomain()
    }
  }, [props.permissionAPITriggered]);
  const getDomain = async() => {
    let response = await getOrgRootDomain("");
    setClientRootDomain(getValue(response, `data.client_root_domain`, ""));
  }
  useEffect(() => {
    // getCountriesList();
  }, [window.location.href, props.permissionAPITriggered]);
  useEffect(() => {
    getStatesList(request.country_id);
    if (request.state_id) {
      getCitiesList(parseInt(request.state_id));
    }
  }, [request.country_id, request.state_id]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [clientRootDomain, setClientRootDomain] = useState("");
  const getData = async (id: string) => {
    try {
      let response = await getOrgRootDomain("");
      setClientRootDomain(getValue(response, `data.client_root_domain`, ""));
      setIsLoading(true);
      let resp = await adminGetSpecificOrganization(id);
      if (resp) {
        let countryCode1 = countries.filter(
          (item: object) =>
            getValue(item, `dial_code`, "") ===
            getValue(resp, `data.country_code_phone1`, "")
        );
        let countryCode2 = countries.filter(
          (item: object) =>
            getValue(item, `dial_code`, "") ===
            getValue(resp, `data.country_code_phone2`, "")
        );
        // mobile_area_code
        let code1: any =
          countryCode1.length > 0 &&
          countryCode1.map((item) => ({
            id: getValue(item, `id`, ""),
            country: item.code,
            label: <>{item.dial_code + " " + item.name}</>,
            value: item.dial_code + " " + item.name,
          }));

        let code2: any =
          countryCode2.length > 0 &&
          countryCode2.map((item) => ({
            id: getValue(item, `id`, ""),
            country: item.code,
            label: <>{item.dial_code + " " + item.name}</>,
            value: item.dial_code + " " + item.name,
          }));

        setRequest({
          ...request,
          organisation_name: getValue(resp, `data.organisation_name`, ""),
          organisation_logo:
            "https://is4-ssl.mzstatic.com/image/thumb/Purple126/v4/47/b4/8a/47b48af8-315c-61a6-788b-50908bc11f24/source/512x512bb.jpg",
          industry_id: getValue(resp, `data.industry.id`, ""),
          business_type_id: getValue(resp, `data.business_type.id`, ""),
          address1: getValue(resp, `data.address1`, ""),
          address2: getValue(resp, `data.address2`, ""),
          country_id: getValue(resp, `data.country.id`, ""),
          state_id: getValue(resp, `data.state.id`, ""),
          city_id: getValue(resp, `data.city.id`, ""),
          pincode: getValue(resp, `data.pincode`, ""),
          domain_name: getValue(resp, `data.domain_name`, "")
            .replace(/\s/g, "")
            .split(`.${getValue(response, `data.client_root_domain`, "")}`)[0],
          is_active: getValue(resp, `data.is_active`, ""),
          phone1: getValue(resp, `data.phone1`, ""),
          phone2: getValue(resp, `data.phone2`, ""),
          country_code_phone1: getValue(resp, `data.country_code_phone1`, ""),
          country_code_phone2: getValue(resp, `data.country_code_phone2`, ""),
          email1: getValue(resp, `data.email1`, ""),
          email2: getValue(resp, `data.email2`, ""),
        });

        // console.log()

        setDirtyFields({
          ...dirtyFields,
          organisation_name: getValue(resp, `data.organisation_name`, ""),
          organisation_logo:
            "https://is4-ssl.mzstatic.com/image/thumb/Purple126/v4/47/b4/8a/47b48af8-315c-61a6-788b-50908bc11f24/source/512x512bb.jpg",
          industry_id: getValue(resp, `data.industry.id`, ""),
          business_type_id: getValue(resp, `data.business_type.id`, ""),
          address1: getValue(resp, `data.address1`, ""),
          address2: getValue(resp, `data.address2`, ""),
          country_id: getValue(resp, `data.country.id`, ""),
          state_id: getValue(resp, `data.state.id`, ""),
          city_id: getValue(resp, `data.city.id`, ""),
          pincode: getValue(resp, `data.pincode`, ""),
          domain_name: getValue(resp, `data.domain_name`, ""),
          is_active: getValue(resp, `data.is_active`, ""),
          phone1: getValue(resp, `data.phone1`, ""),
          phone2: getValue(resp, `data.phone2`, ""),
          country_code_phone1: getValue(resp, `data.country_code_phone1`, ""),
          country_code_phone2: getValue(resp, `data.country_code_phone2`, ""),
          email1: getValue(resp, `data.email1`, ""),
          email2: getValue(resp, `data.email2`, ""),
        });

        setMobile_area_code_1(
          getValue(code1, `length`, 0) > 0 ? getValue(code1, `[${0}]`, "") : ""
        );
        setMobile_area_code_2(
          getValue(code2, `length`, 0) > 0 ? getValue(code2, `[${0}]`, "") : ""
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getCountriesList = async () => {
    try {
      let resp = await adminGetCountriesListManagement();
      if (resp) {
        setCountryList(
          getValue(resp, `data.countries`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const getStatesList = async (countryId: number) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    try {
      let resp = await adminGetStatesListManagement(countryId);
      if (resp) {
        setStateList(
          getValue(resp, `data.states`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const getCitiesList = async (stateId: number) => {
    try {
      let resp = await adminGetCitiesListManagement(stateId);
      if (resp) {
        setCityList(
          getValue(resp, `data.cities`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const getIndustryList = async () => {
    try {
      let resp = await adminGetListManagementGroupCode("industry");
      if (resp) {
        setIndustryTypeList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            id: getValue(item, `id`, ""),
            is_active: getValue(item, `is_active`, ""),
            text: getValue(item, `text`, ""),
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const getBusinessTypeList = async () => {
    try {
      let resp = await adminGetListManagementGroupCode("business-type");
      if (resp) {
        setBusinessTypeList(
          getValue(resp, `data.listItems`, []).map((item: object) => ({
            id: getValue(item, `id`, ""),
            is_active: getValue(item, `is_active`, ""),
            text: getValue(item, `text`, ""),
            value: getValue(item, `text`, ""),
            label: getValue(item, `text`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    var spanElement = document.getElementById("defaultDomainName");
    var domainNameUrl = spanElement?.innerHTML;

    let payload: object | any = {
      organisation_name: request.organisation_name,
      organisation_logo:
        "https://is4-ssl.mzstatic.com/image/thumb/Purple126/v4/47/b4/8a/47b48af8-315c-61a6-788b-50908bc11f24/source/512x512bb.jpg",
      industry_id: request.industry_id,
      business_type_id: request.business_type_id,
      address1: request.address1,
      address2: request.address2,
      country_id: request.country_id,
      state_id: request.state_id,
      city_id: request.city_id,
      pincode: parseInt(request.pincode),
      phone1: request.phone1,
      country_code_phone1: getValue(mobile_area_code_1, `value`, "").split(
        " "
      )[0],
      phone2: request.phone2,
      country_code_phone2: getValue(mobile_area_code_2, `value`, "").split(
        " "
      )[0],
      email1: request.email1,
      email2: request.email2,
      // domain_name: request.domain_name.replaceAll(/\s/g, "") + domainNameUrl,
      domain_name: request.domain_name + `.` + clientRootDomain,
      is_active: request.is_active,
      is_onprem: false,
    };

    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (getValue(params, `id`, "")) {
        try {
          setSubmitLoading(true);
          let resp = await adminUpdateSpecificOrganization(
            getValue(params, `id`, ""),
            removeNullOrUndefinedProperties(payload)
          );
          if (resp) {
            setSubmitLoading(false);
            // confirmNavigation();
            toast.success(getValue(resp, `message`, ""));
            getData(getValue(params, `id`, ""));
            // navigate(`/clientlist/active`);
          }
        } catch (error) {
          setSubmitLoading(false);
        }
      } else {
        // removing null values
        // Object.keys(payload).forEach((key) => {
        // 	if (!payload[key] || payload[key] === undefined) {
        // 		delete payload[key];
        // 	}
        // });
        try {
          setSubmitLoading(true);
          let resp = await adminCreateOrganization(
            removeNullOrUndefinedProperties(payload)
          );
          if (resp) {
            // confirmNavigation();
            toast.success(getValue(resp, `message`, ""));
            navigate(`/clientlist/active`);
          }
        } catch (error) {
          setSubmitLoading(false);
        }
      }
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };
  const handleChangeSelect = (e: any, type: string) => {
    if (type === "country_id") {
      getStatesList(e.id);
      setRequest({
        ...request,
        country_id: e.id,
      });
    }
    if (type === "state_id") {
      getCitiesList(e.id);
      setRequest({
        ...request,
        state_id: e.id,
      });
    }
    if (type === "industry_id") {
      setRequest({
        ...request,
        industry_id: e.id,
      });
    }
    if (type === "business_type_id") {
      setRequest({
        ...request,
        business_type_id: e.id,
      });
    }
    if (type === "city_id") {
      setRequest({
        ...request,
        city_id: e.id,
      });
    }
    // setRequest({
    //   ...request,
    //   [type]: e.id,
    // });
  };

  const onChange = (id: any, status: boolean) => {
    setRequest({
      ...request,
      is_active: status,
    });
  };
  /* -------------------------------------------------------------------------- */
  /*                                Prompt Section                              */
  /* -------------------------------------------------------------------------- */
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    setIsChanged(
      _.isEqualWith(request, dirtyFields, (a: any, b: any) => {
        // if both are null or equal to an empty string then they are equal
        if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
      })
    );
  });
  // const [showPrompt, confirmNavigation, cancelNavigation]: any =
  //   useCallbackPrompt(!isChanged);

  function clsAlphaNoOnly(e: any) {
    e.preventDefault();
    // Accept only alpha numerics, no special characters
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    const { name, value } = e.target;
    if (value) {
      if (regex.test(e.target.value)) {
        setRequest({
          ...request,
          [name]: value,
        });
      }
      return false;
    } else {
      setRequest({
        ...request,
        [name]: "",
      });
    }
  }
  function isValid(str: string) {
    return !/[~`!@#$%\^&*()+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
  }
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes("client_active") ? (
        <NoAccessPage />
      ) : (
        <>
          {/* <PromptModal
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          /> */}
          <ClientListNavigation id={params.id} domain={params.domain} />
          <section className="client-list-details-wrapper">
            <div className="client-list-details-wrapper__organization-info d-flex align-items-center justify-content-between">
              <h3 className="client-list-details-wrapper__organization-title">
                Organization Information
                {/* - ID 7878787 */}
              </h3>
              {getValue(props, `permissions`, []).includes("update") && (
                <div className="client-list-details-wrapper__cta-wrapper">
                  <NavLink to={`/clientlist/active`}>
                    <button className="client-list-details-wrapper__cta-btn client-list-details-wrapper__cta-btn--secondary">
                      Cancel
                    </button>
                  </NavLink>
                  {submitLoading ? (
                    <button className="client-list-details-wrapper__cta-btn client-list-details-wrapper__cta-btn--primary">
                      Please wait...
                    </button>
                  ) : (
                    <button
                      onClick={handleSubmit}
                      disabled={isChanged}
                      className={`client-list-details-wrapper__cta-btn client-list-details-wrapper__cta-btn--${
                        !isChanged ? "primary" : "secondary"
                      }`}
                      // className={`client-list-details-wrapper__cta-btn client-list-details-wrapper__cta-btn--${!isChanged ? "primary" : "secondary"}`}
                    >
                      {getValue(params, `id`, "") ? "Update" : "Save"}
                    </button>
                  )}
                </div>
              )}
            </div>

            <div className="client-list-details-wrapper__form">
              <div className="d-flex align-items-start justify-content-between">
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="name"
                  >
                    Organization Name *
                  </label>
                  <InputField
                    inputType="TEXT"
                    id="organisation_name"
                    name="organisation_name"
                    placeholder="Enter Organisation Name"
                    className="client-list-details-wrapper__form-control form-control"
                    onChange={handleChange}
                    value={getValue(request, `organisation_name`, "")}
                    label="Organization Name"
                    validator={simpleValidator}
                  />
                </div>
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="name"
                  >
                    Domain Name *
                  </label>
                  <div className="domain-name-seperator input-group">
                    <InputField
                      inputType="TEXT_ALPHA"
                      id="domain_name"
                      name="domain_name"
                      placeholder="Enter Domain Name"
                      className="form-control client-list-details-wrapper__form-control"
                      // onChange={clsAlphaNoOnly}
                      label="Domain Name"
                      onChange={(e: any) =>
                        isValid(e.target.value) &&
                        setRequest({
                          ...request,
                          domain_name: e.target.value.toLowerCase(),
                        })
                      }
                      value={getValue(request, `domain_name`, "")}
                      validator={simpleValidator}
                      // defaultDomainName={config.HOSTNAME}
                      defaultDomainName={clientRootDomain}
                      disabled={getValue(params, `id`, "") ? true : false}
                    />
                    {/* <span className="input-group-text" id="defaultDomainName">.dev.lexcare.in</span> */}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="industry"
                  >
                    Industry *
                  </label>
                  <InputField
                    inputType="INPUT_REACT_SELECT"
                    placeholder="Enter Industry"
                    className="form-control w-100"
                    name="industry_id"
                    id="industry_id"
                    options={industryTypeList}
                    value={industryTypeList.filter(
                      (item: object) =>
                        getValue(item, `id`, "") ==
                        getValue(request, `industry_id`, "")
                    )}
                    onChange={(e: any) => handleChangeSelect(e, "industry_id")}
                    label="Industry"
                    validator={simpleValidator}
                  />
                </div>
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="business-type"
                  >
                    Business Type *
                  </label>
                  <InputField
                    inputType="INPUT_REACT_SELECT"
                    placeholder="Enter Business Type"
                    className="form-control w-100"
                    name="business_type_id"
                    id="business_type_id"
                    options={businessTypeList}
                    value={businessTypeList.filter(
                      (item: object) =>
                        getValue(item, `id`, "") ==
                        getValue(request, `business_type_id`, "")
                    )}
                    onChange={(e: any) =>
                      handleChangeSelect(e, "business_type_id")
                    }
                    label="Business Type"
                    validator={simpleValidator}
                  />
                </div>
              </div>
              <div className="client-list-details-wrapper__form-group  client-list-details-wrapper__form-group--company-add form-group">
                <label
                  className="client-list-details-wrapper__label"
                  htmlFor="address1"
                >
                  Company Address ( Line 1) *
                </label>
                <InputField
                  inputType="TEXTAREA"
                  id="address1"
                  name="address1"
                  placeholder="Enter Company Address ( Line 1)"
                  className="form-control client-list-details-wrapper__form-control"
                  onChange={handleChange}
                  value={getValue(request, `address1`, "")}
                  label="Company Address ( Line 1)"
                  validator={simpleValidator}
                />
              </div>
              <div className="client-list-details-wrapper__form-group client-list-details-wrapper__form-group--company-add form-group">
                <label
                  className="client-list-details-wrapper__label"
                  htmlFor="address2"
                >
                  Company Address ( Line 2)
                </label>
                <InputField
                  inputType="TEXTAREA"
                  id="address2"
                  name="address2"
                  placeholder="Enter Company Address ( Line 2)"
                  className="client-list-details-wrapper__form-control form-control"
                  onChange={handleChange}
                  value={getValue(request, `address2`, "")}
                  label="Company Address ( Line 1)"
                  // validator={simpleValidator}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="country"
                  >
                    Country *
                  </label>
                  <InputField
                    inputType="INPUT_REACT_SELECT"
                    placeholder="Enter Country"
                    className="form-control w-100"
                    name="country_id"
                    id="country_id"
                    options={countryList}
                    value={countryList.filter(
                      (item: object) =>
                        getValue(item, `id`, "") ==
                        getValue(request, `country_id`, "")
                    )}
                    onChange={(e: any) => handleChangeSelect(e, "country_id")}
                    label="Country"
                    validator={simpleValidator}
                  />
                </div>
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="state"
                  >
                    State *
                  </label>
                  <InputField
                    inputType="INPUT_REACT_SELECT"
                    placeholder="Enter State"
                    className="form-control w-100"
                    name="state_id"
                    id="state_id"
                    label="Last Name"
                    options={statesList}
                    value={statesList.filter(
                      (item: object) =>
                        getValue(item, `id`, "") ==
                        getValue(request, `state_id`, "")
                    )}
                    onChange={(e: any) => handleChangeSelect(e, "state_id")}
                    validator={simpleValidator}
                  />
                </div>
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="city"
                  >
                    City *
                  </label>
                  <InputField
                    inputType="INPUT_REACT_SELECT"
                    placeholder="Enter State"
                    className="form-control w-100"
                    name="city_id"
                    id="city_id"
                    label="Last Name"
                    options={citiesList}
                    value={citiesList.filter(
                      (item: object) =>
                        getValue(item, `id`, "") ==
                        getValue(request, `city_id`, "")
                    )}
                    onChange={(e: any) => handleChangeSelect(e, "city_id")}
                    validator={simpleValidator}
                  />
                </div>
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="pincode"
                  >
                    Pincode *
                  </label>
                  <InputField
                    inputType="NUMBER"
                    id="pincode"
                    name="pincode"
                    placeholder="Enter Pincode"
                    className="form-control client-list-details-wrapper__form-control"
                    onChange={handleChange}
                    value={getValue(request, `pincode`, "")}
                    label="Pincode"
                    validator={simpleValidator}
                  />
                </div>
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="phone1"
                  >
                    Phone Number 1 Country Code *
                  </label>
                  <InputField
                    inputType="INPUT_REACT_SELECT_COUNTYCODE"
                    placeholder="Enter Country Code"
                    className="form-control w-100"
                    name="mobile_area_code"
                    id="mobile_area_code"
                    label="Country Code"
                    value={mobile_area_code_1}
                    onChange={(e: any) => setMobile_area_code_1(e)}
                    validator={simpleValidator}
                  />
                </div>
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="phone1"
                  >
                    Phone Number 1 *
                  </label>
                  <InputField
                    inputType="PHONE"
                    id="phone1"
                    name="phone1"
                    placeholder="Enter Phone Number"
                    className="form-control client-list-details-wrapper__form-control"
                    onChange={handleChange}
                    value={getValue(request, `phone1`, "")}
                    label="Phone Number"
                    validator={simpleValidator}
                  />
                </div>
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="phone1"
                  >
                    Phone Number 2 Country Code
                  </label>
                  <InputField
                    inputType="INPUT_REACT_SELECT_COUNTYCODE"
                    placeholder="Enter Country Code"
                    className="form-control w-100"
                    name="mobile_area_code"
                    id="mobile_area_code"
                    label="Country Code"
                    value={mobile_area_code_2}
                    onChange={(e: any) => setMobile_area_code_2(e)}
                    // validator={simpleValidator}
                  />
                </div>
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="phone2"
                  >
                    Phone Number 2
                  </label>
                  <InputField
                    inputType="PHONE"
                    id="phone2"
                    name="phone2"
                    placeholder="Enter Alternative Number"
                    className="form-control client-list-details-wrapper__form-control"
                    onChange={handleChange}
                    value={getValue(request, `phone2`, "")}
                    label="Alternative Number"
                    // validator={simpleValidator}
                  />
                </div>
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="email1"
                  >
                    Email Address 1 *
                  </label>
                  <InputField
                    inputType="EMAIL"
                    id="email1"
                    name="email1"
                    placeholder="Enter Primary Email"
                    className="form-control client-list-details-wrapper__form-control"
                    onChange={handleChange}
                    value={getValue(request, `email1`, "")}
                    label="Email"
                    validator={simpleValidator}
                  />
                </div>
                <div className="client-list-details-wrapper__form-group form-group">
                  <label
                    className="client-list-details-wrapper__label"
                    htmlFor="email2"
                  >
                    Email Address 2
                  </label>
                  <InputField
                    inputType="EMAIL"
                    id="email2"
                    name="email2"
                    placeholder="Enter Secondary Email"
                    className="form-control client-list-details-wrapper__form-control"
                    onChange={handleChange}
                    value={getValue(request, `email2`, "")}
                    label="email2"
                    // validator={simpleValidator}
                  />
                </div>
              </div>
              {params.id && (
                <div className="client-list-details-wrapper__form-group">
                  <div className="client-list-details-wrapper__psycology-header d-flex align-items-center justify-content-between">
                    <h4 className="client-list-details-wrapper__psycology-header-title">
                      Deactivate Organisation Account
                    </h4>
                    <Switch
                      is_active={getValue(request, `is_active`, false)}
                      onChange={onChange}
                      id={params.id}
                    />
                  </div>
                  <div className="client-list-details-wrapper__psycology d-flex">
                    <img src="/images/psychology.svg" className="img-fluid" />
                    <p className="client-list-details-wrapper__psycology-text">
                      Deactivating an organisation will deactivate all users
                      from this organisation and this organisation will no
                      longer be able to access the application
                    </p>
                  </div>
                </div>
              )}
              <img
                src="/images/profile-illustration.svg"
                className="img-fluid client-list-details-wrapper__illustration"
              />
            </div>
          </section>
        </>
      )}
    </DashboardLayout>
  );
};

export default ClientlistDetails;
