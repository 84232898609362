export const formatText = (str: string) => {
  const arr = str && str.split("_");
  const result = [];
  for (const word of arr) {
    result.push(
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // 👈️ lowercase remainder
    );
  }
  return result.join(" ");
};

export const formatSmallString = (str: string) => {
  let length = str ? str.length : 0;
  return length > 30 ? str.substring(0, 30).concat("...") : str;
};

export const stringToFixed = (value: any, decimal: number) => {
  return parseFloat(value) ? parseFloat(value).toFixed(decimal) : value;
};
