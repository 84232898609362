import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";
import TimezonePicker from "react-timezone";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import DatePicker from "react-datepicker";
import Select from "react-select";
import countries from "./contry.json";
import { getValue } from "@utils/lodash";

const InputField = (props) => {
	// time picker for train
	const [startDate, setStartDate] = useState(new Date());
	// for country currency code and flags no nedd to send options :)
	const [option, setOption] = useState([]);
	useEffect(() => {
		let options = [];
		countries.map((item) => {
			options.push({
				id: item.id,
				country: item.code,
				label: (
					<>
						{/* <Flag code={item.code} height="16" />{" "} */}
						{item.dial_code + " " + item.name}
					</>
				),
				value: item.dial_code + " " + item.name,
			});
		});
		setOption(options);
	}, []);

	switch (props.inputType) {
		case "TEXT":
			return (
				<div className="form-group m-0">
					<Input
						type="text"
						id={props.id}
						name={props.name}
						onChange={(e) => props.onChange(e, props.setPath)}
						value={props.value}
						placeholder={props.placeholder}
						// autoComplete="off"
						autoComplete="new-password"
						disabled={props.disabled}
					/>
					<label className="on-hover" htmlFor={props.id}>
						{/* {props.label} */}
						{props.required ? <span className="form-imp">*</span> : ""}
					</label>
					{props.validator && (
						<span className="form-error">
							{props.validator.current.message(
								props.label,
								props.value,
								"required"
							)}
						</span>
					)}
				</div>
			);

			case "TEXT_ALPHA":
				return (
					<div className="form-group input-group">
						<div className="input-group">
							<Input
								type="text"
								id={props.id}
								name={props.name}
								onChange={(e) => props.onChange(e, props.setPath)}
								value={props.value}
								placeholder={props.placeholder}
								// autoComplete="off"
								autoComplete="new-password"
								disabled={props.disabled}
							/>
							<span className="input-group-text" id="defaultDomainName">{props.defaultDomainName}</span>
						</div>
						<label className="on-hover" htmlFor={props.id}>
							{/* {props.label} */}
							{props.required ? <span className="form-imp">*</span> : ""}
						</label>

						{props.validator && (
							<span className="form-error">
								{props.validator.current.message(
									props.label,
									props.value,
									"required|alpha_num"
								)}
							</span>
						)}
					</div>
				);
		case "TEXTAREA":
			return (
				<div className="form-group">
					<Input
						type="textarea"
						id={props.id}
						name={props.name}
						onChange={(e) => props.onChange(e, props.setPath)}
						value={props.value}
						placeholder={props.placeholder}
						// autoComplete="off"
						rows={props.rows ? props.rows : 3}
						autoComplete="new-password"
						disabled={props.disabled}
					/>
					<label className="on-hover" htmlFor={props.id}>
						{/* {props.label} */}
						{props.required ? <span className="form-imp">*</span> : ""}
					</label>
					{props.validator && (
						<span className="form-error">
							{props.validator.current.message(
								props.label,
								props.value,
								"required"
							)}
						</span>
					)}
				</div>
			);
		case "TEXTALPHA":
			return (
				<div className="form-group">
					<Input
						type="text"
						id={props.id}
						name={props.name}
						onChange={(e) => props.onChange(e, props.setPath)}
						value={props.value}
						placeholder={props.placeholder}
						autoComplete="off"
					/>
					<label className="on-hover" htmlFor={props.id}>
						{/* {props.label}{' '} */}
						{props.required ? <span className="form-imp">*</span> : ""}
					</label>
					{props.validator && (
						<span className="form-error">
							{props.validator.current.message(
								props.label,
								props.value,
								"required|alpha"
							)}
						</span>
					)}
				</div>
			);
		case "EMAIL":
			return (
				<div className="form-group">
					<Input
						type="email"
						// autocomplete="new-password"
						id={props.id}
						name={props.name}
						onChange={(e) => props.onChange(e, props.setPath)}
						value={props.value}
						placeholder={props.placeholder}
						onKeyDown={props.onKeyDown}
					/>
					<label className="on-hover" htmlFor={props.id}>
						{/* {props.label}{' '} */}
						{props.required ? <span className="form-imp">*</span> : ""}
					</label>
					{props.validator && (
						<span className="form-error">
							{props.validator.current.message(
								props.label,
								props.value,
								"required|email"
							)}
						</span>
					)}
				</div>
			);
		case "NUMBER":
			return (
				<div className="form-group mb-0">
					<Input
						autoComplete="off"
						type="number"
						id={props.id}
						name={props.name}
						onChange={(e) => props.onChange(e, props.setPath)}
						value={props.value}
						placeholder={props.placeholder}
					/>
					<label className="on-hover" htmlFor={props.id}>
						{/* {props.label}{' '} */}
						{props.required ? <span className="form-imp">*</span> : ""}
					</label>
					{props.validator && (
						<span className="form-error">
							{props.validator.current.message(
								props.label,
								props.value,
								"required|integer"
							)}
						</span>
					)}
				</div>
			);
		case "PHONE":
			return (
				<div className="form-group mb-0">
					<Input
						autoComplete="off"
						type="number"
						id={props.id}
						name={props.name}
						onChange={(e) => props.onChange(e, props.setPath)}
						value={props.value}
						placeholder={props.placeholder}
					/>
					<label className="on-hover" htmlFor={props.id}>
						{/* {props.label}{' '} */}
						{props.required ? <span className="form-imp">*</span> : ""}
					</label>
					{props.validator && (
						<span className="form-error">
							{props.validator.current.message(
								props.label,
								props.value,
								"required|integer|size:10"
							)}
						</span>
					)}
				</div>
			);

		// case "PHONE_NUMBER":
		//   return (
		//     <div className="form-group">
		//       <Input
		//         autoComplete="off"
		//         type="number"
		//         id={props.id}
		//         name={props.name}
		//         onChange={(e) => props.onChange(e, props.setPath)}
		//         value={props.value}
		//         placeholder={props.placeholder}
		//       />
		//       <label className="on-hover" htmlFor={props.id}>
		//         {props.label}
		//       </label>
		//       {props.validator && (
		//         <span className="form-error">
		//           {props.validator.current.message(
		//             props.label,
		//             props.value,
		//             "required|integer|size:10"
		//           )}
		//         </span>
		//       )}
		//     </div>
		//   );
		case "DATETYPE":
			return (
				<div className="form-group react-datepicker-single">
					<DatePicker
						className="form-control"
						selected={props.value}
						onChange={(date) => props.onChange(date)}
						startDate={props.startDate}
						dropdownMode="select"
						maxDate={props.maxDate}
						minDate={props.minDate}
						placeholderText={props.placeholder}
						dateFormat="d MMM yyyy"
					/>
					{props.validator && (
						<span className="form-error">
							{props.validator.current.message(
								props.label,
								props.value,
								"required"
							)}
						</span>
					)}
					{/* <Label className="on-hover on-top">{props.label}</Label> */}
				</div>
			);
		case "PASSWORD":
			return (
				<div className="form-group">
					<Input
						type="password"
						autocomplete="new-password"
						id={props.id}
						name={props.name}
						onChange={(e) => props.onChange(e, props.setPath)}
						placeholder={props.placeholder}
						onKeyDown={props.onKeyDown}
					/>
					<label className="on-hover" htmlFor={props.id}>
						{/* {props.label} */}
					</label>
					{props.validator && (
						<span className="form-error">
							{props.validator.current.message(
								props.label,
								props.value,
								"required"
							)}
						</span>
					)}
				</div>
			);

		// case "INPUT_SELECT":
		// 	return (
		// 		<div className="form-group">
		// 			<Input
		// 				type="select"
		// 				multiple={props.isMulti}
		// 				name={props.name}
		// 				onChange={(e) => props.onChange(e, props.setPath)}
		// 			>
		// 				<option>Select {props.label}</option>
		// 				{props.options.map((items) => (
		// 					<option
		// 						value={items[props.valueInput]}
		// 						selected={
		// 							items[props.valueInput] === props.selectedItem ? true : false
		// 						}
		// 					>
		// 						{items[props.textInput]}
		// 					</option>
		// 				))}
		// 			</Input>
		// 			<Label className="on-hover">{props.label}</Label>
		// 			{props.validator && (
		// 				<span className="form-error">
		// 					{props.validator.current.message(
		// 						props.label,
		// 						props.value,
		// 						"required"
		// 					)}
		// 				</span>
		// 			)}
		// 		</div>
		// 	);

		// phone number with country flag
		case "INPUT_REACT_SELECT_COUNTYCODE":
			return (
				<div className="form-group form-react-select m-0">
					<Select
						type="select"
						name={props.name}
						onChange={(e) => props.onChange(e, props.setPath)}
						options={option}
						value={props.value}
						placeholder={getValue(props, `placeholder`, "Select")}
					></Select>
					{/* <Label className="on-hover on-top">
						{props.label?props.label:'Country Code'}
						{props.required ? <span className="form-imp">*</span> : ""}
					</Label> */}
					{props.validator && (
						<span className="form-error">
							{props.validator.current.message(
								props.label,
								props.value,
								"required"
							)}
						</span>
					)}
				</div>
			);
		case "INPUT_REACT_SELECT":
			return (
				<div className="form-group m-0">
					<Select
						name={props.name}
						onChange={(e) => props.onChange(e, props.setPath)}
						options={props.options}
						value={props.value}
						placeholder={getValue(props, `placeholder`, "Select")}
						isClearable={props.isClear ? props.isClear : false}
						isMulti={props.isMulti}
						className={props.inputSelect}
					></Select>
					{/* <Label className="on-hover on-top">
						{props.label}
						{props.required ? <span className="form-imp">*</span> : ""}
					</Label> */}
					{props.validator && (
						<span className="form-error">
							{props.validator.current.message(
								props.label,
								props.value,
								"required"
							)}
						</span>
					)}
				</div>
			);
		case "INPUT_REACT_SELECT_DOB":
			return (
				<div className="form-group">
					<Select
						name={props.name}
						onChange={(val, e) =>
							props.path
								? props.onChange(
									props.type,
									{ target: { ...e, ...val } },
									props.path,
									props.keyItem
								)
								: props.onChange(
									props.type,
									{ target: { ...e, ...val } },
									props.keyItem
								)
						}
						options={props.options}
						// value={getValue(props,`value.value`,'')?props.value:''}
						value={props.options.filter(
							(obj) => parseInt(obj.value) === parseInt(props.selectedOption)
						)}
						placeholder={props.selectName}
						selectedOption={props.selectedOption}
						classNamePrefix="fct"
						className="fct-select"
					></Select>
					<Label className="on-hover on-top">
						{props.label}
						{props.required ? <span className="form-imp">*</span> : ""}
					</Label>
				</div>
			);
		case "INPUT_REACT_SELECT_CSC":
			return (
				<div className="form-group">
					<Select
						name={props.name}
						onChange={(e) => props.onChange(e, props.setPath)}
						options={props.options}
						value={getValue(props, `value.value`, "") ? props.value : ""}
						placeholder="Select"
					></Select>
					<Label className="on-hover on-top">
						{props.label}
						{props.required ? <span className="form-imp">*</span> : ""}
					</Label>
					{props.validator && (
						<span className="form-error">
							{props.validator.current.message(
								props.label,
								props.value,
								"required"
							)}
						</span>
					)}
				</div>
			);

		case "CHECKBOX":
			return (
				<label className="checkbox-container">
					{props.label}
					<input
						type="checkbox"
						onChange={() => props.onChange(props.list)}
						value={props.value}
						checked={props.checked(props.list)}
					/>
					<span className="checkmark"></span>
				</label>
			);

		// calendar
		case "DATETYPE-1":
			return (
				<ReactDatePicker
					selected={props.selected}
					onChange={(date) => props.onChange(date)}
					isClearable
					minDate={props.minDate}
					maxDate={props.maxDate}
					inline
					selectsStart={props.selectsStart}
					selectsEnd={props.selectsEnd}
					startDate={props.startDate}
					endDate={props.endDate}
					monthsShown={2}
				/>
			);
		case "DATETYPE-2":
			return (
				<ReactDatePicker
					selected={props.selected}
					onChange={(date) => props.onChange(date)}
					isClearable
					inline
					minDate={props.minDate}
					maxDate={props.maxDate}
					selectsStart={props.selectsStart}
					selectsEnd={props.selectsEnd}
					startDate={props.startDate}
					endDate={props.endDate}
					monthsShown={1}
				/>
			);

		case "TIMETYPE-1":
			return (
				<DatePicker
					selected={startDate}
					onChange={(date) => setStartDate(date)}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={15}
					timeCaption="Time"
					inline
					dateFormat="h:mm aa"
				/>
			);

		case "DATETYPE-DOB":
			return (
				<div className="form-group react-datepicker-single">
					<DatePicker
						className="form-control"
						selected={props.selected}
						onChange={(date) => props.onChange(date)}
						peekNextMonth
						startDate={props.startDate}
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						maxDate={props.maxDate}
						minDate={props.minDate}
						placeholderText={props.placeholderText}
					// dateFormat="dd mmm yyyy"
					/>
					{props.validator && (
						<span className="form-error">
							{props.validator.current.message(
								props.label,
								props.value,
								"required"
							)}
						</span>
					)}
					<Label className="on-hover on-top">{props.label}</Label>
				</div>
			);

		// FOR REFERENCE LOOK profile.js
		case "TIME_ZONE":
			return (
				<div className="form-group">
					<TimezonePicker
						className="form-control-timezone w-100"
						id={props.id}
						name={props.name}
						onChange={(e) => props.onChange(e, props.setPath)}
						inputProps={props.inputProps}
						value={props.value}
						placeholder={props.placeholder}
					/>
					{/* <label className="on-hover on-top" htmlFor={props.id}>
						{props.label}
					</label> */}
					{props.validator && (
						<span className="form-error">
							{props.validator.current.message(
								props.label,
								props.value,
								"required"
							)}
						</span>
					)}
				</div>
			);

		default:
			return <h1>Input not valid</h1>;
	}
};
export default InputField;
