import './bulk-upload.scss';

export default function CheckPointExport(props: any) {
	return (
		<div className="add-filter-wrapper__filter-container">
			<div className="d-flex" onClick={props.handleExport}>
				<img
					// src="/images/export.svg"
					src="/images/download_sharp.svg"
					className="img-fluid add-filter-wrapper__upload-image"
				/>
			</div>
			<h6 className="add-filter-wrapper__filter-title">EXPORT</h6>
		</div>
	);
}
