import "./filter-form.scss";
// import AddFilterNew from "../AddFilterNew";
import InputField from "@_common/InputRuleFeild";
import SearchableDropdownSelect from "@_common/SearchableDropdown";
import { getValue } from "@utils/lodash";
import { Col, Row } from "reactstrap";

export default function FilterForm(props: any) {
	const {
		handleChangeSelect,
		countryList,
		states,
		categoryOfLawList,
		legislationList,
		ruleList,
		request,
		additionalFilter,
		smartFilterList,
		handleChangeSelectMulti,
		selectedSmartFilterIds,

		//loading...
		categoryLawLoading,
		legislationLoading,
		legislationRuleLoading,
		resetInput
	} = props;
	return (
		<div className="filter-form-wrappper w-100 active">
			<div className="filter-form-wrappper__container">
				<div className="d-flex align-items-center justify-content-between my-3">
					<h6 className="filter-form-wrappper__title">Filter by</h6>
					<img
						src="/images/close-black.svg"
						className="img-fluid cursor-pointer"
						onClick={props.setFilterFormVisibility}
					/>
				</div>
				<div className="d-flex justify-content-between gap">
					<div className="filter-form-wrappper__location flex-1">
						<div className="filter-form-wrappper__location-form-group form-group">
							<label htmlFor="country" className="filter-form-wrappper__label">
								Country
							</label>
							<div className="filter-form-wrappper__location-input">
								{/* <input
                  type="text"
                  placeholder="Choose Company Name"
                  id="country"
                  className="filter-form-wrappper__form-control form-control"
                />
                <img
                  src="/images/arrow-drop.svg"
                  className="img-fluid filter-form-wrappper__image"
                /> */}
								<InputField
									inputType="INPUT_REACT_SELECT"
									placeholder="Choose Country"
									className="form-control w-100"
									name="country_id"
									id="country_id"
									options={countryList}
									value={props.country_ID}
									onChange={(e: any) => handleChangeSelect(e, "country_id")}
									label="Country"
								/>
							</div>
						</div>
						<div className="filter-form-wrappper__location-form-group form-group">
							<label htmlFor="state" className="filter-form-wrappper__label">
								State
							</label>
							<div className="filter-form-wrappper__location-input">
								{/* <input
									type="text"
									placeholder="Choose State Name"
									id="state"
									className="filter-form-wrappper__form-control form-control"
								/>
								<img
									src="/images/arrow-drop.svg"
									className="img-fluid filter-form-wrappper__image"
								/> */}
								<InputField
									inputType="INPUT_REACT_SELECT"
									placeholder="Choose State"
									className="form-control w-100"
									name="state_id"
									id="state_id"
									label="Last Name"
									options={states}
									value={props.state_ID}
									onChange={(e: any) => handleChangeSelect(e, "state_id")}
								/>
							</div>
						</div>
					</div>
					<div className="filter-form-wrappper__location flex-2">
						<div className="filter-form-wrappper__location-form-group form-group">
							<label htmlFor="law" className="filter-form-wrappper__label">
								Category of Law
							</label>
							<div className="filter-form-wrappper__location-input">
								<SearchableDropdownSelect
									placeholder={"Choose Category of Law"}
									options={categoryOfLawList}
									searchLoading={categoryLawLoading}
									handleChange={(val: any) => props.getCategoryLawList(val)}
									handleClick={(e: any) =>
										props.handleChangeSelect(e, "category_of_law_id")
									}
									value={getValue(props, `cat_ID[${0}]`, {})}
									label="label"
									id="id"
									name="Category of Law"
									resetInput={resetInput}
								/>
							</div>
						</div>
						<div className="filter-form-wrappper__location-form-group form-group">
							<label
								htmlFor="legislation"
								className="filter-form-wrappper__label"
							>
								Legislation
							</label>
							<div className="filter-form-wrappper__location-input">
								<SearchableDropdownSelect
									placeholder={"Choose Legislation"}
									options={legislationList}
									searchLoading={legislationLoading}
									handleChange={(val: any) => props.getLegislationList(val)}
									handleClick={(e: any) =>
										props.handleChangeSelect(e, "legislation_id")
									}
									value={getValue(props, `leg_ID[${0}]`, {})}
									label="label"
									id="id"
									name="Legislation"
									resetInput={resetInput}
								/>
							</div>
						</div>
						<div className="filter-form-wrappper__location-form-group form-group">
							<label htmlFor="rule" className="filter-form-wrappper__label">
								Rule
							</label>
							<div className="filter-form-wrappper__location-input">
								<SearchableDropdownSelect
									placeholder={"Choose Legislation Rule"}
									options={ruleList}
									searchLoading={legislationRuleLoading}
									handleChange={(val: any) => props.getLegislationRuleList(val)}
									handleClick={(e: any) =>
										props.handleChangeSelect(e, "legislation_rule_id")
									}
									value={getValue(props, `rule_ID[${0}]`, {})}
									label="label"
									id="id"
									name="Legislation Rule"
									resetInput={resetInput}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{additionalFilter && (
				<div className="filter-form-wrappper__container">
					<h6 className="filter-form-wrappper__title py-2">
						Additional Filter
					</h6>
					<div className="filter-form-wrappper__location-input filter-form-wrappper__location-input--law">
						<Row>
							<Col md="4">
								<InputField
									inputType="INPUT_REACT_SELECT"
									placeholder="Choose Additional Filter"
									className="form-control"
									name="legislation_rule_id"
									id="legislation_rule_id"
									label="Legislation Rule ID"
									options={smartFilterList}
									value={selectedSmartFilterIds}
									onChange={(e: any) => handleChangeSelectMulti(e)}
									isMulti
								/>
							</Col>
						</Row>
					</div>
					<div className="filter-form-wrappper__cta-wrapper">
						{props.country_ID.length > 0 ? (
							<button
								className="filter-form-wrappper__cta-btn filter-form-wrappper__cta-btn--search"
								onClick={props.handleSubmitSmartFilter}
							>
								Search
							</button>
						) : (
							<button
								className="filter-form-wrappper__cta-btn filter-form-wrappper__cta-btn--disable"
								onClick={props.handleSubmitSmartFilter}
							>
								Search
							</button>
						)}
						<button
							className="filter-form-wrappper__cta-btn filter-form-wrappper__cta-btn--reset"
							onClick={props.reset}
						>
							Reset
						</button>
					</div>
					{/* <div className="filter-form-wrappper__location my-2">
						<div className="filter-form-wrappper__location-form-group form-group">
							<label htmlFor="country" className="filter-form-wrappper__label">
								Tags
							</label>
							<div className="filter-form-wrappper__location-input">
								<input
									type="text"
									placeholder="Choose Tags"
									id="country"
									className="filter-form-wrappper__form-control form-control"
								/>
								<img
									src="/images/arrow-drop.svg"
									className="img-fluid filter-form-wrappper__image"
								/>
							</div>
						</div>
					</div> */}
				</div>
			)}

			{/* <AddFilterNew /> */}
		</div>
	);
}
