import { config } from "../env";
import {
  getClient,
  patchClient,
  postClient,
} from "./helpers/client-http-handler";
import {
  Delete,
  DeleteWithPayload,
  get,
  patch,
  post,
} from "./helpers/http-handler";

export const adminCreateOrganization = (payload: object) =>
  post(`${config.API_URL}/organisation`, payload);

export const adminGetOrganization = (queryRequest: string) =>
  get(`${config.API_URL}/organisation?${queryRequest}`);

export const adminGetSpecificOrganization = (id: string) =>
  get(`${config.API_URL}/organisation/${id}`);

export const adminUpdateSpecificOrganization = (id: string, payload: object) =>
  patch(`${config.API_URL}/organisation/${id}`, payload);

export const adminDeleteSpecificOrganization = (id: string) =>
  Delete(`${config.API_URL}/organisation/${id}`);

export const adminUpdateBulkStatusOrganization = (payload: object) =>
  patch(`${config.API_URL}/organisation/bulk/active-inactive`, payload);

// client users
export const adminGetClientUsers = (domain: string, queryRequest: string) =>
  getClient(`${config.CLIENT_API_URL}/users?${queryRequest}`, domain);

export const adminGetClientUsersNew = (domain: string, queryRequest: string) =>
  getClient(`${config.API_URL}/users/client/users?${queryRequest}`, domain);

export const adminGetClientSpecificUser = (id: string, domain: string) =>
  get(`${config.API_URL}/users/${id}/${domain}/client/user`);

export const adminCreateClientUsers = (payload: object) =>
  post(`${config.API_URL}/users/client/user`, payload);

export const adminUpdateClientUsers = (id: string, payload: object) =>
  patch(`${config.API_URL}/users/${id}/client/user`, payload);

export const adminUpdateClientUsersNew = (
  domain: string,
  id: string,
  payload: object
) =>
  patchClient(
    `${config.CLIENT_API_URL}/users/${id}/client/user`,
    payload,
    domain
  );

export const adminResendInviteClientUsers = (payload: object) =>
  patch(`${config.API_URL}/users/bulk/resend-invite`, payload);

// checkpoint master

export const adminGetMasterCheckedPoints = (payload: object) =>
  post(`${config.API_URL}/checkpoint/client/master`, payload);

export const adminGetMappedCheckedPoints = (payload: object) =>
  post(`${config.API_URL}/checkpoint/client/mapped`, payload);

export const adminAssignCheckedPoints = (payload: object) =>
  post(`${config.API_URL}/checkpoint/assign/client/master`, payload);

export const adminUnAssignCheckedPoints = (payload: object) =>
  post(`${config.API_URL}/checkpoint/remove/client/master`, payload);

// permission apis

export const adminSubscription = (queryRequest: string) =>
  get(`${config.API_URL}/organisation/product/subscription?${queryRequest}`);

export const adminCreateSubscription = (payload: object) =>
  post(`${config.API_URL}/organisation/product/subscription`, payload);

export const adminDelateSubscription = (code: string, payload: object) =>
  DeleteWithPayload(
    `${config.API_URL}/organisation/product/subscription/${code}`,
    payload
  );

//new subscription APIs
export const getAllSubscriptions = (queryRequest: string) =>
  get(`${config.API_URL}/subscriptions?${queryRequest}`);

export const attachProductSubscription = (id: string, payload: object) =>
  patch(
    `${config.API_URL}/organisation/${id}/product/subscription/attach`,
    payload
  );

export const getAllAttachedSubscriptions = (id: string, queryRequest: string) =>
  get(
    `${config.API_URL}/organisation/${id}/attachedSubscription?${queryRequest}`
  );

export const removeSubscription = (id: string, payload: object) =>
  DeleteWithPayload(
    `${config.API_URL}/organisation/${id}/subscription/remove`,
    payload
  );


  export const getOrgRootDomain = (id: string) =>
  get(`${config.API_URL}/organisation/client/root-domain`);