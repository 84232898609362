import { authHeader } from "./auth-header";
import { authHeaderOnlyToken } from "./auth-header-collector";

import { toast } from "react-toastify";
import { getValue } from "@utils/lodash";
import { config } from "../../env";

let access_token = localStorage.getItem("accessToken");

export const post = (url, payload) => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const postFileUpload = (url, payload) => {
  const requestOptions = {
    method: "POST",
    headers: { Authorization: "Bearer " + access_token },
    body: payload,
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const patchFileUpload = (url, payload) => {
  const requestOptions = {
    method: "PATCH",
    headers: { Authorization: "Bearer " + access_token },
    body: payload,
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const patch = (url, payload) => {
  const requestOptions = {
    method: "PATCH",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };

  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const put = (url, payload) => {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const Delete = (url) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(url),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const DeleteWithPayload = (url, payload) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

export const get = (url) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: authHeader(url),
    };
    return fetch(`${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  } catch (error) {}
};

export const getPayload = (url, payload) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(url),
    body: JSON.stringify(payload),
  };
  return fetch(`${url}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
};

function logout() {
  localStorage.clear();
}

export const getOnlyToken = (url) => {
  try {
    const requestOptions = {
      method: "GET",
      headers: authHeaderOnlyToken(url),
    };
    return fetch(`${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  } catch (error) {}
};

export const postOnlyToken = (url, payload) => {
  try {
    const requestOptions = {
      method: "POST",
      headers: authHeaderOnlyToken(url),
      body: JSON.stringify(payload),
    };
    return fetch(`${url}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  } catch (error) {}
};

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);
//     if (!response.ok) {
//       if (response.status === 401) {
//         // auto logout if 401 response returned from api
//         logout();
//         window.location.href = (`/login`)
//       }
//       const error = (data && data.message) || response.statusText;

//       // toast.error(error, { className: 'red-circle' });
//       if (typeof data.message === "string") {
// 				const error = (data && data.message) || response.statusText;
// 				toast.error(error);
// 			} else {
// 				getValue(data, `message`, []).forEach((item) => {
// 					toast.error(item);
// 				});
// 			}
//       return Promise.reject(error);
//     }

//     return data;
//   });
// }
function handleResponse(response) {
  const handleOnClick = async () => {
    // if (window.confirm("Would you like to continue the session?")) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        source: "accenture.com",
      },
      body: JSON.stringify({
        refresh_token: getValue(localStorage, `refreshToken`, ""),
      }),
    };
    return fetch(`${config.API_URL}/auth/user/refresh`, requestOptions).then(
      (data) => {
        data.text().then((text) => {
          // const data = text && JSON.parse(text);
          if (!data.ok) {
            if (data.status === 401) {
              toast.error("Unauthorized");
              logout();
              window.location.href = `/login`;
            }
          } else {
            let resp = JSON.parse(text);
            localStorage.setItem(
              "accessToken",
              getValue(resp, `data.accessToken`, "")
            );
            localStorage.setItem(
              "refreshToken",
              getValue(resp, `data.refreshToken`, "")
            );
            localStorage.setItem(
              "user",
              JSON.stringify(getValue(resp, `data.user`, ""))
            );
            window.location.reload();
          }
        });
      }
    );
    // } else {
    // 	logout();
    // 	window.location.href = `/login`;
    // }
  };

  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (getValue(data, `statusCode`, 0) === 401) {
      if (getValue(localStorage, `refreshToken`, "")) {
        handleOnClick();
      } else {
        logout();
        window.location.href = `/login`;
      }
    } else {
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          if (getValue(localStorage, `refreshToken`, "")) {
            handleOnClick();
          } else {
            logout();
            window.location.href = `/login`;
          }
        }
        const error = (data && data.message) || response.statusText;
        if (
          typeof data.message === "string" &&
          data.message !== "Unauthorized"
        ) {
          const error = (data && data.message) || response.statusText;
          toast.error(error);
        } else {
          getValue(data, `message`, []).forEach((item) => {
            toast.error(item);
          });
        }
        return Promise.reject(error);
      }
      return data;
    }
  });
}
